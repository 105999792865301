import AppSettings from '../AppSettings';
// Librairies
import Axios from 'axios';
import Cookies from 'universal-cookie';
// Services
import { showToast } from '../utils/ToastsUtil';
import OfflineUtil from '../utils/OfflineUtil';
import i18n from '../locales/i18n';
import DatesUtil from '../utils/DatesUtil';

const BACKEND_ENDPOINT = AppSettings.getBackendUrl();

export default class OrganizationsService {
    static getUserOrganizations() {
        return Axios.get(BACKEND_ENDPOINT + 'organizations/', {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            if (response.data) {
                const { activeOrganization, linkedUsers, invitations, organizations } = response.data;
                return { activeOrganization: { ...activeOrganization, linkedUsers, invitations }, organizations };
            }
        }, () => {
            showToast('connection_failed');
            return;
        });
    }

    static updateActiveOrganization(organizationId) {
        return Axios.put(BACKEND_ENDPOINT + 'organizations/' + organizationId, null, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response;
        }, () => {
            if (navigator.onLine) showToast('active_organization_update_failed');
            return;
        });
    }

    static getBillingAddress() {
        return Axios.get(BACKEND_ENDPOINT + 'organizations/billingAddress', {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('connection_failed');
            return;
        });
    }

    static updateBillingAddress(billingAddress) {
        return Axios.put(BACKEND_ENDPOINT + 'organizations/billingAddress', billingAddress, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response;
        }, () => {
            if (navigator.onLine) showToast('billing_address_update_failed');
            return;
        });
    }

    static addUsersToOrganization(usersToAdd, organizationId) {
        return Axios.post(BACKEND_ENDPOINT + 'organizations/users/' + organizationId, usersToAdd, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            if (response?.data?.addedUsers?.length) {
                OfflineUtil.modifyDynamicCache(BACKEND_ENDPOINT + 'organizations/', (body) => {
                    body.linkedUsers.push(...response.data.addedUsers);
                    return body;
                });
                showToast('users_added');
            }
            if (response?.data?.invitations?.length) showToast('users_invited');

            return response.data;
        }, (response) => {
            showToast('share_users_addition_failed');
            return response?.response?.data;
        });
    }

    static removeUserFromOrganization(userToRemoveId, isLeaving) {
        OfflineUtil.modifyDynamicCache(BACKEND_ENDPOINT + 'organizations/', (body) => {
            body.linkedUsers = body.linkedUsers.filter(user => user.id !== userToRemoveId);
            return body;
        });

        return Axios.delete(BACKEND_ENDPOINT + 'organizations/users/' + userToRemoveId, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            if (navigator.onLine)
                if (isLeaving) showToast('organization_left');
                else showToast('user_removed');
            return response.data;
        }, () => {
            if (isLeaving) showToast('organization_leaving_failed');
            else showToast('organization_user_removing_failed');
            return;
        });
    }

    static removeInvitationFromOrganization(invitationId) {
        OfflineUtil.modifyDynamicCache(BACKEND_ENDPOINT + 'organizations/', (body) => {
            body.invitations = body.invitations.filter(i => i.id !== invitationId);
            return body;
        });

        return Axios.delete(BACKEND_ENDPOINT + 'organizations/invitations/' + invitationId, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('user_removed');
            return response;
        }, () => {
            showToast('organization_user_removing_failed');
            return;
        });
    }

    static updateOrganization(organizationId, formData) {
        return Axios.put(BACKEND_ENDPOINT + 'organizations/' + organizationId, formData, {
            headers: {
                'Authorization': `Bearer ${new Cookies().get('token')}`,
                'content-type': 'multipart/form-data'
            }
        }).then(response => {
            showToast('organization_updated');
            return response?.data;
        }, () => {
            showToast('organization_update_failed');
        });
    }

    static getFreeOrganizations() {
        return Axios.get(BACKEND_ENDPOINT + 'admin/organizations/free/', {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('organizations_loading_failed');
            return;
        });
    }

    static getOrganizationRows() {
        return Axios.get(BACKEND_ENDPOINT + 'admin/organizations/rows/', {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('organizations_loading_failed');
            return;
        });
    }

    static updateOrganizations(organizationsToModify) {
        return Axios.put(BACKEND_ENDPOINT + 'admin/organizations/', organizationsToModify, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('organizations_updated');
            return response.status;
        }, () => {
            if (navigator.onLine) showToast('organizations_update_failed');
            return;
        });
    }

    static exportOrganizationsAsExcel(elements) {
        return Axios.post(BACKEND_ENDPOINT + 'admin/organizations/exportXLSX/', elements, {
            responseType: 'blob',
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${i18n.t("Organisations")} - ${DatesUtil.getFormattedLocaleDateString(new Date().toISOString())}.xlsx`);
            document.getElementById('admin-panel__grid').appendChild(link);
            link.click();
            document.getElementById('admin-panel__grid').removeChild(link);
        }, () => {
            if (navigator.onLine) showToast('organizations_xlsx_export_failed');
        });
    }

    static transferOrganizationData(transferData, isAdmin = false) {
        return Axios.post(BACKEND_ENDPOINT + (isAdmin ? 'admin/' : '') + 'organizations/transfer/', transferData, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('data_transferred');
            return response.status;
        }, () => {
            if (navigator.onLine) showToast('data_transfer_failed');
        });
    }
}