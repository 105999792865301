// Librairies
import { v4 as uuidv4 } from 'uuid';
import { addDays } from 'date-fns';
import i18n from '../locales/i18n';
// Utils
import DatesUtil from './DatesUtil';
import TreesUtil from './TreesUtil';
import { faCheck, faEdit, faPlus, faQuestion, faTimes, faTrash } from '@fortawesome/pro-solid-svg-icons';

export default class ActionsUtil {
    static createOfflineRecurrences(projectAction) {
        let paerList = [];
        const startDate = DatesUtil.convertUTCDateToDate(projectAction.startDate);
        const endDate = DatesUtil.convertUTCDateToDate(projectAction.endDate);
        const recurrenceSplit = projectAction.recurrence.split(' - ');
        const recurrenceValue = parseInt(recurrenceSplit[0]);
        const recurrenceUnit = recurrenceSplit[1];
        let tempDate = startDate;

        switch (recurrenceUnit) {
            case 'Jours':
                while (tempDate <= endDate) {
                    paerList.push({ id: uuidv4(), date: tempDate, comment: '', isDone: false });
                    tempDate = new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate() + recurrenceValue, tempDate.getHours(), tempDate.getMinutes(), tempDate.getSeconds(), tempDate.getMilliseconds());
                }
                break;
            case 'Semaines':
                while (tempDate <= endDate) {
                    paerList.push({ id: uuidv4(), date: tempDate, comment: '', isDone: false });
                    tempDate = new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate() + recurrenceValue * 7, tempDate.getHours(), tempDate.getMinutes(), tempDate.getSeconds(), tempDate.getMilliseconds());
                }
                break;
            case 'Mois':
                while (tempDate <= endDate) {
                    paerList.push({ id: uuidv4(), date: tempDate, comment: '', isDone: false });
                    tempDate = new Date(tempDate.getFullYear(), tempDate.getMonth() + recurrenceValue, tempDate.getDate(), tempDate.getHours(), tempDate.getMinutes(), tempDate.getSeconds(), tempDate.getMilliseconds());
                }
                break;
            case 'Années':
                while (tempDate <= endDate) {
                    paerList.push({ id: uuidv4(), date: tempDate, comment: '', isDone: false });
                    tempDate = new Date(tempDate.getFullYear() + recurrenceValue, tempDate.getMonth(), tempDate.getDate(), tempDate.getHours(), tempDate.getMinutes(), tempDate.getSeconds(), tempDate.getMilliseconds());
                }
                break;
            default: break;
        }

        return paerList;
    }

    static getRecurrencesInfos(projectAction) {
        const startDate = DatesUtil.convertUTCDateToDate(projectAction.startDate);
        const endDate = DatesUtil.convertUTCDateToDate(projectAction.endDate);
        if (!projectAction.recurrence) return i18n.t("Aucune");
        const recurrenceSplit = projectAction.recurrence.split(' - ');
        const recurrenceValue = parseInt(recurrenceSplit[0]);
        const recurrenceUnit = recurrenceSplit[1];
        let recurrenceString; let nbRecurrences = 0;
        let tempDate = startDate;

        switch (recurrenceUnit) {
            case 'Jours':
                if (recurrenceValue === 1) recurrenceString = i18n.t("Tous les jours");
                else recurrenceString = i18n.t("Tous les {{recurrence}} jours", { recurrence: recurrenceValue });
                // Calcul du nombre de récurrences totales dans le temps
                while (tempDate <= endDate) {
                    nbRecurrences++;
                    tempDate.setDate(tempDate.getDate() + recurrenceValue);
                }
                break;
            case 'Semaines':
                if (recurrenceValue === 1) recurrenceString = i18n.t("Toutes les semaines");
                else recurrenceString = i18n.t("Tous les {{recurrence}} semaines", { recurrence: recurrenceValue });
                // Calcul du nombre de récurrences totales dans le temps
                while (tempDate <= endDate) {
                    nbRecurrences++;
                    tempDate.setDate(tempDate.getDate() + recurrenceValue * 7);
                }
                break;
            case 'Mois':
                if (recurrenceValue === 1) recurrenceString = i18n.t("Tous les mois");
                else recurrenceString = i18n.t("Tous les {{recurrence}} mois", { recurrence: recurrenceValue });
                // Calcul du nombre de récurrences totales dans le temps
                while (tempDate <= endDate) {
                    nbRecurrences++;
                    tempDate = DatesUtil.addMonths(tempDate, recurrenceValue);
                }
                break;
            case 'Années':
                if (recurrenceValue === 1) recurrenceString = i18n.t("Tous les ans");
                else recurrenceString = i18n.t("Tous les {{recurrence}} ans", { recurrence: recurrenceValue });
                // Calcul du nombre de récurrences totales dans le temps
                while (tempDate <= endDate) {
                    nbRecurrences++;
                    tempDate.setFullYear(tempDate.getFullYear() + recurrenceValue);
                }
                break;
            default: break;
        }

        return recurrenceString + ' (' + nbRecurrences + 'x)';
    }

    static getCurrentRecurrenceIndex(projectActionElementRecurrences) {
        let currentRecurrenceIndex = 0, recurrences;
        const today = DatesUtil.getToday();

        // Récurrence en retard, en cours ou à venir non validée (max 7 jours en avance)
        recurrences = projectActionElementRecurrences.filter(x => DatesUtil.daysBetweenTwoDates(DatesUtil.convertUTCDateToDate(x.date), today) < 7);
        if (recurrences) {
            currentRecurrenceIndex = recurrences.findIndex(x => !x.isDone && DatesUtil.daysBetweenTwoDates(DatesUtil.convertUTCDateToDate(x.date), today) < 0); // En retard
            if (currentRecurrenceIndex === -1) currentRecurrenceIndex = recurrences.findIndex(x => !x.isDone && DatesUtil.daysBetweenTwoDates(DatesUtil.convertUTCDateToDate(x.date), today) >= 0); // A venir
            if (currentRecurrenceIndex === -1) { // Dernière validée
                const recurrence = recurrences.reverse().find(x => x.isDone);
                currentRecurrenceIndex = projectActionElementRecurrences.findIndex(x => x === recurrence);
            }
        }

        return currentRecurrenceIndex > -1 ? currentRecurrenceIndex : 0;
    }

    static getRecurrenceIcon({ paer = null, currentRecurrenceIndex = null, index = null } = {}) {
        const paerDate = DatesUtil.convertUTCDateToDate(paer.date);
        const nbDays = DatesUtil.daysBetweenTwoDates(paerDate, new Date());
        const icon = paer.isDone ? { name: 'check', color: 'green', status: i18n.t("Validée") } : // Récurrence validée
            nbDays === 0 && currentRecurrenceIndex === index ? { name: 'circle', color: 'yellow', status: i18n.t("En cours") } : // Récurrence en cours, mais non validée
                nbDays < 0 ? { name: 'warning sign', color: 'red', status: i18n.t("En retard") } : // Récurrence passée, mais non validée validée
                    { name: 'clock', color: 'grey', status: i18n.t("À venir") } // Futures récurrences non validées
        return icon;
    }

    static isRecurrenceBetweenTwoDates(paer, date1, date2) {
        date1 = date1 ? new Date(date1) : null;
        date2 = date2 ? new Date(date2) : null;
        if (!date1 && !date2) return true;
        else {
            let res;
            const paerDate = DatesUtil.convertUTCDateToDate(paer.date);
            if (date1 && date2) {
                const res1 = DatesUtil.daysBetweenTwoDates(paerDate, date1);
                const res2 = DatesUtil.daysBetweenTwoDates(paerDate, date2);
                return res1 >= 0 && res2 <= 0;
            } else if (date1) {
                res = DatesUtil.daysBetweenTwoDates(paerDate, date1);
                return res >= 0;
            } else if (date2) {
                res = DatesUtil.daysBetweenTwoDates(paerDate, date2);
                return res <= 0;
            } else return true;
        }
    }

    static getTreeActionPrice(height, action, customPrice, { isStump, stumpDiameter } = {}) {
        if ((customPrice ? customPrice.isPriceFixed : action.isPriceFixed) || (isStump ? stumpDiameter?.id === 1 : (height > 0 && height <= 5)))
            return customPrice ? customPrice.price1 : action.price1;
        if (isStump ? stumpDiameter?.id === 2 : (height > 5 && height <= 10)) return customPrice ? customPrice.price2 : action.price2;
        if (isStump ? stumpDiameter?.id === 3 : (height > 10 && height <= 15)) return customPrice ? customPrice.price3 : action.price3;
        if (isStump ? stumpDiameter?.id === 4 : (height > 15 && height <= 20)) return customPrice ? customPrice.price4 : action.price4;
        if (isStump ? stumpDiameter?.id === 5 : (height > 20 && height <= 25)) return customPrice ? customPrice.price5 : action.price5;
        if (isStump ? stumpDiameter?.id === 6 : height > 25) return customPrice ? customPrice.price6 : action.price6;
        return 0;
    }

    static getActionPrice(action, feature, priceList = null) {
        let price = 0;
        const customPrice = priceList?.prices?.find(price => price.actionId === action.id);
        switch (feature.properties.category) {
            case 'Arbre':
                const biggestTrunk = TreesUtil.getBiggestTrunk(feature.properties.trunks);
                const height = biggestTrunk ? biggestTrunk.height / 100 : 0;
                const isStump = feature?.properties.isStump;
                const stumpDiameter = isStump && TreesUtil.getStumpDiameterByCircumference(biggestTrunk ? biggestTrunk.circumference : 0);
                price = this.getTreeActionPrice(height, action, customPrice, { isStump, stumpDiameter });
                break;
            case 'Espace vert':
                const { calculationUnit, price1 } = customPrice || action;
                price = feature.properties.dominantCompositionId === 7 && action.categories.includes('Arbre')
                    ? this.getTreeActionPrice(feature.properties.averageHeight / 100, action, customPrice) * feature.properties.nbTrees
                    : (calculationUnit === 'unit' ? price1 : feature.properties.surface * price1);
            case 'Mobilier': break;
            default: break;
        }

        return price;
    }

    static pushProjectActionElements(projectAction, projectActions, override = false) {
        projectActions = JSON.parse(JSON.stringify(projectActions));
        let pa = projectActions.find(x => x.id === projectAction.id);
        if (pa && !override) {
            projectAction.projectActionElements.forEach(pae => {
                const index = pa.projectActionElements.findIndex(x => x.elementId === pae.elementId);
                if (index === -1) pa.projectActionElements.push(pae);
                else if (pae.projectActionElementRecurrences) pa.projectActionElements[index] = pae;
            });
        } else if (pa && override) pa.projectActionElements = projectAction.projectActionElements;
        else projectActions.push(projectAction);
        return projectActions;
    }

    static getActionUrgency(actionsUrgency, projectAction, projectActionElementRecurrences) {
        if (actionsUrgency < 4 && projectActionElementRecurrences.find(paer => !paer.isDone && DatesUtil.convertUTCDateToDate(paer.date) < DatesUtil.getToday()))
            return 4;
        if (actionsUrgency < 3 && projectAction.isUrgent)
            return 3;
        if (actionsUrgency < 2 && projectActionElementRecurrences.find(paer => !paer.isDone && DatesUtil.convertUTCDateToDate(paer.date) < addDays(DatesUtil.getToday(), 30)))
            return 2;
        if (actionsUrgency < 1 && projectActionElementRecurrences.filter(paer => !paer.isDone && DatesUtil.convertUTCDateToDate(paer.date) >= DatesUtil.getToday()).length > 0)
            return 1;
        return actionsUrgency;
    }

    static getElementActionsUrgency(elementId, projectActions, category) {
        let actionsUrgency = 0;
        projectActions.filter(pa => pa.action.categories.includes(category)).forEach(pa => { // Pour chacune des actions du projet
            const pae = pa.projectActionElements.find(pae => pae.elementId === elementId);
            if (pae) actionsUrgency = ActionsUtil.getActionUrgency(actionsUrgency, pa, pae.projectActionElementRecurrences);
        });
        return actionsUrgency;
    }

    static getActionHistoryIcon(type) {
        return {
            action_linked: faPlus,
            action_unlinked: faTrash,
            recurrence_validation: faCheck,
            recurrence_invalidation: faTimes,
            validation_date_update: faEdit,
            comment_addition: faPlus,
            comment_update: faEdit
        }[type] || faQuestion;
    }
}