import AppSettings from '../AppSettings';
// Librairies
import Axios from 'axios';
import Cookies from 'universal-cookie';
import jwt_decode from 'jwt-decode';
// Services
import { showToast, showLoadingToast } from '../utils/ToastsUtil';
import OfflineUtil from '../utils/OfflineUtil';
import i18n from '../locales/i18n';

const BACKEND_ENDPOINT = AppSettings.getBackendUrl();

export default class ProjectsService {
    static addFolder(newFolder) {
        return Axios.post(BACKEND_ENDPOINT + 'projects/folders/', newFolder, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            let folder = JSON.parse(JSON.stringify(response.data.folder));
            //TODO N'est plus bon car on ne renvoie pas forcément de userBaseProjects même en online
            // if (!folder.userBaseProjects) { // Dans le cas où on crée le dossier en offline
            //     folder = { // On attribue les droits à l'utilisateur
            //         ...folder,
            //         type: 'folder',
            //         date: new Date().toISOString().split('T')[0] + ' ' + new Date().toISOString().split('T')[1].slice(0, -1),
            //         childFolders: [],
            //         projects: [],
            //         userBaseProjects: !folder.path &&
            //             [{
            //                 user: {
            //                     id: jwt_decode(new Cookies().get('token')).id,
            //                     lastName: jwt_decode(new Cookies().get('token')).lastName,
            //                     firstName: jwt_decode(new Cookies().get('token')).firstName,
            //                     // subscriptions: [userSubscription]
            //                 },
            //                 isOwner: true,
            //                 write: true,
            //                 import: true,
            //                 export: true,
            //                 actionsManagement: true,
            //                 projectModification: true,
            //                 isManager: true
            //             }]
            //     }
            // }

            return OfflineUtil.modifyDynamicCache(BACKEND_ENDPOINT + 'projects/userBaseProjects/' + jwt_decode(new Cookies().get('token')).id, (body) => {
                body.push(folder);
                return body;
            }).then(() => {
                return { ...response.data, folder };
            });
        }, () => {
            showToast('connection_failed');
            return;
        });
    }

    static updateFolder(folder, ownerId) {
        return Axios.put(BACKEND_ENDPOINT + 'projects/folders/', { folder, ownerId }, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then((response) => {
            showToast('folder_updated');
            return response.data;
        }, () => {
            showToast('folder_update_failed');
            return;
        });
    }

    static addProject(newProject) {
        return Axios.post(BACKEND_ENDPOINT + 'projects/', newProject, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            let project = response.data?.project;
            //TODO N'est plus bon car on ne renvoie pas forcément de userBaseProjects même en online
            // if (!project.userBaseProjects) { // Dans le cas où on crée le projet en offline
            //     project = { // On attribue les droits à l'utilisateur
            //         ...project,
            //         date: new Date().toISOString().split('T')[0] + ' ' + new Date().toISOString().split('T')[1].slice(0, -1),
            //         isPublic: false,
            //         requiredFields: project.requiredFields,
            //         userBaseProjects: [
            //             {
            //                 user: {
            //                     id: jwt_decode(new Cookies().get('token')).id,
            //                     lastName: jwt_decode(new Cookies().get('token')).lastName,
            //                     firstName: jwt_decode(new Cookies().get('token')).firstName,
            //                     subscriptions: [userSubscription]
            //                 },
            //                 isOwner: true,
            //                 write: true,
            //                 import: true,
            //                 export: true,
            //                 actionsManagement: true,
            //                 projectModification: true,
            //                 isManager: true
            //             }
            //         ]
            //     }
            // }

            return OfflineUtil.modifyDynamicCache(BACKEND_ENDPOINT + 'projects/userBaseProjects/' + jwt_decode(new Cookies().get('token')).id, (body) => {
                body.push(project);
                return body;
            }).then(() => {
                showToast('project_added');
                return response.data || {};
            });
        }, () => {
            showToast('connection_failed');
            return;
        });
    }

    static updateBaseProjects(baseProjects) {
        return Axios.put(BACKEND_ENDPOINT + 'admin/projects/', baseProjects, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('base_projects_updated');
            return response.status;
        }, () => {
            showToast('base_projects_update_failed');
            return;
        });
    }

    static joinProject(baseProjectId) {
        return Axios.post(BACKEND_ENDPOINT + 'admin/projects/', baseProjectId, {
            headers: {
                'Authorization': `Bearer ${new Cookies().get('token')}`,
                'Content-Type': 'application/json'
            },
        }).then(response => {
            return response.data;
        }, () => {
            return;
        });
    }

    static addCollaborators(data) {
        return Axios.post(BACKEND_ENDPOINT + 'projects/collaborators/', data, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('collaborators_added');
            return response.data;
        }, () => {
            showToast('collaborators_addition_failed');
            return;
        });
    }

    static addCollaboratorWithInvitation(invitationId, userId) {
        return Axios.post(BACKEND_ENDPOINT + 'projects/collaborators/withInvitation/?invitationId=' + invitationId + '&userId=' + userId).then(response => {
            showToast('collaborator_added');
            return response.data;
        }, error => {
            showToast('connection_failed');
            return;
        });
    }

    static removeCollaborator(projectId, collaboratorId) {
        OfflineUtil.modifyDynamicCache(BACKEND_ENDPOINT + 'projects/userBaseProjects/' + jwt_decode(new Cookies().get('token')).id, (body) => {
            const index = body.findIndex(p => p.id === projectId);
            body[index].userBaseProjects = body[index].userBaseProjects.filter(up => up.user.id !== collaboratorId);
            return body;
        });

        return Axios.delete(BACKEND_ENDPOINT + 'projects/collaborators/?baseProjectId=' + projectId + '&collaboratorId=' + collaboratorId, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('collaborator_removed');
            return response;
        }, () => {
            showToast('connection_failed');
            return;
        });
    }

    static sendInvitation(projectId, email) {
        return Axios.get(`${BACKEND_ENDPOINT}projects/collaborators/sendInvitation/${projectId}/${email}`, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('invitation_sent');
            return response.data;
        }, () => {
            showToast('connection_failed');
            return;
        });
    }

    static removeInvitation(projectId, email) {
        return Axios.delete(BACKEND_ENDPOINT + 'projects/invitations/?baseProjectId=' + projectId + '&email=' + email, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('invitation_removed');
            return response;
        }, () => {
            showToast('connection_failed');
            return;
        });
    }

    static getUserBaseProjects() {
        return Axios.get(BACKEND_ENDPOINT + 'projects/userBaseProjects/' + jwt_decode(new Cookies().get('token')).id, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, () => {
            showToast('projects_loading_failed');
            return;
        });
    }

    static getFolderContent(folderId) {
        return Axios.get(BACKEND_ENDPOINT + 'projects/folders/' + folderId, {
            headers: { 'Authorization': new Cookies().get('token') ? `Bearer ${new Cookies().get('token')}` : '' }
        }).then(response => {
            return response.data;
        }, () => {
            showToast('folder_content_loading_failed');
            return;
        });
    }

    static getAllProjects() {
        return Axios.get(BACKEND_ENDPOINT + 'admin/projects/', {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, () => {
            showToast('projects_loading_failed');
            return;
        });
    }

    static getProject(id) {
        return Axios.get(BACKEND_ENDPOINT + 'projects/' + id, {
            headers: { 'Authorization': new Cookies().get('token') ? `Bearer ${new Cookies().get('token')}` : '' }
        }).then(response => {
            return response.data;
        }, (error) => {
            if (!new Cookies().get('token') && error?.response?.status === 401) showToast('project_login_required');
            else showToast('project_loading_failed');
            return;
        });
    }

    static getOrganizationBaseProjects() {
        return Axios.get(BACKEND_ENDPOINT + 'projects/organizationBaseProjects', {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, error => {
            return;
        });
    }

    static getFolderElements(folderId) {
        return Axios.get(BACKEND_ENDPOINT + 'projects/folders/elements/' + folderId, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, () => {
            showToast('projects_loading_failed');
            return;
        });
    }

    static updateProject(project, removedArea, ownerId) {
        OfflineUtil.modifyDynamicCache(BACKEND_ENDPOINT + 'projects/userBaseProjects/' + jwt_decode(new Cookies().get('token')).id, (body) => {
            const index = body.findIndex(el => el.id === project.id);
            body[index] = project;
            return body;
        });

        const promise = Axios.put(BACKEND_ENDPOINT + 'projects/', { project, removedArea, ownerId }, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        });

        showLoadingToast('project_updating', 'project_updated', 'project_update_failed', promise);
        return promise;
    }

    static updateNotifications(rootFolderId, baseProject, recurrence) {
        OfflineUtil.modifyDynamicCache(BACKEND_ENDPOINT + 'projects/userBaseProjects/' + jwt_decode(new Cookies().get('token')).id, (body) => {
            const index = body.findIndex(p => p.id === (rootFolderId || baseProject.id));
            const userBaseProject = body[index].userBaseProjects.find(x => x.user.id === jwt_decode(new Cookies().get('token')).id);
            if (userBaseProject) {
                if (recurrence !== 'none') {
                    if (!userBaseProject.alertingBaseProjects) userBaseProject.alertingBaseProjects = [];
                    userBaseProject.alertingBaseProjects.push({
                        userBaseProjectUserId: jwt_decode(new Cookies().get('token')).id,
                        userBaseProjectBaseProjectId: rootFolderId || baseProject.id,
                        baseProjectId: baseProject.id,
                        recurrence
                    });
                } else if (userBaseProject.alertingBaseProjects)
                    userBaseProject.alertingBaseProjects = userBaseProject.alertingBaseProjects.filter(abp => abp.baseProjectId !== baseProject.id);
            }
            return body;
        });

        return Axios.put(BACKEND_ENDPOINT + `projects/notifications/?baseProjectId=${baseProject.id}&recurrence=${recurrence}`, null, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('notifications_status_updated');
            return response.status;
        }, () => {
            showToast('notifications_status_update_failed');
            return;
        });
    }

    static toggleFavorite(rootFolderId, baseProject, state) {
        OfflineUtil.modifyDynamicCache(BACKEND_ENDPOINT + 'projects/userBaseProjects/' + jwt_decode(new Cookies().get('token')).id, (body) => {
            const index = body.findIndex(p => p.id === (rootFolderId || baseProject.id));
            const userBaseProject = body[index].userBaseProjects.find(x => x.user.id === jwt_decode(new Cookies().get('token')).id);
            if (userBaseProject) {
                if (state) {
                    if (!userBaseProject.favoriteBaseProjects) userBaseProject.favoriteBaseProjects = [];
                    userBaseProject.favoriteBaseProjects.push({
                        userBaseProjectUserId: jwt_decode(new Cookies().get('token')).id,
                        userBaseProjectBaseProjectId: rootFolderId || baseProject.id,
                        baseProjectId: baseProject.id
                    });
                } else if (userBaseProject.favoriteBaseProjects)
                    userBaseProject.favoriteBaseProjects = userBaseProject.favoriteBaseProjects.filter(fbp => fbp.baseProjectId !== baseProject.id);
            }
            return body;
        });

        return Axios.put(BACKEND_ENDPOINT + `projects/toggleFavorite/${rootFolderId}/${baseProject.id}`, null, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast(state ? 'favorite_status_added' : 'favorite_status_removed', baseProject.type === 'folder' ? 'dossier' : 'projet');
            return response.status;
        }, () => {
            showToast('favorite_status_update_failed');
            return;
        });
    }

    static changeUserRoles(baseProjectId, ubpToModify) {
        OfflineUtil.modifyDynamicCache(BACKEND_ENDPOINT + 'projects/userBaseProjects/' + jwt_decode(new Cookies().get('token')).id, (body) => {
            const index = body.findIndex(p => p.id === baseProjectId);
            let userBaseProjects = body[index].userBaseProjects;
            for (let i = 0; i < ubpToModify.length; i++)
                for (let j = 0; j < userBaseProjects.length; j++)
                    if (userBaseProjects[j].user.id === ubpToModify[i].userId) {
                        userBaseProjects[j].projectRoleId = ubpToModify[i].projectRoleId;
                        userBaseProjects[j].projectRole = ubpToModify[i].projectRole;
                    }
            return body;
        });

        return Axios.put(BACKEND_ENDPOINT + 'projects/changeUserRoles/' + baseProjectId, ubpToModify, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('roles_updated');
            return response.data;
        }, () => {
            showToast('roles_update_failed');
            return;
        });
    }

    static deleteBaseProject(baseProjectId, type, isDeletion) {
        OfflineUtil.modifyDynamicCache(BACKEND_ENDPOINT + 'projects/userBaseProjects/' + jwt_decode(new Cookies().get('token')).id, (body) => {
            return body.filter(baseProject => baseProject.id !== baseProjectId);
        });

        return Axios.delete(BACKEND_ENDPOINT + 'projects/' + baseProjectId + '/' + type, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(() => {
            const action = isDeletion ? 'deleted' : 'left';
            showToast(type === 'project' ? `project_${action}` : `folder_${action}`);
            return true;
        }, (error) => {
            if (navigator.onLine && error?.response) {
                if (error.response.data === 'Project is importing data') showToast('import_in_progress');
                else {
                    const action = isDeletion ? 'deletion' : 'leaving';
                    showToast(type === 'project' ? `project_${action}_failed` : `folder_${action}_failed`);
                }
                return false;
            }
            else return true;
        });
    }

    static moveBaseProjects(baseProjectIds, folderId, type) {
        return Axios.post(BACKEND_ENDPOINT + 'projects/' + folderId, baseProjectIds, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            if (baseProjectIds.length === 1) showToast('base_project_moved', (type === 'folder' ? i18n.t("Dossier") : i18n.t("Projet")).toLowerCase());
            else showToast('base_projects_moved');
            return response.data;
        }, () => {
            showToast('connection_failed');
            return;
        });
    }

    static updateFieldCategories(project) {
        return Axios.put(BACKEND_ENDPOINT + 'projects/fieldCategories/' + project.id, project.fieldCategories || [], {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, () => {
            showToast('project_update_failed');
            return;
        });
    }

    static getLinkedElements(projectId) {
        return Axios.get(BACKEND_ENDPOINT + 'projects/linkedElements/' + projectId, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then((response) => {
            return response.data;
        }, () => {
            showToast('linked_elements_loading_failed');
            return;
        });
    }

    static toggleLinkedElements(linkedElementsToAdd, linkedElementsToDelete, projectId) {
        return Axios.post(BACKEND_ENDPOINT + 'projects/linkedElements/' + projectId, { linkedElementsToAdd, linkedElementsToDelete }, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return OfflineUtil.modifyDynamicCache(BACKEND_ENDPOINT + 'actions/linkedElements/' + projectId, (body) => {
                body = [...body, ...linkedElementsToAdd];
                body = body.filter(le => !linkedElementsToDelete.find(letd => letd.elementId === le.elementId && letd.linkedElementId === le.linkedElementId))
                return body;
            }).then(() => {
                if (navigator.onLine) showToast('elements_linked');
                else showToast('will_sync');
                return response.status;
            });
        }, () => {
            if (navigator.onLine) showToast('connection_failed');
            return;
        });
    }

    static deleteLinkedElements(linkedElements, projectId) {
        return Axios.delete(BACKEND_ENDPOINT + 'projects/linkedElements/' + projectId, {
            headers: {
                'Authorization': `Bearer ${new Cookies().get('token')}`,
            },
            data: linkedElements
        }).then(response => {
            return OfflineUtil.modifyDynamicCache(BACKEND_ENDPOINT + 'actions/linkedElements/' + projectId, (body) => {
                return body.filter(linkedElement => !linkedElements.find(le => le.elementId === linkedElement.elementId && le.linkedElementId === linkedElement.linkedElementId));
            }).then(() => {
                if (navigator.onLine) showToast('elements_unlinked');
                else showToast('will_sync');
                return response.status;
            });
        }, () => {
            if (navigator.onLine) showToast('connection_failed');
            return;
        });
    }

    static getMapAreas() {
        return Axios.get(BACKEND_ENDPOINT + 'projects/mapAreas').then(response => {
            return response.data;
        }, () => {
            showToast('municipalities_loading_failed');
            return;
        });
    }

    static getMunicipalities() {
        return Axios.get(BACKEND_ENDPOINT + 'projects/municipalities').then(response => {
            return response.data;
        }, () => {
            showToast('municipalities_loading_failed');
            return;
        });
    }

    static getMunicipality(id) {
        return Axios.get(BACKEND_ENDPOINT + 'projects/municipality/' + id).then(response => {
            return response.data;
        }, () => {
            showToast('municipalities_loading_failed');
            return;
        });
    }

    static getProjections() {
        return Axios.get(BACKEND_ENDPOINT + 'projects/projections/').then(response => {
            return response.data;
        }, () => {
            showToast('projections_loading_failed');
            return;
        });
    }

    static getInvitation(invitationId) {
        return Axios.get(BACKEND_ENDPOINT + 'projects/getInvitation/' + invitationId).then(response => {
            return response;
        }, error => {
            return error.response.status;
        });
    }

    static getLogs(id) {
        return Axios.get(BACKEND_ENDPOINT + 'projects/logs/' + id, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, error => {
            return error.response.status;
        });
    }

    static getPointInTime(id, year) {
        return Axios.get(BACKEND_ENDPOINT + `projects/pointInTime/${id}/${year}`, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, error => {
            return error.response.status;
        });
    }

    static getOldestHistory(projectId) {
        return Axios.get(BACKEND_ENDPOINT + `projects/oldestHistory/${projectId}`, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, error => {
            return error.response.status;
        });
    }

    static getTags(id) {
        return Axios.get(BACKEND_ENDPOINT + 'projects/tags/' + id, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, () => { return; });
    }

    static addProjectTags(projectTags) {
        return Axios.post(BACKEND_ENDPOINT + 'projects/tags/', projectTags, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, () => { return; });
    }

    static isMaxElementsReached(project, type, text) {
        return Axios.get(BACKEND_ENDPOINT + type + '/isMaxReached/' + project.id, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, (error) => {
            if (navigator.onLine && error?.response) {
                if (error.response.data === 'Project is importing data') showToast('import_in_progress');
                else showToast('elements_limit_not_available', text);
            }
            else {
                if (project) {
                    OfflineUtil.getValueInDynamicCache(BACKEND_ENDPOINT + type + '/isMaxReached/' + project.id).then(isMaxReached => {
                        if (isMaxReached) {
                            if (isMaxReached.state) showToast('elements_limit_reached', text);
                        } else showToast('elements_limit_not_available', text);
                    });
                } else showToast('elements_limit_not_available', text);
            }
            return;
        });
    }

    static updateReferences(projectId, category) {
        return Axios.put(BACKEND_ENDPOINT + 'admin/projects/updateReferences/' + projectId + '/' + category, null, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('references_updated');
            return response.data;
        }, () => {
            showToast('references_update_failed');
            return;
        });
    }

    static duplicateProject(project, fieldCategoriesWithFieldsToCreate = [], duplicateElements = true, isRecommendation = false) {
        return Axios.post(BACKEND_ENDPOINT + 'projects/duplicate', { project, fieldCategoriesWithFieldsToCreate, duplicateElements, isRecommendation }, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, () => {
            showToast('project_duplication_failed');
            return;
        });
    }

    static getInvitations() {
        return Axios.get(BACKEND_ENDPOINT + 'admin/projects/invitations/', {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('invitations_loading_failed');
            return;
        });
    }

    static deleteInvitations(ids) {
        return Axios.delete(BACKEND_ENDPOINT + 'admin/projects/invitations/', {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` },
            data: { ids: ids }
        }).then(response => {
            return response.status;
        }, () => {
            showToast(ids.length > 1 ? 'invitations_deletion_failed' : 'invitation_deletion_failed');
            return;
        });
    }

    static exportHistoryAsXLSX(projectLabel, rows) {
        return Axios.post(BACKEND_ENDPOINT + 'projects/exportXLSX/logs/', rows, {
            responseType: 'blob',
            headers: {
                'Authorization': `Bearer ${new Cookies().get('token')}`,
                'Content-Type': 'application/json'
            }
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${projectLabel} - ${i18n.t("Historique")}.xlsx`);
            document.getElementById('modal-content').appendChild(link);
            link.click();
            document.getElementById('modal-content').removeChild(link);
        }, () => {
            if (navigator.onLine) showToast('elements_xlsx_export_failed');
        });
    }

    static shareProject(baseProjectId, searchParams) {
        return Axios.post(BACKEND_ENDPOINT + 'projects/share/', { baseProjectId, searchParams }, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, () => { return; });
    }
}