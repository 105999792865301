import React, { Component } from 'react';
// Composants
import InfoIcon from '../../../Utils/InfoIcon';
import { Form, TextArea, Grid, Input } from 'semantic-ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import { isMobileOnly } from 'react-device-detect';
import { connect } from 'react-redux';
import i18n from '../../../../locales/i18n';
// Ressources
import { faEye, faTachometerAlt } from '@fortawesome/pro-solid-svg-icons';

class Step2 extends Component {
    state = {
        properties: {
            surface: 0,
            length: 0,
            observation: null
        },
        error: {
            hidden: true,
            messages: [],
            observation: false
        }
    };

    render() {
        const requiredFields = this.props.requiredFields.greenSpaces;
        const { surface, baseLine } = this.props.properties;

        const columnProps = {
            stretched: true,
            computer: 8,
            tablet: 8,
            mobile: 16
        };

        const indicatorsCustomFields = this.props.renderFields(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Indicateurs'));
        const observationCustomFields = this.props.renderFields(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Observation'));

        return (
            <>
                {(requiredFields.surface || (requiredFields.length && baseLine?.length > 0) || baseLine?.width > 0 || indicatorsCustomFields?.lenght > 0) &&
                    <Grid id='cat--indicators' style={{ margin: '14px 0 0 0', border: 'solid 2px var(--blue-100)', borderRadius: '6px' }}>
                        <Grid.Row style={{ display: 'flex', padding: '14px 14px 0 14px', color: 'var(--blue-100)', fontWeight: 'bold', fontSize: '14pt' }}>
                            <FontAwesomeIcon icon={faTachometerAlt} style={{ alignSelf: 'center', marginRight: '5px' }} />
                            <span className='no-themed'>{i18n.t("Indicateurs")}</span>
                        </Grid.Row>
                        {requiredFields.surface &&
                            <Grid.Column {...{ ...columnProps, computer: 4, tablet: 4, mobile: 16 }}>
                                <Form.Field
                                    control={Input} fluid type='number' value={surface || ''}
                                    label={<label>{i18n.t("Surface")} (m²) <InfoIcon content={i18n.t("La surface est mise à jour automatiquement")} iconStyle={{ marginLeft: '3px' }} /> :</label>}
                                />
                            </Grid.Column>}
                        {requiredFields.length && baseLine?.length > 0 &&
                            <Grid.Column {...{ ...columnProps, computer: 4, tablet: 4, mobile: 16 }}>
                                <Form.Field
                                    control={Input} fluid type='number' value={baseLine.length || ''}
                                    label={<label>{i18n.t("Longueur")} (m) <InfoIcon content={i18n.t("La longueur est mise à jour automatiquement")} iconStyle={{ marginLeft: '3px' }} /> :</label>}
                                />
                            </Grid.Column>}
                        {baseLine?.width > 0 &&
                            <Grid.Column {...{ ...columnProps, computer: 4, tablet: 4, mobile: 16 }}>
                                <Form.Field
                                    control={Input} fluid type='number' value={baseLine.width || ''}
                                    label={<label>{i18n.t("Largeur")} (m) :</label>}
                                />
                            </Grid.Column>}
                        {indicatorsCustomFields}
                    </Grid>}
                {(requiredFields.observation || observationCustomFields?.length > 0) &&
                    <Grid id='cat--observation' style={{ margin: '14px 0 0 0', border: 'solid 2px var(--grey-100)', borderRadius: '6px' }}>
                        <Grid.Row style={{ display: 'flex', padding: '14px 14px 0 14px', color: 'var(--grey-100)', fontWeight: 'bold', fontSize: '14pt' }}>
                            <FontAwesomeIcon icon={faEye} style={{ alignSelf: 'center', marginRight: '5px' }} />
                            <span className='no-themed'>{i18n.t("Observation")}</span>
                        </Grid.Row>
                        {requiredFields.observation &&
                            <Grid.Column computer={16} tablet={16} mobile={16} style={{ marginTop: '4px', padding: '0 14px' }}>
                                <Form.Field style={{ marginBottom: '14px', minHeight: isMobileOnly ? '200px' : '100px' }}
                                    control={TextArea} placeholder={i18n.t("Maximum 5000 caractères")}
                                    name='observation' value={this.props.properties.observation || ''}
                                    error={this.props.error.observation} onChange={this.props.handleChange}
                                />
                            </Grid.Column>}
                        {observationCustomFields}
                    </Grid>}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        spaceFunctions: state.spaceFunctions,
        defaultFieldCategories: state.defaultFieldCategories
    };
}

export default connect(mapStateToProps)(Step2);