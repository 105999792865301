import React, { Component } from 'react';
import AppSettings from '../../../../AppSettings';
// Composants
import InfoIcon from '../../../Utils/InfoIcon';
import { Divider, Form, Grid, Select } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProjectAccessiblitySettingsForm from './ProjectAccessiblitySettingsForm';
// Librairies
import i18n from '../../../../locales/i18n';
import { connect } from 'react-redux';
import { setThemes, setPriceLists } from '../../../../actionCreators/usersActions';
import { faFlowerTulip, faLock, faTree } from '@fortawesome/pro-solid-svg-icons';
import Cookies from 'universal-cookie';
import jwt_decode from 'jwt-decode';
// Ressources
import logo from '../../../../resources/images/logo-mono-white.jpg';
// Services
import ThemesService from '../../../../services/ThemesService';
import PriceListsService from '../../../../services/PriceListsService';
// Utils
import ProjectsUtil from '../../../../utils/ProjectsUtil';
import FormulasUtil from '../../../../utils/FormulasUtil';
import FormattersUtil from '../../../../utils/FormattersUtil';

class ProjectMainSettingsForm extends Component {
    state = {
        themes: [],
        priceLists: [],
    };

    render() {
        const { projectToEdit, ownerId, loginAsData, subscription, isDuplication } = this.props;
        const { themes, priceLists } = this.state;
        const userId = jwt_decode(new Cookies().get('token')).id;
        const projectFormulaVersions = projectToEdit?.projectFormulaVersions;
        const status = ProjectsUtil.getStatus();
        const projections = this.props.projections.map(projection => ({ text: projection.label, value: projection.id, title: projection.title, description: projection.description }));
        const userProjects = this.props.userProjects?.filter(up => !up.deletionDate).map(userProject => ({ text: FormattersUtil.formatLastNameAndFirstName(userProject.user.lastName, userProject.user.firstName), value: userProject.userId }));
        const owner = this.props.userProjects?.find(up => up.projectRole.type === 'owner');
        const themeOptions = themes.map(theme => ({ text: theme.label, value: theme.id, title: theme.website, description: this.renderLogo(theme) }));
        themeOptions.unshift({
            text: i18n.t("Par défaut"), value: 0, title: null, description: this.renderLogo()
        });
        const priceListOptions = priceLists.map(priceList => ({ text: priceList.label, value: priceList.id }));
        priceListOptions.unshift({ text: i18n.t("Par défaut"), value: 0 });
        const trunkCircumferenceUnitOptions = [{ text: i18n.t("Circonférence"), value: 'circumference' }, { text: i18n.t("Diamètre"), value: 'diameter' }];
        const isUrbasense = AppSettings.isUrbasenseUrl();

        return (
            <div style={{ flex: 1, overflowY: 'auto', overflowX: 'hidden' }}>
                <h3>Paramètres avancés</h3>
                <Grid>
                    <Grid.Row style={{ paddingBottom: 0 }}>
                        <Grid.Column computer={4} tablet={8} mobile={16}>
                            <Form.Field
                                control={Select} label={`${i18n.t("Statut")} :`} fluid placeholder={i18n.t("Sélectionnez un statut")}
                                value={projectToEdit?.status} options={status} selectOnBlur={false} disabled={loginAsData?.readOnly}
                                onChange={(_, { value }) => this.props.handleChange('status', value)}
                            />
                        </Grid.Column>
                        <Grid.Column computer={4} tablet={8} mobile={16}>
                            <Form.Field
                                control={Select} label={`${i18n.t("Projection")} :`} fluid placeholder={i18n.t("Sélectionnez une projection")}
                                value={projectToEdit?.projectionId || 1} options={projections} search={FormattersUtil.searchList} selectOnBlur={false} disabled={loginAsData?.readOnly}
                                noResultsMessage={i18n.t("Aucun résultat trouvé")} onChange={(_, { value }) => this.props.handleChange('projectionId', value)}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{ paddingTop: 0 }}>
                        {!isUrbasense &&
                            <Grid.Column computer={4} tablet={8} mobile={16}>
                                <Form.Field
                                    control={Select} label={<span>{!subscription?.branding && <FontAwesomeIcon icon={faLock} style={{ marginRight: '5px' }} />}{i18n.t("Thème")} :</span>}
                                    fluid placeholder={i18n.t("Sélectionnez un thème")} value={subscription?.branding ? projectToEdit?.theme?.id || 0 : 0} options={themeOptions} search={FormattersUtil.searchList} selectOnBlur={false}
                                    disabled={!subscription?.branding || loginAsData?.readOnly} noResultsMessage={i18n.t("Aucun résultat trouvé")} onChange={(_, { value }) => this.props.handleChange('theme', themes.find(theme => theme.id === value) || null)}
                                />
                            </Grid.Column>}
                        <Grid.Column computer={4} tablet={8} mobile={16}>
                            <Form.Field
                                control={Select} label={<span>{i18n.t("Liste de prix")} :</span>}
                                fluid placeholder={i18n.t("Sélectionnez une liste de prix")} value={projectToEdit?.priceListId || 0} options={priceListOptions} search={FormattersUtil.searchList} selectOnBlur={false}
                                disabled={loginAsData?.readOnly} noResultsMessage={i18n.t("Aucun résultat trouvé")} onChange={(_, { value }) => this.props.handleChange('priceList', priceLists.find(priceList => priceList.id === value) || null)}
                            />
                        </Grid.Column>
                        <Grid.Column computer={4} tablet={8} mobile={16}>
                            <Form.Field
                                control={Select} label={<span>{i18n.t("Unité de circonférence des axes")} :</span>}
                                fluid placeholder={i18n.t("Sélectionnez une liste de prix")} value={projectToEdit?.trunkCircumferenceUnit} options={trunkCircumferenceUnitOptions} search={FormattersUtil.searchList} selectOnBlur={false}
                                disabled={loginAsData?.readOnly} noResultsMessage={i18n.t("Aucun résultat trouvé")} onChange={(_, { value }) => this.props.handleChange('trunkCircumferenceUnit', value)}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                {projectFormulaVersions?.length > 0 && <>
                    <Divider />
                    <h3 style={{ marginTop: 0, marginBottom: '0px' }}>{i18n.t("Configuration des formules")}</h3>
                    <Grid style={{ marginTop: '3px' }}>
                        {this.renderFormulas()}
                    </Grid>
                </>}
                {(!this.props.rights || this.props.rights?.publicFields) &&
                    <>
                        <Divider />
                        <ProjectAccessiblitySettingsForm
                            project={projectToEdit} subscription={subscription} handleChange={this.props.handleChange}
                            isDuplication={isDuplication}
                        />
                    </>}
            </div>
        );
    }

    componentDidMount = async () => {
        const setThemes = (themes) => {
            themes = JSON.parse(JSON.stringify(themes));
            if (this.props.projectToEdit.theme && !themes.find(theme => theme.id === this.props.projectToEdit.theme.id))
                themes.unshift(this.props.projectToEdit.theme);
            this.setState({ themes });
        };

        if (!this.props.themes)
            ThemesService.getThemes().then(response => {
                if (response) {
                    this.props.setThemes(response);
                    setThemes(response);
                }
            });
        else setThemes(this.props.themes);

        const setPriceLists = (priceLists) => {
            priceLists = JSON.parse(JSON.stringify(priceLists));
            if (this.props.projectToEdit.priceList && !priceLists.find(priceList => priceList.id === this.props.projectToEdit.priceList.id))
                priceLists.unshift(this.props.projectToEdit.priceList);
            this.setState({ priceLists });
        };

        if (!this.props.priceLists)
            PriceListsService.getPriceLists().then(response => {
                if (response) {
                    this.props.setPriceLists(response);
                    setPriceLists(response);
                }
            });
        else setPriceLists(this.props.priceLists);

        ProjectsUtil.assignProjectFormulaVersionsType(this.props.projectToEdit, this.props.formulas);
    }

    renderFormulas = () => {
        const { formulas, projectToEdit, loginAsData } = this.props;
        const projectFormulaVersions = projectToEdit?.projectFormulaVersions;

        return (
            <Grid.Row>
                {formulas.map((formula, index) => {
                    const formulaVersion = projectFormulaVersions.find(pfv => formula.formulaVersions.find(fv => fv.id === pfv.formulaVersionId));
                    const options = formula.formulaVersions.map(fv => { return { text: fv.label, value: fv.id } })
                    const value = options.find(o => o.value === formulaVersion.formulaVersionId).value;
                    const icon = formula.category === 'trees' ? faTree : faFlowerTulip;
                    const tooltip = FormulasUtil.getFormulaTooltip(formula.id);

                    return (<Grid.Column key={index} computer={4} tablet={8} mobile={16}>
                        <Form.Field
                            key={index} control={Select}
                            label={<label><FontAwesomeIcon icon={icon} style={{ marginRight: '5px' }} />{formula.label} <InfoIcon content={tooltip} iconStyle={{ marginLeft: '3px' }} /> :</label>}
                            placeholder={i18n.t("Sélectionnez une formule")} name={formulaVersion.formulaVersionId} disabled={loginAsData?.readOnly}
                            options={options} value={value || []} search={FormattersUtil.searchList} selectOnBlur={false} upward
                            noResultsMessage={i18n.t("Aucun résultat trouvé")} onChange={this.props.handleFormulaVersionChange}
                        />
                    </Grid.Column>);
                })}
            </Grid.Row>
        );
    }

    renderLogo = (theme) => {
        const blobInfos = AppSettings.getBlobInfos();
        const logoUrl = theme?.logoName?.length ? `${blobInfos.endpoint}${blobInfos.containers.photos}/${theme.logoName}` : logo;
        return (<img src={logoUrl} alt='logo' className='ui image' style={{ float: 'right', borderRadius: '5px', marginRight: 0 }} />);
    }
}

const mapStateToProps = (state) => {
    return {
        userProjects: state.userProjects,
        formulas: state.formulas,
        projections: state.projections,
        themes: state.themes,
        priceLists: state.priceLists,
        loginAsData: state.loginAsData,
        rights: state.rights
    };
};

const mapDispatchToProps = {
    setThemes,
    setPriceLists
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectMainSettingsForm);