import AppSettings from '../AppSettings';
// Librairies
import Axios from 'axios';
import Cookies from 'universal-cookie';
import { showToast } from '../utils/ToastsUtil';

const BACKEND_ENDPOINT = AppSettings.getBackendUrl();

export default class BookmarksService {
    static getPredictions(data) {
        return Axios.post(BACKEND_ENDPOINT + 'ai/', data, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` },
        }).then(response => {
            return response.data;
        }, () => {
            showToast('ai_scan_failed');
            return;
        });
    }
}