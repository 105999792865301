import React, { Component } from 'react';
// Composants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Checkbox, Dimmer, Label, Loader, Message } from 'semantic-ui-react';
import InfoIcon from './InfoIcon';
import { Slider } from 'react-semantic-ui-range';
// Librairies
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.gridlayer.googlemutant';
import i18n from '../../locales/i18n';
import { isMobile } from 'react-device-detect';
import { v4 as uuidv4 } from 'uuid';
import { polygon, multiPolygon } from '@turf/turf';
// Redux
import { connect } from 'react-redux';
import { setProject } from '../../actionCreators/projectsActions';
// Ressources
import { faArrowsAlt, faCheck, faTimesCircle, faTrash } from '@fortawesome/pro-solid-svg-icons';
// Services
import TreesService from '../../services/TreesService';
import LocationsService from '../../services/LocationsService';
import ProjectsService from '../../services/ProjectsService';
// Utils
import StylesUtil from '../../utils/StylesUtil';
import WebSocketUtil from '../../utils/WebSocketUtil';
import GeoJsonUtil from '../../utils/GeoJsonUtil';
import GeometriesUtil from '../../utils/GeometriesUtil';

const legendStyle = {
    marginRight: '5px', display: 'inline-flex', position: 'relative', top: '3px', backgroundSize: 'contain',
    border: 'solid 1px black', width: '15px', height: '15px', borderRadius: '10px', alignItems: 'center', justifyContent: 'center'
};

class AIResultMap extends Component {
    state = {
        isLoading: false,
        isConfirming: false,
        addTag: true,
        addObservation: true,
        activeButton: null,
        minProbability: 80,
        legendCount: 0
    };

    render() {
        const { scan } = this.props;
        const { isLoading, isConfirming, addTag, addObservation, legendCount, activeButton } = this.state;

        return (
            <div className='modal-content'>
                <Dimmer active={isLoading} style={StylesUtil.getMapStyles().dimmerStyle}>
                    <Loader content={i18n.t("Ajout des arbres en cours...")} />
                </Dimmer>
                <Dimmer active={isConfirming} style={StylesUtil.getMapStyles().dimmerStyle}>
                    <Message compact className='tableConfirmation'>
                        <Message.Header>{i18n.t("Êtes-vous certain de vouloir ajouter ces arbres sur la carte ?")}</Message.Header>
                        <Message.Content style={{ marginTop: '10px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ display: 'flex' }}>
                                    <Checkbox label={i18n.t("Ajouter un tag 'IA' sur les éléments")} checked={addTag} onChange={(_, { checked: addTag }) => this.setState({ addTag })} style={{ marginBottom: '10px', alignSelf: 'flex-start' }} />
                                    <InfoIcon content={i18n.t("Ce tag vous permettra de retrouver plus facilement les arbres ajoutés via IA à l'aide des filtres")} iconStyle={{ position: 'relative', top: 0, right: 0, marginLeft: '5px' }} />
                                </div>
                                <Checkbox label={i18n.t("Ajouter les données de prédiction dans l'observation")} checked={addObservation} onChange={(_, { checked: addObservation }) => this.setState({ addObservation })} style={{ marginBottom: '10px', alignSelf: 'flex-start' }} />
                            </div>
                            <Button color='red' onClick={() => this.setState({ isConfirming: false })}>
                                <FontAwesomeIcon icon={faTimesCircle} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                            </Button>
                            <Button color='green' onClick={this.handleSubmit}>
                                <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("Valider")}
                            </Button>
                        </Message.Content>
                    </Message>
                </Dimmer>
                <div key='ai-scan-preview' id='ai-scan-preview' className='mapPreview' style={{ height: '100%', width: '100%', marginTop: 0, zIndex: 1, borderRadius: '10px' }}></div>
                <div id='toolbar' style={{ position: 'absolute', top: '10px', left: '10px', border: '1px solid var(--black-50)', borderRadius: '5px' }}>
                    <div className='buttons'>
                        <div id='toolbar-category-0' style={{ flexGrow: 1 }}>
                            <Button className={activeButton === 'drag' ? 'active' : ''} style={{ justifyContent: 'center', padding: '9px 0', fontSize: '14pt' }} onClick={() => this.handleButtonClick('drag')}>
                                <FontAwesomeIcon icon={faArrowsAlt} />
                            </Button>
                            <Button className={activeButton === 'remove' ? 'active' : ''} style={{ justifyContent: 'center', padding: '9px 0', fontSize: '14pt' }} onClick={() => this.handleButtonClick('remove')}>
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        </div>
                    </div>
                </div>
                <div id='legend-container' style={{ bottom: isMobile ? '115px' : '67px', right: '20px' }}>
                    <div className='legend'>
                        <div className='legend-body'>
                            <div className='legend-child'>
                                <div className='legend-canva' style={{ ...legendStyle, background: 'var(--primary-100)' }}>
                                </div>
                                <span style={{ marginRight: '5px' }}>{i18n.t("Arbres déjà présents")}</span>
                                <Label className={`legend-counter${!scan.previousTrees?.length ? ' none' : ''}`}>{scan.previousTrees?.length}</Label>
                            </div>
                            <div className='legend-child'>
                                <div className='legend-canva' style={{ ...legendStyle, background: 'var(--pink-100)' }}>
                                </div>
                                <span style={{ marginRight: '5px' }}>{i18n.t("Arbres résultats du scan")}</span>
                                <Label className={`legend-counter${!legendCount ? ' none' : ''}`}>{legendCount}</Label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='modal-content-footer' style={{ marginTop: '10px', display: !isMobile && 'flex' }}>
                    {isMobile ?
                        <>
                            {this.renderProbabilitySlider()}
                            <Button.Group style={{ width: '100%' }}>
                                <Button type='button' className='form-button' color='red' style={{ width: '50%' }} onClick={this.props.cancelAddWithAI}>
                                    <FontAwesomeIcon icon={faTimesCircle} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                                </Button>
                                <Button className='form-button' type='submit' color='green' disabled={!this.props.isOnline} style={{ width: '50%' }} onClick={() => this.setState({ isConfirming: true })}>
                                    <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("Valider")}
                                </Button>
                            </Button.Group>
                        </>
                        :
                        <>
                            <Button type='button' className='form-button' color='red' onClick={this.props.cancelAddWithAI}>
                                <FontAwesomeIcon icon={faTimesCircle} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                            </Button>
                            <Button className='form-button' type='submit' color='green' disabled={!this.props.isOnline} onClick={() => this.setState({ isConfirming: true })}>
                                <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("Valider")}
                            </Button>
                            {this.renderProbabilitySlider()}
                        </>}
                </div>
            </div>
        );
    }

    componentDidMount = () => {
        const { scan, project, isDarkTheme } = this.props;
        const { minProbability } = this.state;

        const layers = scan.wmsService
            ? (
                !scan.wmsService.tileMatrixSet
                    ? L.tileLayer.wms(scan.wmsService, { layers: scan.wmsService.layers?.join(','), format: 'image/png', maxZoom: 22 })
                    : L.tileLayer.wmts(scan.wmsService.url, { layer: scan.wmsService.layers?.[0], maxZoom: 22, format: scan.wmsService.format, style: scan.wmsService.style, tilematrixSet: scan.wmsService.tileMatrixSet, maxNativeZoom: scan.wmsService.maxNativeZoom })
            ) : L.gridLayer.googleMutant({
                maxZoom: 22, type: 'hybrid',
                styles: [
                    { elementType: 'labels', stylers: [{ visibility: 'off' }] },
                    { featureType: 'road', elementType: 'labels.text', stylers: [{ visibility: 'on' }] },
                    { featureType: 'administrative', elementType: 'labels.text', stylers: [{ visibility: 'on' }] }
                ]
            });

        // Surroundings & bounds
        const surroundingsToRender = JSON.parse(project.surroundings);
        const surroundingsPolygon = surroundingsToRender.geometry.type === 'Polygon'
            ? polygon(surroundingsToRender.geometry.coordinates)
            : multiPolygon(surroundingsToRender.geometry.coordinates);
        const geoJson = GeoJsonUtil.generateGeoJson(GeometriesUtil.invertPolygon(surroundingsPolygon));
        this.surroundingsLayer = L.geoJson(geoJson, { // On affiche les polygones issus du GeoJSON en le stylisant
            style: { ...(isDarkTheme ? StylesUtil.getSurroundingsDarkStyle() : StylesUtil.getSurroundingsLightStyle()), weight: 1.5 },
            pmIgnore: true
        });

        this.map = L.map('ai-scan-preview', { layers, minZoom: 3, zoomControl: false, attributionControl: false, maxBounds: scan.bounds, maxBoundsViscosity: 1.1 });
        this.map.fitBounds(scan.bounds);
        if (this.surroundingsLayer) this.map.addLayer(this.surroundingsLayer);

        if (scan.previousTrees.length)
            scan.previousTrees.forEach(feature => {
                L.circleMarker({ lat: feature.geometry.coordinates[1], lng: feature.geometry.coordinates[0] }, {
                    ...StylesUtil.getTreeStyle(), radius: 10, pmIgnore: true
                }).addTo(this.map);
            });
        this.treesOnMap = [];
        this.scannedTrees = JSON.parse(JSON.stringify(this.props.scan.trees));
        if (this.scannedTrees.length) {
            const treesToAdd = this.scannedTrees.filter(tree => tree.probability >= minProbability);
            treesToAdd.forEach(feature => this.addTreeToMap(feature));
            this.setState({ legendCount: Object.keys(this.treesOnMap).length });
        }

        this.map.keyboard.disable();
        if (this.map.tap) this.map.tap.disable();
        document.getElementById('ai-scan-preview').style.cursor = 'pointer';

        this.lasso = L.lasso(this.map, { polygon: { color: 'var(--primary-100)', weight: 2 } });
        this.map.on('pm:remove', (e) => this.removeTree(e.layer.feature.id));
        this.map.on('lasso.finished', (e) => {
            this.scannedTrees = this.scannedTrees.filter(tree => !e.layers?.find(layer => layer.feature.id === tree.id));
            e.layers.forEach(layer => {
                this.map.removeLayer(layer);
                delete this.treesOnMap[layer.feature.id];
            });
            this.setState({ legendCount: Object.keys(this.treesOnMap).length });
        });

        document.addEventListener('keydown', this.handleKeyDown);
        document.addEventListener('keyup', this.handleKeyUp);
    }

    componentWillUnmount = () => {
        document.removeEventListener('keydown', this.handleKeyDown);
        document.removeEventListener('keyup', this.handleKeyUp);
    }

    renderProbabilitySlider = () => (
        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 'auto' }}>
            <label style={{ fontSize: '.92857143em', fontWeight: 'bold' }}>{i18n.t("Probabilité minimale")}<InfoIcon content={i18n.t("Détermine le taux minimal de certitude que la prédiction soit correcte. Diminuer la probabilité minimale augmentera le nombre de résultats mais diminuera la fiabilité de ceux-ci et inversement.")} iconStyle={{ position: 'relative', top: 0, right: 0, marginLeft: '5px' }} /> :</label>
            <div style={{ display: 'flex' }}>
                <Slider
                    style={{ width: '300px', marginRight: '40px' }}
                    discrete color='green'
                    settings={{
                        min: 30, max: 100, step: 1,
                        start: 80,
                        onChange: this.handleProbabilityChange
                    }}
                />
                <span style={{ position: 'absolute', right: 0 }}>{this.state.minProbability + '%'}</span>
            </div>
        </div>
    );

    handleButtonClick = (name) => {
        this.setState(prevState => ({ activeButton: prevState.activeButton !== name && name }), () => {
            if ((this.state.activeButton === 'drag') !== this.map.pm.globalDragModeEnabled()) this.map.pm.toggleGlobalDragMode();
            if ((this.state.activeButton === 'remove') !== this.map.pm.globalRemovalModeEnabled()) this.map.pm.toggleGlobalRemovalMode();
        });
    }

    handleProbabilityChange = (value) => {
        if (this.probabilityTimeout) clearTimeout(this.probabilityTimeout);
        this.probabilityTimeout = setTimeout(() => {
            this.scannedTrees
                .filter(feature => !this.treesOnMap[feature.id] && feature.probability >= value)
                .forEach(feature => this.addTreeToMap(feature));
            this.scannedTrees
                .filter(feature => this.treesOnMap[feature.id] && feature.probability < value)
                .forEach(feature => {
                    this.map.removeLayer(this.treesOnMap[feature.id]);
                    delete this.treesOnMap[feature.id];
                });
            this.setState({ legendCount: Object.keys(this.treesOnMap).length });
        }, 500);
        this.setState({ minProbability: Number(value) });
    }

    handleKeyDown = (e) => {
        if (this.state.activeButton === 'remove' && (e.ctrlKey || e.metaKey) && ['Control', 'Meta'].includes(e.key))
            this.lasso.enable();
    }

    handleKeyUp = (e) => {
        if (!e.ctrlKey && !e.metaKey && ['Control', 'Meta'].includes(e.key))
            this.lasso.disable();
    }

    addTreeToMap = (feature) => {
        const { coordinates } = feature.geometry;
        const layer = L.circleMarker({ lat: coordinates[1], lng: coordinates[0] }, { ...StylesUtil.getTreeStyle(), fillColor: 'var(--pink-100)', fillOpacity: 1 }).addTo(this.map);
        this.treesOnMap = { ...this.treesOnMap, [feature.id]: layer };
        layer.feature = feature;
        layer.on('mouseover', () => layer.setStyle(StylesUtil.getTreeHighlightStyle()));
        layer.on('mouseout', () => layer.setStyle({ ...StylesUtil.getTreeStyle(), fillColor: 'var(--pink-100)', fillOpacity: 1 }));
        layer.on('pm:dragend', () => this.updateTree(layer.feature.id, layer.getLatLng()));
    }

    removeTree = (treeId) => {
        delete this.treesOnMap[treeId];
        this.scannedTrees = this.scannedTrees.filter(tree => tree.id !== treeId);
        this.setState({ legendCount: Object.keys(this.treesOnMap).length });
    }
    updateTree = (treeId, latLngs) => {
        const tree = this.scannedTrees.find(tree => tree.id === treeId);
        if (tree) tree.geometry.coordinates = [latLngs.lng, latLngs.lat];
    }

    handleSubmit = async () => {
        const project = { ...this.props.project };
        const { addTag, addObservation } = this.state;

        this.setState({ isLoading: true, isConfirming: false });
        this.scannedTrees = this.scannedTrees.filter(feature => this.treesOnMap[feature.id]);

        const promises = this.scannedTrees.map(tree => new Promise(resolve => {
            if (!addObservation) tree.properties.observation = null;
            LocationsService.getPlace(tree.geometry.coordinates[1], tree.geometry.coordinates[0]).then(place => {
                tree.properties.place = place;
                resolve();
            })
        }));

        if (addTag)
            promises.push(new Promise(async resolve => {
                let tagId = project.tags?.find(tag => tag.category === 'Arbre' && tag.label === 'IA')?.id;
                if (!tagId) {
                    tagId = uuidv4();
                    const tagsToAdd = [{ id: tagId, label: 'IA', projectId: this.props.project.id, category: 'Arbre' }];
                    await ProjectsService.addProjectTags(tagsToAdd).then(projectTags => {
                        if (projectTags) {
                            project.tags = projectTags;
                            this.props.setProject(project);
                            WebSocketUtil.sendTags(this.props.webSocketHubs, this.props.project.id, tagsToAdd);
                        }
                    });
                }
                this.scannedTrees.forEach(tree => tree.properties.tagId = [tagId]);
                resolve();
            }));

        Promise.all(promises).then(() => {
            TreesService.addTrees(this.scannedTrees, 'adding', this.props.webSocketHubs).then(() => {
                this.props.finishAddWithAI(this.scannedTrees);
            });
        });
    }
}

const mapStateToProps = (state) => ({
    isOnline: state.isOnline,
    project: state.project,
    isDarkTheme: state.isDarkTheme,
    webSocketHubs: state.webSocketHubs
});

const mapDispatchToProps = {
    setProject
};

export default connect(mapStateToProps, mapDispatchToProps)(AIResultMap);