import React, { Component } from 'react';
// Composants
import { Form, Button, Segment, Divider, Select } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import { isMobileOnly, withOrientationChange } from 'react-device-detect';
import { connect } from 'react-redux';
import i18n from '../../../locales/i18n';
// Redux
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { setCurrentAction } from '../../../actionCreators/appActions';
import { setLayer } from '../../../actionCreators/elementsActions';
// Ressources
import HybridPng from '../../../resources/pngs/hybrid.png';
// Utils
import AppSettings from '../../../AppSettings';
import FormattersUtil from '../../../utils/FormattersUtil';

class ScanForm extends Component {
    constructor(props) {
        super(props);
        this.stationOptions = this.props.stations.map(station => ({ text: station.feature.properties.label, value: station.feature.id }));
    }

    state = {
        selectedLayer: 'google',
        selectedStation: null
    };

    render() {
        const { isOnline, isToolbarExpanded, scan, currentAction, layer } = this.props;
        const { selectedStation } = this.state;

        return (
            <>
                <div className='tool-form' style={{ left: isToolbarExpanded && !isMobileOnly ? '305px' : '45px', transition: 'left 500ms' }}>
                    <Form style={{ width: '100%' }}>
                        <label style={{ fontSize: '.92857143em' }}><b>{i18n.t("Zone à scanner")} :</b></label>
                        <Segment className='no-themed' style={{ margin: 0 }}>
                            <Button
                                color={layer ? 'yellow' : 'blue'} content={layer ? i18n.t("Modifier") : i18n.t("Dessiner")} icon={layer ? 'edit' : 'pencil alternate'}
                                style={{ minHeight: 'fit-content', padding: '8px', width: '100%' }} disabled={currentAction === 'drawingScanZone'} onClick={this.handleDraw}
                            />
                            <Divider horizontal style={{ margin: '7px 0' }}>{i18n.t("Ou")}</Divider>
                            <Select
                                placeholder={i18n.t("Sélectionner une station")} selectOnBlur={false} search={FormattersUtil.searchList}
                                disabled={!this.stationOptions.length || currentAction === 'drawingScanZone'} options={this.stationOptions} value={selectedStation} onChange={this.handleStationChange}
                            />
                        </Segment>
                        {this.props.project.wmsServices?.length > 0 &&
                            <div style={{ overflow: 'auto', flexGrow: 1, paddingRight: '5px', marginTop: '10px' }}>
                                <label style={{ fontSize: '.92857143em' }}><b>{i18n.t("Fond de carte à utiliser")} :</b></label>
                                <div style={{ overflowY: !isMobileOnly && 'overlay', minHeight: isMobileOnly && '90px' }}>
                                    <div className='layer-list' style={{ display: 'flex', flexDirection: 'row', flexWrap: !isMobileOnly && 'wrap', flexShrink: 0, alignItems: isMobileOnly && 'flex-start' }}>
                                        {this.renderBaseLayersList()}
                                    </div>
                                </div>
                            </div>}
                        <div style={{ display: 'flex', marginTop: '10px', flexDirection: 'column' }}>
                            <div style={{ display: 'flex' }}>
                                <Button
                                    type='button' color='red' size='small'
                                    style={{ display: 'inline-flex', justifyContent: 'center', width: '50%' }}
                                    onClick={() => this.props.hideForm(false)}
                                >
                                    <FontAwesomeIcon icon={faTimes} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                                </Button>
                                <Button
                                    id='dDCWEgiE' type='submit' color='green' size='small'
                                    style={{ display: 'inline-flex', justifyContent: 'center', width: '50%' }}
                                    disabled={!isOnline || (!layer && !selectedStation)} onClick={() => this.props.addTreesWithAI(selectedStation)}
                                >
                                    <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("Valider")}
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
            </>
        )
    }

    renderBaseLayersList = () => {
        const blobInfos = AppSettings.getBlobInfos();
        return [
            { id: 'google', label: i18n.t("Par défaut"), url: null, layers: null, preview: HybridPng },
            ...this.props.project.wmsServices
                .filter(({ type }) => type === 'baseLayer')
                .map(service => ({ ...service, preview: service?.preview && `${blobInfos.endpoint}${blobInfos.containers.photos}/${service.preview}` }))
        ].map((wmsService, index) => (
            <div
                key={index} className='base-layer-select' onClick={() => this.setState({ selectedLayer: wmsService.id }, () => this.props.handleChange(wmsService))}
                style={{
                    border: `solid 2px ${this.state.selectedLayer === wmsService.id ? 'var(--primary-100)' : 'transparent'}`,
                    display: 'flex', marginTop: '1px', alignItems: 'center', width: '100%', padding: '2px 4px', borderRadius: '5px', cursor: 'pointer'
                }}
            >
                <div
                    className='base-layer-preview'
                    style={{
                        background: wmsService.preview ? `url(${wmsService.preview})` : null,
                        display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '14pt', fontWeight: 'bold'
                    }}
                >
                    {!wmsService.preview && `${wmsService.label.slice(0, 1).toUpperCase()}${wmsService.label.slice(1, 2).toLowerCase()}`}
                </div>
                <div style={{ fontSize: '10pt', marginLeft: '5px', fontWeight: 'bold' }}>{wmsService.label}</div>
            </div >
        ));
    }

    handleDraw = () => {
        if (this.props.layer) this.props.map.removeLayer(this.props.layer);
        this.props.setCurrentAction('drawingScanZone');
        this.props.map.pm.enableDraw('Polygon');
        this.setState({ selectedStation: null });
    }

    handleStationChange = (_, { value }) => {
        if (this.props.layer) {
            this.props.map.removeLayer(this.props.layer);
            this.props.setLayer(null);
        }
        this.setState({ selectedStation: value });
    }
}

const mapStateToProps = (state) => {
    return {
        isOnline: state.isOnline,
        project: state.project,
        isToolbarExpanded: state.isToolbarExpanded,
        currentAction: state.currentAction,
        layer: state.layer
    };
}

const mapDispatchToProps = {
    setCurrentAction,
    setLayer
}

export default withOrientationChange(connect(mapStateToProps, mapDispatchToProps)(ScanForm));