export default class TooltipsUtil {
    static setMarkerTooltip(layer, text) {
        if (text) {
            layer.bindTooltip(String(text), { permanent: true, direction: 'top', className: 'class-tooltip tooltip-reference', offset: [0, 0] });
            layer.on('remove', function () { layer.closeTooltip(); }).on('add', function () { layer.openTooltip(); });
        }
    }

    static setGreenSpaceTooltip(layer, text) {
        if (text) {
            layer.bindTooltip(String(text), { permanent: true, direction: 'center', className: 'class-tooltip tooltip-reference', offset: [0, 0] });
            layer.on('remove', function () { layer.closeTooltip(); }).on('add', function () { layer.openTooltip(); });
        }
    }
}
