import React, { Component } from 'react';
// Composants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Divider } from 'semantic-ui-react';
import { Menu, Item, contextMenu } from 'react-contexify';
// Librairies
import i18n from '../../locales/i18n';
import { isMobile, isMobileOnly } from 'react-device-detect';
import screenfull from 'screenfull';
// Redux
import { connect } from 'react-redux';
import { setFavoriteTools } from '../../actionCreators/usersActions';
import { setButtonState, toggleToolbarExpand } from '../../actionCreators/appActions';
// Ressources
import {
    faArrowsAlt, faBan, faUserHelmetSafety, faBookmark, faChartLine, faCheck, faCircle, faLasso, faClipboard, faClone, faCompress, faVectorPolygon, faCube, faCubes, faCut, faEdit, faExpand,
    faEye, faFilterList, faHistory, faLayerGroup, faMapMarker, faMapMarkerAlt, faMinus, faPencilAlt, faPlus, faPrint, faRedo, faSearchLocation, faSquare, faGavel, faTimelineArrow,
    faStar, faSync, faTable, faTimes, faToolbox, faTools, faTrash, faTree, faUndo, faWaveSquare, faBringFront, faTablePicnic, faFlowerTulip, faChartTreeMap, faAnglesLeft, faAnglesRight, faFilePdf, faCircleBookmark, faList,
    faObjectSubtract,
    faLocationQuestion
} from '@fortawesome/pro-solid-svg-icons';
import { faBookmark as faBookmarkAlt, faEye as faEyeAlt, faHexagon, faStar as faStarAlt, faMicrochipAi } from '@fortawesome/pro-regular-svg-icons';
// Utils
import ProjectsUtil from '../../utils/ProjectsUtil';
import RightsUtil from '../../utils/RightsUtil';
import { showToast } from '../../utils/ToastsUtil';

const cancelButton = { icon: faTimes, title: i18n.t("Annuler") };
const confirmButton = { className: 'y6zVAZvi', icon: faCheck, title: `${i18n.t("Valider")}`, shortcut: 'Ctrl+S' };

const historyButtons = {
    cancel: cancelButton,
    undo: { className: 'M4ARbGof', icon: faUndo, title: `${i18n.t("Undo")}`, shortcut: 'Ctrl+Z' },
    redo: { className: 'rvTXjjwb', icon: faRedo, title: `${i18n.t("Redo")}`, shortcut: 'Ctrl+Y' },
    confirm: confirmButton
};

const copyPasteButton = {
    className: 'copy-paste', icon: faClipboard, title: i18n.t("Copier/coller"), isDefault: true, isSelectable: true,
    children: { reset: { icon: faBan, title: i18n.t("Vider la sélection") } }
};
const duplicateButton = { className: 'duplicate', icon: faClone, title: i18n.t("Duplication linéaire"), isSelectable: true };
const dragButton = { className: 'GAKD8nG1', icon: faArrowsAlt, children: historyButtons };
const removeButton = { className: 'kRvocV1O', icon: faTrash, title: i18n.t("Suppression"), children: historyButtons };

class Toolbar extends Component {
    state = {
        buttons: {
            adminTools: {
                icon: faGavel, title: i18n.t("Outils d'administration"), isVisible: () => this.props.project && ['Admin', 'Dev'].includes((this.props.logged && this.props.activeOrganization?.subscription)?.shortName) && !['drawingCustomArea', 'drawingProjectSurroundings', 'modifyingProjectSurroundings'].includes(this.props.currentAction),
                children: {
                    printPdfs: { icon: faFilePdf, title: i18n.t("Exporter toutes les fiches PDF avec aperçus"), isDisabledInOffline: true },
                    updateReferences: { icon: faCircleBookmark, title: i18n.t("Faire correspondre les références"), isDisabledInOffline: true },
                }
            },
            favoriteTools: {
                icon: faStar, title: i18n.t("Favoris"), isVisible: () => !['drawingCustomArea', 'drawingProjectSurroundings', 'modifyingProjectSurroundings'].includes(this.props.currentAction), children: {}
            },
            basicTools: {
                id: 'uhRCkpxG', icon: faToolbox, title: i18n.t("Outils de base"), children: {
                    zoomIn: { id: 'CLbPeib8', icon: faPlus, title: i18n.t("Zoomer") },
                    zoomOut: { icon: faMinus, title: i18n.t("Dézoomer") },
                    fullscreen: {
                        title: i18n.t("Plein écran"), isVisible: () => screenfull.isEnabled,
                        states: [
                            { icon: faExpand, title: i18n.t("Activer le mode plein écran") },
                            { icon: faCompress, title: i18n.t("Désactiver le mode plein écran"), isActive: true }
                        ], isReduxManaged: true
                    },
                    lasso: {
                        icon: faLasso, title: i18n.t("Lasso"), isVisible: () => !['drawingCustomArea', 'drawingProjectSurroundings', 'modifyingProjectSurroundings'].includes(this.props.currentAction),
                        states: [
                            { title: i18n.t("Activer l'outil lasso") },
                            { title: i18n.t("Désactiver l'outil lasso"), isActive: true }
                        ], isReduxManaged: true
                    },
                    references: {
                        id: 'Ueu5GQ3h', title: i18n.t("Références"), isVisible: () => this.props.project && this.publicFields?.main.references && !['drawingCustomArea', 'drawingProjectSurroundings', 'modifyingProjectSurroundings'].includes(this.props.currentAction),
                        state: 0, states: [
                            { icon: faBookmark, title: `${i18n.t("Afficher les références")} (${i18n.t("auto-incrémentées")})` },
                            { icon: faBookmark, title: `${i18n.t("Afficher les références")} (${i18n.t("personnalisées")})`, isActive: true },
                            { icon: faBookmarkAlt, title: i18n.t("Masquer les références"), isActive: true }
                        ], isReduxManaged: true
                    },
                    legend: {
                        title: i18n.t("Légende"), isVisible: () => !['drawingCustomArea', 'drawingProjectSurroundings', 'modifyingProjectSurroundings'].includes(this.props.currentAction),
                        state: 0, states: [
                            { icon: faChartTreeMap, title: `${i18n.t("Afficher la légende")}`, isActive: true },
                            { icon: faChartTreeMap, title: i18n.t("Masquer la légende") }
                        ], isReduxManaged: true
                    },
                    geolocation: {
                        icon: faMapMarkerAlt, title: i18n.t("Géolocalisation"),
                        states: [
                            { title: i18n.t("Afficher ma localisation") },
                            { title: i18n.t("Masquer ma localisation"), isActive: true }
                        ], isReduxManaged: true
                    },
                    searchLocation: {
                        icon: faSearchLocation, title: i18n.t("Outil de recherche"),
                        state: 0, states: [
                            { title: i18n.t("Afficher l'outil de recherche") },
                            { title: i18n.t("Masquer l'outil de recherche"), isActive: true }
                        ], autoSwitch: true, isDisabledInOffline: true
                    }
                }
            },
            advancedTools: {
                id: 'cSCfLHkG', icon: faTools, title: i18n.t("Outils avancés"), isVisible: () => !['drawingCustomArea', 'drawingProjectSurroundings', 'modifyingProjectSurroundings'].includes(this.props.currentAction)
                , children: {
                    filters: {
                        id: 'ymw1sd3w', icon: faFilterList, title: `${i18n.t("Filtres")}`, shortcut: 'Ctrl+Maj+F', isVisible: () => RightsUtil.canRead(this.props.rights?.filters) && this.props.activeOrganization?.subscription?.filters && this.publicFields?.main.filters,
                        states: [
                            { title: `${i18n.t("Filtres")}`, shortcut: 'Ctrl+Maj+F' },
                            { title: `${i18n.t("Modifier les filtres")}`, shortcut: 'Ctrl+Maj+F', isActive: true },
                        ], isReduxManaged: true
                    },
                    table: {
                        id: 'W8aOEs9N', icon: faTable, title: `${i18n.t("Tableau de données")}`, shortcut: 'Ctrl+Maj+D',
                        isVisible: () => RightsUtil.canRead(this.props.rights?.tables) && !isMobile && this.props.activeOrganization?.subscription?.dataTable && this.publicFields?.main.dataTable,
                        states: [
                            { title: `${i18n.t("Tableau de données")}`, shortcut: 'Ctrl+Maj+D' },
                            { title: `${i18n.t("Supprimer la surbrillance")}`, shortcut: 'Ctrl+Maj+D', isActive: true },
                        ], isReduxManaged: true
                    },
                    statistics: {
                        id: 'Vp7ZCFyO', icon: faChartLine, title: `${i18n.t("Statistiques")}`, shortcut: 'Ctrl+Maj+S', isVisible: () => (
                            (RightsUtil.canRead(this.props.rights?.statistics) || RightsUtil.canRead(this.props.rights?.charts)) && (
                                (this.props.activeOrganization?.subscription?.statistics || this.props.activeOrganization?.subscription?.charts || this.props.activeOrganization?.subscription?.customCharts)
                                && (this.publicFields?.main?.statistics || this.publicFields?.main?.charts)
                            )
                        ),
                        states: [
                            { title: `${i18n.t("Statistiques")}`, shortcut: 'Ctrl+Maj+S' },
                            { title: `${i18n.t("Supprimer la surbrillance")}`, shortcut: 'Ctrl+Maj+S', autoSwitch: true, isActive: true },
                        ], isReduxManaged: true
                    },
                    timeline: {
                        icon: faTimelineArrow, title: `${i18n.t("Ligne du temps")}`, state: 0, states: [
                            { title: `${i18n.t("Afficher la ligne du temps")}` },
                            { title: i18n.t("Masquer la ligne du temps"), isActive: true }
                        ], isReduxManaged: true, isVisible: () => !isMobileOnly && RightsUtil.canRead(this.props.rights?.timeline), isDisabledInOffline: true
                    },
                    printer: { id: 'KOmV5Upc', icon: faPrint, title: `${i18n.t("Export cartographique")}`, shortcut: 'Ctrl+Maj+P', isSelectable: true, isVisible: () => !isMobileOnly && RightsUtil.canRead(this.props.rights?.mapExport) },
                    actions: {
                        id: 'jugChGsl', icon: faUserHelmetSafety, title: `${i18n.t("Actions")}`, shortcut: 'Ctrl+Maj+A', isVisible: () => this.props.project && RightsUtil.canRead(this.props.rights?.actions),
                        states: [
                            { title: `${i18n.t("Gestion des actions")}`, shortcut: 'Ctrl+Maj+A' },
                            { title: `${i18n.t("Valider les liaisons")}`, shortcut: 'Ctrl+Maj+A', isActive: true },
                        ], isReduxManaged: true
                    },
                    history: {
                        id: 'caMdgjoo', icon: faHistory, title: `${i18n.t("Historique du projet")}`, shortcut: 'Ctrl+Maj+H',
                        states: [
                            { title: `${i18n.t("Historique du projet")}`, shortcut: 'Ctrl+Maj+H' },
                            { title: `${i18n.t("Supprimer la surbrillance")}`, shortcut: 'Ctrl+Maj+H', isActive: true },
                        ], isReduxManaged: true, isVisible: () => this.props.project && RightsUtil.canRead(this.props.rights?.projectHistory)
                    }
                }
            },
            treeTools: {
                className: 'trees', icon: faTree, title: i18n.t("Outils arbres"), onlyOne: true, isVisible: () => this.props.logged && RightsUtil.canWrite(this.props.rights?.trees) && !['drawingCustomArea', 'drawingProjectSurroundings', 'modifyingProjectSurroundings'].includes(this.props.currentAction),
                children: {
                    addTree: { id: 'c3rVPSJ2', icon: faPlus, title: `${i18n.t("Ajouter un arbre")}`, shortcut: 'T', isSelectable: true },
                    addTreeWithGps: { icon: faMapMarker, title: i18n.t("Ajouter un arbre via GPS"), isVisible: () => isMobile },
                    addTreesWithAI: { icon: faMicrochipAi, title: `${i18n.t("Ajouter des arbres via IA (beta)")}`, isSelectable: true },
                    modifyTrees: { icon: faList, title: `${i18n.t("Modifier les propriétés des arbres")}` },
                    copyTrees: {
                        className: 'copy', icon: faClipboard, title: i18n.t("Copie"), atLeastOne: true,
                        children: {
                            copyPasteTrees: copyPasteButton,
                            duplicateTrees: duplicateButton
                        }
                    },
                    dragTrees: { ...dragButton, title: `${i18n.t("Déplacement")}`, shortcut: 'Maj+M' },
                    removeTrees: { ...removeButton, title: `${i18n.t("Suppression")}`, shortcut: 'Maj+D' }
                }
            },
            greenSpaceTools: {
                className: 'greenSpaces', icon: faFlowerTulip, title: i18n.t("Outils espaces verts"), onlyOne: true, isVisible: () => this.props.logged && RightsUtil.canWrite(this.props.rights?.greenSpaces) && !['drawingCustomArea', 'drawingProjectSurroundings', 'modifyingProjectSurroundings'].includes(this.props.currentAction),
                children: {
                    addGreenSpace: {
                        className: 'IlcgK1aE', icon: faPlus, title: i18n.t("Ajouter un espace vert"), atLeastOne: true,
                        children: {
                            addPolygon: { icon: faPencilAlt, title: `${i18n.t("Dessin personnalisé")}`, shortcut: 'G', isDefault: true, isSelectable: true },
                            addLine: { icon: faWaveSquare, title: `${i18n.t("Ligne")}`, shortcut: 'L', isSelectable: true },
                            addRectangle: { className: 'JRR4zY38', icon: faSquare, title: `${i18n.t("Rectangle")}`, shortcut: 'R', isSelectable: true },
                            addCircle: { className: 'bUub0LSp', icon: faCircle, title: `${i18n.t("Cercle")}`, shortcut: 'O', isSelectable: true }
                        }
                    },
                    modifyGreenSpaces: { icon: faList, title: `${i18n.t("Modifier les propriétés des espaces verts")}` },
                    copyGreenSpaces: {
                        className: 'copy', icon: faClipboard, title: i18n.t("Copie"), atLeastOne: true,
                        children: {
                            copyPasteGreenSpaces: copyPasteButton,
                            duplicateGreenSpaces: duplicateButton
                        }
                    },
                    splitGreenSpaces: { className: 'Wv7D7QBU', icon: faCubes, title: `${i18n.t("Division")}`, shortcut: 'Alt+S', children: historyButtons },
                    mergeGreenSpaces: { className: 'HM1Z6QIB', icon: faCube, title: `${i18n.t("Fusion")}`, shortcut: 'Alt+F', children: historyButtons },
                    dragGreenSpaces: { ...dragButton, title: `${i18n.t("Déplacement")}`, shortcut: 'Alt+M' },
                    rotateGreenSpaces: { className: 'fpGjuf9z', icon: faSync, title: `${i18n.t("Rotation")}`, shortcut: 'Alt+R', children: historyButtons },
                    editGreenSpaces: { className: 'nmhlobgI', icon: faVectorPolygon, title: `${i18n.t("Redimensionnement")}`, shortcut: 'Alt+E', children: historyButtons },
                    cutGreenSpaces: { className: 'fzYujGUs', icon: faCut, title: `${i18n.t("Découpage")}`, shortcut: 'Alt+C', children: historyButtons },
                    subtractGreenSpaces: { icon: faObjectSubtract, title: `${i18n.t("Soustraction")}`, children: historyButtons },
                    removeGreenSpaces: { ...removeButton, title: `${i18n.t("Suppression")}`, shortcut: 'Alt+D' }
                }
            },
            furnitureTools: {
                className: 'furnitures', icon: faTablePicnic, title: i18n.t("Outils mobilier urbain"), onlyOne: true, isVisible: () => this.props.logged && RightsUtil.canWrite(this.props.rights?.furnitures) && !['drawingCustomArea', 'drawingProjectSurroundings', 'modifyingProjectSurroundings'].includes(this.props.currentAction),
                children: {
                    addFurniture: { icon: faPlus, title: `${i18n.t("Ajouter un mobilier urbain")}`, shortcut: 'M', isSelectable: true },
                    addFurnitureWithGps: { icon: faMapMarker, title: `${i18n.t("Ajouter un mobilier urbain via GPS")}`, isVisible: () => isMobile },
                    modifyFurnitures: { icon: faList, title: `${i18n.t("Modifier les propriétés des mobiliers")}` },
                    copyFurnitures: {
                        className: 'copy', icon: faClipboard, title: `${i18n.t("Copie")}`, atLeastOne: true,
                        children: {
                            copyPasteFurnitures: copyPasteButton,
                            duplicateFurnitures: duplicateButton
                        }
                    },
                    dragFurnitures: { ...dragButton, title: `${i18n.t("Déplacement")}` },
                    removeFurnitures: { ...removeButton, title: `${i18n.t("Suppression")}` }
                }
            },
            stationTools: {
                className: 'stations', icon: faHexagon, title: i18n.t("Outils stations"), onlyOne: true, isVisible: () => this.props.logged && RightsUtil.canWrite(this.props.rights?.stations) && !['drawingCustomArea', 'drawingProjectSurroundings', 'modifyingProjectSurroundings'].includes(this.props.currentAction),
                children: {
                    addStation: {
                        className: 'IlcgK1aE', icon: faPlus, title: i18n.t("Ajouter une station"), atLeastOne: true,
                        children: {
                            addPolygon: { icon: faPencilAlt, title: `${i18n.t("Dessin personnalisé")}`, isDefault: true, isSelectable: true },
                            addRectangle: { icon: faSquare, title: `${i18n.t("Rectangle")}`, isSelectable: true },
                            addCircle: { icon: faCircle, title: `${i18n.t("Cercle")}`, isSelectable: true }
                        }
                    },
                    dragStations: { ...dragButton, title: `${i18n.t("Déplacement")}`, shortcut: 'Alt+M' },
                    rotateStations: { className: 'fpGjuf9z', icon: faSync, title: `${i18n.t("Rotation")}`, shortcut: 'Alt+R', children: historyButtons },
                    editStations: { className: 'nmhlobgI', icon: faVectorPolygon, title: `${i18n.t("Redimensionnement")}`, shortcut: 'Alt+E', children: historyButtons },
                    removeStations: { ...removeButton, title: `${i18n.t("Suppression")}`, shortcut: 'Alt+D' }
                }
            },
            markerTools: {
                className: 'markers', icon: faLocationQuestion, title: i18n.t("Outils repères"), onlyOne: true, isVisible: () => this.props.logged && RightsUtil.canWrite(this.props.rights?.markers) && !['drawingCustomArea', 'drawingProjectSurroundings', 'modifyingProjectSurroundings'].includes(this.props.currentAction),
                children: {
                    addMarker: { icon: faPlus, title: `${i18n.t("Ajouter un repère")}`, shortcut: 'M', isSelectable: true },
                    addMarkerWithGps: { icon: faMapMarker, title: `${i18n.t("Ajouter un repère via GPS")}`, isVisible: () => isMobile },
                    dragMarkers: { ...dragButton, title: `${i18n.t("Déplacement")}` },
                    removeMarkers: { ...removeButton, title: `${i18n.t("Suppression")}` }
                }
            },
            imageTools: {
                id: 'bSgMFHyv', icon: faLayerGroup, title: `${i18n.t("Outils calques")}`, onlyOne: true, isVisible: () => RightsUtil.canRead(this.props.rights?.backgroundImages) && !['drawingCustomArea', 'drawingProjectSurroundings', 'modifyingProjectSurroundings'].includes(this.props.currentAction),
                children: {
                    addImage: {
                        icon: faPlus, title: `${i18n.t("Ajouter un calque")}`, shortcut: 'I', isVisible: () => RightsUtil.canWrite(this.props.rights?.backgroundImages),
                        children: {
                            cancel: cancelButton,
                            confirm: confirmButton
                        }, isDisabledInOffline: true
                    },
                    showImages: {
                        icon: faEye,
                        state: 0, states: [
                            { icon: faEye, title: i18n.t("Afficher les calques") },
                            { icon: faEyeAlt, title: i18n.t("Masquer les calques"), isActive: true }
                        ], autoSwitch: true
                    },
                    orderImages: {
                        icon: faBringFront, title: i18n.t("Gestion"), isVisible: () => RightsUtil.canWrite(this.props.rights?.backgroundImages),
                        children: {
                            cancel: cancelButton,
                            confirm: confirmButton
                        }
                    },
                    editImages: { className: '76MiPldt', icon: faEdit, title: i18n.t("Édition"), isVisible: () => RightsUtil.canWrite(this.props.rights?.backgroundImages), children: historyButtons },
                    removeImages: { ...removeButton, isVisible: () => RightsUtil.canWrite(this.props.rights?.backgroundImages) }
                }
            }
        },
        selectedButtons: [],
        rightClickedButton: null,
        isToolbarExpanded: false
    };

    render() {
        const { isPreview, isToolbarExpanded } = this.props;
        const { buttons, rightClickedButton } = this.state;
        const buttonName = rightClickedButton?.buttonName;
        const isButtonInFavorite = buttons.favoriteTools.children && buttonName && buttons.favoriteTools.children[buttonName] ? true : false;

        return (
            <div id='toolbar' className={isToolbarExpanded ? 'expanded' : ''} style={!isPreview ? { position: 'absolute', top: 0, bottom: 0, display: 'flex', flexDirection: 'column' } : { display: 'flex', flexDirection: 'column' }}>
                <div className='buttons'>{this.renderButtons(0, buttons)}</div>
                <Menu id='toolbar-context-menu' animation={false}>
                    <Item onClick={this.toggleFavorite}>
                        <span style={{ marginRight: '10px' }}>{isButtonInFavorite ? i18n.t("Retirer des favoris") : i18n.t("Ajouter aux favoris")}</span>
                        <FontAwesomeIcon icon={isButtonInFavorite ? faStar : faStarAlt} style={{ marginLeft: 'auto' }} />
                    </Item>
                </Menu>
                <div>
                    <Button
                        title={!isToolbarExpanded ? i18n.t("Afficher les titres") : i18n.t("Masquer les titres")}
                        style={{ justifyContent: 'right' }}
                        onClick={this.props.toggleToolbarExpand}
                    >
                        <FontAwesomeIcon icon={isToolbarExpanded ? faAnglesLeft : faAnglesRight} style={{ marginRight: '10px' }} />
                    </Button>
                </div>
            </div>
        );
    };

    componentDidMount = () => {
        this.publicFields = ProjectsUtil.getProjectPublicFields(this.props.project, this.props.projectCollaborators || []);
        this.setState({ isToolbarExpanded: this.props.isToolbarExpanded });

        window.addEventListener('resize', this.handleFullScreenChange);
        if (this.props.favoriteTools?.length) {
            let favoriteToolsChildren = {};
            this.props.favoriteTools.forEach(favoriteTool => {
                let button = this.findButton(favoriteTool);
                if (button) {
                    while (button['button']) button = button['button'];
                    favoriteToolsChildren[favoriteTool] = button;
                }
            });
            this.setState(prevState => ({
                ...prevState, buttons: {
                    ...prevState.buttons,
                    favoriteTools: { ...prevState.buttons.favoriteTools, children: favoriteToolsChildren }
                }
            }));
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.projectCollaborators !== this.props.projectCollaborators || prevProps.project !== this.props.project || (!this.props.project && !this.publicFields))
            this.publicFields = ProjectsUtil.getProjectPublicFields(this.props.project, this.props.projectCollaborators || []);
        if (prevProps.isToolbarExpanded !== this.props.isToolbarExpanded) { // Permet d'attendre la fin de l'animation pour masquer les titres
            if (this.props.isToolbarExpanded) this.setState({ isToolbarExpanded: true });
            else setTimeout(() => this.setState({ isToolbarExpanded: false }), 500);
        }
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.handleFullScreenChange);
        Object.keys(this.props.buttonStates).forEach(buttonName => {
            if (this.props.buttonStates[buttonName]) this.props.setButtonState(buttonName, 0);
        });
    }

    renderButtons = (index, buttons) => {
        const { selectedButtons } = this.state;
        if (selectedButtons.length > 1 && selectedButtons[0]) {
            const category = selectedButtons[0].name === 'treeTools' ? 'Arbre' : selectedButtons[0].name === 'greenSpaceTools' ? 'Espace vert' : 'BackgroundImage';
            if (selectedButtons[index - 1]?.name === 'remove' && this.props.selectedElements.filter(l => l.feature.properties.category === category).length) return;
        }

        return (
            <div id={`toolbar-category-${index}`} style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, minHeight: 'fit-content', maxWidth: '100%' }}>
                {index > 0 && <>
                    <Divider style={{ margin: '0', backgroundColor: 'var(--primary-100)', height: '3px', border: 'none' }} />
                    <div className='divider-pointer'></div>
                </>}
                {Object.keys(buttons).map(buttonName => {
                    const button = buttons[buttonName];
                    const buttonState = button.isReduxManaged ? this.props.buttonStates[buttonName] : button.state;
                    const title = button.states?.[buttonState]?.title || button.title;
                    const shortcut = button.states?.[buttonState]?.shortcut || button.shortcut;
                    const icon = button.icon || button.states?.[buttonState]?.icon;
                    const isActive = button.isActive || button.states?.[buttonState]?.isActive;

                    return (!button.isVisible || button.isVisible())
                        // Si le bouton a des enfants, il faut qu'au moins un de ceux-ci puisse être affiché pour render le parent
                        && (!button.children || Object.keys(button.children).some(childName => !button.children[childName].isVisible || button.children[childName].isVisible()))
                        && (
                            <Button
                                key={buttonName} id={button.id} title={`${title}${shortcut ? ` (${shortcut})` : ''}`}
                                className={`${(selectedButtons.includes(button) || isActive) ? 'active' : ''} ${button.className || ''}`}
                                style={{ justifyContent: this.state.isToolbarExpanded ? 'left' : 'center', ...(this.state.isToolbarExpanded ? { paddingLeft: '9.5px' } : {}) }}
                                disabled={button.isDisabled || (button.isDisabledInOffline && !this.props.isOnline && !isActive) || (selectedButtons[index - 1]?.onlyOne && selectedButtons[index] && selectedButtons[index] !== button)}
                                onClick={() => { this.handleButtonClick(index, buttonName, button) }} onContextMenu={(e) => this.handleContextMenu(e, index, buttonName, button)}
                            >
                                <FontAwesomeIcon icon={icon} />
                                {this.state.isToolbarExpanded && <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{title}</span>}
                            </Button>);
                })}

                {selectedButtons[index]?.children && (!selectedButtons[index - 1] || Object.keys(selectedButtons[index - 1].children).some(buttonName => (
                    selectedButtons[index - 1].children[buttonName] === selectedButtons[index]
                ))) && this.renderButtons(index + 1, selectedButtons[index].children)}
            </div>
        );
    };

    handleButtonClick = (index, buttonName, button) => {
        if (button.isSelectable || button.children) {
            button.name = buttonName;
            this.setState(({ selectedButtons }) => {
                if (selectedButtons[index] === button && !selectedButtons[index - 1]?.atLeastOne) {
                    if (buttonName.includes('Tools')) delete selectedButtons[index];
                    else selectedButtons = selectedButtons.slice(0, index);
                } else {
                    selectedButtons[index] = button;
                    if (!buttonName.includes('Tools'))
                        selectedButtons = selectedButtons.slice(0, index + 1);
                    if (button.children) {
                        const defaultButtonName = Object.keys(button.children).find(buttonName => button.children[buttonName].isDefault);
                        if (defaultButtonName) {
                            button.children[defaultButtonName].name = defaultButtonName;
                            selectedButtons.push(button.children[defaultButtonName]);
                        }
                    }
                }

                return { selectedButtons };
            });
        }

        const buttonState = button.isReduxManaged ? this.props.buttonStates[buttonName] : button.state;
        if (!this.props.isPreview) this.props.handleToolbarButtonClick(buttonName, buttonState);

        if (button.states && (button.autoSwitch || button.states[buttonState].autoSwitch)) {
            button.state++;
            if (!button.states[button.state]) button.state = 0;
            this.setState(prevState => ({ buttons: prevState.buttons }));
        }
    }

    handleContextMenu = (event, index, buttonName, button) => {
        if (index === 1) {
            contextMenu.show({ event, id: 'toolbar-context-menu' });
            this.setState({ rightClickedButton: { buttonName, button } });
        }
    }

    toggleFavorite = () => {
        const { rightClickedButton, buttons, selectedButtons } = this.state;
        let favoriteTools = buttons.favoriteTools;
        favoriteTools.children = favoriteTools.children || {};
        if (favoriteTools.children[rightClickedButton.buttonName]) {
            delete favoriteTools.children[rightClickedButton.buttonName];
            showToast('favorite_tool_removed');
        } else {
            favoriteTools.children = { ...favoriteTools.children, [rightClickedButton.buttonName]: rightClickedButton.button };
            showToast('favorite_tool_added');
        }

        const index = selectedButtons.indexOf(buttons.favoriteTools);
        if (index !== -1) {
            selectedButtons.splice(index, 1);
            selectedButtons.push(favoriteTools);
        }

        this.setState(prevState => ({
            ...prevState, buttons: {
                ...prevState.buttons,
                favoriteTools
            }, rightClickedButton: null,
            selectedButtons
        }), () => {
            localStorage.setItem('favoriteTools', JSON.stringify(Object.keys(favoriteTools.children)));
            this.props.setFavoriteTools(Object.keys(favoriteTools.children));
        });
    }

    findButton = (buttonName) => {
        let index = -1, buttonPath;
        const findButton = (buttons, path = []) => {
            if (!button) {
                Object.keys(buttons).forEach(name => {
                    const newPath = [...path, name];
                    if (name === buttonName) {
                        index = newPath.length - 1;
                        buttonPath = newPath;
                        button = buttons[name];
                    } else if (buttons[name].children)
                        findButton(buttons[name].children, newPath);
                });
            }
        };

        let button;
        findButton(this.state.buttons);
        return { index, path: buttonPath, button };
    }

    clickButton = (buttonName) => { // Utilisé uniquement pour les boutons pas gérés par Redux
        const { buttons, selectedButtons } = this.state;

        const { path } = this.findButton(buttonName);
        let index = 0;

        const clickButton = (button) => {
            if (button.isDisabled || (button.isVisible && !button.isVisible())) return;
            if (!selectedButtons.includes(button) && !(button.isActive || button.states?.[button.state]?.isActive))
                this.handleButtonClick(index, path[index], button);
            index++;
            if (path[index] && button.children) setTimeout(() => clickButton(button.children[path[index]]), 100); // Sans le timeout certaines actions sont trop rapides
        }

        if (path?.[0]) clickButton(buttons[path[0]]);
    }

    setButtonState = (buttonName, state, oldState = null) => {
        const { button } = this.findButton(buttonName);
        if ((oldState === null || button.state === oldState) && button?.states?.[state]) {
            button.state = state;
            this.setState(prevState => ({ buttons: prevState.buttons }));
            return true;
        }
        return false;
    }

    setButtonsIsDisabled = (buttonNames, isDisabled) => {
        buttonNames.forEach(buttonName => {
            const { button } = this.findButton(buttonName);
            if (button) button.isDisabled = isDisabled;
        });

        this.setState(prevState => ({ buttons: prevState.buttons }));
    }

    isButtonClickable = (buttonName) => {
        const { buttons } = this.state;
        const { path } = this.findButton(buttonName);
        let button, isClickable = true;
        for (buttonName of path) {
            button = button ? button.children[buttonName] : buttons[buttonName];
            if (button.isDisabled || (button.isVisible && !button.isVisible())) isClickable = false;
        }
        return isClickable;
    }

    setButtonsIsVisible = (buttonNames, isVisible) => {
        buttonNames.forEach(buttonName => {
            const { button } = this.findButton(buttonName);
            if (button) button.isVisible = () => isVisible;
        });

        this.setState(prevState => ({ buttons: prevState.buttons }));
    }

    resetButtonSelection = (index) => {
        this.setState(({ selectedButtons }) => {
            selectedButtons = selectedButtons.slice(0, index);
            return { selectedButtons };
        });
    }

    handleFullScreenChange = () => {
        if (this.props.buttonStates.fullscreen !== 0 && window.screen.height > window.innerHeight)
            this.setState(({ buttons }) => {
                this.props.buttonStates.fullscreen = 0;
                return buttons;
            });
    }
}

const mapStateToProps = (state) => {
    return {
        isDarkTheme: state.isDarkTheme,
        rights: state.rights,
        project: state.project,
        projectCollaborators: state.projectCollaborators,
        activeOrganization: state.activeOrganization,
        buttonStates: state.buttonStates,
        favoriteTools: state.favoriteTools,
        isToolbarExpanded: state.isToolbarExpanded,
        isOnline: state.isOnline,
        currentAction: state.currentAction
    };
}

const mapDispatchToProps = {
    setButtonState,
    setFavoriteTools,
    toggleToolbarExpand
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Toolbar);