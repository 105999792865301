import React, { Component } from 'react';
// Librairies
import { isMobileOnly, isMobile, withOrientationChange } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { difference, polygon, multiPolygon, bboxPolygon, bbox, transformScale } from '@turf/turf';
import i18n from '../../locales/i18n';
import screenfull from 'screenfull';
/*     Leaflet     */
import L from 'leaflet';
import 'leaflet.gridlayer.googlemutant';
import '@geoman-io/leaflet-geoman-free';
import 'Leaflet.MultiOptionsPolyline';
import 'leaflet.locatecontrol/dist/L.Control.Locate.min';
// Composants
import ModalNavbar from '../Navbars/ModalNavbar';
import ProjectList from '../Lists/ProjectList';
import ProjectFilesGallery from '../Galleries/ProjectFilesGallery';
import Toolbar from '../Navbars/Toolbar';
import GeosearchInput from '../Utils/GeosearchInput';
import BaseLayersMenu from '../Utils/BaseLayersMenu';
/*     Forms     */
import FolderCreationForm from '../Forms/Projects/FolderCreationForm';
import ProjectForm from '../Forms/Projects/ProjectForm/ProjectForm';
import ModifySurroundingsForm from '../Forms/Projects/ModifySurroundingsForm';
import ProjectShrinkageForm from '../Forms/Projects/ProjectShrinkageForm';
import CollaboratorsForm from '../Forms/Projects/CollaboratorsForm';
import RoleList from '../Lists/RoleList';
import ImportForm from '../Forms/Projects/ImportForm/ImportForm';
import ExportForm from '../Forms/Projects/ExportForm';
import ExitFormPopup from '../Utils/ExitFormPopup';
// Redux
import { connect } from 'react-redux';
import { setProject, setRights, setUserProjects, setProjectInvitations, setViewProjectAsData } from '../../actionCreators/projectsActions';
import { setCurrentAction, setButtonState } from '../../actionCreators/appActions'
import { setLayer } from '../../actionCreators/elementsActions';
import { setEditedProperties, setFilterFormState, setProjectListState } from '../../actionCreators/componentsActions';
// Ressources
import GDK from '../../resources/gdk'; // Google Dark Theme
// Semantic UI
import { Dimmer, Loader, Segment, Modal, TransitionablePortal, Button } from 'semantic-ui-react'
// Services
import TreesService from '../../services/TreesService';
import GreenSpacesService from '../../services/GreenSpacesService';
import FurnituresService from '../../services/FurnituresService';
import MarkersService from '../../services/MarkersService';
import StationsService from '../../services/StationsService';
// Styles
import 'leaflet/dist/leaflet.css';
import 'leaflet-geosearch/assets/css/leaflet.css';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
// Utils
import { showToast } from '../../utils/ToastsUtil';
import StylesUtil from '../../utils/StylesUtil';
import GeometriesUtil from '../../utils/GeometriesUtil';
import ProjectsUtil from '../../utils/ProjectsUtil';
import GeoJsonUtil from '../../utils/GeoJsonUtil';

class Map extends Component {
    state = {
        loader: {
            active: false,
            message: i18n.t("Chargement de la carte...")
        },
        modal: { // Composant affichant les différents formulaires
            visible: false,
            title: ''
        },
        modalContentType: '', // Type de contenu à afficher dans le modal
        isFullScreen: ['true', null].includes(localStorage.getItem('isFullScreen')) ? true : false,
        currentTools: null,
        projectToEdit: null,
        projectToDuplicate: null,
        differentProperties: null,
        angle: 0,
        elementsOutsideNewBounds: null,
        showExitForm: false,
        showGeosearch: false
    };

    render() {
        const { exitFormWithChanges, isToolbarExpanded } = this.props;
        const { loader, modal, isFullScreen, modalContentType, projectToEdit, projectToDuplicate, elementsOutsideNewBounds, showExitForm, showGeosearch, baseLayers } = this.state;
        const isMobileLandscape = isMobileOnly && this.props.isLandscape;
        const { segmentStyle, dimmerStyle, modalMobileStyle } = StylesUtil.getMapStyles();
        const fullScreenDeactivable = !['ProjectList', 'ProjectCreationForm', 'ProjectDuplicationForm', 'ProjectModificationForm', 'CollaboratorsForm', 'RoleList', 'ImportForm', 'ExportForm', 'ProjectFilesGallery'].includes(modalContentType);

        return (
            <>
                <Helmet>
                    {isMobileLandscape && <link rel='stylesheet' type='text/css' href='/mobile-landscape.css' />}
                    {(isFullScreen || !fullScreenDeactivable) && !isMobile && <link rel='stylesheet' type='text/css' href='/fullscreen-modal.css' />}
                </Helmet>
                <Segment style={segmentStyle} id='map-segment'>
                    <Dimmer active={loader.active} style={dimmerStyle}>
                        <Loader content={loader.message} />
                    </Dimmer>
                    <TransitionablePortal open={modal.visible} transition={{ animation: 'fade up', duration: exitFormWithChanges ? 0 : 300 }} closeOnDocumentClick={false}>
                        <Modal
                            id='mobile-modal' size={'large'} style={isMobile || isFullScreen || !fullScreenDeactivable ? modalMobileStyle : null} open
                            closeOnDocumentClick={false} closeOnEscape={this.props.currentAction !== 'modifyingProjectSurroundings'}
                            closeIcon={false} closeOnDimmerClick={this.props.currentAction !== 'modifyingProjectSurroundings'}
                            onMount={() => { if (document.activeElement.className.includes('leaflet')) document.activeElement.blur(); }}
                            onClose={() => {
                                if (exitFormWithChanges) {
                                    this.setState({ showExitForm: true });
                                    return false;
                                }

                                this.hideForm(false);
                                if (projectToEdit || projectToDuplicate) this.setState({ projectToEdit: null, projectToDuplicate: null });
                                this.props.setUserProjects(null);
                                this.props.setProjectInvitations(null);
                            }}
                        >
                            <Modal.Header style={{ display: 'flex', padding: '7px 2px 7px 11px' }}>
                                {['ProjectModificationForm', 'ProjectDuplicationForm', 'ProjectCreationForm', 'CollaboratorsForm', 'RoleList', 'ImportForm', 'ExportForm', 'ProjectFilesGallery'].includes(modalContentType) &&
                                    <Button
                                        icon='arrow left' color='blue' size='small' id='SMRKL17r' title='Retour'
                                        style={{ marginRight: '10px' }} onClick={this.handleBackButtonClick}
                                    />}
                                <div style={{ alignSelf: 'center' }}>
                                    {(this.props.currentAction !== 'viewingModal' || isMobileOnly) && modal.title}
                                    {(projectToEdit || projectToDuplicate) && !isMobileOnly &&
                                        <div style={{ display: 'inline-block', color: 'rgba(255, 255, 255, 0.6)', marginLeft: '5px' }}>/ {(projectToEdit || projectToDuplicate)?.label}</div>}
                                </div>
                                <div style={{ marginLeft: 'auto', zIndex: 1 }}>
                                    {!isMobile && fullScreenDeactivable &&
                                        <Button
                                            icon={isFullScreen ? 'compress' : 'expand'} size='small'
                                            onClick={() => {
                                                this.setState(prevState => ({ isFullScreen: !prevState.isFullScreen }),
                                                    () => localStorage.setItem('isFullScreen', this.state.isFullScreen));
                                            }}
                                        />}
                                    {this.props.currentAction !== 'modifyingProjectSurroundings' &&
                                        <Button
                                            icon='close' color='blue' size='small' id='kmP9ozeA'
                                            onClick={() => {
                                                if (exitFormWithChanges) {
                                                    this.setState({ showExitForm: true });
                                                    return;
                                                }

                                                if (projectToEdit || projectToDuplicate) this.setState({ projectToEdit: null, projectToDuplicate: null });
                                                this.hideForm(false)
                                            }}
                                        />}
                                </div>
                            </Modal.Header>
                            {this.props.currentAction === 'viewingModal' && this.props.layer &&
                                <ModalNavbar
                                    logged={this.props.logged} changeModalContentType={this.changeModalContentType} hideForm={this.hideForm}
                                    showExportPdfForm={this.showExportPdfForm} modalContentType={modalContentType}
                                />}
                            <Modal.Content
                                id='modal-content' className='scrollable-container'
                                style={isMobile ? { height: '0px', padding: 0 } : (isFullScreen || !fullScreenDeactivable ? { height: '0px' } : null)}
                            >
                                <Modal.Description style={{ height: '100%' }}>
                                    {modalContentType === 'ProjectFilesGallery' && <ProjectFilesGallery projectToEdit={projectToEdit} changeModalContentType={this.changeModalContentType} />}
                                    {modalContentType === 'ProjectList' &&
                                        <ProjectList
                                            hideForm={this.hideForm} showForm={this.showForm} changeModalContentType={this.changeModalContentType}
                                            showProjectMap={this.showProjectMap} drawProjectSurroundings={this.drawProjectSurroundings}
                                        />}
                                    {modalContentType === 'FolderCreationForm' &&
                                        <FolderCreationForm showForm={this.showForm} changeModalContentType={this.changeModalContentType} />}
                                    {modalContentType === 'ProjectCreationForm' &&
                                        <ProjectForm
                                            showForm={this.showForm} changeModalContentType={this.changeModalContentType}
                                            drawProjectSurroundings={this.drawProjectSurroundings} modifyProjectSurroundings={this.modifyProjectSurroundings}
                                        />}
                                    {modalContentType === 'ProjectDuplicationForm' &&
                                        <ProjectForm
                                            showForm={this.showForm} changeModalContentType={this.changeModalContentType} projectToDuplicate={projectToDuplicate} resetProjectToDuplicate={() => this.setState({ projectToDuplicate: null })}
                                            finish={this.handleBackButtonClick} drawProjectSurroundings={this.drawProjectSurroundings} modifyProjectSurroundings={this.modifyProjectSurroundings}
                                        />}
                                    {modalContentType === 'ProjectModificationForm' &&
                                        <ProjectForm
                                            changeModalContentType={this.changeModalContentType} drawProjectSurroundings={this.drawProjectSurroundings}
                                            projectToEdit={projectToEdit} modifyProjectSurroundings={this.modifyProjectSurroundings}
                                        />}
                                    {modalContentType === 'ProjectShrinkageForm' &&
                                        <ProjectShrinkageForm
                                            elementsOutsideNewBounds={elementsOutsideNewBounds}
                                            cancelSurroundingsShrinkage={this.cancelSurroundingsShrinkage}
                                            confirmProjectShrinkage={this.confirmProjectShrinkage}
                                        />}
                                    {modalContentType === 'CollaboratorsForm' &&
                                        <CollaboratorsForm projectToEdit={projectToEdit} changeModalContentType={this.changeModalContentType} />}
                                    {modalContentType === 'RoleList' &&
                                        <RoleList
                                            projectToEdit={projectToEdit} changeModalContentType={this.changeModalContentType} drawCustomArea={this.drawCustomArea}
                                            hideForm={this.hideForm} showProjectMap={this.showProjectMap}
                                        />}
                                    {modalContentType === 'ImportForm' &&
                                        <ImportForm project={projectToEdit} changeModalContentType={this.changeModalContentType} />}
                                    {modalContentType === 'ExportForm' &&
                                        <ExportForm project={projectToEdit} changeModalContentType={this.changeModalContentType} />}
                                </Modal.Description>
                            </Modal.Content>
                        </Modal>
                    </TransitionablePortal>
                    {modalContentType === 'ModifySurroundingsForm' &&
                        <ModifySurroundingsForm
                            finishSurroundingsModification={this.finishSurroundingsModification} finishSurroundingsUpdate={this.finishSurroundingsUpdate}
                        />}
                    <div style={{ height: '100%' }}>
                        <Toolbar ref={this.toolbarRef} logged={this.props.logged} handleToolbarButtonClick={this.handleToolbarButtonClick} />
                    </div>
                    <div id='mapContainer' style={{ left: isToolbarExpanded ? '300px' : '40px', transition: 'left 500ms' }}></div>
                </Segment>
                {showExitForm && <ExitFormPopup close={() => this.setState({ showExitForm: false })} hideForm={this.hideForm} />}
                {showGeosearch && <GeosearchInput map={this.map} closeInput={() => this.setState({ showGeosearch: false }, () => this.toolbarRef.current.setButtonState('searchLocation', 0, 1))} />}
                <BaseLayersMenu map={this.map} baseLayers={baseLayers} showBaseLayer={this.showBaseLayer} />
            </>
        );
    }

    componentDidMount = () => {
        if (this.props.projectListVisible) this.changeModalContentType('ProjectList', i18n.t("Mes projets"));
        this.isToolbarInitialized = false;
        this.toolbarRef = React.createRef();
        // Réinitialisation state Redux
        this.props.changeActiveItem('home');
        this.props.setProject(null);
        this.props.setCurrentAction('');
        this.props.setRights(null);
        this.props.setFilterFormState(null);
        this.props.setLayer(null); // Layer qu'on ajoute ou modifie
        this.props.setViewProjectAsData(null);

        // Définition des layers
        this.googleLayerRoadmap = L.gridLayer.googleMutant({
            maxZoom: 22,
            type: 'roadmap',
            styles: [
                { elementType: 'labels', stylers: [{ visibility: 'off' }] }, // On masque tous les labels
                { featureType: 'road', elementType: 'labels.text', stylers: [{ visibility: 'on' }] }, // Sauf les noms des routes
                { featureType: 'administrative', elementType: 'labels.text', stylers: [{ visibility: 'on' }] } // Et des communes/provinces
            ]
        });

        this.googleLayerRoadmapDark = L.gridLayer.googleMutant({
            maxZoom: 22,
            type: 'roadmap',
            styles: GDK
        });

        this.googleLayerHybrid = L.gridLayer.googleMutant({
            maxZoom: 22,
            type: 'hybrid',
            styles: [
                { elementType: 'labels', stylers: [{ visibility: 'off' }] },
                { featureType: 'road', elementType: 'labels.text', stylers: [{ visibility: 'on' }] },
                { featureType: 'administrative', elementType: 'labels.text', stylers: [{ visibility: 'on' }] }
            ]
        });

        this.OSMLayer = L.tileLayer('https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png', {
            attribution: 'donn&eacute;es &copy; <a href="//osm.org/copyright">OpenStreetMap</a>/ODbL - rendu <a href="//openstreetmap.fr">OSM France</a>',
            maxNativeZoom: 20,
            maxZoom: 22
        });

        const baseLayers = [
            { label: 'Google Roadmap', name: 'Roadmap', layer: this.googleLayerRoadmap, isShown: !this.props.isDarkTheme },
            { label: 'Google Roadmap', name: 'Roadmap Dark', layer: this.googleLayerRoadmapDark, isShown: this.props.isDarkTheme },
            { label: 'Google Hybrid', name: 'Hybrid', layer: this.googleLayerHybrid, isShown: false },
            { label: 'OSM', name: 'OSM', layer: this.OSMLayer, isShown: false }
        ];
        this.setState({ baseLayers });

        /* Création de la carte */
        // Définition de la config de la carte
        const corner1 = L.latLng(-90, -200);
        const corner2 = L.latLng(90, 200);
        this.maxBounds = L.latLngBounds(corner1, corner2);
        // Réinitialisation du container de la map
        let mapContainer = document.getElementById('mapContainer');
        if (!mapContainer) return;
        mapContainer.innerHTML = '<div id="map"></div>';
        // Création & initialisation
        this.map = L.map('map', {
            zoomControl: false,
            layers: baseLayers.find(baseLayer => baseLayer.isShown)?.layer, // Layer par défaut
            minZoom: 3,
            maxBounds: this.maxBounds,
            maxBoundsViscosity: 1.0
        }).setView([50.5, 4.5], 8);
        this.map.locate({ setView: true });
        // Localisation
        this.locateControl = L.control.locate({
            locateOptions: { enableHighAccuracy: true }
        }).addTo(this.map);

        // Assignation des évenements à la carte
        this.assignMapEvents();
        if (this.props.openProjectCreation) this.changeModalContentType('ProjectCreationForm', i18n.t("Création d'un projet"));
    }

    componentDidUpdate = (prevProps) => {
        if (this.state.projectToEdit?.id && JSON.stringify(prevProps.projects) !== JSON.stringify(this.props.projects)) {
            const project = ProjectsUtil.getBaseProject(this.state.projectToEdit.id, this.state.projectToEdit.path, this.props.projects);
            if (project) this.setState({ projectToEdit: project });
        } else if (this.state.projectToDuplicate?.id && JSON.stringify(prevProps.projects) !== JSON.stringify(this.props.projects)) {
            const project = ProjectsUtil.getBaseProject(this.state.projectToDuplicate.id, this.state.projectToDuplicate.path, this.props.projects);
            if (project) this.setState({ projectToDuplicate: project });
        }

        if (this.props.projectListVisible && ![
            'ProjectList', 'ProjectModificationForm', 'ProjectDuplicationForm', 'ProjectCreationForm', 'FolderCreationForm', 'FolderModificationForm', 'CollaboratorsForm', 'RoleList',
            'ImportForm', 'ExportForm', 'ProjectFilesGallery'
        ].includes(this.state.modalContentType))
            this.changeModalContentType('ProjectList', i18n.t("Mes projets"));

        if (prevProps.isDarkTheme !== this.props.isDarkTheme) {
            this.map.removeLayer(this.props.isDarkTheme ? this.googleLayerRoadmap : this.googleLayerRoadmapDark);
            this.map.addLayer(this.props.isDarkTheme ? this.googleLayerRoadmapDark : this.googleLayerRoadmap);
        }

        if (!this.isToolbarInitialized && this.toolbarRef.current) {
            this.isToolbarInitialized = true;
            this.toolbarRef.current.setButtonsIsVisible(['lasso', 'references', 'legend'], false);
            const basicToolsButton = document.getElementById('uhRCkpxG');
            if (basicToolsButton) basicToolsButton.click();
        }
    }

    handleBackButtonClick = () => this.setState({ projectToEdit: null, projectToDuplicate: null }, () => this.changeModalContentType('ProjectList', i18n.t("Mes projets")));
    assignMapEvents = () => {
        const customTranslation = {}; // https://github.com/geoman-io/leaflet-geoman/blob/HEAD/src/assets/translations/en.json
        this.map.pm.setLang('customName', customTranslation, 'fr');
        this.map.pm.setGlobalOptions({
            snapDistance: 5, tooltips: false,
            templineStyle: { color: 'var(--primary-100)', fillColor: 'var(--secondary-100)', fillOpacity: 0.7 },
            hintlineStyle: { color: 'var(--primary-100)', dashArray: [5, 5] },
            pathOptions: { color: 'var(--primary-100)', fillColor: 'var(--secondary-100)', fillOpacity: 0.7 },
            removeVertexValidation: GeometriesUtil.removeVertexValidation
        });

        this.map.on('pm:create', e => {
            const { currentAction } = this.props;
            if (['drawingProjectSurroundings', 'modifyingProjectSurroundings'].includes(currentAction)) { // Si on dessine la zone géographique d'un projet
                this.map.removeLayer(e.layer);
                this.props.setLayer(e.layer).then(() => {
                    const surroundings = GeoJsonUtil.generatePolygonFeature(null, e.layer._latlngs);
                    if (surroundings) {
                        const latLngs = GeometriesUtil.convertPolygonCoordinatesToLatLngs(surroundings.geometry.coordinates, surroundings.geometry.type === 'MultiPolygon');
                        this.surroundingsDottedLayer = L.polygon(latLngs, { color: "#000000", opacity: 0.4, fillOpacity: 0, weight: 4, dashArray: '10 10', pmIgnore: true }).addTo(this.map);
                        const surroundingsPolygon = surroundings.geometry.type === 'Polygon'
                            ? polygon(surroundings.geometry.coordinates)
                            : multiPolygon(surroundings.geometry.coordinates);
                        const invertedSurroundings = GeometriesUtil.invertPolygon(surroundingsPolygon);
                        const invertedLatLngs = GeometriesUtil.convertPolygonCoordinatesToLatLngs(invertedSurroundings.geometry.coordinates);
                        this.tmpSurroundingsLayer = L.polygon(invertedLatLngs, this.props.isDarkTheme ? StylesUtil.getSurroundingsDarkStyle() : StylesUtil.getSurroundingsLightStyle()).addTo(this.map);
                        this.tmpSurroundingsLayer.pm.enable({ removeVertexValidation: GeometriesUtil.removeVertexValidation });
                    }

                    this.map.setMaxBounds(this.maxBounds);
                });
            } else if (currentAction === 'drawingCustomArea') {
                this.map.removeLayer(e.layer);
                this.props.setLayer(e.layer).then(() => {
                    this.props.setCurrentAction('').then(() => {
                        this.changeModalContentType('RoleList', 'Gestion des rôles', this.props.project);
                        this.map.removeLayer(this.tmpSurroundingsLayer);
                        this.map.setMaxBounds(null);
                    });
                });
            }
        });
    }

    showBaseLayer = (layerToShow) => {
        let hiddenBaseLayer;
        this.state.baseLayers.filter(baseLayer => baseLayer.layer !== layerToShow).forEach(baseLayer => {
            if (this.map.hasLayer(baseLayer.layer)) {
                hiddenBaseLayer = baseLayer.layer;
                this.map.removeLayer(baseLayer.layer);
                this.map.addLayer(layerToShow);
            }
        });

        this.setState(prevState => ({
            baseLayers: prevState.baseLayers.map(baseLayer => ({ ...baseLayer, isShown: baseLayer.layer === layerToShow }))
        }));

        return hiddenBaseLayer;
    }

    changeModalContentType = (type, title) => { // Permet de repasser de l'historique au formulaire fourni en paramètres
        this.setState({
            modal: { visible: true, title: title },
            modalContentType: type
        });
    }

    hideForm = (success) => { // Lorsqu'on clique sur le bouton annuler dans un formulaire
        this.setState({ modal: { visible: false } }, () => {
            if (this.props.userProjects) this.props.setUserProjects(null);
            if (this.props.projectListState) this.props.setProjectListState(null);
            document.getElementById('map').focus();
            if (!success && this.props.layer && !this.props.layer[0]) // Si on est pas en mode édition et que l'ajout a échoué
                this.map.removeLayer(this.props.layer); // On retire le layer affiché sur la map
            if (['ProjectList', 'ProjectModificationForm', 'ProjectDuplicationForm', 'ProjectCreationForm', 'CollaboratorsForm', 'RoleList', 'ImportForm', 'ExportForm', 'ProjectFilesGallery'].includes(this.state.modalContentType))
                this.props.showProjectList(false, { renderDefaultMap: false });
            if (this.props.currentAction !== 'modifyingProjectSurroundings') {
                this.props.setEditedProperties(null);
                this.props.setCurrentAction('');
                this.map.pm.setGlobalOptions({ allowSelfIntersection: true });
                this.map.pm.disableDraw(); // On désactive le mode 'dessin'
            }

            this.setState({ modalContentType: '' });
            this.props.setLayer(null);
        });
    }

    showProjectMap = (project) => {
        this.props.setUserProjects(null);
        this.props.setProject(project);
        this.props.history.push('/projects/' + project.id);
    }

    showForm = (project, form, title) => {
        if (form === 'ProjectDuplicationForm') this.setState({ projectToDuplicate: project, projectToEdit: null }, () => this.changeModalContentType(form, title));
        else this.setState({ projectToEdit: project, projectToDuplicate: null }, () => this.changeModalContentType(form, title));
    }

    drawProjectSurroundings = (isModification = false, surroundings = null) => {
        this.props.showProjectList(false);
        this.props.setCurrentAction(isModification ? 'modifyingProjectSurroundings' : 'drawingProjectSurroundings');

        if (this.props.layer) {
            this.map.removeLayer(this.props.layer);
            this.props.setLayer(null);
        }

        this.setState({
            modal: { visible: false, title: '' },
            modalContentType: 'ModifySurroundingsForm'
        });

        if (!isModification || !surroundings) this.map.pm.enableDraw('Polygon');
        else this.tmpSurroundingsLayer.pm.enable({ removeVertexValidation: GeometriesUtil.removeVertexValidation });
    }

    cancelSurroundingsShrinkage = () => {
        this.setState({
            elementsOutsideNewBounds: null,
            modal: { visible: false, title: '' },
            modalContentType: 'ModifySurroundingsForm'
        });
    }

    finishSurroundingsUpdate = (layer = null) => { // Aussi utilisée pour l'annulation
        const { projectToDuplicate, projectToEdit } = this.state;
        let type = projectToDuplicate ? 'duplication' : projectToEdit ? 'modification' : 'creation';
        if (!this.props.editedProperties.project.id) type = 'creation';

        this.props.setCurrentAction('').then(() => {
            if (this.tmpSurroundingsLayer) this.tmpSurroundingsLayer.pm.disable();
            this.props.setLayer(layer).then(() => {
                this.changeModalContentType(type === 'modification' ? 'ProjectModificationForm' : type === 'duplication' ? 'ProjectDuplicationForm' : 'ProjectCreationForm',
                    type === 'modification' ? i18n.t("Paramètres du projet") : type === 'duplication' ? i18n.t("Duplication du projet") : i18n.t("Création d'un projet"));
                if (this.tmpSurroundingsLayer) this.map.removeLayer(this.tmpSurroundingsLayer);
                if (this.surroundingsDottedLayer) this.map.removeLayer(this.surroundingsDottedLayer);
            });
        });
    };

    finishSurroundingsModification = () => {
        if (this.props.editedProperties.project && !this.props.editedProperties.project.id) {
            this.finishSurroundingsUpdate(this.tmpSurroundingsLayer);
            return;
        }

        const initialProject = ProjectsUtil.getBaseProject(this.props.editedProperties.project.id, this.props.editedProperties.project.path, this.props.projects);
        const initialProjectGeometry = JSON.parse(initialProject.surroundings).geometry;
        const invertedSurroundings = GeometriesUtil.invertPolygon(polygon(GeometriesUtil.convertPolygonLatLngsToCoordinates(this.tmpSurroundingsLayer.getLatLngs())));
        const removedArea = difference( // Il faut que le nouveau polygone recouvre entièrement l'ancien
            initialProjectGeometry.type === 'MultiPolygon' ? multiPolygon(initialProjectGeometry.coordinates) : polygon(initialProjectGeometry.coordinates),
            invertedSurroundings
        );
        if (!removedArea) this.finishSurroundingsUpdate(this.tmpSurroundingsLayer);
        else {
            const promises = [];
            this.setState({ loader: { active: true, message: i18n.t("Récupération des éléments à supprimer en cours...") } });
            promises.push(TreesService.getTrees(this.props.editedProperties.project.id, { bounds: JSON.stringify(removedArea.geometry) }));
            promises.push(GreenSpacesService.getGreenSpaces(this.props.editedProperties.project.id, { bounds: JSON.stringify(removedArea.geometry) }));
            promises.push(FurnituresService.getFurnitures(this.props.editedProperties.project.id, { bounds: JSON.stringify(removedArea.geometry) }));
            promises.push(MarkersService.getMarkers(this.props.editedProperties.project.id, { bounds: JSON.stringify(removedArea.geometry) }));
            promises.push(StationsService.getStations(this.props.editedProperties.project.id, { bounds: JSON.stringify(removedArea.geometry) }));
            Promise.all(promises).then(([treesData, greenSpacesData, furnituresData, markers, stations]) => {
                this.setState({ loader: { active: false } });
                if (!treesData?.trees || !greenSpacesData?.greenSpaces || !furnituresData?.furnitures || !markers || !stations) showToast('elements_inside_bounds_loading_failed');
                else if (!treesData.trees.length && !greenSpacesData.greenSpaces.length && !furnituresData.furnitures.length && !markers.length && !stations.length) this.finishSurroundingsUpdate(this.tmpSurroundingsLayer);
                else {
                    this.confirmProjectShrinkage = () => this.finishSurroundingsUpdate.bind(null, this.tmpSurroundingsLayer);
                    this.setState({ elementsOutsideNewBounds: { trees: treesData.trees, greenSpaces: greenSpacesData.greenSpaces, furnitures: furnituresData.furnitures, markers, stations } }, () => {
                        this.changeModalContentType('ProjectShrinkageForm', i18n.t("Redimensionnement de la zone géographique d'un projet"));
                    });
                }
            });
        }
    }

    modifyProjectSurroundings = () => {
        const project = this.props.editedProperties.project;
        const surroundings = JSON.parse(project.surroundings);
        if (surroundings) {
            const latLngs = GeometriesUtil.convertPolygonCoordinatesToLatLngs(surroundings.geometry.coordinates, surroundings.geometry.type === 'MultiPolygon');
            this.surroundingsDottedLayer = L.polygon(latLngs, { color: "#000000", opacity: 0.4, fillOpacity: 0, weight: 4, dashArray: '10 10', pmIgnore: true }).addTo(this.map);
            const surroundingsPolygon = surroundings.geometry.type === 'Polygon'
                ? polygon(surroundings.geometry.coordinates)
                : multiPolygon(surroundings.geometry.coordinates);
            const invertedSurroundings = GeometriesUtil.invertPolygon(surroundingsPolygon);
            const invertedLatLngs = GeometriesUtil.convertPolygonCoordinatesToLatLngs(invertedSurroundings.geometry.coordinates);
            this.tmpSurroundingsLayer = L.polygon(invertedLatLngs, this.props.isDarkTheme ? StylesUtil.getSurroundingsDarkStyle() : StylesUtil.getSurroundingsLightStyle()).addTo(this.map);
        }

        this.drawProjectSurroundings(true, surroundings);
        if (surroundings) this.map.fitBounds(this.surroundingsDottedLayer.getBounds());
    }

    drawCustomArea = () => {
        const { projectToEdit } = this.state;

        let bounds = null;
        if (projectToEdit.type === 'folder') {
            const baseProjects = [...(projectToEdit.childFolders || []), ...(projectToEdit.projects || [])];
            let properties = { baseProjects, bounds: null, tags: [], projectFormulaVersions: [], requiredFields: [], projectLabels: [] };
            ProjectsUtil.mergeProjectsProperties(properties, true);

            if (!properties.bounds) {
                showToast('empty_folder_viewing_not_allowed');
                return;
            } else bounds = properties.bounds;
        }

        this.props.showProjectList(false);
        this.props.setCurrentAction('drawingCustomArea');

        if (this.props.layer) {
            this.map.removeLayer(this.props.layer);
            this.props.setLayer(null);
        }

        const surroundings = bounds || JSON.parse(projectToEdit.surroundings);
        const surroundingsPolygon = surroundings.geometry.type === 'Polygon'
            ? polygon(surroundings.geometry.coordinates)
            : multiPolygon(surroundings.geometry.coordinates);
        const boundsPolygon = bboxPolygon(bbox(surroundingsPolygon));
        const scaledCoordinates = transformScale(boundsPolygon, 1.2).geometry.coordinates;
        this.map.fitBounds(L.polygon(GeometriesUtil.convertPolygonCoordinatesToLatLngs(surroundingsPolygon.geometry.coordinates, surroundingsPolygon.geometry.type === 'MultiPolygon')).getBounds());
        this.map.setMaxBounds(L.polygon(GeometriesUtil.convertPolygonCoordinatesToLatLngs(scaledCoordinates)).getBounds());
        this.tmpSurroundingsLayer = L.polygon(
            GeometriesUtil.convertPolygonCoordinatesToLatLngs(GeometriesUtil.invertPolygon(surroundingsPolygon).geometry.coordinates),
            this.props.isDarkTheme ? StylesUtil.getSurroundingsDarkStyle() : StylesUtil.getSurroundingsLightStyle()
        ).addTo(this.map);

        this.setState({ modal: { visible: false, title: '' } });
        this.map.pm.enableDraw('Polygon');
    }

    handleToolbarButtonClick = (name, state) => {
        switch (name) {
            case 'zoomIn': this.map.zoomIn(); break;
            case 'zoomOut': this.map.zoomOut(); break;
            case 'searchLocation':
                switch (state) {
                    case 0: this.setState({ showGeosearch: true }); break;
                    case 1: this.setState({ showGeosearch: false }); break;
                    default: break;
                }
                break;
            case 'fullscreen':
                if (screenfull.isEnabled) {
                    switch (state) {
                        case 0:
                            screenfull.request();
                            this.props.setButtonState('fullscreen', 1);
                            break;
                        case 1:
                            screenfull.exit();
                            this.props.setButtonState('fullscreen', 0);
                            break;
                        default: break;
                    }
                }
                break;
            case 'geolocation':
                switch (state) {
                    case 0:
                        this.locateControl.start();
                        this.props.setButtonState('geolocation', 1);
                        break;
                    case 1:
                        this.locateControl.stop();
                        this.props.setButtonState('geolocation', 0);
                        break;
                    default: break;
                }
                break;
        }
    }
}

// Fonctions qui permettent de faire le lien avec le store Redux
const mapStateToProps = (state) => {
    return {
        projects: state.projects,
        editedProperties: state.editedProperties,
        layer: state.layer,
        currentAction: state.currentAction,
        projectListState: state.projectListState,
        userProjects: state.userProjects,
        isDarkTheme: state.isDarkTheme,
        exitFormWithChanges: state.exitFormWithChanges,
        isToolbarExpanded: state.isToolbarExpanded,
    };
};

const mapDispatchToProps = {
    setEditedProperties,
    setLayer,
    setCurrentAction,
    setProject,
    setRights,
    setFilterFormState,
    setProjectListState,
    setUserProjects,
    setProjectInvitations,
    setViewProjectAsData,
    setButtonState
};

export default withOrientationChange(connect(mapStateToProps, mapDispatchToProps)(Map));