import React, { Component } from 'react';
// Composants
import { Button, Dimmer, Divider, Grid, Label, Loader, Message, Popup } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faFolder, faFolderOpen, faHome, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import MiniButton from './MiniButton';
// Librairies
import { connect } from 'react-redux';
import i18n from '../../locales/i18n';
import Cookies from 'universal-cookie';
import jwt_decode from 'jwt-decode';
import { setProjects } from '../../actionCreators/projectsActions';
// Utils
import StylesUtil from '../../utils/StylesUtil';
import ProjectsUtil from '../../utils/ProjectsUtil';
import { isMobile, isMobileOnly } from 'react-device-detect';

class MoveBaseProjects extends Component {
    state = {
        isLoading: false,
        userId: jwt_decode(new Cookies().get('token')).id,
        activeIndexes: [null],
        authorizedBaseProjectIds: []
    }

    render() {
        return (
            <Dimmer
                id='move-base-projects-dimmer' active style={{ ...StylesUtil.getMapStyles().dimmerStyle, position: 'fixed', top: 0, left: 0, width: '100%', height: '100vh', zIndex: 9999 }}
                onClick={({ target }) => { if (target.classList.contains('dimmer') && !this.state.isLoading) this.props.cancel(); }}
            >
                <Grid style={{ height: isMobileOnly && '100%', width: isMobileOnly && '100%' }}>
                    <Grid.Row style={{ height: '100%', width: '100%' }} verticalAlign='middle'>
                        <Grid.Column textAlign='left' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                            {this.state.isLoading &&
                                <Dimmer active>
                                    <Loader />
                                </Dimmer>}
                            <Message className='input-popup-form' style={{ display: 'flex', width: isMobileOnly ? '100%' : '500px', height: isMobileOnly ? '100%' : '75vh', overflow: 'hidden' }}>
                                <Message.Header style={{ textAlign: 'center' }}>{i18n.t("Déplacer")}</Message.Header>
                                <Message.Content style={{ flex: 1, display: 'flex', flexDirection: 'column', marginTop: '15px', alignItems: 'flex-start', gap: '10px', overflowY: 'hidden' }}>
                                    <div id='selected-base-projects'>
                                        {this.renderBaseProjectsToMove()}
                                    </div>
                                    <div id='tree-structure'>
                                        {this.renderTreeStructure()}
                                    </div>
                                    <Button color='grey' onClick={this.props.cancel} style={{ width: isMobile && '100%' }}>
                                        <FontAwesomeIcon icon={faTimesCircle} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                                    </Button>
                                </Message.Content>
                            </Message>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Dimmer>
        );
    }

    componentDidMount = () => this.setState({ authorizedBaseProjectIds: this.getAuthorizedSelectedBaseProjectIds() });

    renderBaseProjectsToMove = () => {
        const { baseProjectsToMove } = this.props;
        const { authorizedBaseProjectIds } = this.state;
        const projectsToMove = baseProjectsToMove.filter(baseProject => baseProject.type === 'project');
        const foldersToMove = baseProjectsToMove.filter(baseProject => baseProject.type === 'folder');
        let baseProjects = [];

        // Projets
        if (projectsToMove.length) baseProjects.push(
            <h4 key='projects-to-move' style={{ margin: '0 0 5px 0' }}>
                <FontAwesomeIcon icon={faFileAlt} style={{ marginRight: '5px', marginLeft: '3px' }} />{i18n.t("Projets sélectionnés ({{nbProjects}})", { nbProjects: projectsToMove.length })}
            </h4>
        );
        projectsToMove.forEach(project => {
            const isAuthorized = authorizedBaseProjectIds.includes(project.id);
            const title = !isAuthorized ? i18n.t("Vous n'avez pas le droit de déplacer ce projet. Il ne sera donc pas déplacer avec les autres.") : '';
            const backgroundColor = isAuthorized ? 'var(--primary-100)' : 'var(--grey-100)';
            baseProjects.push(
                <Popup key={project.id} content={title} disabled={isAuthorized} trigger={<Label key={project.id} content={project.label} style={{ backgroundColor, color: isAuthorized && 'white', marginTop: '5px' }} />} />
            );
        });

        // Dossiers
        if (foldersToMove.length) baseProjects.push(
            <h4 key='folders-to-move' style={{ margin: '0 0 5px 0', marginTop: projectsToMove.length > 0 && '10px' }}>
                <FontAwesomeIcon icon={faFolder} style={{ marginRight: '5px', marginLeft: '3px' }} />{i18n.t("Dossiers sélectionnés ({{nbFolders}})", { nbFolders: foldersToMove.length })}
            </h4>);
        foldersToMove.forEach(folder => {
            const isAuthorized = authorizedBaseProjectIds.includes(folder.id);
            const title = !isAuthorized ? i18n.t("Vous n'avez pas le droit de déplacer ce dossier. Il ne sera donc pas déplacer avec les autres.") : '';
            const backgroundColor = isAuthorized ? 'var(--primary-100)' : 'var(--grey-100)';
            baseProjects.push(
                <Popup key={folder.id} content={title} disabled={isAuthorized} trigger={<Label key={folder.id} content={folder.label} style={{ backgroundColor, color: isAuthorized && 'white', marginTop: '5px' }} />} />
            );
        });

        if (!baseProjects.length) baseProjects.push(<p key='not-found'>{i18n.t("Veuillez sélectionner des projets et/ou dossiers.")}</p>);
        return baseProjects;
    }

    renderTreeStructure = () => {
        const { projects, isDarkTheme, isOnline } = this.props;
        const { activeIndexes } = this.state;
        const containsFolder = projects.find(project => project.type === 'folder');

        return (
            <div style={{ marginBottom: '10px', width: '100%', color: isDarkTheme ? 'rgba(255, 255, 255, 0.75)' : 'grey' }}>
                <h4 style={{ margin: '0 0 5px 0' }}><FontAwesomeIcon icon={faFolderOpen} style={{ marginRight: '5px' }} />{i18n.t("Arborescence")}</h4>
                <div className='tree-structure' style={{ marginBottom: '10px' }}>
                    <div className={`tree-structure-item${!containsFolder ? ' disabled' : ''}`} style={{ overflow: 'hidden' }}>
                        <div className='item' style={{ display: 'flex' }}>
                            <div
                                title={!containsFolder ? i18n.t("Aucun dossier trouvé") : ''} style={{ flex: 1, cursor: 'pointer' }}
                                onClick={() => {
                                    const newActiveIndexes = activeIndexes.includes(null) ? activeIndexes.filter(ai => ai !== null) : [...activeIndexes, null];
                                    this.setState({ activeIndexes: newActiveIndexes });
                                }}
                            >
                                <FontAwesomeIcon icon={faHome} style={{ marginRight: '5px' }} />{i18n.t("Racine")}
                            </div>
                            <MiniButton
                                color='blue' icon='arrow right' disabled={!this.checkIfCanMoveTo() || !isOnline} title={i18n.t("Déplacer vers la racine")}
                                onClick={() => this.moveBaseProjects()}
                            />
                        </div>
                        {containsFolder && activeIndexes.includes(null) && this.renderFoldersStructure(projects.filter(project => project.type === 'folder'), 1)}
                    </div>
                </div>
            </div>
        )
    }

    renderFoldersStructure = (folders, level, indexes = []) => {
        const { activeIndexes } = this.state;

        return folders.map(folder => {
            const icon = folder.type === 'project' ? faFileAlt : faFolder;
            const subFolders = folder.childFolders || [];
            const isRootFolder = this.props.projects.find(project => project.id === folder.id) ? true : false;
            const areSubFoldersLoaded = !isRootFolder || folder.childFolders || folder.projects ? true : false;
            const title = areSubFoldersLoaded && !subFolders.length ? i18n.t("Le contenu du dossier est vide") : !areSubFoldersLoaded ? i18n.t("Le contenu du dossier n'a pas été téléchargé") : '';
            const isActive = activeIndexes.includes(folder.id);

            return (
                <div key={folder.id} className={`tree-structure-item${!areSubFoldersLoaded ? ' disabled' : ''}`} style={{ marginLeft: `${level * 5}px`, overflow: 'hidden' }}>
                    <div className='item' style={{ display: 'flex' }}>
                        <div
                            title={title} style={{ flex: 1, cursor: 'pointer' }}
                            onClick={() => {
                                const newActiveIndexes = activeIndexes.includes(folder.id) ? activeIndexes.filter(ai => ai !== folder.id) : [...activeIndexes, folder.id];
                                this.setState({ activeIndexes: newActiveIndexes });
                            }}
                        >
                            <FontAwesomeIcon icon={icon} style={{ marginRight: '5px' }} />{folder.label}
                        </div>
                        <MiniButton
                            color='blue' icon='arrow right' disabled={!this.checkIfCanMoveTo(folder) || !this.props.isOnline} title={i18n.t("Déplacer vers {{target}}", { target: folder.label })}
                            onClick={() => this.moveBaseProjects(folder)}
                        />
                    </div>
                    {subFolders.length > 0 && isActive && this.renderFoldersStructure(subFolders, level + 1, [...indexes, folder.id])}
                </div>
            );
        });
    }

    checkIfCanMoveTo = (destination = { id: null, path: null }) => {
        const baseProjectsToMove = this.props.baseProjectsToMove.filter(bptm => this.state.authorizedBaseProjectIds.includes(bptm.id));
        return ProjectsUtil.checkIfCanMoveTo(destination, this.state.userId, baseProjectsToMove, this.props.projects).isAllowed;
    }

    getAuthorizedSelectedBaseProjectIds = () => ProjectsUtil.getAuthorizedSelectedBaseProjectIds(this.props.baseProjectsToMove, this.props.projects);
    moveBaseProjects = (folder) => {
        const { authorizedBaseProjectIds } = this.state;
        const { baseProjectsToMove, projects, currentFolder, rootFolder } = this.props;
        this.setState({ isLoading: true });
        ProjectsUtil.moveBaseProjects(folder, authorizedBaseProjectIds, baseProjectsToMove, projects, currentFolder, rootFolder, this.props.webSocketHubs).then((response) => {
            this.setState({ isLoading: false });
            this.props.setCurrentProjectList(response.currentProjectList, response.currentFolder, response.rootFolder, response.baseProjects);
            this.props.cancel();
        });
    }
}

const mapStateToProps = (state) => {
    return {
        isOnline: state.isOnline,
        isDarkTheme: state.isDarkTheme,
        projects: state.projects,
        webSocketHubs: state.webSocketHubs
    };
};

const mapDispatchToProps = {
    setProjects
}

export default connect(mapStateToProps, mapDispatchToProps)(MoveBaseProjects);