import React, { Component } from 'react';
// Composants
import InfoIcon from '../../../Utils/InfoIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import i18n from '../../../../locales/i18n';
// Redux
import { connect } from 'react-redux';
// Ressources
import { faTh, faTools, faTrees } from '@fortawesome/pro-solid-svg-icons';
// Semantic UI
import { Form, Grid, Input, Select } from 'semantic-ui-react';
// Services
import FormattersUtil from '../../../../utils/FormattersUtil';

class Step3 extends Component {
    state = {
        properties: {
            dominantCompositionId: 0,
            detailedComposition: 0,
            managementClassId: 0,
            annualMaintenanceFrequency: 0
        }
    };

    render() {
        const requiredFields = this.props.requiredFields.greenSpaces;
        const { dominantCompositionId, detailedComposition, managementClassId, annualMaintenanceFrequency } = this.props.properties;

        const woodedMassif = this.props.dominantCompositions.find(dc => dc.id === 7);
        const dominantCompositions = [
            { text: <span><FontAwesomeIcon icon={faTrees} style={{ marginRight: '5px' }} />{woodedMassif.label}{woodedMassif.definition && <InfoIcon content={woodedMassif.definition} iconStyle={{ float: 'right' }} />}</span>, rawText: woodedMassif.label, value: woodedMassif.id },
            ...FormattersUtil.sortByAlphabeticalOrder(this.props.dominantCompositions.filter(dc => dc.id !== 7).map(x => { return { text: <span>{x.label}{x.definition && <InfoIcon content={x.definition} iconStyle={{ float: 'right', marginLeft: '5px' }} />}</span>, rawText: x.label, value: x.id } }))
        ];
        const availableManagementClasses = this.props.dominantCompositions
            .find(dc => dc.id === dominantCompositionId)?.dominantCompositionManagementClasses
            .map(dcmc => dcmc.managementClassId) || [];
        const managementClasses = this.props.managementClasses
            .filter(mc => availableManagementClasses.includes(mc.id))
            .map(x => { return { text: <span>{x.value}{x.definition && <InfoIcon content={x.definition} iconStyle={{ float: 'right', marginLeft: '5px' }} />}</span>, rawText: x.value, value: x.id } });

        const columnProps = {
            stretched: true,
            computer: 8,
            tablet: 8,
            mobile: 16
        };

        const compositionCustomFields = this.props.renderFields(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Composition'));
        const maintenanceCustomFields = this.props.renderFields(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Entretien'));

        return (
            <>
                {(requiredFields.dominantComposition || requiredFields.detailedComposition || compositionCustomFields?.length > 0) &&
                    <Grid id='cat--composition' style={{ margin: '14px 0 0 0', border: 'solid 2px var(--orange-100)', borderRadius: '6px' }}>
                        <Grid.Row style={{ display: 'flex', padding: '14px 14px 0 14px', color: 'var(--orange-100)', fontWeight: 'bold', fontSize: '14pt' }}>
                            <FontAwesomeIcon icon={faTh} style={{ alignSelf: 'center', marginRight: '5px' }} />
                            <span className='no-themed'>{i18n.t("Composition")}</span>
                        </Grid.Row>
                        {requiredFields.dominantComposition &&
                            <Grid.Column {...columnProps}>
                                <Form.Field
                                    control={Select} placeholder={i18n.t("Sélectionnez une composition")} style={{ width: '100%' }}
                                    label={<label>{i18n.t("Composition dominante")} <InfoIcon content={i18n.t("Composition dominante de l'espace considéré")} iconStyle={{ marginLeft: '3px' }} /> :</label>}
                                    name='dominantCompositionId' options={dominantCompositions} value={dominantCompositionId || ''}
                                    selectOnBlur={false} clearable search={FormattersUtil.searchList} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                                    onChange={this.props.handleDominantCompositionChange}
                                />
                            </Grid.Column>}
                        {requiredFields.detailedComposition &&
                            <Grid.Column {...columnProps}>
                                <Form.Field
                                    control={Input} placeholder={i18n.t("Indiquez une composition détaillée")}
                                    label={<label>{i18n.t("Composition détaillée")} <InfoIcon content={i18n.t("Détail de la composition (végétal ou minéral)")} iconStyle={{ marginLeft: '3px' }} /> :</label>}
                                    name='detailedComposition' value={detailedComposition || ''} onChange={this.props.handleChange} disabled={!dominantCompositionId}
                                />
                            </Grid.Column>}
                        {compositionCustomFields}
                    </Grid>}
                {(requiredFields.managementClass || requiredFields.annualMaintenanceFrequency || maintenanceCustomFields?.length > 0) &&
                    <Grid id='cat--maintenance' style={{ margin: '14px 0 0 0', border: 'solid 2px #C83030', borderRadius: '6px' }}>
                        <Grid.Row style={{ display: 'flex', padding: '14px 14px 0 14px', color: '#C83030', fontWeight: 'bold', fontSize: '14pt' }}>
                            <FontAwesomeIcon icon={faTools} style={{ alignSelf: 'center', marginRight: '5px' }} />
                            <span className='no-themed'>{i18n.t("Entretien")}</span>
                        </Grid.Row>
                        {requiredFields.managementClass &&
                            <Grid.Column {...columnProps}>
                                <Form.Field
                                    control={Select} placeholder={i18n.t("Sélectionnez une classe")} style={{ width: '100%' }}
                                    label={<label>{i18n.t("Classe de gestion")} <InfoIcon content={i18n.t("Niveau de gestion différenciée appliqué sur 4")} iconStyle={{ marginLeft: '3px' }} /> :</label>}
                                    name='managementClassId' options={managementClasses} value={managementClassId || ''} disabled={!dominantCompositionId || managementClasses.length < 1}
                                    selectOnBlur={false} clearable onChange={this.props.handleManagementClassChange}
                                />
                            </Grid.Column>}
                        {requiredFields.annualMaintenanceFrequency &&
                            <Grid.Column {...columnProps}>
                                <Form.Field
                                    control={Input} fluid type='number' step='1'
                                    label={<label>{i18n.t("Fréquence annuelle d'entretien")} <InfoIcon content={i18n.t("Nombre de passage annuel pour l'entretien")} iconStyle={{ marginLeft: '3px' }} /> :</label>}
                                    name='annualMaintenanceFrequency' value={annualMaintenanceFrequency} disabled={!dominantCompositionId || !managementClassId}
                                    onBlur={() => this.props.handleNumberPropertyBlur('annualMaintenanceFrequency')} onChange={this.props.handleChange}
                                />
                            </Grid.Column>}
                        {maintenanceCustomFields}
                    </Grid>}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        dominantCompositions: state.dominantCompositions,
        managementClasses: state.managementClasses,
        spaceTypes: state.spaceTypes,
        defaultFieldCategories: state.defaultFieldCategories
    };
};

export default connect(mapStateToProps)(Step3);