export const setProject = (action) => async (dispatch, getState) => {
    dispatch({ type: 'SET_PROJECT', data: action });
    return Promise.resolve();
};

export const setNewProject = (properties) => async (dispatch, getState) => {
    dispatch({ type: 'SET_NEW_PROJECT', data: properties });
    return Promise.resolve();
};

export const setRights = (rights) => async (dispatch, getState) => {
    dispatch({ type: 'SET_RIGHTS', data: rights });
    return Promise.resolve();
};

export const setActions = (properties) => async (dispatch, getState) => {
    dispatch({ type: 'SET_ACTIONS', data: properties });
    return Promise.resolve();
};

export const setProjectActions = (properties) => async (dispatch, getState) => {
    dispatch({ type: 'SET_PROJECT_ACTIONS', data: properties });
    return Promise.resolve();
};

export const setProjects = (properties) => async (dispatch, getState) => {
    dispatch({ type: 'SET_PROJECTS', data: properties });
    return Promise.resolve();
};

export const setProjectCustomFields = (projectId, customFields) => async (dispatch, getState) => {
    dispatch({ type: 'SET_PROJECT_CUSTOM_FIELDS', data: { projectId, customFields } });
    return Promise.resolve();
};

export const setProjectsCustomFields = (projectCustomFields) => async (dispatch, getState) => {
    dispatch({ type: 'SET_PROJECTS_CUSTOM_FIELDS', data: projectCustomFields });
    return Promise.resolve();
};

export const setUserProjects = (userProjects) => async (dispatch, getState) => {
    dispatch({ type: 'SET_USER_PROJECTS', data: userProjects });
    return Promise.resolve();
};

export const setProjectCollaborators = (projectCollaborators) => async (dispatch, getState) => {
    dispatch({ type: 'SET_PROJECT_COLLABORATORS', data: projectCollaborators });
    return Promise.resolve();
};

export const setProjectInvitations = (projectInvitations) => async (dispatch, getState) => {
    dispatch({ type: 'SET_PROJECT_INVITATIONS', data: projectInvitations });
    return Promise.resolve();
};

export const setViewProjectAsData = (viewProjectAsData) => async (dispatch, getState) => {
    dispatch({ type: 'SET_VIEW_PROJECT_AS_DATA', data: viewProjectAsData });
    return Promise.resolve();
};