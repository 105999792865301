import React, { Component, createRef } from 'react';
// Composants
import { Button, Form, Input, List, Ref } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronLeft, faChevronRight, faChevronUp, faHome, faInfoCircle, faLock, faMinus, faPlus, faSearch } from '@fortawesome/pro-solid-svg-icons';
import { SketchPicker } from 'react-color';
import InfoIcon from './InfoIcon';
import IconPicker from '../Utils/IconPicker';
// Librairies
import { connect } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { isMobile, isMobileOnly } from 'react-device-detect';
import i18n from '../../locales/i18n';
import { v4 as uuidv4 } from 'uuid';
import reactCSS from 'reactcss';
// Ressources
import GralityLogo from '../../resources/pngs/grality.png';
// Utils
import FormattersUtil from '../../utils/FormattersUtil';
import FieldsUtil from '../../utils/FieldsUtil';

const customFieldsTypes = FieldsUtil.getCustomFieldsTypes();
const defaultStyles = {
    'default': {
        color: {
            width: '20px',
            height: '20px',
            borderRadius: '50%'
        },
        swatch: {
            padding: '3px',
            marginLeft: '1px',
            background: '#fff',
            borderRadius: '50%',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            position: 'relative',
            top: '2px'
        },
        popover: {
            position: 'absolute',
            zIndex: '2',
            bottom: '5px',
            right: '35px'
        },
        cover: {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
            zIndex: '-1'
        }
    }
};

class FieldsManagement extends Component {
    state = {
        selectedIndex: 0,
        selectedFields: [],
        fieldsNotUsed: [],
        fieldsUsed: [],
        fieldsNotUsedSearch: '',
        fieldsUsedSearch: '',
        disabledCategories: [],
        categoryToModify: null
    }

    render() {
        const { isOnline, activeOrganization, project, isDarkTheme } = this.props;
        const { selectedFields, fieldsNotUsedSearch, fieldsUsedSearch } = this.state;
        const selectedCategory = selectedFields.length ? this.props.fields.find(f => f.id === selectedFields[0].id)?.isChecked : false;
        const subscription = (project.organization || activeOrganization).subscription;
        const areCustomFieldsAllowed = subscription.customFields;
        const canEditRequiredFields = subscription.customFields;

        return (
            <>
                {!canEditRequiredFields &&
                    <div style={{ height: '100%', width: '100%', position: 'absolute', top: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'not-allowed', backgroundColor: isDarkTheme ? 'rgba(0, 0, 0, 0.5)' : 'rgba(192, 192, 192, 0.5)', zIndex: 5 }}>
                        <div style={{ textAlign: 'center', maxWidth: '400px' }}>
                            <FontAwesomeIcon icon={faLock} size='5x' />
                            <h3>{i18n.t("Upgradez votre licence afin de débloquer la personnalisation du modèle de données")}</h3>
                        </div>
                    </div>}
                <DragDropContext onDragStart={this.handleDragStart} onDragEnd={this.handleDragEnd}>
                    <div style={{ display: 'flex', marginLeft: 'auto', marginRight: 'auto', width: isMobile && '100%', flexDirection: isMobileOnly && 'column' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: !isMobile && '450px', flexGrow: isMobile && 1 }}>
                            <div className='custom-fields-header'>
                                <h4>{i18n.t("Non utilisés")}</h4>
                                <div style={{ marginLeft: 'auto' }}>
                                    <Form.Field control={Input} placeholder={i18n.t("Rechercher...")} value={fieldsNotUsedSearch} onChange={e => this.setState({ fieldsNotUsedSearch: e.target.value })} />
                                </div>
                            </div>
                            <div className='list-of-fields'>{this.renderFieldsNotUsed()}</div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: isMobileOnly ? 'row' : 'column', justifyContent: 'center', margin: isMobileOnly ? '15px 0' : '0 30px' }}>
                            <Button key='add-button' type='button' color='grey' disabled={selectedCategory || !selectedFields.length} onClick={this.changeCategory} style={{ alignSelf: 'center', marginBottom: '5px' }}>
                                <FontAwesomeIcon icon={isMobileOnly ? faChevronDown : faChevronRight} />
                            </Button>
                            <Button key='remove-button' type='button' color='grey' disabled={!selectedCategory || !selectedFields.length} onClick={this.changeCategory} style={{ alignSelf: 'center', marginBottom: '5px' }}>
                                <FontAwesomeIcon icon={isMobileOnly ? faChevronUp : faChevronLeft} />
                            </Button>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: !isMobile && '450px', flexGrow: isMobile && 1 }}>
                            <div className='custom-fields-header'>
                                <h4>{i18n.t("Utilisés")}</h4>
                                <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                                    <Button
                                        type='button' color='blue' title={i18n.t("Créer une nouvelle catégorie")} style={{ padding: 0, height: '30px', width: '30px' }}
                                        disabled={!isOnline || !areCustomFieldsAllowed} onClick={this.addCategory}
                                    >
                                        <FontAwesomeIcon icon={faPlus} />
                                    </Button>
                                    <Form.Field control={Input} placeholder={i18n.t("Rechercher...")} value={fieldsUsedSearch} onChange={e => this.setState({ fieldsUsedSearch: e.target.value })} />
                                </div>
                            </div>
                            <div id='field-used' className='list-of-fields'>{this.renderFieldsUsed()}</div>
                        </div>
                    </div>
                </DragDropContext>
            </>
        );
    }

    componentDidMount = () => {
        this.renameRef = createRef();
        const fieldsUsed = [];

        if (this.props.categories?.length)
            this.props.categories.forEach(category => {
                fieldsUsed.push({
                    id: String(category.id), label: category.label, color: category.color, category: category.category, isDefault: category.isDefault,
                    fields: this.props.fields.filter(f => f.isChecked && f.fieldCategoryId === category.id), allowCustomFields: category.allowCustomFields,
                    icon: category.icon
                });
            });

        this.setState({
            selectedFields: [],
            fieldsNotUsed: this.getFieldsNotUsed(),
            fieldsUsed: this.orderFields(fieldsUsed)
        });

        document.addEventListener('keydown', this.handleKeyDown);
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.category !== this.props.category) {
            this.setState({
                selectedFields: [],
                fieldsNotUsed: this.getFieldsNotUsed(),
                fieldsUsed: []
            });
        }

        if (JSON.stringify(prevProps.categories) !== JSON.stringify(this.props.categories) || JSON.stringify(prevProps.fields) !== JSON.stringify(this.props.fields)) {
            const fieldsUsed = [];

            if (this.props.categories?.length)
                this.props.categories.forEach(category => {
                    fieldsUsed.push({
                        ...category, id: String(category.id), fields: this.props.fields.filter(f => f.isChecked && f.fieldCategoryId === category.id),
                        allowCustomFields: category.allowCustomFields, icon: category.icon
                    });
                });

            this.setState({ fieldsNotUsed: this.getFieldsNotUsed(), fieldsUsed });
        }
    }

    componentWillUnmount = () => document.removeEventListener('keydown', this.handleKeyDown);
    renderFieldsNotUsed = () => {
        const categoriesToRender = this.getFilteredFields('fieldsNotUsed');
        if (!categoriesToRender.length) return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', pointerEvents: 'none' }}>
                <FontAwesomeIcon icon={faSearch} size='5x' style={{ marginTop: 'auto' }} />
                <h4 style={{ marginBottom: 'auto' }}>{i18n.t("Aucun résultat trouvé")}</h4>
            </div>
        );

        const isExpertModeAllowed = (this.props.project.organization || this.props.activeOrganization).subscription.expertMode;
        return categoriesToRender.map((category) => {
            const isDropDisabled = this.state.disabledCategories.find(dc => dc.id === category.id) ? true : false;
            return (<Droppable key={category.id} droppableId={`fieldsNotUsed_${category.id}`} isDropDisabled={isDropDisabled}>
                {(provided1) => (
                    <div {...provided1.droppableProps} ref={provided1.innerRef} className={`category${isDropDisabled ? ' disabled' : ''}`} style={{ cursor: isDropDisabled && 'no-drop' }} onClick={this.cancelSelection}>
                        <div className='header' style={{ padding: '5px 6px 5px 10px', userSelect: 'none' }} onClick={(e) => this.handleCategoryClick(e, `fieldsNotUsed_${category.id}`)}>
                            <div className='category-label' style={{ display: 'flex', alignItems: 'center' }}>
                                <FontAwesomeIcon icon={category.icon} style={{ marginRight: '10px' }} />
                                {category.label}
                                {this.renderColorPicker(category, true)}
                            </div>
                        </div>
                        {category.fields.map((fnu, i) => {
                            const isSelected = this.state.selectedFields.find(sf => sf.id === fnu.id) ? true : false;
                            const isCustomField = fnu.isCustomField ? true : false;
                            const customField = isCustomField ? this.props.customFields.find(cf => cf.id === parseInt(fnu.id)) : null;
                            const isExpertMode = ['root', 'collar', 'trunk', 'branch', 'leaf'].some(organ => fnu.id?.includes?.(organ));
                            const isExpertDisabled = isExpertMode && !isExpertModeAllowed;
                            return (
                                <Draggable key={fnu.id} style={{ display: 'flex', alignContent: 'center' }} draggableId={fnu.id} index={i} isDragDisabled={(fnu.isRequired || this.props.loginAsData?.readOnly || isExpertDisabled) ? true : false}>
                                    {(provided2) => (
                                        <div
                                            ref={provided2.innerRef} {...provided2.draggableProps} {...provided2.dragHandleProps}
                                            className={`field${isSelected ? ' selected' : ''}${(fnu.isRequired || this.props.loginAsData?.readOnly || isExpertDisabled) ? ' required' : ''}`}
                                            onClick={e => { if (!fnu.isRequired && !this.props.loginAsData?.readOnly && !isExpertDisabled) this.selectField(e, fnu, 'fieldsNotUsed') }}
                                        >
                                            {isExpertDisabled && <FontAwesomeIcon icon={faLock} style={{ marginRight: '8px' }} title={i18n.t("Upgradez votre licence pour débloquer cette fonctionnalité")} />}
                                            {fnu.label} {isCustomField && <InfoIcon content={() => this.renderCustomFieldInfo(customField)} iconStyle={{ marginLeft: '3px' }} />}
                                            {fnu.isGralityField && <img src={GralityLogo} title={i18n.t("Champs par défaut de Grality")} alt='grality' style={{ height: '18px', float: 'right', position: 'relative', top: '2px' }} />}
                                        </div>
                                    )}
                                </Draggable>
                            );
                        })}
                        {provided1.placeholder}
                    </div>
                )}
            </Droppable>);
        });
    }

    renderFieldsUsed = () => {
        const categoriesToRender = this.getFilteredFields('fieldsUsed');
        if (!categoriesToRender.length) return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', pointerEvents: 'none' }}>
                <FontAwesomeIcon icon={faSearch} size='5x' style={{ marginTop: 'auto' }} />
                <h4 style={{ marginBottom: 'auto' }}>{i18n.t("Aucun résultat trouvé")}</h4>
            </div>
        );

        return categoriesToRender.map((category) => {
            const isDropDisabled = this.state.disabledCategories.find(dc => dc.id === category.id) ? true : false;
            const isModifyingLabel = this.state.categoryToModify?.id === category.id && this.state.categoryToModify.type === 'label';

            return (<Droppable key={category.id} droppableId={`fieldsUsed_${category.id}`} isDropDisabled={isDropDisabled}>
                {(provided1) => (
                    <div {...provided1.droppableProps} ref={provided1.innerRef} className={`category${isDropDisabled ? ' disabled' : ''}`} style={{ cursor: isDropDisabled && 'no-drop' }} onClick={this.cancelSelection}>
                        <div className='header' style={{ padding: !isModifyingLabel ? '5px 6px 5px 10px' : '1px 10px', userSelect: 'none' }} onClick={(e) => this.handleCategoryClick(e, category.id)}>
                            {!isModifyingLabel ?
                                <div className='category-label' style={{ display: 'flex', alignItems: 'center' }}>
                                    <FontAwesomeIcon icon={category.icon} style={{ marginRight: '10px' }} />
                                    {category.label}
                                    {this.renderColorPicker(category)}
                                    {!category.isDefault &&
                                        <>
                                            <IconPicker iconName={category.icon} direction='topleft' closeOnSelect={true} handleIconChange={(icon) => this.handleIconCategoryChange(category.id, icon)} />
                                            <Button
                                                type='button' color='red' title={i18n.t("Supprimer la catégorie")}
                                                style={{ padding: 0, marginLeft: '5px', height: '25px', width: '25px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                                onClick={() => this.removeCategory(category.id)}
                                            >
                                                <FontAwesomeIcon icon={faMinus} />
                                            </Button>
                                        </>}
                                </div>
                                : <Ref innerRef={this.renameRef}>
                                    <Form.Field
                                        control={Input} placeholder={i18n.t("Nom de catégorie")} name='label' value={category.label || ''}
                                        onBlur={() => this.setState({ categoryToModify: null }, () => this.props.handleFieldCategory(category, true))}
                                        onChange={this.handleCategoryNameChange}
                                    />
                                </Ref>}
                        </div>
                        {category.fields.map((fu, i) => {
                            const isSelected = this.state.selectedFields.find(sf => sf.id === fu.id) ? true : false;
                            const isCustomField = fu.isCustomField ? true : false;
                            const customField = isCustomField ? this.props.customFields.find(cf => cf.id === parseInt(fu.id)) : null;
                            return (
                                <Draggable key={fu.id} draggableId={fu.id} index={i} isDragDisabled={(fu.isRequired || this.props.loginAsData?.readOnly) ? true : false}>
                                    {(provided2) => (
                                        <div
                                            ref={provided2.innerRef} {...provided2.draggableProps} {...provided2.dragHandleProps}
                                            className={`field${isSelected ? ' selected' : ''}${(fu.isRequired || this.props.loginAsData?.readOnly) ? ' required' : ''}`}
                                            onClick={e => { if (!fu.isRequired && !this.props.loginAsData?.readOnly) this.selectField(e, fu, 'fieldsUsed') }}
                                        >
                                            {fu.label} {isCustomField && <InfoIcon content={() => this.renderCustomFieldInfo(customField)} iconStyle={{ marginLeft: '3px' }} />}
                                            {fu.isGralityField && <img src={GralityLogo} title={i18n.t("Champs par défaut de Grality")} alt='grality' style={{ height: '18px', float: 'right', position: 'relative', top: '2px' }} />}
                                        </div>
                                    )}
                                </Draggable>
                            );
                        })}
                        {provided1.placeholder}
                    </div>
                )}
            </Droppable>);
        });
    }

    renderCustomFieldInfo = (customField) => {
        return (<List>
            <List.Header><FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: '7px' }} />{i18n.t("Informations")}</List.Header>
            <List.Item style={{ color: 'var(--primary-100)' }}><span style={{ color: 'var(--white-100)' }}>{i18n.t("Id")} :</span> {customField.id}</List.Item>
            <List.Item style={{ color: 'var(--primary-100)' }}><span style={{ color: 'var(--white-100)' }}>{i18n.t("Global")} :</span> {customField.isGlobal ? i18n.t("Oui") : i18n.t("Non")}</List.Item>
            <List.Item style={{ color: 'var(--primary-100)' }}><span style={{ color: 'var(--white-100)' }}>{i18n.t("Type")} :</span> {customFieldsTypes.find(cft => cft.id === customField.type).label}</List.Item>
            {customField.type === 'number' && <>
                <List.Item style={{ color: 'var(--primary-100)' }}><span style={{ color: 'var(--white-100)' }}>{i18n.t("Minimum")} :</span> {typeof customField.min === 'number' ? customField.min : i18n.t("Aucun")}</List.Item>
                <List.Item style={{ color: 'var(--primary-100)' }}><span style={{ color: 'var(--white-100)' }}>{i18n.t("Maximum")} :</span> {typeof customField.max === 'number' ? customField.max : i18n.t("Aucun")}</List.Item>
                <List.Item style={{ color: 'var(--primary-100)' }}><span style={{ color: 'var(--white-100)' }}>{i18n.t("Étape")} :</span> {typeof customField.step === 'number' ? customField.step : i18n.t("Aucune")}</List.Item>
            </>}
            {customField.type === 'string' && <List.Item style={{ color: 'var(--primary-100)' }}><span style={{ color: 'var(--white-100)' }}>{i18n.t("Longueur maximale")} :</span> {typeof customField.maxLength === 'number' ? customField.maxLength : i18n.t("Aucune")}</List.Item>}
            {customField.type === 'list' && <>
                <List.Item style={{ color: 'var(--primary-100)' }}><span style={{ color: 'var(--white-100)' }}>{i18n.t("Multiple")} :</span> {customField.isMultiple ? i18n.t("Oui") : i18n.t("Non")}</List.Item>
                <List.Item style={{ color: 'var(--primary-100)' }}><span style={{ color: 'var(--white-100)' }}>{i18n.t("Numérique")} :</span> {customField.isMultiple ? i18n.t("Oui") : i18n.t("Non")}</List.Item>
            </>}
            {customField.category === 'Arbre' && <>
                <List.Item style={{ color: 'var(--primary-100)' }}><span style={{ color: 'var(--white-100)' }}>{i18n.t("Vide")} :</span> {customField.forEmpty ? i18n.t("Oui") : i18n.t("Non")}</List.Item>
                <List.Item style={{ color: 'var(--primary-100)' }}><span style={{ color: 'var(--white-100)' }}>{i18n.t("Mort")} :</span> {customField.forDead ? i18n.t("Oui") : i18n.t("Non")}</List.Item>
                <List.Item style={{ color: 'var(--primary-100)' }}><span style={{ color: 'var(--white-100)' }}>{i18n.t("Souche")} :</span> {customField.forStump ? i18n.t("Oui") : i18n.t("Non")}</List.Item>
            </>}
        </List>);
    }

    renderColorPicker = (category, disabled = false) => {
        const fieldsUsed = JSON.parse(JSON.stringify(this.state.fieldsUsed));
        const styles = reactCSS({ ...defaultStyles });
        const showSketchPicker = this.state.categoryToModify?.id === category.id;

        return (
            <div style={{ position: 'relative', marginLeft: 'auto' }}>
                {showSketchPicker &&
                    <div style={styles.popover}>
                        <div style={styles.cover} onClick={() => this.setState({ categoryToModify: null })} />
                        <div style={{ zIndex: 4 }}>
                            <SketchPicker color={category.color}
                                onChange={color => {
                                    color = 'rgba(' + color.rgb.r + ', ' + color.rgb.g + ', ' + color.rgb.b + ', ' + color.rgb.a + ')';
                                    const index = fieldsUsed.findIndex(c => c.id === category.id);
                                    if (index !== -1) {
                                        fieldsUsed[index].color = color;
                                        this.setState({ fieldsUsed });
                                    }
                                }}
                                onChangeComplete={() => {
                                    const index = fieldsUsed.findIndex(c => c.id === category.id);
                                    if (index !== -1) {
                                        let categoryToUpdate = { ...fieldsUsed[index] };
                                        if (categoryToUpdate.fields) delete categoryToUpdate.fields;
                                        this.props.handleFieldCategory(categoryToUpdate, true);
                                    }
                                }}
                            />
                        </div>
                    </div>}
                <div style={{ ...styles.swatch, cursor: !category.isDefault && !disabled && 'pointer', zIndex: 3, marginRight: '5px' }} onClick={() => { if (!category.isDefault && !disabled) this.setState({ categoryToModify: { id: category.id, type: 'color' } }) }}>
                    <div style={{ ...styles.color, backgroundColor: category.color }} />
                </div>
            </div>
        );
    }

    getFieldsNotUsed = () => {
        const fieldsNotUsed = [{ id: 'general', label: i18n.t("Général"), color: 'black', icon: faHome, fields: this.props.fields.filter(f => !f.isChecked && !this.props.customFieldCategories.find(ucfc => ucfc.id === f.fieldCategoryId)) }];
        if (this.props.customFieldCategories?.length) {
            this.props.customFieldCategories.forEach(ucfc => {
                fieldsNotUsed.push({
                    id: String(ucfc.id), label: ucfc.label, color: ucfc.color, icon: ucfc.icon,
                    fields: this.props.fields.filter(f => !f.isChecked && f.fieldCategoryId === ucfc.id)
                })
            });
        }

        return fieldsNotUsed;
    }

    changeCategory = (fields) => {
        const fieldsToChange = Array.isArray(fields) ? fields : this.state.selectedFields;
        const isChecked = !this.props.fields.find(f => f.id === fieldsToChange[0].id).isChecked;
        const sourceName = isChecked ? 'fieldsNotUsed' : 'fieldsUsed';
        const destinationName = isChecked ? 'fieldsUsed' : 'fieldsNotUsed';
        let source = JSON.parse(JSON.stringify(this.state[sourceName]));
        let destination = JSON.parse(JSON.stringify(this.state[destinationName]));

        const listIds = [];
        fieldsToChange.forEach(sf => {
            sf.isChecked = isChecked;
            const index = destination.findIndex(category => (isChecked && !sf.isCustomField && category.id === String(sf.fieldCategoryId)) || (sf.isCustomField && category.label === i18n.t("Autres")) || (!isChecked && category.id === 'general'));
            if (index !== -1) {
                sf.fieldCategoryId = destinationName === 'fieldsNotUsed' && sf.isGralityField ? sf.fieldCategoryId : destination[index].id;
                destination[index].fields.push(sf);
                if (!listIds.includes(destination[index].id)) listIds.push(destination[index].id);
            }
        });

        source.forEach(category => {
            category.fields = category.fields.filter(item => !fieldsToChange.find(sf => sf.id === item.id));
        });

        destination = this.orderFields(destination);
        this.setState(prevState => ({ selectedFields: !Array.isArray(fields) ? [] : prevState.selectedFields, [sourceName]: source, [destinationName]: destination }), () => {
            this.props.handleCheckChange(fieldsToChange, isChecked);
        });
    }

    selectField = (e, field, listName) => {
        return new Promise(resolve => {
            const { project, activeOrganization } = this.props;
            let { selectedFields, selectedIndex } = this.state;
            const list = JSON.parse(JSON.stringify(this.state[listName]));
            const fields = list.flatMap(category => category.fields);
            const isUsingWindows = navigator.platform.indexOf('Win') >= 0;
            const shiftKey = e.shiftKey;
            const ctrlKey = isUsingWindows ? e.ctrlKey : e.metaKey;
            const areCustomFieldsAllowed = (project.organization || activeOrganization).subscription.customFields;

            const isFieldUsed = this.props.fields.find(f => f.id === field.id).isChecked;
            const areFieldsUsed = selectedFields.length ? this.props.fields.find(f => f.id === selectedFields[0].id)?.isChecked : false;
            if (shiftKey && selectedFields.length && isFieldUsed === areFieldsUsed) {
                const currentSelectedFieldIndex = fields.findIndex(f => f.id === field.id);
                const startIndex = selectedIndex < currentSelectedFieldIndex ? selectedIndex : currentSelectedFieldIndex;
                const endIndex = currentSelectedFieldIndex > selectedIndex ? currentSelectedFieldIndex : selectedIndex;
                let i = 0;
                selectedFields = [];
                list.forEach(category => {
                    category.fields.forEach(field => {
                        if (i >= startIndex && i <= endIndex && (!field.isCustomField || (field.isCustomField && areCustomFieldsAllowed)))
                            selectedFields.push(field);
                        i++;
                    });
                });
                selectedFields = selectedFields.filter(sf => !sf.isRequired);
            } else if (ctrlKey) {
                if (selectedFields.find(sf => sf.id === field.id)) selectedFields = selectedFields.filter(sf => sf.id !== field.id);
                else selectedFields = isFieldUsed === areFieldsUsed ? [...selectedFields, field] : [field];
                selectedIndex = fields.findIndex(f => f.id === field.id);
            } else {
                if (selectedFields.length === 1 && selectedFields.find(sf => sf.id === field.id)) {
                    selectedFields = [];
                    selectedIndex = 0;
                } else {
                    selectedFields = [field];
                    selectedIndex = fields.findIndex(f => f.id === field.id);
                }
            }

            this.setState({ selectedFields, selectedIndex }, resolve);
        });
    }

    cancelSelection = ({ target }) => {
        if (target.classList.contains('category'))
            this.setState({ selectedFields: [], selectedIndex: 0 });
    }

    handleDragStart = ({ draggableId, source }) => {
        let disabledCategories = [];
        const sourceList = source.droppableId.startsWith('fieldsNotUsed') ? this.state.fieldsNotUsed : this.state.fieldsUsed;
        const index = sourceList.findIndex(category => category.id === source.droppableId.split('_')[1]);
        if (index !== -1) {
            const draggedItem = sourceList[index].fields.find(f => f.id === draggableId);
            if (!draggedItem.isCustomField) {
                disabledCategories = [
                    ...this.state.fieldsUsed.filter(category => String(category.id) !== String(draggedItem.fieldCategoryId)).map(category => category.id),
                    ...this.props.customFieldCategories.map(ucfc => String(ucfc.id))
                ];
            }
            else {
                let disabledCustomFieldCategories = [];
                const userCustomField = this.props.organizationCustomFields.find(ucf => String(ucf.id) === draggedItem.id);
                if (userCustomField) disabledCustomFieldCategories = this.state.fieldsNotUsed.filter(fnu => String(fnu.id) !== String(userCustomField.customFieldCategoryId || 'general')).map(fnu => fnu.id)

                disabledCategories = [
                    ...this.state.fieldsUsed.filter(category => !category.allowCustomFields && category.isDefault).map(category => category.id),
                    ...disabledCustomFieldCategories
                ];
            }
            if (disabledCategories.length) disabledCategories = disabledCategories.map(disabledCategory => ({ id: disabledCategory, label: this.props.defaultFieldCategories.find(dc => dc.id === disabledCategory) || disabledCategory }));
        }

        this.setState({ disabledCategories, selectedFields: [] });
    }

    handleDragEnd = ({ source, destination }) => {
        if (!destination) {
            this.setState({ disabledCategories: [] });
            return;
        }

        const sourceName = source.droppableId.startsWith('fieldsNotUsed') ? 'fieldsNotUsed' : 'fieldsUsed';
        const destinationName = destination.droppableId.startsWith('fieldsNotUsed') ? 'fieldsNotUsed' : 'fieldsUsed';

        let sourceList = this.state[sourceName];
        let destinationList = this.state[destinationName];
        const index = sourceList.findIndex(category => category.id === source.droppableId.split('_')[1]);

        if (index !== -1 && (sourceName !== destinationName || sourceName === 'fieldsUsed')) {
            let draggedItem = JSON.parse(JSON.stringify(this.getFilteredFields(sourceName)[index].fields[source.index]));
            draggedItem.isChecked = destination.droppableId.startsWith('fieldsUsed');
            draggedItem.fieldCategoryId = String(!draggedItem.isCustomField ? draggedItem.fieldCategoryId : !draggedItem.isChecked ? 'general' : destination.droppableId.split('_')[1]);
            sourceList[index].fields = sourceList[index].fields.filter(f => f.id !== draggedItem.id);
            destinationList.find(category => (draggedItem.isChecked && category.id === destination.droppableId.split('_')[1]) || (!draggedItem.isChecked && category.id === 'general'))
                .fields.splice(destination.index, 0, draggedItem);

            destinationList = this.orderFields(destinationList);
            this.setState({ [source.droppableId.split('_')[1]]: sourceList, [destination.droppableId.split('_')[1]]: destinationList, disabledCategories: [] }, () => {
                this.props.handleCheckChange([draggedItem], draggedItem.isChecked)
            });
        } else this.setState({ disabledCategories: [] });
    }

    getFilteredFields = (type) => {
        let fields = [];
        const search = type === 'fieldsNotUsed' ? this.state.fieldsNotUsedSearch : this.state.fieldsUsedSearch;
        if (search === '') return this.state[type];
        this.state[type].forEach(field => {
            fields.push({ ...field, fields: field.fields.filter(f => FormattersUtil.getNormalizedString(f.label).toLowerCase().includes(FormattersUtil.getNormalizedString(search).toLowerCase())) })
        });
        return fields;
    }

    addCategory = () => {
        const id = uuidv4();
        const category = { id, label: i18n.t("Nouvelle catégorie"), color: 'rgba(175, 175, 175, 1)', category: this.props.category, icon: 'plus', allowCustomFields: true, isDefault: false };
        this.setState(prevState => ({ fieldsUsed: [...prevState.fieldsUsed, { ...category, fields: [] }], categoryToModify: { id, type: 'label' } }), () => {
            const element = document.getElementById('field-used');
            element.scroll({ top: element.scrollHeight - element.clientHeight, behavior: 'smooth' });
            setTimeout(() => {
                if (this.renameRef.current) {
                    const input = this.renameRef.current.querySelector('input');
                    input.focus();
                    input.setSelectionRange(0, input.value.length);
                }
            }, 500);
            this.props.handleFieldCategory(category);
        });
    }

    removeCategory = (id) => {
        let fieldsUsed = JSON.parse(JSON.stringify(this.state.fieldsUsed));
        const index = fieldsUsed.findIndex(category => category.id === id);
        if (index !== -1) {
            const category = fieldsUsed[index];
            if (category.fields.length) this.changeCategory(category.fields);
            fieldsUsed = fieldsUsed.filter(category => category.id !== id);
            this.setState({ fieldsUsed }, () => this.props.handleFieldCategory(category));
        }
    }

    handleCategoryClick = (e, id) => {
        if (!id.includes('fieldsNotUsed') && !this.props.defaultFieldCategories.find(dc => String(dc.id) === id)) {
            if (!this.categoryClicked || this.categoryClicked !== id || !e.target.classList.contains('category-label')) {
                this.categoryClicked = id;
                clearTimeout(this.singleClickTimer);
                this.clickCount = 0;
            }

            this.clickCount++;
            if (this.clickCount === 1) this.singleClickTimer = setTimeout(() => this.clickCount = 0, 300);
            else if (this.clickCount === 2) {
                clearTimeout(this.singleClickTimer);
                this.clickCount = 0;
                this.setState({ categoryToModify: { id, type: 'label' } }, () => {
                    if (this.renameRef.current) {
                        const input = this.renameRef.current.querySelector('input');
                        input.focus();
                        input.setSelectionRange(0, input.value.length);
                    }
                });
            }
        }
    }

    handleCategoryNameChange = (_, { value }) => {
        if (value.length <= 30) {
            let { fieldsUsed } = this.state;
            const index = fieldsUsed.findIndex(category => category.id === this.state.categoryToModify.id);
            if (index !== -1) {
                fieldsUsed[index].label = value;
                this.setState({ fieldsUsed });
            }
        }
    }

    handleKeyDown = (e) => {
        if (e.key?.toLowerCase() === 'enter' && this.state.categoryToModify) {
            e.preventDefault();
            const category = this.state.fieldsUsed.find(category => category.id === this.state.categoryToModify.id);
            this.setState({ categoryToModify: null }, () => this.props.handleFieldCategory(category, true));
        }
    }

    handleIconCategoryChange = (categoryId, icon) => {
        let category = JSON.parse(JSON.stringify(this.state.fieldsUsed.find(category => category.id === categoryId)));
        category.icon = icon.iconName;
        this.props.handleFieldCategory(category, true);
    }

    orderFields = (lists = []) => {
        let allCustomFields = [];
        lists.forEach(list => {
            const defaultOrder = this.props.defaultOrder[list.id];
            let order = 0;
            if (defaultOrder) {
                const sortedArray = list.fields.filter(field => field.isGralityField).sort((a, b) => defaultOrder.findIndex(field => field.id === a.id) - defaultOrder.findIndex(field => field.id === b.id));
                const customFields = list.fields.filter(field => field.isCustomField).map((customField) => { customField.order = customField.fieldCategoryId !== 'general' ? order++ : 0; return customField });
                allCustomFields = [...allCustomFields, ...customFields];
                list.fields = [...sortedArray, ...customFields];
            }
            else {
                const customFields = list.fields.filter(field => field.isCustomField).map((customField) => { customField.order = customField.fieldCategoryId !== 'general' ? order++ : 0; return customField });
                allCustomFields = [...allCustomFields, ...customFields];
            }
        });

        this.props.updateCustomFieldsOrder(this.props.category, allCustomFields);
        return lists;
    }
}

const mapStateToProps = (state) => {
    return {
        isOnline: state.isOnline,
        activeOrganization: state.activeOrganization,
        userProjects: state.userProjects,
        defaultFieldCategories: state.defaultFieldCategories,
        customFieldCategories: state.customFieldCategories,
        organizationCustomFields: state.organizationCustomFields,
        loginAsData: state.loginAsData,
        isDarkTheme: state.isDarkTheme
    };
};

export default connect(mapStateToProps)(FieldsManagement);