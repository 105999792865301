import React, { Component } from 'react';
// Componsants
import RequiredFieldsForm from '../RequiredFieldsForm';
import { Link } from 'react-router-dom';
// Librairies
import i18n from '../../../../locales/i18n';
// Utils
import ProjectsUtil from '../../../../utils/ProjectsUtil';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';

class ProjectElementsSettingsForm extends Component {
    render() {
        const { project, subscription } = this.props;
        const requiredFields = ProjectsUtil.getProjectRequiredFields(project, project.id);
        const amenityFormulaType = project.projectFormulaVersions.find(pfv => pfv.formulaId === 4)?.formulaType;

        return (
            <>
                <h3 style={{ margin: isMobile ? '0 0 20px 0' : 0 }}>{i18n.t("Paramètres des champs")}</h3>
                {!isMobile && <small>
                    <p style={{ margin: 0 }}>{i18n.t("La création de champs se fait depuis la page « Mon compte ».")}</p>
                    <Link to='/accountPanel/customFields'>{i18n.t("Cliquez ici pour accéder la gestion de vos champs personnalisés.")}</Link>
                </small>}
                <div style={{ marginTop: !isMobile && '15px', flex: 1, overflowY: 'hidden', display: 'flex' }}>
                    <RequiredFieldsForm
                        requiredFields={requiredFields} amenityFormulaType={amenityFormulaType} project={project}
                        subscription={subscription} handleChange={this.props.handleChange} handleFieldCategory={this.props.handleFieldCategory}
                    />
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        projectCollaborators: state.projectCollaborators
    };
};

export default connect(mapStateToProps)(ProjectElementsSettingsForm);