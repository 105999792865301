import React, { Component } from 'react';
// Composants
import InfoIcon from '../../../Utils/InfoIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import { isMobileOnly } from 'react-device-detect';
// Redux
import { connect } from 'react-redux';
// Ressources
import { faHeartbeat, faMapMarkedAlt } from '@fortawesome/pro-solid-svg-icons';
// Semantic UI
import { Input, Form, Grid, Select } from 'semantic-ui-react';
import i18n from '../../../../locales/i18n';
// Utils
import FormattersUtil from '../../../../utils/FormattersUtil';

class Step1 extends Component {
    state = {
        properties: {
            place: '',
            customReference: null,
            tagId: [],
            conditionId: 0
        },
        error: {
            hidden: true,
            messages: []
        }
    }

    render() {
        const requiredFields = this.props.requiredFields.furnitures;
        let tags = [];

        if (this.props.project && this.props.projectTags) {
            this.props.projectTags.forEach(tag => {
                if (tag.category === 'Mobilier')
                    tags.push({ key: tag.id, text: tag.label, value: tag.id });
            });
        }

        const conditions = this.props.conditions.map(x => { return { text: x.label, value: x.id } });

        const siteCustomFields = this.props.renderFields(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Mobilier' && dfc.label === 'Emplacement'));
        const conditionCustomFields = this.props.renderFields(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Mobilier' && dfc.label === 'État'));

        return (
            <>
                {(requiredFields.place || requiredFields.customReference || requiredFields.tags || siteCustomFields?.length > 0) &&
                    <Grid id='cat--site' style={{ margin: '14px 0 0 0', border: 'solid 2px var(--yellow-100)', borderRadius: '6px' }}>
                        <Grid.Row style={{ display: 'flex', padding: '14px 14px 0 14px', color: 'var(--yellow-100)', fontWeight: 'bold', fontSize: '14pt' }}>
                            <FontAwesomeIcon icon={faMapMarkedAlt} style={{ alignSelf: 'center', marginRight: '5px' }} />
                            <span className='no-themed'>{i18n.t("Emplacement & en-tête")}</span>
                        </Grid.Row>
                        {requiredFields.place &&
                            <Grid.Column stretched computer={8} tablet={12} mobile={16}>
                                <Form.Field
                                    control={Input} label={i18n.t("Lieu") + ' : '} placeholder={i18n.t("Indiquez un lieu")}
                                    name='place' value={this.props.properties.place || ''} className='place'
                                    error={this.props.error.place} onChange={this.props.handleChange}
                                    disabled={!this.props.placeLoaded} loading={!this.props.placeLoaded}
                                />
                            </Grid.Column>}
                        {requiredFields.customReference &&
                            <Grid.Column stretched computer={8} tablet={16} mobile={16}>
                                <Form.Field
                                    control={Input} type='text' label={<label>{i18n.t("Référence personnalisée")} <InfoIcon content={i18n.t("Référence libre")} iconStyle={{ marginLeft: '3px' }} /> :</label>} placeholder='Ex: be-ch-13'
                                    name='customReference' value={this.props.properties.customReference || ''}
                                    onChange={this.props.handleChange}
                                />
                            </Grid.Column>}
                        {requiredFields.tags &&
                            <Grid.Column stretched computer={16} tablet={16} mobile={16}>
                                <Form.Field
                                    control={Select} label={<label>{i18n.t("Tags")} <InfoIcon content={i18n.t("Note complémentaire (ex: n° quartier, caractéristique supplémentaire, ...)")} iconStyle={{ marginLeft: '3px' }} /> :</label>} placeholder='Sélectionnez un ou plusieurs tags'
                                    name='tagId' options={tags} value={this.props.properties.tagId || []} clearable
                                    multiple search={FormattersUtil.searchList} selection allowAdditions selectOnBlur={false} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                                    additionLabel={i18n.t("Ajouter") + ' '} onAddItem={(_, data) => this.props.handleAddTag(data.value)}
                                    onChange={this.props.handleTagsChange}
                                />
                            </Grid.Column>}
                        {siteCustomFields}
                    </Grid>}
                {(requiredFields.condition || conditionCustomFields?.length > 0) &&
                    <Grid id='cat--condition' style={{ margin: '14px 0 0 0', border: 'solid 2px var(--red-100)', borderRadius: '6px' }}>
                        {!isMobileOnly &&
                            <Grid.Row style={{ display: 'flex', padding: '14px 14px 0 14px', color: 'var(--red-100)', fontWeight: 'bold', fontSize: '14pt' }}>
                                <FontAwesomeIcon icon={faHeartbeat} style={{ alignSelf: 'center', marginRight: '5px' }} />
                                <span className='no-themed'>{i18n.t("État")}</span>
                            </Grid.Row>}
                        {requiredFields.condition &&
                            <Grid.Column stretched computer={8} tablet={16} mobile={16}>
                                <Form.Field
                                    control={Select} label={<label>{i18n.t("État")} :</label>} placeholder={i18n.t("Sélectionnez un état")}
                                    name='conditionId' options={conditions}
                                    value={this.props.properties.conditionId || ''}
                                    selectOnBlur={false} clearable
                                    onChange={this.props.handleChange}
                                />
                            </Grid.Column>}
                        {conditionCustomFields}
                    </Grid>}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        project: state.project,
        conditions: state.conditions,
        defaultFieldCategories: state.defaultFieldCategories
    };
};

export default connect(mapStateToProps)(Step1);