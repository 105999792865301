import React, { Component } from 'react';
// Composants
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import DataGrid, { Row as GridRow } from 'react-data-grid';
/*     Editors     */
import BooleanEditor from '../Editors/BooleanEditor';
import DateEditor from '../Editors/DateEditor';
import DropDownEditor from '../Editors/DropDownEditor';
import NumberEditor from '../Editors/NumberEditor';
import TextEditor from '../Editors/TextEditor';
/*     Filters     */
import BooleanFilter from '../../Tables/Filters/BooleanFilter';
import NumberFilter from '../../Tables/Filters/NumberFilter';
import TextFilter from '../../Tables/Filters/TextFilter';
// Librairies
import i18n from '../../../locales/i18n';
// Redux
import { connect } from 'react-redux';
// Semantic UI
import { Dimmer, Loader, Form, Button, Menu, Input, Segment, Label, Icon, Checkbox } from 'semantic-ui-react';
// Services
import SubscriptionsService from '../../../services/SubscriptionsService';
// Styles
import '../../../styles/react-contextmenu.css';
import '../../../styles/rdg.css';
// Utils
import { showToast } from '../../../utils/ToastsUtil';
import FormattersUtil from '../../../utils/FormattersUtil';
import StylesUtil from '../../../utils/StylesUtil';
import DatesUtil from '../../../utils/DatesUtil';

const initialFilters = {
    name: '',
    price: '',
    activationDate: '',
    expirationDate: '',
    nbUsers: '',
    nbTrees: '',
    nbGreenSpaces: '',
    import: '',
    branding: '',
    reminderMails: '',
    costEstimation: '',
    customFields: '',
    customCharts: '',
    thematicMaps: '',
    expertMode: ''
};

class UserTable extends Component {
    state = {
        data: {
            columns: [],
            rows: []
        },
        elementsToModify: [],
        elementsToDelete: [],
        modificationsHistory: [],
        modificationsHistoryIndex: 0,
        rowIndex: 0,
        sortColumn: null,
        sortDirection: 'NONE',
        enableFilterRow: false,
        showProsOnly: true,
        filters: initialFilters,
        isLoading: true,
        isUpdating: false
    }

    render() {
        const canEdit = ['Dev', 'Admin'].includes(this.props.activeOrganization?.subscription.shortName);

        const {
            data, elementsToModify, elementsToDelete, modificationsHistory, modificationsHistoryIndex, showProsOnly,
            sortColumn, sortDirection, enableFilterRow, filters, rowIndex, selectedRow, selectedColumn, isUpdating, isLoading
        } = this.state;
        const rows = this.getFilteredRows();

        return (
            <Segment style={{ display: 'flex', flexFlow: 'column', padding: 0, width: '100%', height: '100%' }}>
                <Dimmer active={isUpdating} style={StylesUtil.getMapStyles().dimmerStyle}>
                    <Loader content={i18n.t("Mise à jour des licences en cours...")} />
                </Dimmer>
                {data?.columns &&
                    <>
                        <h3 style={{ textAlign: 'center', margin: '10px 0 0 0' }}>{i18n.t("Gestion des licences")}</h3>
                        <Menu attached='top' tabular style={{ margin: 0, flexWrap: 'wrap' }}>
                            <Menu.Item>
                                <Form.Field
                                    control={Input} type='number' step='1' placeholder={i18n.t("Numéro de ligne")}
                                    value={rowIndex || ''}
                                    onChange={(e, { value }) => this.setState({ rowIndex: value })}
                                />
                                <Button
                                    className='button--secondary' icon='arrow down' style={{ marginLeft: '10px' }}
                                    onClick={() => { if (this.gridRef.current) this.gridRef.current.scrollToRow(rowIndex - 1) }}
                                />
                            </Menu.Item>
                            <Menu.Item>
                                <Button.Group>
                                    <Button
                                        title={enableFilterRow ? i18n.t("Désactiver les filtres") : i18n.t("Activer les filtres")}
                                        className={enableFilterRow ? 'button--secondary' : null} color={!enableFilterRow ? 'grey' : null} icon='filter'
                                        onClick={this.toggleFilters}
                                    />
                                    <Button
                                        title={i18n.t("Réinitialiser les filtres")} className='button--secondary' icon='dont'
                                        onClick={this.clearFilters} disabled={!this.areFiltersApplied()}
                                    />
                                    <>
                                        <Button
                                            title={i18n.t("Annuler la dernière modification")} className='button--secondary' icon='undo'
                                            onClick={this.restorePreviousModification} disabled={modificationsHistoryIndex < 1}
                                        />
                                        <Button
                                            title={i18n.t("Rétablir la modification suivante")} className='button--secondary' icon='redo'
                                            disabled={modificationsHistoryIndex === modificationsHistory.length}
                                            onClick={this.restoreNextModification}
                                        />
                                        <Button
                                            title={i18n.t("Valider les modifications")} className='button--secondary' icon='check'
                                            onClick={() => this.handleSubmit(false)} disabled={elementsToModify.length < 1 && elementsToDelete.length < 1}
                                        />
                                    </>
                                </Button.Group>
                            </Menu.Item>
                            <Menu.Item>
                                <div>
                                    <Checkbox
                                        toggle label={i18n.t("Licence payante")} checked={showProsOnly}
                                        onChange={(_, { checked }) => this.setState({ showProsOnly: checked })}
                                    />
                                </div>
                            </Menu.Item>
                            {elementsToDelete.length > 0 &&
                                <Menu.Item position='right' style={{ padding: '26px 7px 0 0' }}>
                                    <Label color='red' content={`${i18n.t("Éléments supprimés")} : ` + elementsToDelete.length} />
                                </Menu.Item>}
                            <Menu.Item style={{ width: '100%', padding: '1px 0', border: 'none', height: '32px' }} className='full-width-input-item'>
                                {(selectedColumn?.editable === true || (typeof selectedColumn?.editable === 'function' && selectedColumn.editable(selectedRow))) ?
                                    selectedColumn.editor({
                                        row: selectedRow, column: selectedColumn,
                                        onRowChange: this.handleRowChange,
                                        onClose: (commitChanges) => { if (commitChanges) this.handleRowChange(selectedRow) }
                                    })
                                    : <Input disabled placeholder={i18n.t("Sélectionnez une cellule éditable")} />}
                                <div style={{ position: 'absolute', width: '150px', right: 0, paddingRight: '10px', display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                                    <span style={{ borderLeft: 'solid 1px var(--grey-100)', paddingLeft: '10px', fontWeight: 'bold' }}>{rows?.length}</span>
                                    {rows?.length !== data?.rows?.length && <span style={{ marginLeft: '4px' }}>{` / ${data?.rows?.length}`}</span>}
                                </div>
                            </Menu.Item>
                        </Menu>
                        <DataGrid
                            ref={this.gridRef} className={this.props.isDarkTheme ? 'rdg-dark' : 'rdg-light'}
                            style={{ flex: '1 1 auto' }}
                            columns={data.columns}
                            rows={rows} rowRenderer={this.rowRenderer}
                            defaultColumnOptions={{ sortable: true, resizable: true }}
                            cellNavigationMode='LOOP_OVER_ROW'
                            sortColumn={sortColumn} sortDirection={sortDirection}
                            onSort={this.handleSort} onFill={this.handleFill} enableFilterRow={enableFilterRow}
                            filters={filters} onFiltersChange={filters => this.setState({ filters: filters })}
                            emptyRowsRenderer={() => (<div style={{ textAlign: 'center' }}>
                                <span>{isLoading ? i18n.t("Chargement en cours...") : i18n.t("Aucun résultat trouvé")}</span>
                            </div>)}
                            onSelectedCellChange={({ idx, rowIdx }) => this.setState({ selectedRow: rows[rowIdx], selectedColumn: data.columns[idx] })}
                            onRowsChange={this.handleRowsChange}
                        />
                        {canEdit &&
                            <ContextMenu id='grid-context-menu'>
                                <MenuItem onClick={this.resetRow}>{i18n.t("Réinitialiser")}</MenuItem>
                                <MenuItem onClick={this.deleteRow}>{i18n.t("Supprimer")}</MenuItem>
                            </ContextMenu>}
                    </>}
            </Segment>
        );
    }

    componentDidMount = () => {
        this.gridRef = React.createRef();
        this.loadData();

        document.addEventListener('keydown', this.handleKeyDown);
    }

    componentWillUnmount = () => document.removeEventListener('keydown', this.handleKeyDown);

    rowRenderer = (props) => {
        return (
            <ContextMenuTrigger id='grid-context-menu' collect={() => ({ rowIdx: props.rowIdx })}>
                <GridRow {...props} />
            </ContextMenuTrigger>
        );
    }

    updateSelectedRow = (row) => this.setState({ selectedRow: row });

    handleRowChange = (row) => {
        const { data, selectedRow } = this.state;
        if (selectedRow) {
            const updatedRows = [...data.rows];
            const index = updatedRows.findIndex(row => row.id === selectedRow.id);
            updatedRows[index] = row;
            this.handleRowsChange(updatedRows);
        }
    };

    handleRowsChange = (newRows) => {
        this.setState(prevState => {
            let rows = prevState.data.rows;
            newRows.forEach(newRow => {
                const index = rows.findIndex(row => row.id === newRow.id);
                rows[index] = newRow;
            });
            return { data: { columns: prevState.data.columns, rows: rows } };
        });
    }

    loadData = () => {
        const subscription = this.props.activeOrganization?.subscription.shortName;
        const canEdit = (subscriptionToEdit) => subscription === 'Dev' || (subscription === 'Admin' && subscriptionToEdit === 'Pro');

        let data = {
            columns: [],
            rows: []
        };

        const getEditor = (type, row, column, onRowChange, onClose, config = {}) => {
            const props = {
                elements: this.state.elements, elementsToModify: this.state.elementsToModify, ...config,
                row: row, column: column, onRowChange: onRowChange, onClose: onClose, updateSelectedRow: this.updateSelectedRow,
                pushToModificationsHistory: this.pushToModificationsHistory, changeElementsToModify: this.changeElementsToModify
            };
            switch (type) {
                case 'boolean': return <BooleanEditor {...props} />;
                case 'date': return <DateEditor {...props} />;
                case 'dropdown': return <DropDownEditor {...props} />;
                case 'number': return <NumberEditor {...props} />;
                case 'text': return <TextEditor {...props} />;
                default: return;
            }
        }

        // Définition des colonnes
        data.columns = [
            {
                name: i18n.t("Libellé"), key: 'name', width: 200,
                frozen: true, sortable: true, editable: true,
                filterRenderer: (props) => <TextFilter p={props} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('text', row, column, onRowChange, onClose)
            },
            {
                name: i18n.t("Type"), key: 'shortName', width: 120,
                sortable: true, editable: (props) => {
                    const subscription = this.props.activeOrganization?.subscription;
                    return ['Free', 'Pro'].includes(props.shortName) || subscription.shortName === 'Dev';
                },
                formatter: (props) => {
                    const subscription = this.props.activeOrganization?.subscription;
                    return (
                        <div className={!(['Free', 'Pro'].includes(props.row.shortName) || subscription.shortName === 'Dev') ? 'disabled' : ''}>
                            {props.row.shortName}
                        </div>
                    );
                },
                filterRenderer: (props) => <TextFilter p={props} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor(
                    'dropdown', row, column, onRowChange, onClose,
                    {
                        options: this.props.activeOrganization?.subscription.shortName === 'Dev'
                            ? ['Free', 'Pro', 'Admin', 'Dev', 'Com', 'Demo'].map(shortName => ({ label: shortName, value: shortName }))
                            : ['Free', 'Pro'].map(shortName => ({ label: shortName, value: shortName }))
                    }
                ),
                editorOptions: { editOnClick: true }
            },
            {
                name: i18n.t("Prix"), key: 'price', width: 110,
                sortable: true, editable: true,
                formatter: (props) => props.row.price + '€',
                filterRenderer: (props) => <NumberFilter p={props} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('number', row, column, onRowChange, onClose, { min: -1, max: 10, step: 1 })
            },
            {
                name: i18n.t("Date d'activation"), key: 'activationDate', width: 180,
                sortable: true, editable: (props) => !['Free', 'Dev', 'Admin', 'Com'].includes(props.shortName),
                formatter: (props) => ['Free', 'Dev', 'Admin', 'Com'].includes(props.row.shortName) ?
                    <div className='disabled'>
                        {props.row.name !== 'Free' && props.row.activationDate}
                    </div>
                    : props.row.activationDate,
                filterRenderer: (props) => <TextFilter p={props} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('date', row, column, onRowChange, onClose, { futureDate: false })
            },
            {
                name: i18n.t("Date d'expiration"), key: 'expirationDate', width: 180,
                sortable: true, editable: (props) => !['Free', 'Dev', 'Admin', 'Com'].includes(props.shortName),
                formatter: (props) => ['Free', 'Dev', 'Admin', 'Com'].includes(props.row.shortName) ?
                    <div className='disabled'>
                        {props.row.name !== 'Free' && props.row.expirationDate}
                    </div>
                    : props.row.expirationDate,
                filterRenderer: (props) => <TextFilter p={props} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('date', row, column, onRowChange, onClose, { futureDate: true })
            },
            {
                name: i18n.t("Utilisateurs"), key: 'nbUsers', width: 110,
                sortable: true, editable: (props) => canEdit(props.shortName),
                formatter: (props) => <div className={!canEdit(props.row.shortName) ? 'disabled' : ''}>
                    {props.row.nbUsers > -1 ? props.row.nbUsers : i18n.t("Illimité")}
                </div>,
                filterRenderer: (props) => <NumberFilter p={props} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('number', row, column, onRowChange, onClose, { min: 1 })
            },
            {
                name: i18n.t("Arbres"), key: 'nbTrees', width: 140,
                sortable: true, editable: (props) => canEdit(props.shortName),
                formatter: (props) => <div className={!canEdit(props.row.shortName) ? 'disabled' : ''}>
                    {props.row.nbTrees > -1 ? props.row.nbTrees : i18n.t("Illimité")}
                </div>,
                filterRenderer: (props) => <NumberFilter p={props} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('number', row, column, onRowChange, onClose, { min: -1 })
            },
            {
                name: i18n.t("Espaces verts"), key: 'nbGreenSpaces', width: 140,
                sortable: true, editable: (props) => canEdit(props.shortName),
                formatter: (props) => <div className={!canEdit(props.row.shortName) ? 'disabled' : ''}>
                    {props.row.nbGreenSpaces > -1 ? props.row.nbGreenSpaces : i18n.t("Illimité")}
                </div>,
                filterRenderer: (props) => <NumberFilter p={props} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('number', row, column, onRowChange, onClose, { min: -1 })
            },
            {
                name: i18n.t("Mobiliers"), key: 'nbFurnitures', width: 140,
                sortable: true, editable: (props) => canEdit(props.shortName),
                formatter: (props) => <div className={!canEdit(props.row.shortName) ? 'disabled' : ''}>
                    {props.row.nbFurnitures > -1 ? props.row.nbFurnitures : i18n.t("Illimité")}
                </div>,
                filterRenderer: (props) => <NumberFilter p={props} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('number', row, column, onRowChange, onClose, { min: -1 })
            },
            {
                name: i18n.t("Import"), key: 'import', width: 110,
                sortable: true, editable: (props) => canEdit(props.shortName),
                formatter: (props) => <div className={!canEdit(props.row.shortName) ? 'disabled' : ''} style={!canEdit(props.row.shortName) ? { display: 'flex', alignItems: 'flex-start' } : null}>
                    {props.row.import === i18n.t("Oui")
                        ? <Icon name='check' color='green' />
                        : <Icon name='times' color='red' />}
                </div>,
                filterRenderer: (props) => <BooleanFilter p={props} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('boolean', row, column, onRowChange, onClose),
                editorOptions: { editOnClick: true }
            },
            {
                name: i18n.t("Thèmes"), key: 'branding', width: 110,
                sortable: true, editable: (props) => canEdit(props.shortName),
                formatter: (props) => <div className={!canEdit(props.row.shortName) ? 'disabled' : ''} style={!canEdit(props.row.shortName) ? { display: 'flex', alignItems: 'flex-start' } : null}>
                    {props.row.branding === i18n.t("Oui")
                        ? <Icon name='check' color='green' />
                        : <Icon name='times' color='red' />}
                </div>,
                filterRenderer: (props) => <BooleanFilter p={props} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('boolean', row, column, onRowChange, onClose),
                editorOptions: { editOnClick: true }
            },
            {
                name: i18n.t("Rappels par mail"), key: 'reminderMails', width: 150,
                sortable: true, editable: (props) => canEdit(props.shortName),
                formatter: (props) => <div className={!canEdit(props.row.shortName) ? 'disabled' : ''} style={!canEdit(props.row.shortName) ? { display: 'flex', alignItems: 'flex-start' } : null}>
                    {props.row.reminderMails === i18n.t("Oui")
                        ? <Icon name='check' color='green' />
                        : <Icon name='times' color='red' />}
                </div>,
                filterRenderer: (props) => <BooleanFilter p={props} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('boolean', row, column, onRowChange, onClose),
                editorOptions: { editOnClick: true }
            },
            {
                name: i18n.t("Estimation des coûts"), key: 'costEstimation', width: 175,
                sortable: true, editable: (props) => canEdit(props.shortName),
                formatter: (props) => <div className={!canEdit(props.row.shortName) ? 'disabled' : ''} style={!canEdit(props.row.shortName) ? { display: 'flex', alignItems: 'flex-start' } : null}>
                    {props.row.costEstimation === i18n.t("Oui")
                        ? <Icon name='check' color='green' />
                        : <Icon name='times' color='red' />}
                </div>,
                filterRenderer: (props) => <BooleanFilter p={props} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('boolean', row, column, onRowChange, onClose),
                editorOptions: { editOnClick: true }
            },
            {
                name: i18n.t("Champs personnalisés"), key: 'customFields', width: 180,
                sortable: true, editable: (props) => canEdit(props.shortName),
                formatter: (props) => <div className={!canEdit(props.row.shortName) ? 'disabled' : ''} style={!canEdit(props.row.shortName) ? { display: 'flex', alignItems: 'flex-start' } : null}>
                    {props.row.customFields === i18n.t("Oui")
                        ? <Icon name='check' color='green' />
                        : <Icon name='times' color='red' />}
                </div>,
                filterRenderer: (props) => <BooleanFilter p={props} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('boolean', row, column, onRowChange, onClose),
                editorOptions: { editOnClick: true }
            },
            {
                name: i18n.t("Graphiques personnalisés"), key: 'customCharts', width: 200,
                sortable: true, editable: (props) => canEdit(props.shortName),
                formatter: (props) => <div className={!canEdit(props.row.shortName) ? 'disabled' : ''} style={!canEdit(props.row.shortName) ? { display: 'flex', alignItems: 'flex-start' } : null}>
                    {props.row.customCharts === i18n.t("Oui")
                        ? <Icon name='check' color='green' />
                        : <Icon name='times' color='red' />}
                </div>,
                filterRenderer: (props) => <BooleanFilter p={props} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('boolean', row, column, onRowChange, onClose),
                editorOptions: { editOnClick: true }
            },
            {
                name: i18n.t("Cartes thématiques personnalisées"), key: 'thematicMaps', width: 275,
                sortable: true, editable: (props) => canEdit(props.shortName),
                formatter: (props) => <div className={!canEdit(props.row.shortName) ? 'disabled' : ''} style={!canEdit(props.row.shortName) ? { display: 'flex', alignItems: 'flex-start' } : null}>
                    {props.row.thematicMaps === i18n.t("Oui")
                        ? <Icon name='check' color='green' />
                        : <Icon name='times' color='red' />}
                </div>,
                filterRenderer: (props) => <BooleanFilter p={props} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('boolean', row, column, onRowChange, onClose),
                editorOptions: { editOnClick: true }
            },
            {
                name: i18n.t("Module expert"), key: 'expertMode', width: 135,
                sortable: true, editable: (props) => canEdit(props.shortName),
                formatter: (props) => <div className={!canEdit(props.row.shortName) ? 'disabled' : ''} style={!canEdit(props.row.shortName) ? { display: 'flex', alignItems: 'flex-start' } : null}>
                    {props.row.expertMode === i18n.t("Oui")
                        ? <Icon name='check' color='green' />
                        : <Icon name='times' color='red' />}
                </div>,
                filterRenderer: (props) => <BooleanFilter p={props} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('boolean', row, column, onRowChange, onClose),
                editorOptions: { editOnClick: true }
            }
        ];

        // Ajout des données
        SubscriptionsService.getAllSubscriptions().then(subscriptions => {
            data.rows = subscriptions.map((subscription) => this.getRowValue(subscription));
            const elements = [...subscriptions];

            const initialOrder = data.rows.map(row => row.id);
            this.setState({ data: data, elements: elements, initialOrder: initialOrder, isLoading: false });
        });
    }

    // Filtres
    areFiltersApplied = () => {
        if (!this.state.enableFilterRow) return false;
        let filtersApplied = false;
        for (const property in this.state.filters)
            if (this.state.filters[property]) filtersApplied = true;
        return filtersApplied;
    }

    toggleFilters = () => this.setState(prevState => ({ enableFilterRow: !prevState.enableFilterRow }));
    clearFilters = () => this.setState({ filters: initialFilters });

    getFilteredRows = () => {
        const { filters, showProsOnly } = this.state;
        let rows = [...this.state.data.rows];

        const $ = (str) => FormattersUtil.getNormalizedString(str);
        return rows.filter(r => {
            return (!showProsOnly || r.shortName === 'Pro') &&
                (!this.state.enableFilterRow || (
                    (filters.name ? $(r.name)?.includes($(filters.name)) : true)
                    && (filters.price ? r.price === Number(filters.price) : true)
                    && (filters.activationDate ? $(r.activationDate).includes($(filters.activationDate)) : true)
                    && (filters.expirationDate ? $(r.expirationDate).includes($(filters.expirationDate)) : true)
                    && (filters.nbUsers ? r.nbUsers === Number(filters.nbUsers) : true)
                    && (filters.nbTrees ? r.nbTrees === Number(filters.nbTrees) : true)
                    && (filters.nbGreenSpaces ? r.nbGreenSpaces === Number(filters.nbGreenSpaces) : true)
                    && (filters.nbFurnitures ? r.nbFurnitures === Number(filters.nbFurnitures) : true)
                    && (filters.import ? r.import === filters.import : true)
                    && (filters.branding ? r.branding === filters.branding : true)
                    && (filters.reminderMails ? r.reminderMails === filters.reminderMails : true)
                    && (filters.costEstimation ? r.costEstimation === filters.costEstimation : true)
                    && (filters.customFields ? r.customFields === filters.customFields : true)
                    && (filters.customCharts ? r.customCharts === filters.customCharts : true)
                    && (filters.thematicMaps ? r.thematicMaps === filters.thematicMaps : true)
                    && (filters.expertMode ? r.expertMode === filters.expertMode : true)
                ));
        });
    }

    // Tri
    handleSort = (columnKey, direction) => this.setState({ sortColumn: columnKey, sortDirection: direction }, this.sortRows);
    sortRows = () => {
        const sortDirection = this.state.sortDirection;
        let rows = [...this.state.data.rows];
        if (sortDirection === 'NONE') {
            for (let i = 0; i < this.state.initialOrder.length; i++) {
                let temp = rows[i];
                const index = rows.findIndex(row => row.id === this.state.initialOrder[i]);
                rows[i] = rows[index];
                rows[index] = temp;
            }

            this.setState(prevState => ({
                data: {
                    columns: prevState.data.columns,
                    rows: rows
                }
            }));
        } else {
            const sortColumn = this.state.sortColumn;
            if (['price', 'nbUsers', 'nbTrees', 'nbGreenSpaces', 'nbFurnitures', 'nbCollaborators'].includes(sortColumn))
                rows = rows.sort((a, b) => (a[sortColumn] || 0) - (b[sortColumn] || 0));
            else if (['activationDate', 'expirationDate'].includes(sortColumn))
                rows = rows.sort((a, b) => {
                    const aDate = DatesUtil.convertDateStringToDate(a[sortColumn]), bDate = DatesUtil.convertDateStringToDate(b[sortColumn]);
                    return !aDate ? -1 : !bDate ? 1 : aDate - bDate;
                });
            else rows = rows.sort((a, b) => (a[sortColumn] || '').localeCompare(b[sortColumn] || ''));

            this.setState(prevState => ({
                data: {
                    columns: prevState.data.columns,
                    rows: sortDirection === 'DESC' ? rows.reverse() : rows
                }
            }));
        }
    }

    // Gestion des modifications
    changeElementsToModify = (elementsToModify) => this.setState({ elementsToModify: elementsToModify });
    changeElementsToModifyLocally = (id, property, value, elementsToModify) => {
        const index = elementsToModify.findIndex(element => element.id === id);
        elementsToModify[index][property] = value;
        return elementsToModify;
    }

    /*     Historique     */
    pushToModificationsHistory = (modifications) => {
        let modificationsHistory = this.state.modificationsHistory;
        modificationsHistory = modificationsHistory.slice(0, this.state.modificationsHistoryIndex);
        modificationsHistory.push(modifications);
        this.setState(prevState => ({
            modificationsHistory: modificationsHistory,
            modificationsHistoryIndex: prevState.modificationsHistoryIndex + 1
        }));
    }

    handleKeyDown = (e) => {
        if (e.ctrlKey && e.key === 'z') this.restorePreviousModification();
        else if (e.ctrlKey && e.key === 'y') this.restoreNextModification();
        else if ((e.ctrlKey || e.metaKey) && e.key === 'c') {
            const { selectedColumn, selectedRow } = this.state;
            if (selectedColumn && selectedRow) navigator.clipboard.writeText(selectedRow[selectedColumn.key] || '');
        }
    }

    restorePreviousModification = () => {
        const index = this.state.modificationsHistoryIndex;
        const previousModification = this.state.modificationsHistory[index - 1];

        if (previousModification) {
            let data = {
                columns: [...this.state.data.columns],
                rows: [...this.state.data.rows]
            };
            let { elementsToModify, elementsToDelete } = this.state;

            let modificationsToCreate = [], previousElementsId = [], previousElementsProperties = [];
            previousModification.forEach(modification => {
                const { elementId, property, oldValue } = modification;

                previousElementsId.push(elementId);
                previousElementsProperties.push(property);

                if (property !== 'delete') {
                    let row = data.rows.find(row => row.id === elementId);
                    modificationsToCreate.push({ property: property, elementId: elementId, oldValue: row[property] });
                    row[property] = oldValue;
                    if (row.id === this.state.selectedRow?.id) this.updateSelectedRow(row);
                    const value = this.getPropertyValue(property, oldValue);
                    elementsToModify = this.changeElementsToModifyLocally(elementId, property, value, elementsToModify);
                } else {
                    modificationsToCreate.push({ property: property, elementId: elementId, oldValue: oldValue });
                    elementsToDelete = elementsToDelete.filter(element => element.id !== oldValue.id);
                    data.rows.splice(elementId, 0, oldValue);
                }

            });

            let modificationsHistory;
            if (index === this.state.modificationsHistory.length) {
                modificationsHistory = this.state.modificationsHistory;
                modificationsHistory.push(modificationsToCreate);
            } else {
                let actualElementsId = [], actualElementsProperties = [];

                this.state.modificationsHistory[index].forEach(modification => actualElementsId.push(modification.elementId));
                this.state.modificationsHistory[index].forEach(modification => actualElementsProperties.push(modification.property));

                if (JSON.stringify(previousElementsId) !== JSON.stringify(actualElementsId)
                    || JSON.stringify(previousElementsProperties) !== JSON.stringify(actualElementsProperties)
                    || previousModification[0].property === 'delete') {
                    modificationsHistory = this.state.modificationsHistory;
                    modificationsHistory[index] = modificationsToCreate;
                }
            }

            this.setState(prevState => ({
                data: data,
                elementsToModify: elementsToModify,
                elementsToDelete: elementsToDelete,
                modificationsHistory: modificationsHistory || prevState.modificationsHistory,
                modificationsHistoryIndex: index - 1,
            }));
        }
    }

    restoreNextModification = () => {
        const index = this.state.modificationsHistoryIndex;
        const nextModification = this.state.modificationsHistory[index + 1];

        if (nextModification) {
            let data = {
                columns: [...this.state.data.columns],
                rows: [...this.state.data.rows]
            };
            let { elementsToModify, elementsToDelete } = this.state;

            let modificationsToCreate = [], nextElementsId = [], nextElementsProperties = [];
            nextModification.forEach(modification => {
                const { elementId, property, oldValue } = modification;

                nextElementsId.push(elementId);
                nextElementsProperties.push(property);

                if (property !== 'delete') {
                    let row = data.rows.find(row => row.id === elementId);
                    modificationsToCreate.push({ property: property, elementId: elementId, oldValue: row[property] });
                    row[property] = oldValue;
                    if (row.id === this.state.selectedRow?.id) this.updateSelectedRow(row);
                    const value = this.getPropertyValue(property, oldValue);
                    elementsToModify = this.changeElementsToModifyLocally(elementId, property, value, elementsToModify);
                } else {
                    modificationsToCreate.push({ property: property, elementId: elementId, oldValue: oldValue });
                    elementsToDelete.push(oldValue);
                    data.rows.splice(elementId, 1);
                }
            });

            let modificationsHistory, actualElementsId = [], actualElementsProperties = [];

            this.state.modificationsHistory[index].forEach(modification => actualElementsId.push(modification.elementId));
            this.state.modificationsHistory[index].forEach(modification => actualElementsProperties.push(modification.property));

            if (JSON.stringify(nextElementsId) !== JSON.stringify(actualElementsId)
                || nextElementsProperties !== actualElementsProperties
                || nextModification[0].property === 'delete') {
                modificationsHistory = this.state.modificationsHistory;
                modificationsHistory[index] = modificationsToCreate;
            }

            if (index === this.state.modificationsHistory.length - 2)
                modificationsHistory = this.state.modificationsHistory.slice(0, this.state.modificationsHistory.length - 1);

            this.setState(prevState => ({
                data: data,
                elementsToModify: elementsToModify,
                elementsToDelete: elementsToDelete,
                modificationsHistory: modificationsHistory || prevState.modificationsHistory,
                modificationsHistoryIndex: index + 1
            }));
        }
    }

    getPropertyValue = (property, value) => { // Map les valeurs affichées aux valeurs réelles
        switch (property) {
            case 'activationDate': return value ? new Date(value.replaceAll('-', '/').split('/').reverse().join('/')).toISOString() : null;
            case 'expirationDate': return value ? new Date(value.replaceAll('-', '/').split('/').reverse().join('/')).toISOString() : null;
            case 'import': return value === i18n.t("Oui");
            case 'branding': return value === i18n.t("Oui");
            case 'reminderMails': return value === i18n.t("Oui");
            case 'costEstimation': return value === i18n.t("Oui");
            case 'customFields': return value === i18n.t("Oui");
            case 'customCharts': return value === i18n.t("Oui");
            case 'thematicMaps': return value === i18n.t("Oui");
            case 'expertMode': return value === i18n.t("Oui");
            default: return value;
        }
    }

    /*     Remplissage     */
    handleFill = ({ columnKey, sourceRow, targetRows }) => {
        let elementsToModify = this.state.elementsToModify;

        let rowsUpdated = false;
        targetRows.forEach(row => {
            rowsUpdated = true;
            const value = this.getPropertyValue(columnKey, sourceRow[columnKey]);;
            const index = elementsToModify.findIndex(element => element.id === row.id);
            if (index === -1) {
                let element = JSON.parse(JSON.stringify(this.state.elements.find(element => element.id === row.id)));
                element[columnKey] = value;
                elementsToModify.push(element);
            } else elementsToModify[index][columnKey] = value;
        });
        if (rowsUpdated) this.changeElementsToModify(elementsToModify);

        let modificationsToCreate = [];
        const newRows = targetRows.map(row => {
            modificationsToCreate.push({ property: columnKey, elementId: row.id, oldValue: row[columnKey] });
            return { ...row, [columnKey]: sourceRow[columnKey] };
        });
        this.pushToModificationsHistory(modificationsToCreate);

        return newRows;
    }

    /*     Réinitialisation     */
    resetRow = (e, { rowIdx }) => {
        let elementsToModify = this.state.elementsToModify;
        let data = {
            columns: [...this.state.data.columns],
            rows: [...this.state.data.rows]
        };

        // On reset les données de la ligne sélectionnée
        let filteredRows = this.getFilteredRows();
        const index = elementsToModify.findIndex(element => element.id === filteredRows[rowIdx].id);
        if (index !== -1) {
            const initialElement = this.state.elements.find(element => filteredRows[rowIdx].id === element.id);
            elementsToModify[index] = JSON.parse(JSON.stringify(initialElement));
            const newDisplayedData = { ...filteredRows[rowIdx], ...this.getRowValue(initialElement) };
            let modificationsToCreate = [];
            let rowIndex = data.rows.findIndex(row => row.id === filteredRows[rowIdx].id);
            for (const property in newDisplayedData)
                if (data.rows[rowIndex][property] !== newDisplayedData[property])
                    modificationsToCreate.push({ property: property, elementId: data.rows[rowIndex].id, oldValue: data.rows[rowIndex][property] });
            if (modificationsToCreate.length > 0)
                this.pushToModificationsHistory(modificationsToCreate);
            data.rows[rowIndex] = newDisplayedData;
            this.updateSelectedRow(newDisplayedData);
        }

        this.setState({
            data: data,
            elementsToModify: elementsToModify
        });
    }

    getRowValue = (element) => {
        return {
            ...element,
            activationDate: element.activationDate ? DatesUtil.getFormattedLocaleDateString(element.activationDate) : '',
            expirationDate: element.expirationDate ? DatesUtil.getFormattedLocaleDateString(element.expirationDate) : '',
            import: element.import ? i18n.t("Oui") : i18n.t("Non"),
            branding: element.branding ? i18n.t("Oui") : i18n.t("Non"),
            reminderMails: element.reminderMails ? i18n.t("Oui") : i18n.t("Non"),
            costEstimation: element.costEstimation ? i18n.t("Oui") : i18n.t("Non"),
            customFields: element.customFields ? i18n.t("Oui") : i18n.t("Non"),
            customCharts: element.customCharts ? i18n.t("Oui") : i18n.t("Non"),
            expertMode: element.expertMode ? i18n.t("Oui") : i18n.t("Non"),
        };
    }

    /*     Suppression     */
    deleteRow = (_, { rowIdx }) => {
        let elementsToDelete = this.state.elementsToDelete;
        let data = {
            columns: [...this.state.data.columns],
            rows: [...this.state.data.rows]
        };

        // On supprime la ligne sélectionnée et l'ajoute aux éléments à supprimer
        let filteredRows = this.getFilteredRows();
        const initialElement = this.state.elements.find(element => filteredRows[rowIdx].id === element.id);
        if (!['Free', 'Admin', 'Dev'].includes(initialElement.shortName)) {
            elementsToDelete.push(JSON.parse(JSON.stringify(initialElement)));
            let rowIndex = data.rows.findIndex(row => row.id === filteredRows[rowIdx].id);
            this.pushToModificationsHistory([{ property: 'delete', elementId: rowIndex, oldValue: data.rows[rowIndex] }]);
            data.rows.splice(rowIndex, 1);

            this.setState({ data, elementsToDelete, selectedRow: null, selectedColumn: null });
        } else showToast('subscription_deletion_not_allowed');
    }

    handleSubmit = () => {
        let { elementsToModify, elementsToDelete, elements } = this.state;
        let elementsNotToModifyAnymore = [];

        elementsToModify.forEach(elementToModify => {
            if (JSON.stringify(elementToModify) === JSON.stringify(this.state.elements.find(element => element.id === elementToModify.id))
                || elementsToDelete.find(element => element.id === elementToModify.id))
                elementsNotToModifyAnymore.push(elementToModify);
        });
        elementsToModify = elementsToModify.filter(element => !elementsNotToModifyAnymore.includes(element));

        if (elementsToModify.length > 0 || elementsToDelete.length > 0) {
            this.setState({ isUpdating: true });
            let promises = [];
            if (elementsToModify.length > 0)
                promises.push(new Promise(resolve => {
                    SubscriptionsService.updateSubscriptions(elementsToModify).then(response => {
                        if (response === 200) {
                            elementsToModify.forEach(elementToModify => {
                                const index = elements.findIndex(element => element.id === elementToModify.id);
                                elements[index] = elementToModify;
                            });
                            this.setState({ elementsToModify: [] }, () => resolve());
                        }
                    });
                }));

            if (elementsToDelete.length > 0)
                promises.push(new Promise(resolve => {
                    SubscriptionsService.deleteSubscriptions(elementsToDelete.map(element => element.id)).then(response => {
                        if (response === 200) {
                            elements = elements.filter(element => !elementsToDelete.find(elementToDelete => elementToDelete.id === element.id));
                            this.setState({ elementsToDelete: [] }, () => resolve());
                        }
                    });
                }));

            Promise.all(promises).then(() => {
                this.setState({ elements: elements, modificationsHistory: [], modificationsHistoryIndex: 0, isUpdating: false });
            });
        } else {
            this.setState({ elementsToModify: [], modificationsHistory: [], modificationsHistoryIndex: 0 });
            showToast('subscriptions_updated');
        }
    }
}

const mapStateToProps = (state) => {
    return {
        isDarkTheme: state.isDarkTheme,
        activeOrganization: state.activeOrganization
    };
};

export default connect(mapStateToProps)(UserTable);