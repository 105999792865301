import React, { Component } from 'react';
// Composants
import DropDownWithCheckboxes from '../Utils/DropDownWithCheckboxes';
import DatePickerWithPeriod from '../Utils/DatePickerWithPeriod';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PACreationForm from '../Forms/Actions/PACreationForm';
import PAModificationForm from '../Forms/Actions/PAModificationForm';
import InfoIcon from '../Utils/InfoIcon';
import InputPopupForm from '../Utils/InputPopupForm';
import WorkloadChart from '../Charts/WorkloadChart';
/*     Editors     */
import CommentEditor from './Editors/CommentEditor';
/*     Filters     */
import TextFilter from './Filters/TextFilter';
import NumberFilter from './Filters/NumberFilter';
import Woops from '../Utils/Woops';
// Librairies
import DataGrid, { Row as GridRow, SortableHeaderCell } from 'react-data-grid';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import i18n from '../../locales/i18n';
import { endOfDay, endOfMonth, startOfDay, startOfMonth } from 'date-fns';
import { faFlowerTulip, faCalendarClock, faCheck, faSquareCheck, faClock, faExclamationTriangle, faHourglassClock, faShapes, faTimesCircle, faTree, faLink, faTrash, faTablePicnic, faPenToSquare, faEye, faCalendarDays, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { isMobile, isMobileOnly, withOrientationChange } from 'react-device-detect';
// Redux
import { connect } from 'react-redux';
import { setCurrentAction } from '../../actionCreators/appActions';
import { setEditedProperties, unlockEditedProperties, setTableState } from '../../actionCreators/componentsActions';
import { setPhotosGalleries } from '../../actionCreators/elementsActions';
import { setProjectActions } from '../../actionCreators/projectsActions';
import { setLayer } from '../../actionCreators/elementsActions';
import { setPriceLists } from '../../actionCreators/usersActions';
// Ressources
import { faFolder, faFolderOpen } from '@fortawesome/pro-solid-svg-icons';
// Semantic UI
import { Button, Segment, Grid, Message, Dimmer, Dropdown, List, Icon, Select, Checkbox, Form } from 'semantic-ui-react';
// Services
import ActionsService from '../../services/ActionsService';
import PriceListsService from '../../services/PriceListsService';
// Styles
import '../../styles/react-contextmenu.css';
import '../../styles/rdg.css';
// Utils
import { showToast } from '../../utils/ToastsUtil';
import ActionsUtil from '../../utils/ActionsUtil';
import DatesUtil from '../../utils/DatesUtil';
import WebSocketUtil from '../../utils/WebSocketUtil';
import StylesUtil from '../../utils/StylesUtil';
import FormattersUtil from '../../utils/FormattersUtil';
import RightsUtil from '../../utils/RightsUtil';
import UrlsUtil from '../../utils/UrlsUtil';
import DatePicker from '../Utils/DatePicker';

const initialFilters = {
    label: '',
    reference: '',
    recurrence: '',
    place: '',
    links: '',
    price: '',
    nbDone: '',
    nbToDo: '',
    nbTotal: '',
    elements: ['trees', 'greenspaces', 'furnitures'],
    status: ['late', 'current', 'upcoming', 'done']
};

class ActionTable extends Component {
    state = {
        period: 'month',
        startDate: startOfDay(startOfMonth(new Date())),
        endDate: endOfDay(endOfMonth(new Date())),
        data: {
            columns: [],
            rows: []
        },
        sortColumn: null,
        sortDirection: 'NONE',
        enableFilterRow: false,
        showZeros: false,
        showWorkload: false,
        filters: initialFilters,
        groupedBy: 'none',
        paToDelete: null,
        isDeleteLoading: false,
        addCategory: null,
        isLoading: true,
        newProjectAction: null,
        projectActionToEdit: null,
        nbPaerToDelete: 0,
        priceLists: [],
        priceListOptions: [{ text: i18n.t("Par défaut"), value: 0 }],
        priceListId: this.props.project.priceListId || 0,
        arePriceListsLoading: true,
        showExports: false,
        rowToEdit: null,
        search: null,
        paStartDate: null,
        projectActionToValidate: null,
        validationMethod: 'all',
        bulkValidationDate: new Date(),
        isValidating: false
    }

    render() {
        const { isOnline, rights, activeOrganization, isDarkTheme, project, projectActions } = this.props;
        const {
            period, startDate, endDate, data, sortColumn, sortDirection, enableFilterRow, showWorkload, filters, rowToEdit, paerList, paStartDate, projectActionToValidate, validationMethod, bulkValidationDate,
            paToDelete, isDeleteLoading, addCategory, isLoading, isOverflowing, newProjectAction, projectActionToEdit, nbPaerToDelete, priceListOptions, priceListId, arePriceListsLoading, isValidating
        } = this.state;
        const rows = this.getFilteredRows();
        const parentRows = rows.filter(row => !row.parentId && !row.isSubrow);
        const summaryRow = {
            recurrence: parentRows.map(row => row.children.length).reduce((prev, curr) => prev + curr, 0),
            links: parentRows.map(row => row.links).reduce((prev, curr) => prev + curr, 0),
            price: parentRows.map(row => row.price).reduce((prev, curr) => Number(prev) + Number(curr), 0),
            nbDone: parentRows.map(row => row.nbDone).reduce((prev, curr) => prev + curr, 0),
            nbToDo: parentRows.map(row => row.nbToDo).reduce((prev, curr) => prev + curr, 0)
        };
        summaryRow.nbTotal = summaryRow.nbDone + summaryRow.nbToDo;
        const projectSubscription = project.organization.subscription;
        const isExportable = RightsUtil.canExport(rights?.actions) && projectSubscription.export && activeOrganization?.subscription.export && project.type === 'project';

        return (
            <>
                <div className='modal-content'>
                    {!projectActions ?
                        <Woops />
                        :
                        <>
                            {rowToEdit &&
                                <InputPopupForm
                                    title={i18n.t("Date de réalisation")} value={new Date(data.rows.find(r => r.id === rowToEdit).validationDate.valueOf())}
                                    inputType='date' showCurrentValue={false} submitButtonIcon={faCheck} submitButtonLabel={i18n.t("Valider")} disabled={!this.props.isOnline}
                                    submit={this.handleValidationDateSubmit} cancel={() => this.setState({ rowToEdit: null })}
                                />}
                            <div className='modal-content-header' style={{ display: 'flex', alignItems: 'center', flexDirection: isMobile ? 'column' : 'row', marginBottom: '4px' }}>
                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: isMobile ? 'column' : 'row' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', width: '100%', flex: 1, marginBottom: !isMobileOnly && isMobile && '5px' }}>
                                        <div style={{ display: 'flex' }}>
                                            <DatePickerWithPeriod hideLabel={true} period={period} startDate={startDate} endDate={endDate} forceTime={true} setDates={this.setDates} setPeriod={this.setPeriod} />
                                        </div>
                                        {!isMobile && this.renderToolbar()}
                                        {!isMobileOnly && isMobile &&
                                            <div style={{ marginLeft: 'auto' }}>
                                                {project.type === 'project' &&
                                                    <Select
                                                        style={{ marginRight: '5px' }} disabled={arePriceListsLoading} loading={arePriceListsLoading}
                                                        options={priceListOptions} value={priceListId} onChange={(_, { value }) => this.setState(prevState => ({ priceListId: value, search: UrlsUtil.adjustSearch(prevState.search, { priceListId: value !== (this.props.project.priceListId || 0) ? value : null }) }))}
                                                    />}
                                                {project.type === 'project' && !isMobile && RightsUtil.canWrite(this.props.rights?.actions) && this.renderNewActionButton()}
                                            </div>}
                                    </div>
                                </div>
                                {isMobile
                                    ? this.renderToolbar()
                                    : (
                                        <div style={{ marginLeft: 'auto', order: 2, marginBottom: isMobile && '5px' }}>
                                            {project.type === 'project' &&
                                                <Select
                                                    style={{ marginRight: '5px' }} disabled={arePriceListsLoading} loading={arePriceListsLoading}
                                                    options={priceListOptions} value={priceListId} onChange={(_, { value }) => this.setState(prevState => ({ priceListId: value, search: UrlsUtil.adjustSearch(prevState.search, { priceListId: value !== (this.props.project.priceListId || 0) ? value : null }) }))}
                                                />}
                                            {project.type === 'project' && !isMobile && RightsUtil.canWrite(this.props.rights?.actions) && this.renderNewActionButton()}
                                        </div>
                                    )}
                            </div>
                            <div className='modal-content-body'>
                                <Segment id='action-table__segment' style={{ display: 'flex', flexFlow: 'column', padding: 0, width: '100%', height: '100%' }}>
                                    {showWorkload &&
                                        <Dimmer active style={{ ...StylesUtil.getMapStyles().dimmerStyle, zIndex: 1 }} onClick={() => this.setState({ showWorkload: false })}>
                                            <Segment style={{ display: 'flex', justifyContent: 'center' }} onClick={(e) => e.stopPropagation()}>
                                                <WorkloadChart recurrences={paerList} setPAStartDate={this.setPAStartDate} />
                                            </Segment>
                                        </Dimmer>}
                                    {data?.columns &&
                                        <>
                                            <DataGrid
                                                ref={this.gridRef} className={isDarkTheme ? 'rdg-dark' : 'rdg-light'}
                                                columns={data.columns}
                                                rows={rows} rowRenderer={this.rowRenderer} rowClass={(row) => row.parentId ? 'child-row' : 'parent-row'}
                                                defaultColumnOptions={{ sortable: true, resizable: true }}
                                                cellNavigationMode='LOOP_OVER_ROW'
                                                sortColumn={sortColumn} sortDirection={sortDirection}
                                                onSort={this.handleSort} enableFilterRow={enableFilterRow}
                                                filters={filters} onFiltersChange={filters => this.setState(prevState => ({ filters, search: UrlsUtil.adjustSearch(prevState.search, { filters: Object.keys(filters).filter(f => !['elements', 'status'].includes(f) && filters[f]?.trim?.()).map(f => `${f}:${filters[f].trim()}`).join(',') }) }))}
                                                emptyRowsRenderer={() => (<div style={{ textAlign: 'center' }}>
                                                    <span>{isLoading ? i18n.t("Chargement en cours...") : i18n.t("Aucun résultat trouvé")}</span>
                                                </div>)}
                                                onSelectedCellChange={({ idx, rowIdx }) => { this.selectedRow = rows[rowIdx]; this.selectedColumn = data.columns[idx]; }}
                                                onRowsChange={this.handleRowsChange}
                                                summaryRows={[summaryRow]}
                                                style={{ height: (72 + (35 * rows.length) + (enableFilterRow ? 45 : 0) + (isOverflowing ? 10 : 0)) + 'px' }}
                                            />
                                            {project.type === 'project' &&
                                                <>
                                                    <ContextMenu id='grid-manageable-parent-context-menu'>
                                                        {RightsUtil.canWrite(rights?.actions) && <MenuItem onClick={this.manageActionElements}>{i18n.t("Attribuer à un objet")}</MenuItem>}
                                                        <MenuItem disabled={!this.props.isOnline} onClick={this.editProjectAction}>{i18n.t("Modifier")}</MenuItem>
                                                        <MenuItem onClick={this.validateProjectAction}>{i18n.t("Valider")}</MenuItem>
                                                        {isExportable && isOnline && <MenuItem onClick={this.exportActionAsPDF}>{i18n.t("Exporter")}</MenuItem>}
                                                        {RightsUtil.canWrite(rights?.actions) && <MenuItem onClick={this.handleDelete}>{i18n.t("Supprimer")}</MenuItem>}
                                                    </ContextMenu>
                                                    <ContextMenu id='grid-not-manageable-parent-context-menu'>
                                                        <MenuItem onClick={this.manageActionElements}>{i18n.t("Voir")}</MenuItem>
                                                        <MenuItem onClick={this.validateProjectAction}>{i18n.t("Valider")}</MenuItem>
                                                        {isExportable && isOnline && <MenuItem onClick={this.exportActionAsPDF}>{i18n.t("Exporter")}</MenuItem>}
                                                    </ContextMenu>
                                                </>}
                                        </>}
                                </Segment>
                            </div>
                            {(addCategory || isMobile || projectActionToEdit) &&
                                <div className='modal-content-footer'>
                                    {isMobile && !addCategory && this.renderNewActionButton()}
                                    {(addCategory || projectActionToEdit) &&
                                        <Segment style={{ marginTop: '12px' }}>
                                            {addCategory &&
                                                <PACreationForm
                                                    startDate={paStartDate} category={addCategory} projectAction={newProjectAction} selectElementsToLink={this.selectElementsToLink}
                                                    cancel={() => this.setState({ addCategory: null, showWorkload: false, newProjectAction: null })} submit={() => this.setState({ addCategory: null, showWorkload: false, newProjectAction: null })}
                                                />}
                                            {projectActionToEdit &&
                                                <PAModificationForm
                                                    projectAction={projectActionToEdit} cancel={() => this.setState({ projectActionToEdit: null })}
                                                    submit={(projectActionToEdit, nbPaerToDelete) => this.setState({ projectActionToEdit, nbPaerToDelete }, () => { if (!nbPaerToDelete) this.handleModificationConfirmation(); })}
                                                />}
                                        </Segment>}
                                </div>}
                        </>}
                </div>
                {(paToDelete || nbPaerToDelete > 0) &&
                    <Dimmer active style={{ ...StylesUtil.getMapStyles().dimmerStyle, position: 'fixed' }}>
                        <Grid style={{ height: '100%' }}>
                            <Grid.Row style={{ height: '100%' }} verticalAlign='middle'>
                                <Grid.Column>
                                    <Message compact className='tableConfirmation'>
                                        <Message.Content style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Message icon error style={{ textAlign: 'left' }}>
                                                <Icon name='trash' />
                                                <Message.Content>
                                                    <Message.Header>
                                                        {paToDelete
                                                            ? i18n.t("Êtes-vous certain de vouloir supprimer cette action ?")
                                                            : i18n.t("Êtes-vous certain de vouloir modifier cette action ?")}
                                                    </Message.Header>
                                                    {i18n.t("Cela engendrera")}
                                                    <List as='ul' style={{ marginTop: 0 }}>
                                                        {paToDelete ? <>
                                                            <List.Item as='li' className='error'>{i18n.t("La suppression des récurrences existantes.")}</List.Item>
                                                            <List.Item as='li' className='error'>{i18n.t("La suppression des photos liées aux récurrences.")}</List.Item>
                                                        </>
                                                            : <>
                                                                <List.Item as='li' className='error'>{nbPaerToDelete > 1 ? i18n.t("La suppression de {{count}} récurrences existantes.", { count: nbPaerToDelete }) : i18n.t("La suppression d'une récurrence existante.")}</List.Item>
                                                                <List.Item as='li' className='error'>{nbPaerToDelete > 1 ? i18n.t("La suppression des photos liées aux {{count}} récurrences.", { count: nbPaerToDelete }) : i18n.t("La suppression des photos liées à la récurrence.")}</List.Item>
                                                            </>}
                                                    </List>
                                                </Message.Content>
                                            </Message>
                                            <div style={{ marginLeft: 'auto' }}>
                                                <Button color='red' disabled={isDeleteLoading} onClick={paToDelete ? this.handleDeleteCancel : this.handleModificationCancel}>
                                                    {i18n.t("Annuler")}
                                                </Button>
                                                <Button color='green' disabled={isDeleteLoading} loading={isDeleteLoading} onClick={paToDelete ? this.handleDeleteConfirmation : this.handleModificationConfirmation}>
                                                    {i18n.t("Valider")}
                                                </Button>
                                            </div>
                                        </Message.Content>
                                    </Message>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Dimmer>}
                {projectActionToValidate &&
                    <Dimmer active style={{ ...StylesUtil.getMapStyles().dimmerStyle, position: 'fixed' }}>
                        <Segment>
                            <Form style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <Checkbox radio style={{ margin: '5px 0' }} label={i18n.t("Valider toutes les récurrences")} value='all' checked={validationMethod === 'all'} onChange={(_, { value }) => this.setState({ validationMethod: value })} />
                                <Checkbox radio style={{ margin: '5px 0' }} label={i18n.t("Valider les récurences jusqu'au ...")} value='until' checked={validationMethod === 'until'} onChange={(_, { value }) => this.setState({ validationMethod: value })} />
                                <DatePicker
                                    name='validationDate' value={bulkValidationDate} disabled={validationMethod !== 'until' || isValidating}
                                    onChange={(_, { value }) => this.setState({ bulkValidationDate: value })}
                                />
                                <div style={{ display: 'flex', marginTop: '10px' }}>
                                    <Button type='button' color='red' disabled={isValidating} onClick={() => this.setState({ projectActionToValidate: null })}>
                                        <FontAwesomeIcon icon={faTimes} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                                    </Button>
                                    <Button type='submit' color='green' disabled={isValidating} loading={isValidating} onClick={this.validateRecurrences}>
                                        <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("Valider")}
                                    </Button>
                                </div>
                            </Form>
                        </Segment>
                    </Dimmer>}
            </>
        );
    }

    componentDidMount = async () => {
        window.addEventListener('resize', this.handleResize);
        this.props.unlockEditedProperties(); // On débloque la sauvegarde des propriétés dans le store Redux
        if (this.props.tableState) {
            const { elements, data: oldData, ...state } = this.props.tableState;
            this.elements = elements;
            const data = await this.loadData({ groupedBy: state.groupedBy, rows: oldData.rows });
            this.setState({ data, ...state });
            this.props.setTableState(null);
        } else {
            this.elements = {};

            const editedProperties = this.props.editedProperties ? { ...this.props.editedProperties } : null;
            if (editedProperties) { // Si un state a été sauvegardé, on le restore
                this.setState({ ...this.state, ...editedProperties }, () => {
                    this.props.setEditedProperties(null).then(() => this.props.unlockEditedProperties());
                    if (this.props.layersToLink?.length || this.props.layersToUnlink?.length) { // Si des layers ont été sélectionné
                        if (editedProperties.addCategory) {
                            this.setState(prevState => ({
                                newProjectAction: {
                                    ...prevState.newProjectAction, projectActionElements: [
                                        ...(prevState.newProjectAction.projectActionElements || []).filter(pae => !this.props.layersToUnlink.find(layer => layer.feature.id === pae.elementId)),
                                        ...this.props.layersToLink.map(layer => ({ elementId: layer.feature.id, elementType: layer.feature.properties.category }))
                                    ]
                                }
                            }));
                        } else {
                            const projectAction = this.state.projectAction;
                            const paeToRemove = projectAction.projectActionElements.filter(pae => this.props.layersToUnlink.find(layer => layer.feature.id === pae.elementId));
                            const paeToAdd = this.props.layersToLink
                                .filter(layer => !projectAction.projectActionElements.find(pae => pae.elementId === layer.feature.id))
                                .map(layer => ({ elementId: layer.feature.id, elementType: layer.feature.properties.category }));

                            const categories = projectAction.action.categories;
                            if (paeToAdd.length > 0 || paeToRemove.length > 0)
                                ActionsService.updateProjectAction(projectAction, this.props.project.id, { paeToAdd, paeToRemove, successToast: 'action_linked_several', errorToast: 'connection_failed' }).then(response => {
                                    projectAction.projectActionElements = response.projectAction.projectActionElements;
                                    const expandedRows = this.state.data.rows.filter(row => row.isExpanded).map(row => row.id);
                                    this.loadData().then(() => { this.toggleSubRows(expandedRows) });
                                    WebSocketUtil.sendActionsHistories(this.props.webSocketHubs, this.props.project.id, response.history);
                                    WebSocketUtil.updateProjectActions(this.props.webSocketHubs, this.props.project.id, [projectAction]);
                                    this.setState({ projectAction: null });
                                    [...this.props.layersToLink, ...this.props.layersToUnlink].forEach(layer => {
                                        layer.feature.properties.actionsUrgency = ActionsUtil.getElementActionsUrgency(layer.feature.id, this.props.projectActions, layer.feature.properties.category);
                                    });
                                    if (this.props.trees.activeChild === i18n.t("Actions") && categories.includes('Arbre'))
                                        this.props.updateTreesLayerStyle();
                                    if (this.props.greenSpaces.activeChild === i18n.t("Actions") && ['Espace vert', 'Massif arboré'].some(category => categories.includes(category)))
                                        this.props.updateGreenSpacesLayerStyle();
                                    if (this.props.furnitures.activeChild === i18n.t("Actions") && categories.includes('Mobilier'))
                                        this.props.updateFurnituresLayerStyle();
                                    this.props.setLayer(null);
                                });
                        }
                    }
                });
            } else {
                const setPriceLists = (priceLists) => {
                    if (!priceLists) priceLists = [];
                    if (this.props.project.priceList && !priceLists.find(priceList => priceList.id === this.props.project.priceListId))
                        priceLists.push(this.props.project.priceList)
                    this.setState(prevState => ({
                        arePriceListsLoading: false, priceLists,
                        priceListOptions: [...prevState.priceListOptions, ...priceLists.map(priceList => ({ text: priceList.label, value: priceList.id }))]
                    }));
                };

                if (!this.props.priceLists)
                    PriceListsService.getPriceLists().then(priceLists => {
                        if (priceLists) this.props.setPriceLists([...priceLists]);
                        setPriceLists(priceLists);
                    });
                else setPriceLists(this.props.priceLists)

                const { period, startDate, endDate, enableFilterRow, showZeros, filters, groupedBy, elements, status, priceListId, toggledRows } = UrlsUtil.getSearchParams(this.props.location?.search);
                this.setState(prevState => ({
                    period: period || prevState.period,
                    startDate: startDate ? new Date(startDate) : prevState.startDate,
                    endDate: endDate ? new Date(endDate) : prevState.endDate,
                    enableFilterRow: [true, false].includes(enableFilterRow) ? enableFilterRow : prevState.enableFilterRow,
                    showZeros: [true, false].includes(showZeros) ? showZeros : prevState.showZeros,
                    priceListId: priceListId || prevState.priceListId,
                    filters: {
                        ...prevState.filters,
                        ...(filters?.split(',').reduce((prevValue, filter) => ({ ...prevValue, [filter.split(':')[0]]: filter.split(':')[1] }), {}) || {}),
                        elements: elements === 'none' ? [] : (elements?.split(',') || prevState.filters.elements),
                        status: status === 'none' ? [] : (status?.split(',') || prevState.filters.status)
                    },
                    search: this.props.location?.search || UrlsUtil.adjustSearch(prevState.search, { startDate: (startDate ? new Date(startDate) : prevState.startDate).toISOString(), endDate: (endDate ? new Date(endDate) : prevState.endDate).toISOString() })
                }), () => {
                    this.loadData({ groupedBy: groupedBy || 'none' }).then(() => {
                        if (toggledRows) this.toggleSubRows(toggledRows.split ? toggledRows.split(',').map(id => +id) : [+toggledRows]);
                    });;
                });
            }
        }

        this.gridRef = React.createRef();
        document.addEventListener('keydown', this.handleKeyDown);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (JSON.stringify(prevProps.projectActions) !== JSON.stringify(this.props.projectActions)) {
            const expandedRows = this.state.data.rows.filter(row => row.isExpanded).map(row => row.id);
            this.loadData().then(() => { this.toggleSubRows(expandedRows) });
        }

        const { isOverflowing, data } = this.state;
        if (isOverflowing === undefined && data?.columns) {
            const gridElement = document.getElementsByClassName('rdg')[0];
            if (gridElement) {
                const width = data.columns.reduce((previousValue, column) => previousValue + column.width, 0);
                this.setState({ isOverflowing: gridElement.clientWidth < width });
            }
        }

        if (prevState.search?.long !== this.state.search?.long) this.props.history.push({ search: this.state.search?.long })
    }

    componentWillUnmount = () => {
        document.removeEventListener('keydown', this.handleKeyDown);
        document.removeEventListener('resize', this.handleResize);
    }

    renderToolbar = () => {
        const { isOnline, rights, activeOrganization, project } = this.props;
        const { enableFilterRow, showZeros, showWorkload, filters, groupedBy, showExports } = this.state;

        const projectSubscription = project.organization.subscription;
        const isExportable = RightsUtil.canExport(rights?.actions) && projectSubscription.export && activeOrganization?.subscription.export && project.type === 'project';

        const checkIcon = <FontAwesomeIcon icon={faCheck} style={{ position: 'relative', left: '4px' }} />;
        const elementsOptions = [];
        if (RightsUtil.canRead(rights?.trees)) elementsOptions.push({ label: i18n.t("Arbres"), isChecked: filters.elements.includes('trees'), onClick: () => this.toggleSelection('elements', 'trees') });
        if (RightsUtil.canRead(rights?.greenSpaces)) elementsOptions.push({ label: i18n.t("Espaces verts"), isChecked: filters.elements.includes('greenspaces'), onClick: () => this.toggleSelection('elements', 'greenspaces') });
        if (RightsUtil.canRead(rights?.furnitures)) elementsOptions.push({ label: i18n.t("Mobilier urbain"), isChecked: filters.elements.includes('furnitures'), onClick: () => this.toggleSelection('elements', 'furnitures') });

        return (
            <div style={{ marginTop: isMobileOnly && '5px', ...(isMobileOnly ? { paddingBottom: '5px', display: 'flex', maxWidth: '100%', overflow: 'overlay', alignItems: 'center' } : {}) }}>
                <Button.Group style={{ marginRight: '3.5px' }}>
                    <Button
                        title={enableFilterRow ? i18n.t("Désactiver les filtres") : i18n.t("Activer les filtres")}
                        className={enableFilterRow ? 'button--secondary' : null} color={!enableFilterRow ? 'grey' : null} icon='filter'
                        onClick={this.toggleFilters}
                    />
                    <Button
                        title={i18n.t("Réinitialiser les filtres")} className='button--secondary' icon='dont'
                        onClick={this.clearFilters} disabled={!this.areFiltersApplied()}
                    />
                    <DropDownWithCheckboxes
                        icon={faShapes} buttonStyle={{ height: '100%', borderRadius: 0 }}
                        options={elementsOptions}
                    />
                    <DropDownWithCheckboxes
                        icon={faClock} buttonStyle={{ height: '100%', borderRadius: 0 }}
                        options={[
                            { label: i18n.t("En retard"), isChecked: filters.status.includes('late'), onClick: () => this.toggleSelection('status', 'late') },
                            { label: i18n.t("Aujourd'hui"), isChecked: filters.status.includes('current'), onClick: () => this.toggleSelection('status', 'current') },
                            { label: i18n.t("Validée"), isChecked: filters.status.includes('done'), onClick: () => this.toggleSelection('status', 'done') },
                            { label: i18n.t("À venir"), isChecked: filters.status.includes('upcoming'), onClick: () => this.toggleSelection('status', 'upcoming') },
                            { label: i18n.t("Urgente uniquement"), isChecked: filters.status.includes('urgent'), onClick: () => this.toggleSelection('status', 'urgent') }
                        ]}
                    />
                    <Dropdown floating icon='group' button className='icon button--secondary' title={i18n.t("Regroupement")}>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                text={this.renderDropdownText(i18n.t("Aucun"), groupedBy, 'none', checkIcon)}
                                onClick={() => this.handleGroupChange('none')}
                            />
                            <Dropdown.Item
                                text={this.renderDropdownText(i18n.t("Action"), groupedBy, 'action', checkIcon)}
                                onClick={() => this.handleGroupChange('action')}
                            />
                            <Dropdown.Item
                                text={this.renderDropdownText(i18n.t("Référence"), groupedBy, 'reference', checkIcon)}
                                onClick={() => this.handleGroupChange('reference')}
                            />
                        </Dropdown.Menu>
                    </Dropdown>
                    <Button
                        title={showZeros ? i18n.t("Masquer les zéros") : i18n.t("Afficher les zéros")}
                        className={showZeros ? 'button--secondary' : null} color={!showZeros || groupedBy !== 'none' ? 'grey' : null} icon='eye'
                        disabled={groupedBy !== 'none'} onClick={() => this.setState(prevState => ({ showZeros: !prevState.showZeros, search: UrlsUtil.adjustSearch(prevState.search, { showZeros: prevState.showZeros ? false : null }) }))}
                    />
                </Button.Group>
                {!isMobileOnly &&
                    <Button.Group style={{ marginRight: '3.5px' }}>
                        <Button title={i18n.t("Masquer toutes les récurrences")} className='button--secondary' style={{ padding: '11px' }} onClick={this.collapseAll}>
                            <FontAwesomeIcon icon={faFolder} style={{ height: '12px' }} />
                        </Button>
                        <Button title={i18n.t("Afficher toutes les récurrences")} className='button--secondary' style={{ padding: '11px' }} onClick={this.expandAll}>
                            <FontAwesomeIcon icon={faFolderOpen} style={{ height: '12px' }} />
                        </Button>
                    </Button.Group>}
                <Button
                    style={{ display: isMobileOnly && 'flex' }} title={showWorkload ? i18n.t("Masquer le calendrier") : i18n.t("Afficher le calendrier")}
                    className={showWorkload ? 'button--secondary' : null} color={!showWorkload ? 'grey' : null}
                    onClick={() => this.setState(prevState => ({ showWorkload: !prevState.showWorkload }))}
                >
                    <FontAwesomeIcon icon={faCalendarDays} style={{ marginRight: '5px' }} />{i18n.t("Calendrier")}
                </Button>
                {isExportable &&
                    <>
                        <Button
                            style={{ display: isMobileOnly && 'flex' }} title={i18n.t("Exporter les données")} className='button--secondary' icon='download' content={i18n.t("Exporter")}
                            disabled={!isOnline} onClick={() => this.setState(prevState => ({ showExports: !prevState.showExports }))}
                        />
                        {showExports &&
                            <Button.Group>
                                <Button title='Excel' className='button--secondary' icon='file excel' disabled={!isOnline} onClick={this.exportTableAsXLSX} />
                                <Button title='Fiche PDF' className='button--secondary' icon='file pdf' disabled={!isOnline} onClick={this.exportTableAsPDF} />
                            </Button.Group>}
                    </>}
            </div>
        );
    }

    renderNewActionButton = () => {
        return (
            <Dropdown
                text={i18n.t("Nouvelle action")} item floating button direction='left' className={`button--primary${isMobile ? ' form-button' : ''}`}
                style={{ textAlign: isMobile && 'center', marginLeft: 'auto' }}
            >
                <Dropdown.Menu>
                    {RightsUtil.canRead(this.props.rights?.trees) &&
                        <Dropdown.Item onClick={() => this.setState({ addCategory: 'Arbre', showWorkload: true, projectActionToEdit: null })}>
                            <FontAwesomeIcon icon={faTree} style={{ marginRight: '10px' }} />{i18n.t("Arbre")}
                        </Dropdown.Item>}
                    {RightsUtil.canRead(this.props.rights?.greenSpaces) &&
                        <Dropdown.Item onClick={() => this.setState({ addCategory: 'Espace vert', showWorkload: true, projectActionToEdit: null })}>
                            <FontAwesomeIcon icon={faFlowerTulip} style={{ marginRight: '10px' }} />{i18n.t("Espace vert")}
                        </Dropdown.Item>}
                    {RightsUtil.canRead(this.props.rights?.furnitures) &&
                        <Dropdown.Item onClick={() => this.setState({ addCategory: 'Mobilier', showWorkload: true, projectActionToEdit: null })}>
                            <FontAwesomeIcon icon={faTablePicnic} style={{ marginRight: '10px' }} />{i18n.t("Mobilier")}
                        </Dropdown.Item>}
                </Dropdown.Menu>
            </Dropdown>
        );
    }

    renderDropdownText = (label, condition, value, icon1, icon2) => {
        return (<div style={{ display: 'flex', width: '100%' }}>
            <div style={{ marginRight: '30px' }}>{label}</div>
            <div style={{ marginLeft: 'auto' }}>{condition === value ? icon1 : icon2}</div>
        </div>);
    }

    rowRenderer = (props) => {
        const today = DatesUtil.getToday();
        const isManageable = this.props.project.type === 'project' && !props.row.parentId
            && today <= DatesUtil.convertUTCDateToDate(props.row.endDate);
        return (
            <>
                {props.row.isSubrow || ['action', 'reference'].includes(this.state.groupedBy) ?
                    <GridRow {...props} />
                    :
                    <ContextMenuTrigger id={`grid-${!props.row.parentId ? `${isManageable ? 'manageable' : 'not-manageable'}-parent` : 'child'}-context-menu`} collect={() => ({ rowIdx: props.rowIdx })}>
                        <GridRow {...props} />
                    </ContextMenuTrigger>}
            </>
        );
    }

    handleRowsChange = (newRows) => {
        this.setState(prevState => {
            let rows = prevState.data.rows;
            newRows.forEach(newRow => {
                const index = rows.findIndex(row => row.id === newRow.id);
                rows[index] = newRow;
            });
            return { data: { columns: prevState.data.columns, rows: rows } };
        });
    }

    getRows = (groupedBy) => {
        let rows = [];

        const categories = { 'Arbre': 'trees', 'Espace vert': 'greenspaces', 'Mobilier': 'furnitures' };
        const layers = [...this.props.trees.getLayers(), ...this.props.greenSpaces.getLayers(), ...this.props.furnitures.getLayers()];

        const getDescription = ({ category, essenceId, dominantCompositionId, typeId }) => {
            let description = '';

            if (category === 'Arbre') {
                const essence = this.props.essences.find(essence => essence.id === essenceId);
                description = `${essence?.gender || ''} ${essence?.species || ''} ${essence?.cultivar || ''}`.trim();
            }
            else if (category === 'Espace vert')
                description = this.props.dominantCompositions.find(dominantComposition => dominantComposition.id === dominantCompositionId)?.label;
            else if (category === 'Mobilier')
                description = this.props.furnitureTypes.find(type => type.id === typeId)?.label;

            return description;
        };

        switch (groupedBy) {
            case 'none':
                rows = this.props.projectActions.map(pa => {
                    const startDate = DatesUtil.getFormattedLocaleDateString(pa.startDate);
                    const endDate = DatesUtil.getFormattedLocaleDateString(pa.endDate);
                    const recurrence = ActionsUtil.getRecurrencesInfos(pa);

                    return {
                        id: pa.id,
                        categories: pa.action.categories?.map(category => categories[category]),
                        label: pa.action.label,
                        isUrgent: pa.isUrgent,
                        action: pa.action,
                        projectActionElements: pa.projectActionElements,
                        links: pa.projectActionElements.length,
                        startDate: pa.startDate,
                        endDate: pa.endDate,
                        recurrence: !recurrence.includes('1x')
                            ? `${startDate} - ${endDate}, ${recurrence}`
                            : `${startDate} (1x)`,
                        children: pa.projectActionElements.flatMap(pae => pae.projectActionElementRecurrences.map(paer => {
                            let element = this.elements[pae.elementId];
                            if (!element) element = layers.find(layer => layer.feature.id === pae.elementId)?.feature;
                            if (element) {
                                const { projectReference, customReference, place, category } = element.properties;

                                return {
                                    id: paer.id,
                                    category: categories[category],
                                    parentId: pa.id,
                                    elementId: pae.elementId,
                                    isDone: paer.isDone,
                                    date: paer.date,
                                    validationDate: paer.isDone && (paer.validationDate || paer.date),
                                    reference: projectReference + (customReference ? ` (${customReference})` : ''),
                                    place,
                                    description: getDescription(element.properties),
                                    comment: paer.comment
                                };
                            } else return null;
                        })).filter(row => row)
                    };
                });
                break;
            case 'action':
                let id = 0;
                this.props.projectActions.forEach(pa => {
                    pa.projectActionElements.forEach(pae => {
                        let element = this.elements[pae.elementId];
                        if (!element) element = layers.find(layer => layer.feature.id === pae.elementId)?.feature;
                        if (element) {
                            const { projectReference, customReference, place, category } = element.properties;

                            const row = rows.find(row => row.label === pa.action.label);
                            if (row) {
                                row.links++;
                                row.children.push(...pae.projectActionElementRecurrences.map(paer => ({
                                    id: paer.id,
                                    parentId: row.id,
                                    elementId: pae.elementId,
                                    projectAction: pa,
                                    category: categories[category],
                                    isDone: paer.isDone,
                                    date: paer.date,
                                    validationDate: paer.isDone && (paer.validationDate || paer.date),
                                    reference: projectReference + (customReference ? ` (${customReference})` : ''),
                                    isUrgent: pa.isUrgent,
                                    place,
                                    description: getDescription(element.properties),
                                    comment: paer.comment
                                })));
                            } else {
                                id++;
                                rows.push({
                                    id,
                                    label: pa.action.label,
                                    links: 1,
                                    children: pae.projectActionElementRecurrences.map(paer => ({
                                        id: paer.id,
                                        parentId: id,
                                        elementId: pae.elementId,
                                        projectAction: pa,
                                        category: categories[category],
                                        isDone: paer.isDone,
                                        date: paer.date,
                                        validationDate: paer.isDone && (paer.validationDate || paer.date),
                                        reference: projectReference + (customReference ? ` (${customReference})` : ''),
                                        isUrgent: pa.isUrgent,
                                        place,
                                        description: getDescription(element.properties),
                                        comment: paer.comment
                                    }))
                                });
                            }
                        }
                    });

                });
                break;
            case 'reference':
                this.props.projectActions.forEach(pa => {
                    pa.projectActionElements.forEach(pae => {
                        let element = this.elements[pae.elementId];
                        if (!element) element = layers.find(layer => layer.feature.id === pae.elementId)?.feature;
                        if (element) {
                            const { projectReference, customReference, place, category } = element.properties;

                            const row = rows.find(row => row.id === pae.elementId);
                            if (row) {
                                row.links++;
                                row.children.push(...pae.projectActionElementRecurrences.map(paer => ({
                                    id: paer.id,
                                    parentId: row.id,
                                    elementId: pae.elementId,
                                    projectAction: pa,
                                    isDone: paer.isDone,
                                    date: paer.date,
                                    validationDate: paer.isDone && (paer.validationDate || paer.date),
                                    label: pa.action.label,
                                    isUrgent: pa.isUrgent,
                                    comment: paer.comment
                                })))
                            } else rows.push({
                                id: pae.elementId,
                                category: categories[category],
                                reference: projectReference + (customReference ? ` (${customReference})` : ''),
                                place,
                                description: getDescription(element.properties),
                                links: 1,
                                children: pae.projectActionElementRecurrences.map(paer => ({
                                    id: paer.id,
                                    parentId: pae.elementId,
                                    elementId: pae.elementId,
                                    projectAction: pa,
                                    isDone: paer.isDone,
                                    date: paer.date,
                                    validationDate: paer.isDone && (paer.validationDate || paer.date),
                                    label: pa.action.label,
                                    isUrgent: pa.isUrgent,
                                    comment: paer.comment
                                }))
                            });
                        }
                    });

                });
                break;
            default: break;
        }

        return rows.map(row => ({ ...row, children: row.children.sort((a, b) => new Date(a.date) - new Date(b.date)) }));
    }

    loadData = ({ groupedBy = this.state.groupedBy, rows } = {}) => new Promise(resolve => {
        const isAct = groupedBy === 'action', isRef = groupedBy === 'reference';
        const data = {
            columns: [],
            rows: rows || []
        };

        const getHeaderRenderer = ({ column, onSort, sortColumn, sortDirection }, icon, color) => (
            <div>
                <SortableHeaderCell
                    column={column}
                    onSort={onSort}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                >
                    {column.name} {icon && <FontAwesomeIcon icon={icon} style={{ marginLeft: '5px' }} color={color} />}
                </SortableHeaderCell>
            </div>
        );

        const checkRecurrence = (paer) => {
            const today = DatesUtil.getToday();
            if (!paer) return 0;
            else if (paer.isDone) return 2; // Faite
            else if (DatesUtil.daysBetweenTwoDates(DatesUtil.convertUTCDateToDate(paer.date), today) > 0) return 1; // A venir
            else if (DatesUtil.daysBetweenTwoDates(DatesUtil.convertUTCDateToDate(paer.date), today) < 0) return 4; // En retard
            else return 3; // En cours (aujourd'hui)
        };

        const getExpandFormatter = (childRows, value, expanded, onCellExpand) => {
            const globalStatus = Math.max(...childRows.map(row => checkRecurrence(row)));
            const expander = globalStatus === 4 ? { title: i18n.t("En retard"), color: '#c83030' }
                : globalStatus === 3 ? { title: i18n.t("Aujourd'hui"), color: '#daa817' }
                    : globalStatus === 2 ? { title: i18n.t("À jour"), color: '#68bd46' }
                        : { title: i18n.t("À venir"), color: '#7d7d7d' };

            return (
                <div className='expandable'>
                    <div className='rdg-cell-value'>
                        <div>{value}</div>
                    </div>
                    <div className='expand-formatter'>
                        <span title={expander.title} onClick={e => { e.stopPropagation(); onCellExpand() }}>
                            <span tabIndex={-1} className='expander' style={{ color: expander.color }}>
                                {expanded ? '\u25BC' : '\u25B6'}
                            </span>
                        </span>
                    </div>
                </div>
            )
        };

        const getStatusButton = (row) => {
            const { rights, project } = this.props;
            const { parentId, date, isDone } = row;
            const projectSubscription = project.organization.subscription;
            const paerDate = DatesUtil.convertUTCDateToDate(date);
            const nbDays = DatesUtil.daysBetweenTwoDates(paerDate, new Date());
            const color = isDone ? 'green' : nbDays === 0 ? 'yellow' : nbDays < 0 ? 'red' : 'grey';
            const title = isDone ? i18n.t("Cliquer pour invalider l'action") : i18n.t("Cliquer pour valider l'action");
            const icon = isDone ? faSquareCheck : nbDays === 0 ? faClock : nbDays < 0 ? faHourglassClock : faCalendarClock;
            const content = isDone ? i18n.t("Validée") : nbDays === 0 ? i18n.t("Aujourd'hui") : nbDays < 0 ? i18n.t("En retard") : i18n.t("À venir");

            // Recherche de la currentRecurrence (2)
            const elementId = isRef ? row.parentId : row.elementId;
            const disabled = !RightsUtil.canWrite(rights?.actions) || !projectSubscription.actions || this.props.project.type !== 'project' ? true : false;

            return (
                <div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Button
                        color={color} title={title} style={{ padding: '6px 20px', width: '100%' }}
                        disabled={disabled} onClick={() => { if (!disabled) this.updateProjectActionElementRecurrence(parentId, row, !isDone) }}
                    >
                        <FontAwesomeIcon icon={icon} style={{ marginRight: '7px' }} />{content}
                    </Button>
                </div>
            );
        };

        data.columns = [
            {
                name: isRef ? i18n.t("Référence") : i18n.t("Libellé"), key: isRef ? 'reference' : 'label', width: isRef ? 200 : 250, sortable: true,
                formatter: (props) => props.row.isSubrow
                    ? <div className='subheader'>{i18n.t("Statut")}</div>
                    : !props.row.parentId
                        ? props.row.children?.length ?
                            getExpandFormatter(
                                props.row.children,
                                <div style={{ display: 'flex', alignItems: 'center', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                    {isRef && <Button size='mini' color='blue' icon='eye' className='show-button' style={{ marginRight: '5px' }} onClick={() => this.showElement(props.row.id, props.row.category)} />}
                                    {props.row.category &&
                                        <FontAwesomeIcon
                                            icon={props.row.category === 'trees' ? faTree : props.row.category === 'greenspaces' ? faFlowerTulip : faTablePicnic}
                                            title={props.row.category === 'trees' ? i18n.t("Arbre") : props.row.category === 'greenspaces' ? i18n.t("Espace vert") : i18n.t("Mobilier")}
                                            style={{ marginRight: '7px', alignSelf: 'center' }}
                                        />}
                                    {props.row.isUrgent && <FontAwesomeIcon icon={faExclamationTriangle} title={i18n.t("Urgente")} style={{ color: 'rgb(255,165,0)', marginRight: '7px', alignSelf: 'center' }} />}
                                    {props.row[isRef ? 'reference' : 'label']}
                                </div>,
                                props.row.isExpanded === true,
                                () => this.toggleSubRows([props.row.id])
                            ) : <div style={{ display: 'flex', alignItems: 'center' }}>
                                {props.row.category &&
                                    <FontAwesomeIcon
                                        icon={props.row.category === 'trees' ? faTree : props.row.category === 'greenspaces' ? faFlowerTulip : faTablePicnic}
                                        title={props.row.category === 'trees' ? i18n.t("Arbre") : props.row.category === 'greenspaces' ? i18n.t("Espace vert") : i18n.t("Mobilier")}
                                        style={{ marginRight: '7px' }}
                                    />}
                                {props.row.isUrgent && <FontAwesomeIcon icon={faExclamationTriangle} title={i18n.t("Urgente")} style={{ color: 'rgb(255,165,0)', marginRight: '7px' }} />}
                                {props.row[isRef ? 'reference' : 'label']}
                            </div>
                        : getStatusButton(props.row),
                summaryFormatter: () => <div className='subheader total'>{i18n.t("Total")}</div>,
                filterRenderer: (props) => <TextFilter p={props} />
            },
            {
                name: isAct ? '' : isRef ? i18n.t("Lieu") : i18n.t("Récurrence"), key: isAct ? '' : isRef ? 'place' : 'recurrence', width: 325, sortable: true,
                formatter: (props) => props.row.isSubrow
                    ? <div className='subheader'>{i18n.t("Date")}</div>
                    : !props.row.parentId
                        ? isAct ? '' : props.row[isRef ? 'place' : 'recurrence']
                        :
                        <div style={{ display: 'flex' }}>
                            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'flex' }}>
                                <div title={i18n.t("Date planifiée")}>
                                    <FontAwesomeIcon icon={faCalendarClock} style={{ marginRight: '5px' }} />
                                    {DatesUtil.getFormattedLocaleDateString(props.row.date)}
                                </div>
                                {props.row.validationDate &&
                                    <div title={i18n.t("Date de réalisation")} style={{ marginLeft: '15px' }}>
                                        <FontAwesomeIcon icon={faSquareCheck} style={{ marginRight: '5px' }} />
                                        {DatesUtil.getFormattedLocaleDateString(props.row.validationDate, true)}
                                    </div>}
                            </div>
                            <Button
                                color='yellow' className='edit-button' title={i18n.t("Modifier")} disabled={!props.row.validationDate || this.props.project.type !== 'project' || !this.props.isOnline || !RightsUtil.canWrite(this.props.rights?.actions)}
                                style={{ marginLeft: 'auto', padding: 0, height: '20px', width: '20px' }} onClick={() => this.showEditValidationDate(props.row.id, props.row.validationDate)}
                            >
                                <FontAwesomeIcon icon={faPenToSquare} />
                            </Button>
                        </div>,
                summaryFormatter: (props) => <div className='subheader total'>{props.row.recurrence}</div>,
                filterRenderer: (props) => !isAct && <TextFilter p={props} />
            },
            {
                name: isRef ? i18n.t("Descriptif") : i18n.t("Liaisons"), key: isRef ? 'description' : 'links', width: isRef ? 250 : 140, sortable: true,
                formatter: (props) => props.row.isSubrow
                    ? <div className='subheader'>{isRef ? i18n.t("Libellé") : i18n.t("Référence")}</div>
                    : !props.row.parentId
                        ? <div style={{ display: 'flex', alignItems: 'center' }}>
                            {isRef
                                ? props.row.description
                                : <>
                                    {props.row.links}
                                    {!props.row.links &&
                                        <InfoIcon content={i18n.t("Aucune occurrence de cette action n'existe pour la période sélectionnée")} iconStyle={{ marginLeft: '7px' }} />}
                                </>}
                        </div>
                        : <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ display: 'flex', alignItems: 'center', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                {props.row.category &&
                                    <FontAwesomeIcon
                                        icon={props.row.category === 'trees' ? faTree : props.row.category === 'greenspaces' ? faFlowerTulip : faTablePicnic}
                                        title={props.row.category === 'trees' ? i18n.t("Arbre") : props.row.category === 'greenspaces' ? i18n.t("Espace vert") : i18n.t("Mobilier")}
                                        style={{ marginRight: '7px' }}
                                    />}
                                {props.row.isUrgent && <FontAwesomeIcon icon={faExclamationTriangle} title={i18n.t("Urgente")} style={{ marginRight: '7px' }} />}
                                {props.row[isRef ? 'label' : 'reference']}
                            </div>
                            {!isRef &&
                                <Button
                                    color='blue' className='show-button' title={i18n.t("Voir sur la carte")}
                                    style={{ marginLeft: 'auto', padding: 0, height: '20px', width: '20px' }} onClick={() => this.showElement(props.row.elementId, props.row.category)}
                                >
                                    <FontAwesomeIcon icon={faEye} size='sm' />
                                </Button>}
                        </div>,
                summaryFormatter: (props) => <div className='subheader total'>{props.row.links}</div>,
                filterRenderer: (props) => <NumberFilter p={props} />
            },
            {
                name: i18n.t("Prix total"), key: 'price', width: 140, sortable: true,
                formatter: (props) => {
                    if (props.row.isSubrow) return <div className='subheader'>{i18n.t("Prix unitaire")}</div>;
                    else {
                        const priceList = this.state.priceLists.find(priceList => priceList.id === this.state.priceListId);
                        const currency = this.props.currencies.find(currency => currency.id === priceList?.currencyId);
                        return props.row.price + (currency?.symbol || currency?.code || '€');
                    }
                },
                summaryFormatter: (props) => {
                    const priceList = this.state.priceLists.find(priceList => priceList.id === this.state.priceListId);
                    const currency = this.props.currencies.find(currency => currency.id === priceList?.currencyId);
                    return (<div className='subheader total'>{`${props.row.price}${currency?.symbol || currency?.code || '€'}`}</div>);
                },
                filterRenderer: (props) => <NumberFilter p={props} />
            },
            {
                name: i18n.t("Fait"), key: 'nbDone', width: isRef ? 140 : 250, sortable: true,
                formatter: (props) => props.row.isSubrow
                    ? <div className='subheader'>{isRef ? '' : i18n.t("Lieu")}</div>
                    : !props.row.parentId
                        ? props.row.nbDone
                        : isRef ? '' : props.row.place,
                summaryFormatter: (props) => <div className='subheader total'>{props.row.nbDone}</div>,
                headerRenderer: (props) => getHeaderRenderer(props, faSquareCheck, 'var(--green-100)'),
                filterRenderer: (props) => <NumberFilter p={props} />
            },
            {
                name: i18n.t("À faire"), key: 'nbToDo', width: isRef ? 140 : 250, sortable: true,
                formatter: (props) => props.row.isSubrow
                    ? <div className='subheader'>{isRef ? '' : i18n.t("Descriptif")}</div>
                    : !props.row.parentId
                        ? props.row.nbToDo
                        : isRef ? '' : props.row.description,
                summaryFormatter: (props) => <div className='subheader total'>{props.row.nbToDo}</div>,
                headerRenderer: (props) => getHeaderRenderer(props, faTimesCircle, 'var(--red-100)'),
                filterRenderer: (props) => <NumberFilter p={props} />,
            },
            {
                name: i18n.t("Total"), key: 'nbTotal', width: isRef ? 140 : 250,
                sortable: true, editable: (props) => props.parentId && !isRef ? true : false,
                formatter: (props) => props.row.isSubrow
                    ? <div className='subheader'>{isRef ? '' : i18n.t("Commentaire")}</div>
                    : !props.row.parentId
                        ? props.row.nbTotal
                        : isRef ? '' : props.row.comment,
                summaryFormatter: (props) => <div className='subheader total'>{props.row.nbTotal}</div>,
                filterRenderer: (props) => <NumberFilter p={props} />,
                editor: ({ row, onRowChange, onClose }) => <CommentEditor row={row} onRowChange={onRowChange} onClose={onClose} updateProjectActionElementRecurrence={this.updateProjectActionElementRecurrence} />
            }
        ];

        if (isRef)
            data.columns.splice(3, 0, {
                name: i18n.t("Liaisons"), key: 'links', width: 250, sortable: true, editable: (props) => props.parentId ? true : false,
                formatter: (props) => props.row.isSubrow
                    ? <div className='subheader'>{i18n.t("Commentaire")}</div>
                    : !props.row.parentId
                        ? <div style={{ display: 'flex', alignItems: 'center' }}>
                            {props.row.links}
                            {!props.row.links &&
                                <InfoIcon content={i18n.t("Aucune occurrence de cette action n'existe pour la période sélectionnée")} iconStyle={{ marginLeft: '7px' }} />}
                        </div>
                        : props.row.comment,
                summaryFormatter: (props) => <div className='subheader total'>{props.row.links}</div>,
                filterRenderer: (props) => <NumberFilter p={props} />,
                editor: ({ row, onRowChange, onClose }) => <CommentEditor row={row} onRowChange={onRowChange} onClose={onClose} updateProjectActionElementRecurrence={this.updateProjectActionElementRecurrence} />
            });

        if (groupedBy === 'none' && this.props.project.type === 'project' && RightsUtil.canWrite(this.props.rights?.actions))
            data.columns.splice(0, 0, {
                name: '', key: 'actions', width: 110,
                formatter: (props) =>
                    !props.row.isSubrow && !props.row.parentId &&
                    <>
                        <Button color='blue' title={i18n.t("Attribuer à un objet")} style={{ padding: 0, height: '25px', width: '25px' }} onClick={() => this.manageActionElements(null, props)}>
                            <FontAwesomeIcon icon={faLink} />
                        </Button>
                        <Button color='yellow' title={i18n.t("Modifier")} style={{ padding: 0, height: '25px', width: '25px' }} disabled={!this.props.isOnline} onClick={() => this.editProjectAction(null, props)}>
                            <FontAwesomeIcon icon={faPenToSquare} />
                        </Button>
                        <Button color='red' title={i18n.t("Supprimer")} style={{ padding: 0, height: '25px', width: '25px' }} onClick={() => this.handleDelete(null, props)}>
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    </>
            });

        if (!rows) {
            data.rows = this.getRows(groupedBy);

            const paerList = this.props.projectActions.flatMap(pa => (
                pa.projectActionElements.flatMap(pae => pae.projectActionElementRecurrences)
            ));

            const initialOrder = [];
            data.rows.forEach(row => {
                initialOrder.push(row.id);
                row.children.forEach(childRow => initialOrder.push(`${row.id}|${childRow.id}`));
            });

            this.setState(prevState => ({ data, paerList, initialOrder, groupedBy, isLoading: false, search: UrlsUtil.adjustSearch(prevState.search, { groupedBy: groupedBy === 'none' ? null : groupedBy }) }), () => resolve());
        } else resolve(data);
    });

    setDates = (startDate, endDate) => this.setState(prevState => ({ startDate, endDate, search: UrlsUtil.adjustSearch(prevState.search, { startDate: startDate?.toISOString(), endDate: endDate?.toISOString() }) }));
    setPeriod = (period) => this.setState(prevState => ({ period, search: UrlsUtil.adjustSearch(prevState.search, { period: period === 'none' ? null : period }) }));
    handleGroupChange = (groupedBy) => this.loadData({ groupedBy });
    toggleSelection = (property, element) => {
        const { filters } = this.state;
        if (filters[property].includes(element)) filters[property] = filters[property].filter(e => e !== element);
        else filters[property].push(element);
        const renderSearch = property === 'elements'
            ? filters[property].length !== 3
            : ['late', 'current', 'done', 'upcoming'].some(status => !filters[property].includes(status)) || filters[property].includes('urgent')
        this.setState(prevState => ({ filters, search: UrlsUtil.adjustSearch(prevState.search, { [property]: renderSearch ? filters[property].join(',') || 'none' : null }) }));
    }

    setPAStartDate = (date) => {
        if (!this.state.addCategory) return;
        const now = new Date();
        date = new Date(date);
        date.setHours(now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds());
        this.setState({ paStartDate: date });
    }

    manageActionElements = (_, { rowIdx }) => { // Lorsqu'on clique pour lier des éléments à l'action*
        const filteredRows = this.getFilteredRows();
        const projectAction = this.props.projectActions.find(pa => pa.id === filteredRows[rowIdx].id);
        this.props.setEditedProperties({ ...this.state, projectAction, addCategory: null, showWorkload: false, projectActionToEdit: null }).then(() => {
            this.props.manageActionElements(projectAction);
        });
    }

    editProjectAction = (_, { rowIdx }) => {
        const filteredRows = this.getFilteredRows();
        const projectActionToEdit = this.props.projectActions.find(pa => pa.id === filteredRows[rowIdx].id);
        this.setState(prevState => ({
            addCategory: null, showWorkload: false,
            projectActionToEdit: !prevState.projectActionToEdit || prevState.projectActionToEdit?.id !== projectActionToEdit.id ? JSON.parse(JSON.stringify(projectActionToEdit)) : null
        }));
    }

    validateProjectAction = (_, { rowIdx }) => {
        const filteredRows = this.getFilteredRows();
        const projectActionToValidate = this.props.projectActions.find(pa => pa.id === filteredRows[rowIdx].id);
        this.setState({ projectActionToValidate: JSON.parse(JSON.stringify(projectActionToValidate)) });
    }

    updateProjectActionElementRecurrence = (paId, paerToUpdate, state, validationDate) => {
        const paer = this.state.paerList.find(paer => paer.id === paerToUpdate.id);
        paer.validationDate = state ? validationDate || DatesUtil.getUTCDate() : null;
        paer.isDone = state; // Met à jour la liste dans le state mais aussi la récurrence dans Redux (même objet)
        paer.comment = paerToUpdate.comment;

        ActionsService.updateRecurrences(this.props.project.id, paId, [paer]).then(response => {
            if (response?.recurrences) WebSocketUtil.updateRecurrences(this.props.webSocketHubs, this.props.project.id, response.recurrences);

            // Mise à jour des rows
            this.setState(prevState => {
                const rows = [...prevState.data.rows];
                const parentRow = rows.find(row => row.id === paerToUpdate.parentId);
                const childRowInParent = parentRow.children.find(row => row.id === paerToUpdate.id);
                childRowInParent.isDone = paer.isDone; childRowInParent.comment = paer.comment;
                const childRow = rows.find(row => row.id === paerToUpdate.id);
                childRow.isDone = paer.isDone; childRow.comment = paer.comment; childRow.validationDate = paer.validationDate;
                return { data: { columns: prevState.data.columns, rows }, rowToEdit: null };
            });
        });
    }

    validateRecurrences = () => {
        const { projectActionToValidate: projectAction, bulkValidationDate, validationMethod } = this.state;

        bulkValidationDate.setHours(23, 59, 59, 999);
        const recurrenceIds = projectAction.projectActionElements
            .flatMap(pae => pae.projectActionElementRecurrences)
            .filter(paer => !paer.isDone && (validationMethod === 'all' || paer.date < bulkValidationDate.toISOString()))
            .map(paer => paer.id);
        const recurrences = this.state.paerList.filter(paer => recurrenceIds.includes(paer.id));

        const validationDate = DatesUtil.getUTCDate();
        recurrences.forEach(recurrence => {
            recurrence.validationDate = validationDate;
            recurrence.isDone = true; // Met à jour la liste dans le state mais aussi la récurrence dans Redux (même objet)
        });

        if (recurrences.length) {
            this.setState({ isValidating: true });
            if (recurrences.length > 0)
                ActionsService.updateRecurrences(this.props.project.id, projectAction.id, recurrences).then(response => {
                    if (response?.recurrences) WebSocketUtil.updateRecurrences(this.props.webSocketHubs, this.props.project.id, response.recurrences);

                    // Mise à jour des rows
                    this.setState(prevState => {
                        const rows = [...prevState.data.rows];
                        const parentRow = rows.find(row => row.id === projectAction.id);
                        recurrences.forEach(recurrence => {
                            const childRowInParent = parentRow.children.find(row => row.id === recurrence.id)
                            if (childRowInParent) { childRowInParent.isDone = true; childRowInParent.validationDate = validationDate; }
                            const childRow = rows.find(row => row.id === recurrence.id);
                            if (childRow) { childRow.isDone = true; childRow.validationDate = validationDate; }
                        });
                        return { data: { columns: prevState.data.columns, rows }, projectActionToValidate: null, validationMethod: 'all', bulkValidationDate: new Date(), isValidating: false };
                    });
                });
        } else {
            showToast('action_updated');
            this.setState({ projectActionToValidate: null, validationMethod: 'all', bulkValidationDate: new Date() });
        }
    }

    getRowsForExport = () => {
        const categories = { 'trees': i18n.t("Arbre"), 'greenspaces': i18n.t("Espace vert"), 'furnitures': i18n.t("Mobilier") };
        return this.getFilteredRows()
            .filter(row => !row.parentId && !row.isSubrow)
            .flatMap(row => [row, ...(row.children || [])])
            .map(row => ({
                ...row,
                status: row.status === 'late' ? i18n.t("En retard")
                    : row.status === 'current' ? i18n.t("Aujourd'hui")
                        : row.status === 'done' ? i18n.t("Validée")
                            : row.status === 'upcoming' ? i18n.t("À venir") : '',
                date: row.date ? DatesUtil.getFormattedLocaleDateString(row.date) : '',
                category: row.category && categories[row.category]
            }))
    }

    exportTableAsPDF = () => {
        this.setState(prevState => ({ showExports: !prevState.showExports }));

        let { startDate, endDate, groupedBy } = this.state;
        startDate = startOfDay(startDate);
        endDate = endOfDay(endDate);

        const convertDate = (date) => DatesUtil.convertUTCDateToDate(date.toISOString()).toLocaleDateString();

        this.props.setCurrentAction('exportingBacklog');
        this.props.exportActionTableAsPDF(this.getRowsForExport(), { startDate: convertDate(startDate), endDate: convertDate(endDate), groupedBy });
    }

    exportTableAsXLSX = () => {
        this.setState(prevState => ({ showExports: !prevState.showExports }));
        ActionsService.exportBacklogAsXLSX(this.props.project.label, this.getRowsForExport(), this.state.groupedBy);
    }

    exportActionAsPDF = (_, { rowIdx }) => {
        const filteredRows = this.getFilteredRows();
        const projectAction = this.props.projectActions.find(pa => pa.id === filteredRows[rowIdx].id);
        this.props.setCurrentAction('exportingAction');
        this.props.exportActionAsPDF(projectAction);
    }

    handleDelete = (_, { rowIdx }) => {
        const filteredRows = this.getFilteredRows();
        const projectAction = this.props.projectActions.find(pa => pa.id === filteredRows[rowIdx].id);
        this.setState({ paToDelete: projectAction.id });
    }
    handleDeleteCancel = () => this.setState({ paToDelete: null });
    handleDeleteConfirmation = () => {
        const { paToDelete } = this.state;
        this.setState({ isDeleteLoading: true });
        ActionsService.removeProjectAction(paToDelete, this.props.project.id).then(() => {// Mise à jour des rows
            const recurrenceIds = this.props.projectActions.find(pa => pa.id === paToDelete).projectActionElements.flatMap(pae => pae.projectActionElementRecurrences.map(paer => paer.id));
            this.props.setPhotosGalleries(this.props.photosGalleries.filter(photo => !recurrenceIds.includes(photo.recurrenceId)));
            this.props.setProjectActions(this.props.projectActions.filter(pa => pa.id !== paToDelete));
            WebSocketUtil.removeProjectActions(this.props.webSocketHubs, this.props.project.id, [paToDelete])
            this.setState(prevState => ({
                data: {
                    columns: prevState.data.columns,
                    rows: prevState.data.rows.filter(row => row.id !== paToDelete && row.parentId !== paToDelete)
                },
                paToDelete: null, isDeleteLoading: false
            }));
        });
    }

    handleModificationCancel = () => this.setState({ projectActionToEdit: this.props.projectActions.find(pa => pa.id === this.state.projectActionToEdit.id), nbPaerToDelete: 0 });
    handleModificationConfirmation = () => {
        ActionsService.updateProjectAction(this.state.projectActionToEdit, this.props.project.id).then(({ projectAction, history }) => {
            WebSocketUtil.sendActionsHistories(this.props.webSocketHubs, this.props.project.id, history);
            const projectActions = JSON.parse(JSON.stringify(this.props.projectActions));
            const index = projectActions.findIndex(pa => pa.id === projectAction.id);
            if (index !== -1) {
                projectActions[index] = projectAction;
                this.props.setProjectActions(projectActions);
                WebSocketUtil.updateProjectActions(this.props.webSocketHubs, this.props.project.id, [projectAction]);
            }
            this.setState({ projectActionToEdit: null, nbPaerToDelete: 0 });
        });
    }

    // Filtres
    areFiltersApplied = () => {
        if (!this.state.enableFilterRow) return false;
        let filtersApplied = false;
        for (const property in this.state.filters)
            if (this.state.filters[property] && property !== 'elements') filtersApplied = true;
        return filtersApplied;
    }

    toggleFilters = () => this.setState(prevState => ({ enableFilterRow: !prevState.enableFilterRow, search: UrlsUtil.adjustSearch(prevState.search, { enableFilterRow: prevState.enableFilterRow ? null : true }) }));
    clearFilters = () => this.setState(prevState => ({ filters: initialFilters, search: UrlsUtil.adjustSearch(prevState.search, { filters: null }) }));

    getFilteredRows = () => {
        const filters = this.state.filters;
        let rows = [...this.state.data.rows];
        let { startDate, endDate, priceListId } = this.state;
        startDate = startOfDay(startDate);
        endDate = endOfDay(endDate);

        const isAct = this.state.groupedBy === 'action', isRef = this.state.groupedBy === 'reference';
        const $ = (str) => FormattersUtil.getNormalizedString(str);
        const rowMatchesFilters = (row) => {
            return (filters[isRef ? 'reference' : 'label'] ? $(row[isRef ? 'reference' : 'label'])?.includes($(filters[isRef ? 'reference' : 'label'])) : true)
                && (!isAct && filters[isRef ? 'place' : 'recurrence'] ? $(row[isRef ? 'place' : 'recurrence'])?.includes($(filters[isRef ? 'place' : 'recurrence'])) : true)
                && (filters.links ? row.links === Number(filters.links) : true)
                && (filters.price ? row.price === Number(filters.price) : true)
                && (filters.nbDone ? row.nbDone === Number(filters.nbDone) : true)
                && (filters.nbToDo ? row.nbToDo === Number(filters.nbToDo) : true)
                && (filters.nbTotal ? row.nbTotal === Number(filters.nbTotal) : true);
        };
        const parentMatchesFilters = (parentId) => {
            const parentRow = rows.filter(row => row.children).find(row => row.id === parentId);
            return rowMatchesFilters(parentRow);
        };

        const trees = this.props.trees.getLayers(), greenSpaces = this.props.greenSpaces.getLayers(), furnitures = this.props.furnitures.getLayers();
        const priceList = this.state.priceLists.find(priceList => priceList.id === priceListId);
        rows = rows
            // On retire les lignes qui ne respectent pas la catégorie et les lignes enfants affichées si elles ne sont pas dans la bonne période
            .filter(row => {
                if (!row.parentId) return isAct || filters.elements.includes(row.category) || row.categories?.some(category => filters.elements.includes(category));

                const parentRow = rows.find(r => r.id === row.parentId);
                const nbDays = DatesUtil.daysBetweenTwoDates(DatesUtil.convertUTCDateToDate(row.date), new Date());
                const status = row.isDone ? 'done' : nbDays === 0 ? 'current' : nbDays < 0 ? 'late' : 'upcoming';
                const isUrgent = isAct || isRef ? row.isUrgent : parentRow.isUrgent;

                return filters.elements.includes((isRef ? parentRow : row).category)
                    && filters.status.includes(status) && (!filters.status.includes('urgent') || isUrgent)
                    && DatesUtil.convertUTCDateToDate(row.date) >= startDate && DatesUtil.convertUTCDateToDate(row.date) <= endDate;
            })
            .map(row => {
                if (row.parentId) return row;

                return { // On retire les récurrences qui ne sont pas dans la période sélectionnée des lignes parents
                    ...row, children: row.children
                        .map(childRow => {
                            const nbDays = DatesUtil.daysBetweenTwoDates(DatesUtil.convertUTCDateToDate(childRow.date), new Date());
                            const status = childRow.isDone ? 'done' : nbDays === 0 ? 'current' : nbDays < 0 ? 'late' : 'upcoming';
                            const isUrgent = isAct || isRef ? childRow.isUrgent : row.isUrgent;
                            return { ...childRow, status, isUrgent };
                        })
                        .filter(childRow => {
                            return (!isAct || filters.elements.includes(childRow.category)) && filters.status.includes(childRow.status) && (!filters.status.includes('urgent') || childRow.isUrgent)
                                && DatesUtil.convertUTCDateToDate(childRow.date) >= startDate && DatesUtil.convertUTCDateToDate(childRow.date) <= endDate
                        })
                };
            })
            .map(row => { // On calcule les propriétés dynamiques en fonction des résultats des filtres ci-dessus
                let price = 0;
                if (!row.parentId) {
                    let nbDone = 0, nbToDo = 0;
                    const children = row.children.map(childRow => {
                        const elementId = isRef ? childRow.parentId : childRow.elementId;
                        const category = (isRef ? row : childRow).category;
                        let element = this.elements[elementId];
                        if (!element) {
                            element = (category === 'trees' ? trees : category === 'greenspaces' ? greenSpaces : furnitures).find(layer => layer.feature.id === elementId)?.feature;
                            this.elements[elementId] = element;
                        }
                        const childPrice = ActionsUtil.getActionPrice(isAct || isRef ? childRow.projectAction.action : row.action, element, priceList);
                        if (element) price += childPrice;
                        if (childRow.isDone) nbDone++;
                        else nbToDo++;
                        return { ...childRow, price: childPrice };
                    });
                    //! Attention, modifier la référence de la row peut empêcher de pouvoir éditer si des renders sont déclenché entre temps (ex fixé : onSelectedCellChange)
                    return {
                        ...row, children, price: price % 1 !== 0 ? price.toFixed(2) : price,
                        nbDone, nbToDo, nbTotal: nbDone + nbToDo
                    };
                } else {
                    const parentRow = rows.find(r => r.id === row.parentId);
                    const elementId = isRef ? row.parentId : row.elementId;
                    const category = (isRef ? parentRow : row).category;
                    let element = this.elements[elementId];
                    if (!element) element = (category === 'trees' ? trees : category === 'greenspaces' ? greenSpaces : furnitures).find(layer => layer.feature.id === elementId)?.feature;
                    if (element) price = ActionsUtil.getActionPrice(isAct || isRef ? row.projectAction.action : parentRow.action, element, priceList);
                    return { ...row, price: price % 1 !== 0 ? price.toFixed(2) : price };
                }
            })
            .filter(row => row.parentId || row.children.length > 0 || (
                this.state.showZeros && this.state.groupedBy === 'none' && (!this.state.filters.status.includes('urgent') || row.isUrgent) && (
                    (DatesUtil.convertUTCDateToDate(row.startDate) >= startDate && DatesUtil.convertUTCDateToDate(row.startDate) <= endDate)
                    || (DatesUtil.convertUTCDateToDate(row.endDate) >= startDate && DatesUtil.convertUTCDateToDate(row.endDate) <= endDate)
                    || (DatesUtil.convertUTCDateToDate(row.startDate) < startDate && DatesUtil.convertUTCDateToDate(row.endDate) > endDate)
                )
            ));

        return rows.filter(row => { // On retourne le résultat correspondant aux filtres
            const isParent = row.children ? true : false;
            return !this.state.enableFilterRow
                || (isParent && rowMatchesFilters(row))
                || (!isParent && parentMatchesFilters(row.parentId));
        }).flatMap(row => { // En ajoutant les subheaders si nécessaire
            if (!row.parentId && row.isExpanded && rows.find(r => r.parentId === row.id)) return [row, { isSubrow: true }]
            else return row;
        });
    }

    // Tri
    handleSort = (columnKey, direction) => this.setState({ sortColumn: columnKey, sortDirection: direction }, this.sortRows);
    getSortPropertyValue = (row, sortColumn) => {
        const trees = this.props.trees.getLayers(), greenSpaces = this.props.greenSpaces.getLayers(), furnitures = this.props.furnitures.getLayers();
        let { startDate, endDate } = this.state;
        startDate = startOfDay(startDate);
        endDate = endOfDay(endDate);

        const isAct = this.state.groupedBy === 'action', isRef = this.state.groupedBy === 'reference';
        const children = row.children?.filter(childRow => (
            DatesUtil.convertUTCDateToDate(childRow.date) >= startDate && DatesUtil.convertUTCDateToDate(childRow.date) <= endDate
        ));
        switch (sortColumn) {
            case 'reference': return Number(row.reference.split(' (')[0]);
            case 'links': return [...new Set(children.map(childRow => (isRef ? childRow.projectAction.id : childRow.elementId)))].length;
            case 'price':
                return children.reduce((previousValue, childRow) => {
                    let element = this.elements[childRow.elementId];
                    if (!element) {
                        const category = (isRef ? row : childRow).category;
                        element = (category === 'trees' ? trees : category === 'greenspaces' ? greenSpaces : furnitures).find(layer => layer.feature.id === childRow.elementId)?.feature;
                        this.elements[childRow.elementId] = element;
                    }
                    return previousValue + ((element && ActionsUtil.getActionPrice(isAct || isRef ? childRow.projectAction.action : row.action, element)) || 0);
                }, 0);
            case 'nbDone': return children.filter(childRow => childRow.isDone).length;
            case 'nbToDo': return children.filter(childRow => !childRow.isDone).length;
            case 'nbTotal': return children.length;
            default: return row[sortColumn];
        }
    }
    sortRows = () => {
        const { sortColumn, sortDirection, initialOrder, data } = this.state;

        const sort = (rows) => {
            let sortedRows = [...rows];
            if (['reference', 'links', 'price', 'nbDone', 'nbToDo', 'nbTotal'].includes(sortColumn)) {
                sortedRows = sortedRows.sort((a, b) => {
                    const aValue = this.getSortPropertyValue(a, sortColumn);
                    const bValue = this.getSortPropertyValue(b, sortColumn);
                    return aValue - bValue
                });
            }
            else sortedRows = sortedRows.sort((a, b) => (a[sortColumn] || '').localeCompare(b[sortColumn] || ''));
            if (sortDirection === 'DESC') sortedRows.reverse()
            return sortedRows;
        };

        let rows;
        if (sortDirection === 'NONE') {
            rows = [...data.rows];
            let replaceIndex = 0;
            initialOrder.forEach(elementId => {
                let index = rows.findIndex(row => !row.parentId && `${row.id}` === `${elementId}`);
                if (index === -1) index = rows.findIndex(row => {
                    const ids = elementId.split('|');
                    return `${row.parentId}` === `${ids[0]}` && `${row.id}` === `${ids[1]}`;
                });
                if (index !== -1) {
                    let temp = rows[replaceIndex];
                    rows[replaceIndex] = rows[index];
                    rows[index] = temp;
                    replaceIndex++;
                }
            });
        } else {
            rows = sort([...data.rows.filter(row => !row.parentId)]);

            let childRows = {};
            data.rows.filter(row => row.parentId).forEach(row => {
                if (childRows[row.parentId]) childRows[row.parentId].push(row);
                else childRows[row.parentId] = [row];
            });
            for (const parentId in childRows) {
                let children = childRows[parentId];
                let index = rows.findIndex(row => row.id === children[0].parentId);
                if (index !== -1)
                    children.forEach(row => {
                        rows.splice(index + 1, 0, row);
                        index++;
                    });
            }
        }

        this.setState(prevState => ({
            data: {
                columns: prevState.data.columns,
                rows: rows
            }
        }));
    }

    handleKeyDown = (e) => {
        if ((e.ctrlKey || e.metaKey) && e.key === 'c') {
            if (this.selectedColumn && this.selectedRow) navigator.clipboard.writeText(this.selectedRow[this.selectedColumn.key] || '');
        }
    }

    handleResize = () => {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            const { isOverflowing, data } = this.state;
            const gridElement = document.getElementsByClassName('rdg')[0];
            if (gridElement && data?.columns) {
                const width = data.columns.reduce((previousValue, column) => previousValue + column.width, 0);
                if (isOverflowing && gridElement.clientWidth >= width) this.setState({ isOverflowing: false });
                if (!isOverflowing && gridElement.clientWidth < width) this.setState({ isOverflowing: true });
            }
        }, 100);
    }

    expandAll = () => {
        let elementIds = this.state.data.rows
            .filter(row => !row.parentId && !row.isExpanded)
            .map(row => row.id);
        this.toggleSubRows(elementIds);
    }

    collapseAll = () => {
        let elementIds = this.state.data.rows
            .filter(row => !row.parentId && row.isExpanded)
            .map(row => row.id);
        this.toggleSubRows(elementIds);
    }

    toggleSubRows = (elementIds) => {
        let rows = [...this.state.data.rows];

        elementIds.forEach(elementId => {
            const rowIndex = rows.findIndex(r => r.id === elementId);
            const row = rows[rowIndex];
            if (row) {
                let { children } = row;

                if (children) {
                    rows[rowIndex] = { ...row, isExpanded: !row.isExpanded };
                    if (!row.isExpanded) rows.splice(rowIndex + 1, 0, ...children);
                    else rows.splice(rowIndex + 1, children.length);
                }
            }
        });

        this.setState(prevState => ({ data: { ...prevState.data, rows }, search: UrlsUtil.adjustSearch(prevState.search, { toggledRows: rows.filter(row => row.isExpanded).map(row => row.id).join(',') }) }), this.sortRows);
    }

    getPropertyValue = (property, value) => { // Map les valeurs affichées aux valeurs réelles
        switch (property) { // Pas besoin de mapping pour les formules
            default: return value;
        }
    }

    createFromRow = (_, { rowIdx }) => {
        const row = this.getFilteredRows()[rowIdx];
        this.props.createFormulaFromTemplate({
            formulaId: row.parentId || row.id,
            label: row.parentId ? row.label : '',
            value: row.value
        });
    }

    showElement = (elementId, category) => {
        const categories = {
            trees: { layerContainer: this.props.trees, category: 'Arbres' },
            greenspaces: { layerContainer: this.props.greenSpaces, category: 'Espaces verts' },
            furnitures: { layerContainer: this.props.furnitures, category: 'Mobilier urbain' }
        };

        const { layerContainer, category: elementCategory } = categories[category];
        const layer = layerContainer.getLayers().find(layer => layer.feature?.id === elementId);
        if (layer)
            this.props.setTableState({ ...this.state, elements: this.elements }).then(() => {
                this.props.showElement(elementCategory, layer.feature, 'ActionTable');
            });
    }

    showEditValidationDate = (rowId, validationDate) => {
        if (!validationDate || this.props.project.type !== 'project') return;
        this.setState({ rowToEdit: rowId });
    }

    handleValidationDateSubmit = (validationDate) => {
        const { rowToEdit } = this.state;
        const rows = JSON.parse(JSON.stringify(this.state.data.rows));
        const row = rows.find(row => row.id === rowToEdit);
        this.updateProjectActionElementRecurrence(row.parentId, row, row.isDone, validationDate)
    }

    selectElementsToLink = (newProjectAction) => {
        this.setState({ newProjectAction }, () => {
            this.props.setEditedProperties(this.state).then(() => {
                this.props.manageActionElements(newProjectAction);
            });
        });
    }
}

const mapStateToProps = (state) => {
    return {
        webSocketHubs: state.webSocketHubs,
        layer: state.layer,
        editedProperties: state.editedProperties,
        project: state.project,
        projectActions: state.projectActions,
        projectCollaborators: state.projectCollaborators,
        isDarkTheme: state.isDarkTheme,
        isOnline: state.isOnline,
        activeOrganization: state.activeOrganization,
        photosGalleries: state.photosGalleries,
        rights: state.rights,
        tableState: state.tableState,
        priceLists: state.priceLists,
        currencies: state.currencies,
        essences: state.essences,
        dominantCompositions: state.dominantCompositions,
        furnitureTypes: state.furnitureTypes
    };
};

const mapDispatchToProps = {
    setEditedProperties,
    unlockEditedProperties,
    setCurrentAction,
    setProjectActions,
    setPhotosGalleries,
    setLayer,
    setTableState,
    setPriceLists
};

export default withOrientationChange(connect(mapStateToProps, mapDispatchToProps)(ActionTable));