import AppSettings from '../AppSettings';
// Librairies
import L from 'leaflet';
import html2canvas from 'html2canvas';
import { distance } from '@turf/turf';
import { isMobile, isMobileOnly } from 'react-device-detect';
import i18n from '../locales/i18n';
import domtoimage from 'dom-to-image/src/dom-to-image';
import { v4 as uuidv4 } from 'uuid';
// Ressources
import banner from '../resources/images/banner.png';
// Utils
import MapsUtil from './MapsUtil';

export async function exportScreenshot(mapId, scale, options) {
    return new LeafletScreenshoter(mapId, scale, options).export();
};

class LeafletScreenshoter {
    constructor(mapId, scale, options) {
        this.scale = scale;
        this.options = options;

        this.mapElement = document.getElementById(mapId);
        this.canvas = document.createElement('canvas');
        this.canvas.width = this.mapElement.clientWidth;
        this.canvas.height = this.mapElement.clientHeight;
        this.ctx = this.canvas.getContext('2d');
        this.ctx.imageSmoothingEnabled = false;
    }

    async export() {
        if (this.mapElement) {
            const mapDataUrl = await domtoimage.toJpeg(this.mapElement, {
                filter: MapsUtil.filterNode,
                width: this.canvas.width,
                height: this.canvas.height,
                style: {
                    transform: 'translate(-40px)',
                    transformOrigin: 'top left'
                }
            });

            await new Promise(resolve => {
                const img = new Image();
                img.crossOrigin = 'anonymous';
                img.onload = () => {
                    this.ctx.drawImage(img, 0, 0, img.width, img.height);
                    resolve(img)
                };
                img.src = mapDataUrl;
            });

            if (this.options.titles?.left || this.options.titles?.middle || this.options.titles?.right)
                await this._drawTitle();

            await this._drawLegend()

            return {
                canvas: this.canvas,
                url: this.canvas.toDataURL(),
                width: this.canvas.width,
                height: this.canvas.height
            };
        }
    }

    async _drawTitle() {
        const { left: leftTitle, middle: middleTitle, right: rightTitle } = this.options.titles;

        const titlesHeight = 35;
        this.ctx.fillStyle = 'rgba(104, 189, 70, 0.8)';
        this.ctx.fillRect(0, 0, this.canvas.width, titlesHeight);
        this.ctx.font = `${titlesHeight / 1.5}px Arial`;
        this.ctx.fillStyle = 'black';

        const numberOfTitles = [leftTitle, middleTitle, rightTitle].filter(x => x).length
        const maxWidth = this.canvas.width / (numberOfTitles + (numberOfTitles > 1 ? 0.5 : 0));

        if (leftTitle) {
            this.ctx.textAlign = 'left';
            this.ctx.fillText(leftTitle, 10, titlesHeight * (9 / 12), maxWidth);
        }
        if (middleTitle) {
            this.ctx.textAlign = 'center';
            this.ctx.fillText(middleTitle, this.canvas.width / 2, titlesHeight * (9 / 12), maxWidth);
        }
        if (rightTitle) {
            this.ctx.textAlign = 'right';
            this.ctx.fillText(rightTitle, this.canvas.width - 10, titlesHeight * (9 / 12), maxWidth);
        }
    }

    async _drawLegend() {
        const legendElement = document.getElementById('legend-container');
        if (legendElement && legendElement.children.length) {
            for (let i = 0; i < legendElement.children.length; i++)
                legendElement.children[i].children[0].classList.remove('arrow');

            const legend = await html2canvas(legendElement, { backgroundColor: null, scale: 1 });
            if (legend) this.ctx.drawImage(legend, legendElement.getBoundingClientRect().left - 40, legendElement.getBoundingClientRect().top - (isMobile ? 48 : 60));

            for (let i = 0; i < legendElement.children.length; i++)
                legendElement.children[i].children[0].classList.add('arrow');
        }
    }
}

export async function exportStatistics(logo, brandingRight) {
    return new StatisticsExporter(logo, brandingRight).export();
};

class StatisticsExporter {
    constructor(projectLabel, logoName) {
        this.projectLabel = projectLabel;
        this.logoName = logoName;
    }

    async export() {
        const detailsElement = document.getElementById('project-details');
        if (detailsElement && detailsElement.children.length) {
            const width = isMobileOnly ? 1423 : isMobile ? 1383 : 1403;

            const canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = width * 1.4145;
            const ctx = canvas.getContext('2d');
            ctx.fillStyle = 'white';
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            // Logo
            const img = new Image();
            img.crossOrigin = 'anonymous';
            img.onload = () => {
                const imgWidth = img.width / img.height * 100;
                ctx.drawImage(img, 10, 10, imgWidth, 100);
            };
            if (this.logoName) {
                const blobInfos = AppSettings.getBlobInfos();
                img.src = `${blobInfos.endpoint}${blobInfos.containers.photos}/${this.logoName}`;
            } else img.src = banner;

            // Contenu
            const statistics = await html2canvas(detailsElement, {
                backgroundColor: 'white',
                scale: 1,
                windowWidth: 1400,
                width,
                allowTaint: true
            });

            ctx.drawImage(statistics, 0, 160);

            // Titres
            const maxWidth = canvas.width / 3 - 20;
            ctx.font = 'bold 35px Arial';
            ctx.fillStyle = 'black';
            ctx.textAlign = 'left';
            ctx.fillText(this.projectLabel, 30, 155, maxWidth);
            ctx.textAlign = 'center';
            ctx.fillText(i18n.t("Statistiques"), canvas.width / 2, 155, maxWidth);

            // Footer
            ctx.font = '20px Arial';
            ctx.fillText('Grality.be', canvas.width / 2, canvas.height - 20, maxWidth);

            return {
                canvas: canvas,
                url: canvas.toDataURL(),
                width: canvas.width,
                height: canvas.height
            };
        }
    }
}

export async function exportScaledMap(map, scale, options) {
    return new LeafletExporter(map, scale, options).export();
};

class LeafletExporter {
    constructor(map, scale, options) {
        this.map = map;
        this.scale = scale;
        this.options = options;
        this.tileLayers = [];
        this.markers = {};
        this.path = {};
        this.circles = {};
        this.references = {};

        if (this.options.elements) this._zoomOnElements(this.options.elements);

        const dimensions = map.getSize();
        this.canvas = document.createElement('canvas');
        this.canvas.width = dimensions.x;
        this.canvas.height = dimensions.y;
        this.ctx = this.canvas.getContext('2d');
        this.ctx.imageSmoothingEnabled = false;

        this.bounds = map.getPixelBounds();

        this._setScale(this.scale);
    }

    async export() {
        if (this.scale.x > 1 && this.scale.y > 1) {
            this.map.getRenderer(this.map).options.padding = Math.max(this.scale.x, this.scale.y) - 1;
            this.map.setZoom(this.map.getZoom(), { animate: false });
        }

        await this._fetchLayers();

        for (let tileLayer of this.tileLayers)
            for (let tile of Object.values(tileLayer.tileImages))
                this.ctx.drawImage(tile.img, tile.x, tile.y, tileLayer.tileSize, tileLayer.tileSize);
        for (let path of Object.values(this.path))
            await this._drawPath(path);
        for (let marker of Object.values(this.markers))
            this.ctx.drawImage(marker.img, marker.x, marker.y);
        for (let circle of Object.values(this.circles))
            await this._drawCircle(circle);
        for (let reference of Object.values(this.references))
            this._drawReference(reference);

        if (this.initialBounds) this.map.fitBounds(this.initialBounds, { animate: false });
        this.map.getRenderer(this.map).options.padding = 0.1;

        return {
            canvas: this.canvas,
            url: this.canvas.toDataURL(),
            width: this.canvas.width,
            height: this.canvas.height
        };
    }

    _setScale(scale) {
        if (!scale.x || scale.x <= 0 || !scale.y || scale.y <= 0) return;

        const addX = (this.bounds.max.x - this.bounds.min.x) / 2 * (scale.x - 1);
        const addY = (this.bounds.max.y - this.bounds.min.y) / 2 * (scale.y - 1);

        this.bounds.min.x -= addX;
        this.bounds.min.y -= addY;
        this.bounds.max.x += addX;
        this.bounds.max.y += addY;

        this.canvas.width *= scale.x;
        this.canvas.height *= scale.y;
    }

    async _fetchLayers() {
        const promises = []
        const pushPromise = (layer) => {
            let promise;
            try {
                if (layer instanceof L.Marker && layer._icon && layer._icon.src && !layer._handled?._url.includes('background-images')) {
                    promise = this._fetchMarkerLayer(layer);
                } else if (layer instanceof L.TileLayer) {
                    promise = this._fetchTileLayer(layer);
                } else if (layer instanceof L.CircleMarker) {
                    if (!this.circles[layer._leaflet_id]) this.circles[layer._leaflet_id] = layer;
                    const tooltip = layer.getTooltip();
                    if (tooltip) this.references[uuidv4()] = tooltip;
                    promise = Promise.resolve();
                } else if (layer instanceof L.Path) {
                    this._fetchPathLayer(layer);
                    const tooltip = layer.getTooltip();
                    if (tooltip) this.references[uuidv4()] = tooltip;
                    promise = Promise.resolve();
                } else if (layer?.options?.className?.includes('tooltip-reference')) {
                    if (!this.references[layer._leaflet_id]) this.references[layer._leaflet_id] = layer;
                    promise = Promise.resolve();
                } else promise = Promise.resolve();
            } catch { promise = Promise.resolve(); }
            promises.push(promise);
        };

        this.map.eachLayer(layer => {
            if (layer.eachLayer) layer.eachLayer(layer => pushPromise(layer));
            else pushPromise(layer);
        });

        return await Promise.all(promises);
    }

    async _fetchMarkerLayer(layer) {
        if (this.markers[layer._leaflet_id]) return;

        let pixelPoint = this.map.project(layer._latlng);
        pixelPoint = pixelPoint.subtract(new L.Point(this.bounds.min.x, this.bounds.min.y));

        if (layer.options.icon && layer.options.icon.options && layer.options.icon.options.iconAnchor) {
            pixelPoint.x -= layer.options.icon.options.iconAnchor[0];
            pixelPoint.y -= layer.options.icon.options.iconAnchor[1];
        }

        if (this._isPointInViewport(pixelPoint)) {
            let image = new Image();
            const url = layer._icon.src;
            const promise = new Promise(resolve => {
                image.onload = () => {
                    this.markers[layer._leaflet_id] = {
                        img: image,
                        x: pixelPoint.x,
                        y: pixelPoint.y
                    };
                    resolve();
                };
                image.onerror = () => resolve();
            });
            image.src = url;
            await promise;
        }
    }

    async _fetchTileLayer(layer) {
        const tileSize = layer._tileSize.x;
        const tileLayer = {
            tiles: [],
            tileSize,
            tileBounds: L.bounds(this.bounds.min.divideBy(tileSize)._floor(), this.bounds.max.divideBy(tileSize)._floor()),
            tileImages: []
        };
        this.tileLayers.push(tileLayer);

        for (let j = tileLayer.tileBounds.min.y; j <= tileLayer.tileBounds.max.y; j++)
            for (let i = tileLayer.tileBounds.min.x; i <= tileLayer.tileBounds.max.x; i++)
                tileLayer.tiles.push(new L.Point(i, j));

        const promises = [];
        tileLayer.tiles.forEach(tilePoint => {
            let originalTilePoint = tilePoint.clone();
            if (layer._adjustTilePoint) layer._adjustTilePoint(tilePoint);

            let tilePos = originalTilePoint.scaleBy(new L.Point(tileLayer.tileSize, tileLayer.tileSize)).subtract(this.bounds.min);

            if (tilePoint.y < 0) return;

            promises.push(this._fetchTile(tilePoint, tilePos, layer, tileLayer));
        });

        return await Promise.all(promises);
    }

    _fetchPathLayer(layer) {
        let correct = false;
        const parts = [];

        if (layer._mRadius || !layer._latlngs) return;

        const latlngs = layer._latlngs;
        latlngs.forEach(polygon => {
            const index = parts.push([]) - 1;
            polygon.forEach(latLng => {
                let pixelPoint = this.map.project(latLng);
                pixelPoint = pixelPoint.subtract(new L.Point(this.bounds.min.x, this.bounds.min.y));
                parts[index].push(pixelPoint);
                if (pixelPoint.x < this.canvas.width && pixelPoint.y < this.canvas.height) correct = true;
            });
        });

        if (correct)
            this.path[layer._leaflet_id] = {
                parts: parts,
                closed: layer.options.fill,
                options: layer.options
            };
    }

    async _fetchTile(tilePoint, tilePos, layer, tileLayer) {
        const image = new Image();
        image.crossOrigin = 'Anonymous';

        let url, attempt = 0;
        if (layer.getTileUrlAsync) url = await layer.getTileUrlAsync(tilePoint);
        else url = layer.getTileUrl(tilePoint);

        const promise = new Promise(resolve => {
            image.onload = () => {
                let promise;
                if (layer.transformTileImage) promise = layer.transformTileImage(image);
                else promise = Promise.resolve();

                promise.then(() => {
                    tileLayer.tileImages.push({
                        img: image,
                        x: tilePos.x,
                        y: tilePos.y
                    });
                    resolve();
                }).catch(() => resolve());
            };

            image.onerror = () => {
                if (attempt < 3) { // Si une erreur se produit lors du chargement de la tile, on réessaie
                    attempt++;
                    image.src = url;
                } else resolve();
            };
        })

        attempt++;
        image.src = url;
        await promise;
    }

    _isPointInViewport(point) {
        return (point.x >= 0 && point.y >= 0 && point.x < this.canvas.width && point.y <= this.canvas.height);
    }

    async _drawPath(path) {
        this.ctx.beginPath();
        path.parts.forEach(polygon => {
            polygon.forEach((point, index) => this.ctx[index !== 0 ? 'lineTo' : 'moveTo'](point.x, point.y));
            if (path.closed) this.ctx.closePath();
        });
        await this._feelPath(path.options)
    }

    async _drawCircle(layer) {
        // if (layer._empty()) return;

        let point = this.map.project(layer._latlng);
        point = point.subtract(new L.Point(this.bounds.min.x, this.bounds.min.y));

        let r, s;
        if (layer._radius !== undefined) {
            r = Math.max(Math.round(layer._radius), 1);
            s = (Math.max(Math.round(layer._radiusY), 1) || r) / r;
        } else {
            r = layer.options.radius;
            s = 1;
        }

        if (s !== 1) {
            this.ctx.save();
            this.ctx.scale(1, s);
        }

        this.ctx.beginPath();
        this.ctx.arc(point.x, point.y / s, r * (this.options.elements?.length > 1 ? 0.5 : (this.options.overflowZoom ? Math.pow(2.5, this.map.getZoom() - this.options.overflowZoom) || 1 : 1)), 0, Math.PI * 2, false);

        if (s !== 1) this.ctx.restore();

        await this._feelPath(layer.options);
    }

    async _feelPath(options) {
        if (options.fill) {
            this.ctx.globalAlpha = options.fillOpacity;
            if (options.fillPattern) {
                const img = document.createElement('img');
                await new Promise(resolve => {
                    img.onload = () => {
                        this.ctx.scale(0.25, 0.25);
                        const pattern = this.ctx.createPattern(img, 'repeat');
                        this.ctx.fillStyle = pattern;
                        resolve();
                    };
                    img.src = options.fillPattern.options.svg.replace('url(', '').replace(')', '');
                });
            } else {
                let fillColor = options.fillColor || options.color;
                if (fillColor.includes('var')) fillColor = document.body.style.getPropertyValue(fillColor.slice(4, -1));
                this.ctx.fillStyle = fillColor;
            }
            this.ctx.fill(options.fillRule || 'evenodd');
        }

        if (options.stroke && options.weight !== 0) {
            // if (this.ctx.setLineDash) this.ctx.setLineDash(options?._dashArray || []);
            this.ctx.globalAlpha = options.opacity;
            this.ctx.lineWidth = options.weight * (this.options.elements?.length > 1 ? 0.5 : (this.options.overflowZoom ? Math.pow(2.5, this.map.getZoom() - this.options.overflowZoom) || 1 : 1));
            let strokeColor = options.color;
            if (strokeColor.includes('var')) strokeColor = document.body.style.getPropertyValue(strokeColor.slice(4, -1));
            this.ctx.strokeStyle = strokeColor;
            this.ctx.lineCap = options.lineCap;
            this.ctx.lineJoin = options.lineJoin;
            this.ctx.stroke();
        }

        if (options.fillPattern) this.ctx.scale(4, 4); // Permet d'annuler le scale(0.25, 0.25)
        this.ctx.globalAlpha = 1;
    }

    async _drawReference(layer) {
        let point = this.map.project(layer._latlng || layer._source._latlng);
        point = point.subtract(new L.Point(this.bounds.min.x, this.bounds.min.y));

        const fontSize = 12 * (Math.pow(2.5, this.map.getZoom() - this.options.overflowZoom) || 1);
        this.ctx.font = `bold ${fontSize}px Arial`;
        this.ctx.fillStyle = 'black';
        this.ctx.strokeStyle = 'white';
        this.ctx.lineWidth = fontSize * 0.4;
        this.ctx.textAlign = 'center';
        this.ctx.strokeText(layer._content, point.x, point.y - fontSize);
        this.ctx.fillText(layer._content, point.x, point.y - fontSize);
    }

    async _zoomOnElements(elements) {
        this.initialBounds = this.map.getBounds();

        let mapDistX, mapDistY;
        const setMapDistances = () => { // On calcule les distances affichées sur la carte en X & Y
            const mapBounds = this.map.getBounds();
            const mapNorthWest = mapBounds.getNorthWest(); const mapSouthEast = mapBounds.getSouthEast();
            mapDistX = distance([mapNorthWest.lng, mapNorthWest.lat], [mapSouthEast.lng, mapNorthWest.lat]);
            mapDistY = distance([mapSouthEast.lng, mapNorthWest.lat], [mapSouthEast.lng, mapSouthEast.lat]);
        }

        if (elements.length === 1 && !elements[0].getBounds) { // Dans le cas d'un arbre seul
            const latLng = {
                lat: elements[0].feature.geometry.coordinates[1],
                lng: elements[0].feature.geometry.coordinates[0]
            };

            this.map.setView(latLng, 19, { animate: false });
            setMapDistances();
        } else {
            const margin = elements.length > 1 ? 1.2 : 2; // 20% de plus que les bounds des éléments soit 10% de chaque coté

            // On calcule les distances minimales pour afficher les éléments en X & Y
            const elementsBounds = L.featureGroup(elements).getBounds();
            const elementsNorthWest = elementsBounds.getNorthWest(); const elementsSouthEast = elementsBounds.getSouthEast();
            const elementsDistX = distance([elementsNorthWest.lng, elementsNorthWest.lat], [elementsSouthEast.lng, elementsNorthWest.lat]);
            const elementsDistY = distance([elementsSouthEast.lng, elementsNorthWest.lat], [elementsSouthEast.lng, elementsSouthEast.lat]);

            this.map.fitBounds(elementsBounds, { animate: false });
            setMapDistances();

            // On assure la marge en X
            const minX = elementsDistX * margin;
            this.scale.x = minX / mapDistX;
            mapDistX = minX;

            // On assure la marge en Y
            const minY = elementsDistY * margin;
            this.scale.y = minY / mapDistY;
            mapDistY = minY;
        }

        // On force un ratio de 1.75 pour X/Y
        this.scale.x *= (mapDistX / mapDistY) > 1.75 ? 1 : (mapDistY * 1.75) / mapDistX;
        this.scale.y *= (mapDistX / mapDistY) < 1.75 ? 1 : (mapDistX / 1.75) / mapDistY;

        // On diminue le niveau de zoom et on crop pour afficher les noms de rues etc en plus grands (moins bonne qualité mais meilleure lisibilité)
        if (this.map.getZoom() > 16) {
            this.map.setZoom(this.map.getZoom() - 1, { animate: false });
            this.scale.x /= 2;
            this.scale.y /= 2;
        }
    }
}