import React, { Component } from 'react';
// Composants
import AdminNavbar from './AdminNavbar';
import AdminDashboard from './AdminDashboard';
import UserForm from './AccountManagement/UserForm';
import OrganizationTable from './AccountManagement/OrganizationTable';
import UserTable from './AccountManagement/UserTable';
import ProjectTable from './AccountManagement/ProjectTable';
import InvitationTable from './AccountManagement/InvitationTable';
import SubscriptionForm from './AccountManagement/SubscriptionForm';
import SubscriptionTable from './AccountManagement/SubscriptionTable';
import NotificationForm from './AccountManagement/NotificationForm';
import NotificationTable from './AccountManagement/NotificationTable';
import EssenceForm from './DataManagement/EssenceForm';
import EssenceTable from './DataManagement/EssenceTable';
import ActionForm from './DataManagement/ActionForm';
import ActionTable from './DataManagement/ActionTable';
import FormulaVersionForm from './DataManagement/FormulaVersionForm';
import FormulaTable from './DataManagement/FormulaTable';
import CustomFieldForm from './DataManagement/CustomFieldForm';
import CustomFieldTable from './DataManagement/CustomFieldTable';
import Statistics from './Statistics/Statistics';
import { Grid, Segment, Divider } from 'semantic-ui-react';
// Librairies
import Cookies from 'universal-cookie';
import Helmet from 'react-helmet';
// Redux
import { connect } from 'react-redux';
import { setProject, setViewProjectAsData } from '../../actionCreators/projectsActions';

class AdminPanel extends Component {
    state = {
        isAuthorized: false,
        panelContentType: null,
        formulaTemplate: null
    };

    render() {
        const { isAuthorized, panelContentType, formulaTemplate } = this.state;

        return (
            <>
                {isAuthorized &&
                    <>
                        <Helmet>
                            {panelContentType === 'ManageFormulas' && <link rel='stylesheet' type='text/css' href='/rdg-override.css' />}
                        </Helmet>
                        <AdminNavbar {...this.props} panelContentType={panelContentType} changePanelContentType={this.changePanelContentType} />
                        <Grid id='admin-panel__grid' textAlign='center' style={{ margin: '0', flexGrow: 1, zIndex: 1001 }} verticalAlign='middle' className='grality-background'>
                            <Grid.Column style={['Dashboard', 'Manage', 'Statistics'].some(type => panelContentType.includes(type)) ? { padding: 0, width: '100%', height: '100%' } : { width: 'auto' }}>
                                {panelContentType === 'Dashboard' &&
                                    <AdminDashboard changePanelContentType={this.changePanelContentType} />}
                                {panelContentType === 'ManageOrganizations' &&
                                    <OrganizationTable />}
                                {panelContentType === 'AddUser' &&
                                    <UserForm />}
                                {panelContentType === 'ManageUsers' &&
                                    <UserTable {...this.props} />}
                                {panelContentType === 'ManageProjects' &&
                                    <ProjectTable />}
                                {panelContentType === 'ManageInvitations' &&
                                    <InvitationTable />}
                                {panelContentType === 'AddSubscription' &&
                                    <SubscriptionForm />}
                                {panelContentType === 'ManageSubscriptions' &&
                                    <SubscriptionTable />}
                                {panelContentType === 'AddNotification' &&
                                    <NotificationForm />}
                                {panelContentType === 'ManageNotifications' &&
                                    <NotificationTable />}
                                {panelContentType === 'AddEssence' &&
                                    <EssenceForm />}
                                {panelContentType === 'ManageEssences' &&
                                    <EssenceTable />}
                                {panelContentType === 'AddAction' &&
                                    <ActionForm />}
                                {panelContentType === 'ManageActions' &&
                                    <ActionTable />}
                                {panelContentType === 'AddFormulaVersion' &&
                                    <FormulaVersionForm formulaTemplate={formulaTemplate} />}
                                {panelContentType === 'ManageFormulas' &&
                                    <FormulaTable createFormulaFromTemplate={this.createFormulaFromTemplate} />}
                                {panelContentType === 'AddCustomField' &&
                                    <Segment style={{ padding: '20px', width: '60vw', height: '65vh', display: 'flex', flexDirection: 'column' }}>
                                        <h3 style={{ textAlign: 'center', marginBottom: 0 }}>Ajout d'un nouveau champs</h3>
                                        <Divider />
                                        <CustomFieldForm isCreating={true} isAdmin={true} />
                                    </Segment>}
                                {panelContentType === 'ManageCustomFields' &&
                                    <CustomFieldTable />}
                                {panelContentType === 'Statistics' &&
                                    <Statistics />}
                            </Grid.Column>
                        </Grid>
                    </>}
            </>
        );
    }

    componentDidMount = () => {
        const { logged, activeOrganization } = this.props;
        this.props.changeActiveItem('adminPanel');
        this.props.setViewProjectAsData(null);

        if (logged && new Cookies().get('token') && activeOrganization)
            if (!['Dev', 'Admin', 'Com'].includes(activeOrganization.subscription.shortName))
                this.props.history.push('/');
            else {
                this.props.setProject(null);
                this.setState({ isAuthorized: true, panelContentType: activeOrganization.subscription.name === 'Commercial' ? 'ManageUsers' : 'Dashboard' });
            }
        else this.props.history.push('/');
    }

    componentDidUpdate = () => {
        if (!this.props.logged) this.props.history.push('/login');
    }

    changePanelContentType = (contentType) => this.setState({ panelContentType: contentType });

    createFormulaFromTemplate = (formulaTemplate) => {
        this.setState({ formulaTemplate, panelContentType: 'AddFormulaVersion' }, () => {
            this.setState({ formulaTemplate: null });
        });
    }
}

const mapStateToProps = (state) => {
    return {
        activeOrganization: state.activeOrganization
    };
};

const mapDispatchToProps = {
    setProject,
    setViewProjectAsData
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminPanel);