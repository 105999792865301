import React, { Component } from 'react';
// Librairies
import { connect } from 'react-redux';
import { setRequest, setCurrentAction } from '../../../actionCreators/appActions';
import { setProject, setProjectActions, setProjects } from '../../../actionCreators/projectsActions';
import i18n from '../../../locales/i18n';
import { Confirm } from 'semantic-ui-react';
// Services
import TreesService from '../../../services/TreesService';
import GreenSpacesService from '../../../services/GreenSpacesService';
import BackgroundImagesService from '../../../services/BackgroundImagesService';
import FurnituresService from '../../../services/FurnituresService';
import MarkersService from '../../../services/MarkersService';
import StationsService from '../../../services/StationsService';
// Utils
import { showToast } from '../../../utils/ToastsUtil';
import ProjectsUtil from '../../../utils/ProjectsUtil';

class RemoveForm extends Component {
    render() {
        const nbElements = this.props.layersToRemove?.length || 1;
        const message = nbElements > 1
            ? i18n.t("Êtes-vous certain de vouloir supprimer ces {{nbElements}} éléments ?", { nbElements })
            : i18n.t("Êtes-vous certain de vouloir supprimer cet élément ?");

        return (
            <Confirm
                open onCancel={this.cancel} onConfirm={this.confirm}
                cancelButton={i18n.t("Annuler")} confirmButton={i18n.t("Supprimer")}
                header={i18n.t("Suppression d'un élément")} content={message}
            />
        );
    }

    confirm = async () => {
        const { layerContainers, project, projects, formulas, projectActions } = this.props;
        let layersToRemove = this.props.layersToRemove?.length ? this.props.layersToRemove : [this.props.layer[0]];
        let trees = [], greenSpaces = [], furnitures = [], markers = [], stations = [], backgroundImages = [];

        const removeFromContainer = (legendName) => {
            Object.keys(layerContainers).filter(key => layerContainers[key].label === legendName || layerContainers[key].label === legendName).forEach(key => {
                layerContainers[key].eachLayer(layer => {
                    if (layersToRemove.find(l => l.feature.id === layer.feature?.id))
                        layerContainers[key].removeLayer(layer);
                });
            });
            this.props.updateLegend(legendName);
            this.props.updateHeatmaps();
        }

        layersToRemove.forEach(layerToRemove => {
            const element = layerToRemove.feature;
            const category = element.properties.category;

            switch (category) { // On appelle le service correspondant à la catégorie de l'élément à supprimer
                case 'Arbre': trees.push(layerToRemove.feature); break;
                case 'Espace vert': greenSpaces.push(layerToRemove.feature); break;
                case 'Mobilier': furnitures.push(layerToRemove.feature); break;
                case 'Repère': markers.push(layerToRemove.feature); break;
                case 'Station': stations.push(layerToRemove.feature); break;
                case 'BackgroundImage': backgroundImages.push(layerToRemove.feature); break;
                default: break;
            }
        });

        if (this.props.resetActionHistory) this.props.resetActionHistory(false);
        this.props.unselectElements(this.props.layersToRemove).then(() => {
            this.props.checkHistoryButtons();
            this.props.hideRemoveForm(true, trees.length ? 'Arbre' : null, this.props.layersToRemove); // On cache le formulaire de suppression
            let ids = [];
            if (trees.length > 0) {
                ids = [...ids, ...trees.map(t => t.id)];
                removeFromContainer(i18n.t("Arbres"));
                TreesService.removeTrees(trees, this.props.webSocketHubs);
            }
            if (greenSpaces.length > 0) {
                ids = [...ids, ...greenSpaces.map(gs => gs.id)];
                removeFromContainer(i18n.t("Espaces verts"));
                GreenSpacesService.removeGreenSpaces(greenSpaces, this.props.webSocketHubs);
            }
            if (furnitures.length > 0) {
                ids = [...ids, ...furnitures.map(f => f.id)];
                removeFromContainer(i18n.t("Mobilier urbain"));
                FurnituresService.removeFurnitures(furnitures, this.props.webSocketHubs);
            }
            if (markers.length > 0) {
                ids = [...ids, ...markers.map(f => f.id)];
                removeFromContainer(i18n.t("Repères"));
                MarkersService.removeMarkers(markers, this.props.webSocketHubs);
            }
            if (stations.length > 0) {
                ids = [...ids, ...stations.map(s => s.id)];
                removeFromContainer(i18n.t("Stations"));
                StationsService.removeStations(stations, this.props.webSocketHubs);
            }
            if (backgroundImages.length > 0) {
                removeFromContainer("BackgroundImages");
                BackgroundImagesService.removeBackgroundImages(backgroundImages, this.props.webSocketHubs);
            }

            // Suppression des liens
            if (ids.length && project?.linkedElements?.length) {
                let updateProject = JSON.parse(JSON.stringify(project));
                updateProject.linkedElements = updateProject.linkedElements.filter(le => !ids.includes(le.elementId) && !ids.includes(le.linkedElementId));
                if (updateProject.linkedElements.length !== project.linkedElements.length)
                    ProjectsUtil.updateProjectsInProps(updateProject, projects, formulas, project, this.props.setProjects, this.props.setProject);
            }

            // Suppression des actions
            if (ids.length && projectActions.length) {
                const updatedProjectsActions = [...projectActions];
                updatedProjectsActions.forEach(pa => {
                    pa.projectActionElements = pa.projectActionElements.filter(pae => !ids.includes(pae.elementId));
                });
                this.props.setProjectActions(updatedProjectsActions);
            }
        });
    }

    cancel = () => {
        const nbElements = this.props.layersToRemove?.length || 1;
        showToast(`${nbElements > 1 ? 'elements' : 'element'}_deletion_cancelled`);
        if (this.props.currentAction !== 'removing' && !this.props.removingWithShortcut) {
            let category = this.props.layersToRemove[0]?.feature.properties.category || this.props.layer[0]?.feature.properties.category;
            let contentType = category === 'Arbre' ? 'TreeDetail' : '';
            this.props.changeModalContentType(contentType, i18n.t("Détails"));
        } else {
            this.props.setCurrentAction('');
            this.props.hideRemoveForm(true, this.props.layersToRemove.find(x => x.feature.properties.type === 'Arbre') ? 'Arbre' : null);
        }
    }
}

const mapStateToProps = (state) => {
    return {
        currentAction: state.currentAction,
        layer: state.layer,
        project: state.project,
        projects: state.projects,
        projectActions: state.projectActions,
        formulas: state.formulas,
        webSocketHubs: state.webSocketHubs
    };
};

const mapDispatchToProps = {
    setRequest,
    setCurrentAction,
    setProject,
    setProjects,
    setProjectActions
}

export default connect(mapStateToProps, mapDispatchToProps)(RemoveForm);