import React, { Component } from 'react';
// Composants
import { Dimmer, Button, Form, Select, Message } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Libraries
import { faCheck, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import i18n from '../../../locales/i18n';
import { connect } from 'react-redux';
import { setProjects } from '../../../actionCreators/projectsActions';
import Cookies from 'universal-cookie';
import jwt_decode from 'jwt-decode';
// Utils
import FormattersUtil from '../../../utils/FormattersUtil';
import StylesUtil from '../../../utils/StylesUtil';
import WebSocketUtil from '../../../utils/WebSocketUtil';
import ProjectsUtil from '../../../utils/ProjectsUtil';
import ProjectsService from '../../../services/ProjectsService';

class OwnershipTransferForm extends Component {
    state = {
        isLoading: false,
        initialOwnerId: null,
        ownerId: null,
    };

    render() {
        const { baseProject, userProjects, isOnline } = this.props;
        const { isLoading, initialOwnerId, ownerId } = this.state;
        const collaborators = userProjects.filter(up => !up.deletionDate).map(userProject => ({ text: FormattersUtil.formatLastNameAndFirstName(userProject.user.lastName, userProject.user.firstName), value: userProject.userId }));

        return (
            <Dimmer
                active style={{ ...StylesUtil.getMapStyles().dimmerStyle, position: 'fixed', top: 0, left: 0, width: '100%', height: '100vh', zIndex: 9999 }}
                onClick={({ target }) => { if (target.classList.contains('dimmer') && !isLoading) this.props.close(); }}
            >
                <Message className='messageForm' style={{ maxWidth: '400px' }}>
                    <Message.Header>{baseProject.label}</Message.Header>
                    <Message.Content style={{ marginTop: '10px' }}>
                        <div style={{ textAlign: 'left' }}>
                            <Form.Field
                                control={Select} label={`${i18n.t("Propriétaire")} :`} fluid placeholder={i18n.t("Sélectionnez un propriétaire")}
                                value={ownerId} options={collaborators} search={FormattersUtil.searchList} selectOnBlur={false}
                                noResultsMessage={i18n.t("Aucun résultat trouvé")} onChange={this.handleChange}
                            />
                        </div>
                        <div style={{ marginTop: '10px' }}>
                            <Button color='grey' disabled={isLoading} onClick={this.props.close}>
                                <FontAwesomeIcon icon={faTimesCircle} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                            </Button>
                            <Button color='green' loading={isLoading} disabled={initialOwnerId === ownerId || isLoading || !isOnline} onClick={this.handleSubmit}>
                                <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("Valider")}
                            </Button>
                        </div>
                    </Message.Content>
                </Message>
            </Dimmer>
        );
    }

    componentDidMount = () => {
        const ownerId = this.props.userProjects?.find(userProject => userProject.projectRole.type === 'owner')?.userId || null;
        this.setState({ initialOwnerId: ownerId, ownerId });
    }

    handleChange = (_, { value }) => this.setState({ ownerId: value });
    handleSubmit = async () => {
        const { baseProject, userProjects } = this.props;
        const { ownerId } = this.state;
        const oldPath = baseProject.path;
        const userId = jwt_decode(new Cookies().get('token')).id;

        this.setState({ isLoading: true });
        const { newOwner, newUserBaseProjects } = baseProject.type === 'folder'
            ? await ProjectsService.updateFolder(baseProject, ownerId)
            : await ProjectsService.updateProject(baseProject, null, ownerId);

        if (baseProject.path?.length) {
            await this.props.removeBaseProject(baseProject, true);
            baseProject.path = null;
            baseProject.parentFolderId = null;
            baseProject.userBaseProjects = newUserBaseProjects;
            this.props.setProjects([...this.props.projects, baseProject]);

            // Suppression du projet chez les utilisateurs qui n'en font pas partie
            let usersToAlert = userProjects.filter(ubp => ubp.userId !== userId && !newUserBaseProjects.find(nubp => nubp.userId === ubp.userId)).map(up => up.user.id);
            WebSocketUtil.removeProject(this.props.webSocketHubs, usersToAlert, baseProject.id, oldPath);

            // Mise à jour du projet chez les utilisateurs qui en font partie
            usersToAlert = newUserBaseProjects.filter(nubp => nubp.userId !== userId).map(up => up.user.id);
            WebSocketUtil.moveProjects(this.props.webSocketHubs, usersToAlert, [baseProject], oldPath);
        } else {
            let oldOwner = userProjects.find(userProject => userProject.projectRole.type === 'owner');
            if (newOwner && oldOwner.userId !== newOwner.userId) {
                const managerRole = baseProject.projectRoles.find(pr => pr.type === 'manager');
                oldOwner.isOwner = false;
                oldOwner.projectRoleId = managerRole.id;
                oldOwner.projectRole = managerRole;
                const newOwnerIndex = userProjects.findIndex(userProject => userProject.userId === newOwner.userId);
                userProjects[newOwnerIndex] = newOwner;
                const updatedBaseProject = { ...baseProject, userBaseProjects: userProjects, projectRoles: baseProject.projectRoles.map(pr => ({ ...pr, userBaseProjects: userProjects.filter(up => up.projectRoleId === pr.id) })) };
                const usersToAlert = userProjects.filter(up => up.user.id !== userId).map(up => up.user.id);

                WebSocketUtil.updateProject(this.props.webSocketHubs, usersToAlert, updatedBaseProject);
                baseProject.userBaseProjects = userProjects;
                ProjectsUtil.updateProjectsInProps(updatedBaseProject, this.props.projects, this.props.formulas, this.props.project, this.props.setProjects, this.props.setProject);
            }
        }

        this.setState({ isLoading: false });
        this.props.close();
    }
}

const mapStateToProps = (state) => {
    return {
        isOnline: state.isOnline,
        project: state.project,
        projects: state.projects,
        formulas: state.formulas,
        webSocketHubs: state.webSocketHubs,
    };
};

const mapDispatchToProps = {
    setProjects,
}

export default connect(mapStateToProps, mapDispatchToProps)(OwnershipTransferForm);