import React from 'react';
// Composants
import {
    faArrowsAlt, faBell, faCalculator, faCamera, faClipboard, faClipboardCheck, faClone, faCompass, faVectorPolygon, faCube, faCubes, faCut, faDownload, faEdit,
    faEnvelope, faExclamation, faExclamationTriangle, faFile, faFilePdf, faFilter, faHistory, faImage, faKey, faLink, faMapMarkedAlt, faPlus, faQuestion,
    faSearchPlus, faShareAlt, faSignOutAlt, faStar, faSync, faTimes, faTools, faTrash, faUnlink, faUpload, faFolder, faShieldHalved, faMicrochipAi, faTree, faTrophy,
    faArrowRightArrowLeft,
    faObjectSubtract
} from '@fortawesome/pro-solid-svg-icons';
import { faStar as farFaStar } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Toast from 'react-hot-toast';
import StylesUtil from './StylesUtil';
import i18n from '../locales/i18n';

const TOASTS = {
    //* Succès
    action_added: { type: "success", icon: faPlus, content: "L'action a été créée" },
    action_deleted: { type: "success", icon: faTrash, content: "L'action a été supprimée" },
    action_linked_single: { type: "success", icon: faLink, content: "L'action a été liée à l'élément" },
    action_linked_several: { type: "success", icon: faLink, content: "Les liaisons de l'action ont été modifiées" },
    action_unlinked: { type: "success", icon: faUnlink, content: "L'action a été déliée de l'élément" },
    action_updated: { type: "success", icon: faEdit, content: "L'action a été modifiée" },
    actions_updated: { type: "success", icon: faEdit, content: "Les actions ont été modifiées" },
    amenity_value_updated: { type: "success", icon: faCalculator, content: "La valeur d'agrément a été calculée" },
    background_image_added: { type: "success", icon: faPlus, content: "Le calque a été ajouté" },
    background_image_deleted: { type: "success", icon: faEdit, content: "Le calque a été supprimé" },
    background_image_updated: { type: "success", icon: faEdit, content: "Le calque a été modifié" },
    background_images_deleted: { type: "success", icon: faEdit, content: "Les calques ont été supprimés" },
    background_images_updated: { type: "success", icon: faEdit, content: "Les calques ont été modifiés" },
    backlog_pdf_export_completed: { type: "success", icon: faDownload, content: "L'export de la fiche des actions (__ - __) est terminé" },
    base_projects_moved: { type: "success", icon: faEdit, content: "Les projets/dossiers ont été déplacés" },
    base_projects_updated: { type: "success", icon: faEdit, content: "Les projets/dossiers ont été modifiés" },
    billing_address_updated: { type: "success", icon: faEdit, content: "L'adresse de facturation a été modifiée" },
    bookmark_added: { type: "success", icon: faPlus, content: "Le bookmark a été créé" },
    bookmark_deleted: { type: "success", icon: faTrash, content: "Le bookmark a été supprimé" },
    collaborator_added: { type: "success", icon: faPlus, content: "Vous avez été ajouté au projet" },
    collaborator_removed: { type: "success", icon: faTrash, content: "Le collaborateur a été retiré du projet" },
    collaborators_added: { type: "success", icon: faPlus, content: "Les collaborateurs ont été ajoutés au projet" },
    custom_chart_added: { type: "success", icon: faPlus, content: "Le graphique personnalisé a été créé" },
    custom_chart_deleted: { type: "success", icon: faTrash, content: "Le graphique personnalisé a été supprimé" },
    custom_chart_shared: { type: "success", icon: faShareAlt, content: "Le graphique personnalisé a été partagé" },
    custom_chart_updated: { type: "success", icon: faEdit, content: "Le graphique personnalisé a été modifié" },
    custom_field_added: { type: "success", icon: faPlus, content: "Le champs personnalisé a été créé" },
    custom_field_deleted: { type: "success", icon: faTrash, content: "Le champs personnalisé a été supprimé" },
    custom_field_updated: { type: "success", icon: faEdit, content: "Le champs personnalisé a été modifié" },
    custom_field_category_added: { type: "success", icon: faPlus, content: "La catégorie a été créée" },
    custom_field_category_deleted: { type: "success", icon: faTrash, content: "La catégorie a été supprimée" },
    custom_field_category_updated: { type: "success", icon: faEdit, content: "La catégorie a été modifiée" },
    custom_fields_updated: { type: "success", icon: faEdit, content: "Les champs personnalisés ont été modifiés" },
    custom_filter_added: { type: "success", icon: faPlus, content: "Le filtre personnalisé a été créé" },
    custom_filter_deleted: { type: "success", icon: faTrash, content: "Le filtre personnalisé a été supprimé" },
    custom_filter_shared: { type: "success", icon: faShareAlt, content: "Le filtre personnalisé a été partagé" },
    data_transferred: { type: "success", icon: faArrowRightArrowLeft, content: "Les données ont été transférées" },
    ea_account_linked: { type: "success", icon: faLink, content: "Le compte a été lié" },
    ea_account_unlinked: { type: "success", icon: faUnlink, content: "Le compte a été délié" },
    elements_linked: { type: "success", icon: faUnlink, content: "Les éléments ont été liés" },
    elements_unlinked: { type: "success", icon: faUnlink, content: "Les éléments ont été déliés" },
    essence_added: { type: "success", icon: faPlus, content: "L'essence a été créée" },
    essences_updated: { type: "success", icon: faEdit, content: "Les essences ont été modifiées" },
    favorite_status_added: { type: "success", icon: faStar, content: "Le __ a été ajouté aux favoris" },
    favorite_status_removed: { type: "success", icon: farFaStar, content: "Le __ a été retiré des favoris" },
    favorite_tool_added: { type: "success", icon: faStar, content: "L'outil a été ajouté aux favoris" },
    favorite_tool_removed: { type: "success", icon: farFaStar, content: "L'outil a été retiré des favoris" },
    file_added: { type: "success", icon: faPlus, content: "Le fichier a été ajouté" },
    file_deleted: { type: "success", icon: faTrash, content: "Le fichier a été supprimé" },
    file_updated: { type: "success", icon: faPlus, content: "Le fichier a été modifié" },
    files_deleted: { type: "success", icon: faTrash, content: "Les fichiers ont été supprimé" },
    folder_added: { type: "success", icon: faPlus, content: "Le dossier a été créé" },
    folder_deleted: { type: "success", icon: faTrash, content: "Le dossier a été supprimé" },
    folder_updated: { type: "success", icon: faEdit, content: "Le dossier a été modifié" },
    formula_version_added: { type: "success", icon: faPlus, content: "La version de la formule a été ajoutée" },
    furniture_added: { type: "success", icon: faPlus, content: "Le mobilier a été créé" },
    furniture_copy_pasted: { type: "success", icon: faClipboardCheck, content: "Le mobilier a été copié" },
    furniture_deleted: { type: "success", icon: faTrash, content: "Le mobilier a été supprimé" },
    furniture_dragged: { type: "success", icon: faArrowsAlt, content: "Le mobilier a été déplacé" },
    furniture_duplicated: { type: "success", icon: faClone, content: "Le mobilier a été dupliqué" },
    furniture_restored: { type: "success", icon: faHistory, content: "Le mobilier a été restauré" },
    furniture_updated: { type: "success", icon: faEdit, content: "Le mobilier a été modifié" },
    furnitures_copy_pasted: { type: "success", icon: faClipboardCheck, content: "__ mobiliers ont été copiés" },
    furnitures_deleted: { type: "success", icon: faTrash, content: "Les mobiliers ont été supprimés" },
    furnitures_dragged: { type: "success", icon: faArrowsAlt, content: "Les mobiliers ont été déplacés" },
    furnitures_duplicated: { type: "success", icon: faClone, content: "__ mobiliers ont été dupliqués" },
    furnitures_updated: { type: "success", icon: faEdit, content: "Les mobiliers ont été modifiés" },
    greenspace_added: { type: "success", icon: faPlus, content: "L'espace vert a été créé" },
    greenspace_copy_pasted: { type: "success", icon: faClipboardCheck, content: "L'espace vert a été copié" },
    greenspace_cut: { type: "success", icon: faCut, content: "L'espace vert a été découpé" },
    greenspace_deleted: { type: "success", icon: faTrash, content: "L'espace vert a été supprimé" },
    greenspace_dragged: { type: "success", icon: faArrowsAlt, content: "L'espace vert a été déplacé" },
    greenspace_duplicated: { type: "success", icon: faClone, content: "L'espace vert a été dupliqué" },
    greenspace_edited: { type: "success", icon: faVectorPolygon, content: "L'espace vert a été redimensionné" },
    greenspace_merged: { type: "success", icon: faCube, content: "Les espaces verts ont été fusionnés" },
    greenspace_restored: { type: "success", icon: faHistory, content: "L'espace vert a été restauré" },
    greenspace_rotated: { type: "success", icon: faSync, content: "L'espace vert a été tourné" },
    greenspace_split: { type: "success", icon: faCubes, content: "L'espace vert a été divisé" },
    greenspace_subtracted: { type: "success", icon: faObjectSubtract, content: "Les espaces verts ont été soustraits" },
    greenspace_updated: { type: "success", icon: faEdit, content: "L'espace vert a été modifié" },
    greenspaces_copy_pasted: { type: "success", icon: faClipboardCheck, content: "__ espaces verts ont été copiés" },
    greenspaces_cut: { type: "success", icon: faCut, content: "Les espaces verts ont été découpés" },
    greenspaces_deleted: { type: "success", icon: faTrash, content: "Les espaces verts ont été supprimés" },
    greenspaces_dragged: { type: "success", icon: faArrowsAlt, content: "Les espaces verts ont été déplacés" },
    greenspaces_duplicated: { type: "success", icon: faClone, content: "__ espaces verts ont été dupliqués" },
    greenspaces_rotated: { type: "success", icon: faSync, content: "Les espaces verts ont été tournés" },
    greenspaces_edited: { type: "success", icon: faVectorPolygon, content: "Les espaces verts ont été redimensionnés" },
    greenspaces_split: { type: "success", icon: faCubes, content: "Les espaces verts ont été divisés" },
    greenspaces_updated: { type: "success", icon: faEdit, content: "Les espaces verts ont été modifiés" },
    infos_updated: { type: "success", icon: faEdit, content: "Les informations ont été modifiées" },
    invitation_sent: { type: "success", icon: faTrash, content: "L'invitation au projet a été envoyée" },
    invitation_removed: { type: "success", icon: faTrash, content: "L'invitation au projet a été supprimée" },
    invitations_updated: { type: "success", icon: faEdit, content: "Les invitations ont été modifiées" },
    marker_added: { type: "success", icon: faPlus, content: "Le repère a été créé" },
    marker_deleted: { type: "success", icon: faTrash, content: "Le repère a été supprimé" },
    marker_dragged: { type: "success", icon: faArrowsAlt, content: "Le repère a été déplacé" },
    marker_updated: { type: "success", icon: faEdit, content: "Le repère a été modifié" },
    markers_deleted: { type: "success", icon: faTrash, content: "Les repères ont été supprimés" },
    markers_dragged: { type: "success", icon: faArrowsAlt, content: "Les repères ont été déplacés" },
    markers_updated: { type: "success", icon: faEdit, content: "Les repères ont été modifiés" },
    notification_added: { type: "success", icon: faPlus, content: "La notification a été créée" },
    notifications_status_updated: { type: "success", icon: faBell, content: "Le statut des notifications a été modifié" },
    notifications_updated: { type: "success", icon: faEdit, content: "Les notifications ont été modifiées" },
    organization_left: { type: "success", icon: faSignOutAlt, content: "Vous avez quitté l'organisation" },
    organization_updated: { type: "success", icon: faEdit, content: "L'organisation a été modifiée" },
    organizations_updated: { type: "success", icon: faEdit, content: "Les organisations ont été modifiées" },
    password_updated: { type: "success", icon: faEdit, content: "Le mot de passe a été modifié" },
    pdf_export_completed: { type: "success", icon: faDownload, content: "L'export de la fiche de l'__ __ est terminé" },
    preview_updated: { type: "success", icon: faEdit, content: "L'aperçu a été mis à jour" },
    photo_added: { type: "success", icon: faTrash, content: "La photo a été ajoutée" },
    photo_deleted: { type: "success", icon: faTrash, content: "La photo a été supprimée" },
    photo_edited: { type: "success", icon: faEdit, content: "La photo a été modifiée" },
    price_list_added: { type: "success", icon: faPlus, content: "La liste de prix a été créée" },
    price_list_deleted: { type: "success", icon: faTrash, content: "La liste de prix a été supprimée" },
    price_list_updated: { type: "success", icon: faEdit, content: "La liste de prix a été modifiée" },
    project_added: { type: "success", icon: faPlus, content: "Le projet a été créé" },
    project_deleted: { type: "success", icon: faTrash, content: "Le projet a été supprimé" },
    project_left: { type: "success", icon: faTrash, content: "Le projet a été quitté" },
    project_updated: { type: "success", icon: faEdit, content: "Le projet a été modifié" },
    references_updated: { type: "success", icon: faEdit, content: "Les références auto-incrémentées ont été modifiées" },
    rights_updated: { type: "success", icon: faEdit, content: "Les droits des collaborateurs ont été modifiés" },
    role_added: { type: "success", icon: faPlus, content: "Le rôle a été créé" },
    role_deleted: { type: "success", icon: faTrash, content: "Le rôle a été supprimé" },
    role_updated: { type: "success", icon: faEdit, content: "Le rôle a été modifié" },
    roles_added: { type: "success", icon: faPlus, content: "Les rôles ont été créés" },
    roles_updated: { type: "success", icon: faEdit, content: "Les rôles ont été modifiés" },
    share_left: { type: "success", icon: faSignOutAlt, content: "Vous avez quitté le partage" },
    station_added: { type: "success", icon: faPlus, content: "La station a été créée" },
    station_deleted: { type: "success", icon: faTrash, content: "La station a été supprimée" },
    station_dragged: { type: "success", icon: faArrowsAlt, content: "La station a été déplacée" },
    station_edited: { type: "success", icon: faVectorPolygon, content: "La station a été redimensionnée" },
    station_rotated: { type: "success", icon: faSync, content: "La station a été tournée" },
    station_updated: { type: "success", icon: faEdit, content: "La station a été modifiée" },
    stations_deleted: { type: "success", icon: faTrash, content: "Les stations ont été supprimées" },
    stations_dragged: { type: "success", icon: faArrowsAlt, content: "Les stations ont été déplacées" },
    stations_rotated: { type: "success", icon: faSync, content: "Les stations ont été tournées" },
    stations_edited: { type: "success", icon: faVectorPolygon, content: "Les stations ont été redimensionnées" },
    stations_updated: { type: "success", icon: faEdit, content: "Les stations ont été modifiées" },
    subscription_added: { type: "success", icon: faPlus, content: "L'abonnement a été créé" },
    subscription_renewed: { type: "success", icon: faEdit, content: "L'abonnement sera renouvelé" },
    subscription_will_cancel: { type: "success", icon: faEdit, content: "L'abonnement sera annulé en fin de période" },
    subscriptions_updated: { type: "success", icon: faEdit, content: "Les abonnements ont été modifiés" },
    template_added: { type: "success", icon: faPlus, content: "Le template a été créé" },
    template_deleted: { type: "success", icon: faTrash, content: "Le template a été supprimé" },
    thematic_map_added: { type: "success", icon: faPlus, content: "La carte thématique a été créée" },
    thematic_map_deleted: { type: "success", icon: faTrash, content: "La carte thématique a été supprimée" },
    thematic_map_updated: { type: "success", icon: faEdit, content: "La carte thématique a été modifiée" },
    theme_added: { type: "success", icon: faPlus, content: "Le thème a été créé" },
    theme_deleted: { type: "success", icon: faTrash, content: "Le thème a été supprimé" },
    theme_updated: { type: "success", icon: faEdit, content: "Le thème a été modifié" },
    tree_added: { type: "success", icon: faPlus, content: "L'arbre a été créé" },
    tree_copy_pasted: { type: "success", icon: faClipboardCheck, content: "L'arbre a été copié" },
    tree_deleted: { type: "success", icon: faTrash, content: "L'arbre a été supprimé" },
    tree_dragged: { type: "success", icon: faArrowsAlt, content: "L'arbre a été déplacé" },
    tree_duplicated: { type: "success", icon: faClone, content: "L'arbre a été dupliqué" },
    tree_restored: { type: "success", icon: faHistory, content: "L'arbre a été restauré" },
    tree_updated: { type: "success", icon: faEdit, content: "L'arbre a été modifié" },
    trees_added: { type: "success", icon: faPlus, content: "Les arbres ont été créés" },
    trees_copy_pasted: { type: "success", icon: faClipboardCheck, content: "__ arbres ont été copiés" },
    trees_deleted: { type: "success", icon: faTrash, content: "Les arbres ont été supprimés" },
    trees_dragged: { type: "success", icon: faArrowsAlt, content: "Les arbres ont été déplacés" },
    trees_duplicated: { type: "success", icon: faClone, content: "__ arbres ont été dupliqués" },
    trees_updated: { type: "success", icon: faEdit, content: "Les arbres ont été modifiés" },
    user_added: { type: "success", icon: faPlus, content: "L'utilisateur a été créé" },
    user_removed: { type: "success", icon: faTrash, content: "L'utilisateur a été retiré de l'organisation" },
    users_added: { type: "success", icon: faPlus, content: "Les utilisateurs ont été ajoutés" },
    users_invited: { type: "success", icon: faPlus, content: "Les utilisateurs ont été invités" },
    users_updated: { type: "success", icon: faEdit, content: "Les utilisateurs ont été modifiés" },
    wms_service_added: { type: "success", icon: faPlus, content: "Le service WMS a été ajouté" },
    wms_service_deleted: { type: "success", icon: faTrash, content: "Le service WMS a été supprimé" },
    wms_service_updated: { type: "success", icon: faEdit, content: "Le service WMS a été modifié" },

    //? Info
    background_image_adding: { type: "info", icon: faPlus, content: "Ajout du calque en cours..." },
    background_image_deleting: { type: "info", icon: faEdit, content: "Suppression du calque en cours..." },
    background_image_updating: { type: "info", icon: faEdit, content: "Modification du calque en cours..." },
    background_images_deleting: { type: "info", icon: faEdit, content: "Suppression des calques en cours..." },
    background_images_updating: { type: "info", icon: faEdit, content: "Modification des calques en cours..." },
    backlog_pdf_exporting: { type: "info", icon: faFilePdf, content: "Export de la fiche des actions (__ - __) en cours..." },
    collaborators_not_added: { type: "info", icon: faPlus, content: "Aucun collaborateur n'a été ajouté au projet" },
    element_limit_almost_reached: { type: "info", icon: faExclamation, content: "Limite d'__ bientôt atteinte __" },
    element_deletion_cancelled: { type: "info", icon: faTimes, content: "L'élément n'a pas été supprimé" },
    elements_deletion_cancelled: { type: "info", icon: faTimes, content: "Les éléments n'ont pas été supprimés" },
    email_copied: { type: "info", icon: faClipboard, content: "E-mail copiée dans le presse-papier" },
    filters_applied: { type: "info", icon: faFilter, content: "Les filtres ont été appliqués" },
    furniture_adding: { type: "info", icon: faPlus, content: "Ajout du mobilier en cours..." },
    furniture_copy_pasting: { type: "info", icon: faClipboard, content: "Copie du mobilier en cours..." },
    furniture_deleting: { type: "info", icon: faTrash, content: "Suppression du mobilier en cours..." },
    furniture_dragging: { type: "info", icon: faArrowsAlt, content: "Déplacement du mobilier en cours..." },
    furniture_duplicating: { type: "info", icon: faClone, content: "Duplication du mobilier en cours..." },
    furniture_restoring: { type: "info", icon: faHistory, content: "Restauration du mobilier en cours..." },
    furniture_updating: { type: "info", icon: faEdit, content: "Modification du mobilier en cours..." },
    furnitures_copy_pasting: { type: "info", icon: faClipboard, content: "Copie de __ mobiliers en cours..." },
    furnitures_deleting: { type: "info", icon: faTrash, content: "Suppression des mobiliers en cours..." },
    furnitures_dragging: { type: "info", icon: faArrowsAlt, content: "Déplacement des mobiliers en cours..." },
    furnitures_duplicating: { type: "info", icon: faClone, content: "Duplication de __ mobiliers en cours..." },
    furnitures_limit_almost_reached: { type: "info", icon: faExclamation, content: "Limite de mobiliers bientôt atteinte __" },
    furnitures_updating: { type: "info", icon: faEdit, content: "Modification des mobiliers en cours..." },
    greenspace_copy_pasting: { type: "info", icon: faClipboard, content: "Copie de l'espace vert en cours..." },
    greenspace_cut_cancelled: { type: "info", icon: faTimes, content: "L'espace vert n'a pas été découpé" },
    greenspace_adding: { type: "info", icon: faPlus, content: "Ajout de l'espace vert en cours..." },
    greenspace_cutting: { type: "info", icon: faCut, content: "Découpe de l'espace vert en cours..." },
    greenspace_deleting: { type: "info", icon: faTrash, content: "Suppression de l'espace vert en cours..." },
    greenspace_dragging: { type: "info", icon: faArrowsAlt, content: "Déplacement de l'espace vert en cours..." },
    greenspace_duplicating: { type: "info", icon: faClone, content: "Duplication de l'espace vert en cours..." },
    greenspace_editing: { type: "info", icon: faVectorPolygon, content: "Redimensionnement de l'espace vert en cours..." },
    greenspace_merging: { type: "info", icon: faSync, content: "Fusion des espaces verts en cours..." },
    greenspace_restoring: { type: "info", icon: faHistory, content: "Restauration de l'espace vert en cours..." },
    greenspace_rotating: { type: "info", icon: faSync, content: "Rotation de l'espace vert en cours..." },
    greenspace_splitting: { type: "info", icon: faSync, content: "Division de l'espace vert en cours..." },
    greenspace_subtracting: { type: "info", icon: faSync, content: "Soustraction des espaces verts en cours..." },
    greenspace_updating: { type: "info", icon: faEdit, content: "Modification de l'espace vert en cours..." },
    greenspaces_copy_pasting: { type: "info", icon: faClipboard, content: "Copie de __ espaces verts en cours..." },
    greenspaces_cutting: { type: "info", icon: faCut, content: "Découpe des espaces verts en cours..." },
    greenspaces_deleting: { type: "info", icon: faTrash, content: "Suppression des espaces verts en cours..." },
    greenspaces_dragging: { type: "info", icon: faArrowsAlt, content: "Déplacement des espaces verts en cours..." },
    greenspaces_duplicating: { type: "info", icon: faClone, content: "Duplication de __ espaces verts en cours..." },
    greenspaces_editing: { type: "info", icon: faVectorPolygon, content: "Redimensionnement des espaces verts en cours..." },
    greenspaces_limit_almost_reached: { type: "info", icon: faExclamation, content: "Limite d'espaces verts bientôt atteinte __" },
    greenspaces_rotating: { type: "info", icon: faSync, content: "Rotation des espaces verts en cours..." },
    greenspaces_splitting: { type: "info", icon: faSync, content: "Division des espaces verts en cours..." },
    greenspaces_updating: { type: "info", icon: faEdit, content: "Modification des espaces verts en cours..." },
    link_copied: { type: "info", icon: faClipboard, content: "Lien copié dans le presse-papier" },
    link_mode_activated: { type: "info", icon: faLink, content: "Le mode de liaison à une action a été activé" },
    link_mode_deactivated: { type: "info", icon: faLink, content: "Le mode de liaison à une action a été désactivé" },
    marker_adding: { type: "info", icon: faPlus, content: "Ajout du repère en cours..." },
    marker_deleting: { type: "info", icon: faTrash, content: "Suppression du repère en cours..." },
    marker_dragging: { type: "info", icon: faArrowsAlt, content: "Déplacement du repère en cours..." },
    marker_updating: { type: "info", icon: faEdit, content: "Modification du repère en cours..." },
    markers_deleting: { type: "info", icon: faTrash, content: "Suppression des repères en cours..." },
    markers_dragging: { type: "info", icon: faArrowsAlt, content: "Déplacement des repères en cours..." },
    markers_updating: { type: "info", icon: faEdit, content: "Modification des repères en cours..." },
    pdf_exporting: { type: "info", icon: faFilePdf, content: "Export de la fiche de l'__ __ en cours..." },
    pdfs_exporting: { type: "info", icon: faFilePdf, content: "Export des fiches en cours..." },
    photo_adding: { type: "info", icon: faPlus, content: "Ajout de la photo en cours..." },
    photos_exporting: { type: "info", icon: faImage, content: "Export des photos en cours..." },
    project_updating: { type: "info", icon: faEdit, content: "Modification du projet en cours..." },
    references_tip: { type: "info", icon: faSearchPlus, content: "Zoomez pour faire apparaître les références" },
    station_adding: { type: "info", icon: faPlus, content: "Ajout de la station en cours..." },
    station_deleting: { type: "info", icon: faTrash, content: "Suppression de la station en cours..." },
    station_dragging: { type: "info", icon: faArrowsAlt, content: "Déplacement de la station en cours..." },
    station_editing: { type: "info", icon: faVectorPolygon, content: "Redimensionnement de la station en cours..." },
    station_rotating: { type: "info", icon: faSync, content: "Rotation de la station en cours..." },
    station_updating: { type: "info", icon: faEdit, content: "Modification de la station en cours..." },
    stations_deleting: { type: "info", icon: faTrash, content: "Suppression des stations en cours..." },
    stations_dragging: { type: "info", icon: faArrowsAlt, content: "Déplacement des stations en cours..." },
    stations_editing: { type: "info", icon: faVectorPolygon, content: "Redimensionnement des stations en cours..." },
    stations_rotating: { type: "info", icon: faSync, content: "Rotation des stations en cours..." },
    stations_updating: { type: "info", icon: faEdit, content: "Modification des stations en cours..." },
    tree_adding: { type: "info", icon: faPlus, content: "Ajout de l'arbre en cours..." },
    tree_copy_pasting: { type: "info", icon: faClipboard, content: "Copie de l'arbre en cours..." },
    tree_deleting: { type: "info", icon: faTrash, content: "Suppression de l'arbre en cours..." },
    tree_dragging: { type: "info", icon: faArrowsAlt, content: "Déplacement de l'arbre en cours..." },
    tree_duplicating: { type: "info", icon: faClone, content: "Duplication de l'arbre en cours..." },
    tree_restoring: { type: "info", icon: faHistory, content: "Restauration de l'arbre en cours..." },
    tree_updating: { type: "info", icon: faEdit, content: "Modification de l'arbre en cours..." },
    trees_adding: { type: "info", icon: faPlus, content: "Ajout des arbres en cours..." },
    trees_copy_pasting: { type: "info", icon: faClipboard, content: "Copie de __ arbres en cours..." },
    trees_deleting: { type: "info", icon: faTrash, content: "Suppression des arbres en cours..." },
    trees_dragging: { type: "info", icon: faArrowsAlt, content: "Déplacement des arbres en cours..." },
    trees_duplicating: { type: "info", icon: faClone, content: "Duplication de __ arbres en cours..." },
    trees_limit_almost_reached: { type: "info", icon: faExclamation, content: "Limite d'arbres bientôt atteinte __" },
    trees_updating: { type: "info", icon: faEdit, content: "Modification des arbres en cours..." },
    will_sync: { type: "info", icon: faSync, content: "L'action sera réalisée au retour de la connexion" },

    // TODO Warning
    action_addition_not_allowed: { type: "warning", icon: faPlus, content: "Impossible d'ajouter une action déjà existante" },
    ai_no_result: { type: "warning", icon: faQuestion, content: "Aucun résultat trouvé" },
    amenity_value_update_not_allowed: { type: "warning", icon: faCalculator, content: "Veuillez compléter les champs coefficients, dimensions et essence" },
    background_images_limit_reached: { type: "warning", icon: faExclamation, content: "Impossible d'ajouter plus de __ calques" },
    backlog_pdf_export_unallowed: { type: "warning", icon: faFilePdf, content: "Aucune récurrence n'est présente dans le tableau" },
    base_projects_move_not_allowed: { type: "warning", icon: faEdit, content: "Impossible de déplacer ces dossiers/projets" },
    coordinates_not_found: { type: "warning", icon: faMapMarkedAlt, content: "Les coordonnées doivent être définies" },
    disable_tool: { type: "warning", icon: faTools, content: "Veuillez désactiver l'outil en cours d'utilisation" },
    ea_account_not_available: { type: "warning", icon: faLink, content: "Le compte n'est pas disponible" },
    ea_add_password: { type: "warning", icon: faUnlink, content: "Veuillez ajouter un mot de passe à votre compte" },
    ea_email_not_available: { type: "warning", icon: faLink, content: "L'adresse mail n'est pas disponible" },
    element_addition_not_allowed: { type: "warning", icon: faPlus, content: "Impossible d'ajouter un élément en dehors de la zone géographique du projet" },
    element_drag_not_allowed: { type: "warning", icon: faArrowsAlt, content: "Impossible de déplacer un élément en dehors de la zone géographique du projet" },
    element_not_found: { type: "warning", icon: faQuestion, content: "Élément supprimé ou inexistant" },
    element_outside_surroundings: { type: "warning", icon: faPlus, content: "Impossible d'ajouter un élément en dehors de la zone géographique du projet" },
    element_update_not_allowed: { type: "warning", icon: faEdit, content: "Impossible d'appliquer un état égal à l'état actuel" },
    elements_inside_bounds_loading_failed: { type: "warning", icon: faSync, content: "Impossible de vérifier les éléments qui seront potentiellement supprimés" },
    elements_limit_reached: { type: "warning", icon: faExclamation, content: "Limite du nombre d'__ atteinte" },
    elements_locked: { type: "warning", icon: faExclamation, content: "Certains éléments sont en cours d'édition par __" },
    elements_outside_surroundings: { type: "warning", icon: faPlus, content: "Impossible d'ajouter __ éléments en dehors de la zone géographique du projet" },
    email_not_valid: { type: "warning", icon: faEnvelope, content: "Veuillez saisir une adresse mail valide" },
    empty_folder_viewing_not_allowed: { type: "warning", icon: faFolder, content: "Impossible de visualiser un dossier vide" },
    essence_addition_not_allowed: { type: "warning", icon: faPlus, content: "Impossible d'ajouter une essence déjà existante" },
    file_format_not_supported: { type: "warning", icon: faFile, content: "Format de fichier non supporté" },
    files_limit_reached: { type: "warning", icon: faExclamation, content: "Impossible d'ajouter plus de __ fichiers" },
    filter_not_efficient: { type: "warning", icon: faFilter, content: "Impossible de sauvegarder un filtre inefficace" },
    filter_not_restored_properly: { type: "warning", icon: faFilter, content: "Le filtre a été partiellement restauré car un ou plusieurs champs sont manquants" },
    finish_resizing: { type: "warning", icon: faVectorPolygon, content: "Veuillez d'abord terminer le redimensionnement" },
    formula_version_addition_not_allowed: { type: "warning", icon: faPlus, content: "Impossible d'ajouter une version déjà existante" },
    formula_version_invalid: { type: "warning", icon: faPlus, content: "Le pattern encodé est invalide" },
    furnitures_limit_reached: { type: "warning", icon: faExclamation, content: "Limite du nombre de mobiliers atteinte" },
    furnitures_limit_verification_failed: { type: "warning", icon: faExclamation, content: "Impossible de vérifier la limite de mobiliers" },
    gps_location_not_activated: { type: "warning", icon: faCompass, content: "La localisation gps n'est pas activée" },
    greenspace_linear_cut_not_allowed: { type: "warning", icon: faCut, content: "Impossible de découper un polygone linéaire" },
    greenspace_cut_not_allowed: { type: "warning", icon: faCut, content: "Impossible de découper le polygone en plusieurs polygones distincts" },
    greenspace_deletion_not_allowed: { type: "warning", icon: faTrash, content: "Impossible de supprimer cet espace vert" },
    greenspace_merge_not_allowed: { type: "warning", icon: faCube, content: "Impossible de fusionner des polygones distants" },
    greenspace_split_area_not_allowed: { type: "warning", icon: faCubes, content: "Impossible de créer un scindement aussi proche d'un angle ou d'un autre scindement" },
    greenspace_split_not_allowed: { type: "warning", icon: faCubes, content: "Impossible de scinder cet espace vert" },
    greenspace_subtraction_not_allowed: { type: "warning", icon: faObjectSubtract, content: "Impossible de soustraire des polygones distants" },
    greenspaces_limit_reached: { type: "warning", icon: faExclamation, content: "Limite du nombre d'espaces verts atteinte" },
    greenspaces_limit_verification_failed: { type: "warning", icon: faExclamation, content: "Impossible de vérifier la limite d'espaces verts" },
    import_in_progress: { type: "warning", icon: faUpload, content: "Un import est en cours..." },
    merge_submit_not_allowed: { type: "warning", icon: faCube, content: "Veuillez faire un choix pour chacune des propriétés" },
    photos_limit_reached: { type: "warning", icon: faExclamation, content: "Impossible d'ajouter plus de __ photos" },
    project_login_required: { type: "warning", icon: faShieldHalved, content: "Veuillez vous connecter pour accéder à ce projet" },
    quit_link_mode: { type: "warning", icon: faLink, content: "Veuillez sortir du mode de liaison" },
    scan_zone_too_large: { type: "warning", icon: faSearchPlus, content: "La zone sélectionnée est trop grande pour être scannée (25 hectares max)" },
    scan_not_allowed: { type: "warning", icon: faMicrochipAi, content: "Impossible de scanner en dehors de la zone géographique du projet" },
    select_furnitures: { type: "warning", icon: faTree, content: "Veuillez sélectionner au moins un mobilier avant d'utiliser cet outil" },
    select_greenSpaces: { type: "warning", icon: faTree, content: "Veuillez sélectionner au moins un espace vert avant d'utiliser cet outil" },
    select_trees: { type: "warning", icon: faTree, content: "Veuillez sélectionner au moins un arbre avant d'utiliser cet outil" },
    self_intersection_detected: { type: "warning", icon: faVectorPolygon, content: "Impossible de créer une auto-intersection" },
    subscription_already_exists: { type: "warning", icon: faTrophy, content: "Une licence existe déjà avec ce libellé" },
    subscription_deletion_not_allowed: { type: "warning", icon: faTrash, content: "Impossible de supprimer cette licence" },
    subscription_management_failed: { type: "warning", icon: faEdit, content: "Impossible de gérer votre licence" },
    thematic_map_addition_not_allowed: { type: "warning", icon: faPlus, content: "Veuillez renseigner au moins une couleur correcte" },
    trees_limit_reached: { type: "warning", icon: faExclamation, content: "Limite du nombre d'arbres atteinte" },
    trees_limit_verification_failed: { type: "warning", icon: faExclamation, content: "Impossible de vérifier la limite d'arbres" },
    vertex_deletion_not_allowed_self_intersection: { type: "warning", icon: faVectorPolygon, content: "Impossible de supprimer ce sommet : création d'une auto-intersection" },
    vertex_deletion_not_allowed_too_few_vertexes: { type: "warning", icon: faVectorPolygon, content: "Impossible de supprimer ce sommet : 3 sommets minimum" },
    wrong_password: { type: "warning", icon: faKey, content: "Mot de passe incorrect" },

    //! Error
    account_deletion_failed: { type: "error", icon: faTrash, content: "La suppression de votre compte a échoué" },
    action_addition_failed: { type: "error", icon: faPlus, content: "L'ajout de l'action a échoué" },
    action_deletion_failed: { type: "error", icon: faTrash, content: "La suppression de l'action a échoué" },
    action_link_failed: { type: "error", icon: faLink, content: "La liaison de l'action a échoué" },
    action_loading_failed: { type: "error", icon: faSync, content: "Le chargement des actions a échoué" },
    action_pdf_export_failed: { type: "error", icon: faFilePdf, content: "L'export de la fiche de l'action a échoué" },
    action_unlink_failed: { type: "error", icon: faUnlink, content: "La suppression de la liaison avec l'action a échoué" },
    actions_deletion_failed: { type: "error", icon: faTrash, content: "La suppression des actions a échoué" },
    actions_update_failed: { type: "error", icon: faEdit, content: "La modification des actions a échoué" },
    ai_scan_failed: { type: "error", icon: faMicrochipAi, content: "Le scan de la zone a échoué" },
    amenity_value_update_failed: { type: "error", icon: faCalculator, content: "Le calcul de la valeur d'agrément a échoué" },
    background_image_addition_failed: { type: "error", icon: faPlus, content: "L'ajout du calque a échoué" },
    background_image_deletion_failed: { type: "error", icon: faEdit, content: "La suppression du calque a échoué" },
    background_image_update_failed: { type: "error", icon: faEdit, content: "La modification du calque a échoué" },
    background_images_deletion_failed: { type: "error", icon: faEdit, content: "La suppression des calques a échoué" },
    background_images_update_failed: { type: "error", icon: faEdit, content: "La modification des calques a échoué" },
    background_tasks_loading_failed: { type: "error", icon: faSync, content: "Le chargement des tâches a échoué" },
    backlog_pdf_export_failed: { type: "error", icon: faFilePdf, content: "L'export de la fiche des actions (__ - __) a échoué" },
    base_projects_update_failed: { type: "error", icon: faEdit, content: "La modification des projets/dossiers a échoué" },
    billing_address_update_failed: { type: "error", icon: faEdit, content: "La modification de l'adressse de facturation a échoué" },
    bookmark_deletion_failed: { type: "error", icon: faTrash, content: "La suppression du bookmark a échoué" },
    branch_symptoms_loading_failed: { type: "error", icon: faSync, content: "Le chargement des symptômes branches a échoué" },
    collaborators_addition_failed: { type: "error", icon: faPlus, content: "L'ajout de collaborateurs a échoué" },
    collar_symptoms_loading_failed: { type: "error", icon: faSync, content: "Le chargement des symptômes collet a échoué" },
    conditions_loading_failed: { type: "error", icon: faSync, content: "Le chargement des états de mobilier a échoué" },
    connection_failed: { type: "error", icon: faUnlink, content: "La connexion à la base de données a échoué" },
    cover_types_loading_failed: { type: "error", icon: faSync, content: "Le chargement des types de couverture a échoué" },
    custom_chart_addition_failed: { type: "error", icon: faPlus, content: "L'ajout du graphique personnalisé a échoué" },
    custom_chart_deletion_failed: { type: "error", icon: faTrash, content: "La suppression du graphique personnalisé a échoué" },
    custom_chart_sharing_failed: { type: "error", icon: faShareAlt, content: "Le partage du graphique personnalisé a échoué" },
    custom_chart_update_failed: { type: "error", icon: faEdit, content: "La modification du graphique personnalisé a échoué" },
    custom_field_addition_failed: { type: "error", icon: faPlus, content: "L'ajout du champs personnalisé a échoué" },
    custom_field_categories_loading_failed: { type: "error", icon: faPlus, content: "Le chargement des catégories a échoué" },
    custom_field_category_addition_failed: { type: "error", icon: faPlus, content: "L'ajout de la catégorie a échoué" },
    custom_field_category_deletion_failed: { type: "error", icon: faTrash, content: "La suppression de la catégorie a échoué" },
    custom_field_category_update_failed: { type: "error", icon: faEdit, content: "La modification de la catégorie a échoué" },
    custom_field_deletion_failed: { type: "error", icon: faTrash, content: "La suppression du champs personnalisé a échoué" },
    custom_field_update_failed: { type: "error", icon: faEdit, content: "La modification du champs personnalisé a échoué" },
    custom_fields_deletion_failed: { type: "error", icon: faTrash, content: "La suppression des champs personnalisés a échoué" },
    custom_fields_loading_failed: { type: "error", icon: faSync, content: "Le chargement des champs supplémentaires a échoué" },
    custom_fields_update_failed: { type: "error", icon: faEdit, content: "La modification des champs personnalisés a échoué" },
    custom_filter_addition_failed: { type: "error", icon: faPlus, content: "L'ajout du filtre personnalisé a échoué" },
    custom_filter_deletion_failed: { type: "error", icon: faTrash, content: "La suppression du filtre personnalisé a échoué" },
    custom_filter_sharing_failed: { type: "error", icon: faShareAlt, content: "Le partage du filtre personnalisé a échoué" },
    dashboard_loading_failed: { type: "error", icon: faSync, content: "Le chargement des infos du dashboard a échoué" },
    data_transfer_failed: { type: "error", icon: faArrowRightArrowLeft, content: "Le transfert des données a échoué" },
    dominant_compositions_loading_failed: { type: "error", icon: faSync, content: "Le chargement des compositions dominantes a échoué" },
    ea_link_failed: { type: "error", icon: faLink, content: "La liaison du compte a échoué" },
    ea_unlink_failed: { type: "error", icon: faUnlink, content: "La suppression de la liaison du compte a échoué" },
    element_history_loading_failed: { type: "error", icon: faSync, content: "Le chargement de l'historique a échoué" },
    elements_import_failed: { type: "error", icon: faUpload, content: "L'import des données a échoué" },
    elements_pdf_export_failed: { type: "error", icon: faFilePdf, content: "Le téléchargement des fiches a échoué" },
    elements_shp_export_failed: { type: "error", icon: faFile, content: "L'export des données (.shp) a échoué" },
    elements_xlsx_export_failed: { type: "error", icon: faDownload, content: "L'export des données (.xlsx) a échoué" },
    epiphytes_loading_failed: { type: "error", icon: faSync, content: "Le chargement des épiphytes a échoué" },
    essence_addition_failed: { type: "error", icon: faPlus, content: "L'ajout de l'essence a échoué" },
    essence_deletion_failed: { type: "error", icon: faTrash, content: "La suppression de l'essence a échoué" },
    essences_deletion_failed: { type: "error", icon: faTrash, content: "La suppression des essences a échoué" },
    essences_update_failed: { type: "error", icon: faEdit, content: "La modification des essences a échoué" },
    essences_loading_failed: { type: "error", icon: faSync, content: "Le chargement des essences a échoué" },
    favorite_status_update_failed: { type: "error", icon: faStar, content: "La modification du statut de favori a échoué" },
    file_addition_failed: { type: "error", icon: faPlus, content: "L'ajout du fichier a échoué" },
    file_deletion_failed: { type: "error", icon: faTrash, content: "La suppression du fichier a échoué" },
    file_update_failed: { type: "error", icon: faUpload, content: "La modification du fichier a échoué" },
    file_upload_failed: { type: "error", icon: faUpload, content: "L'envoi du fichier a échoué" },
    files_deletion_failed: { type: "error", icon: faTrash, content: "La suppression des fichiers a échoué" },
    files_loading_failed: { type: "error", icon: faSync, content: "Le chargement des fichers a échoué" },
    files_upload_failed: { type: "error", icon: faUpload, content: "L'envoi des fichiers a échoué" },
    folder_content_loading_failed: { type: "error", icon: faSync, content: "Le chargement du contenu du dossier a échoué" },
    folder_deletion_failed: { type: "error", icon: faTrash, content: "La suppression du dossier a échoué" },
    folder_update_failed: { type: "error", icon: faEdit, content: "La modification du dossier a échoué" },
    formula_version_addition_failed: { type: "error", icon: faPlus, content: "L'ajout de la version de la formule a échoué" },
    formulas_loading_failed: { type: "error", icon: faSync, content: "Le chargement des formules a échoué" },
    furniture_addition_failed: { type: "error", icon: faPlus, content: "L'ajout du mobilier a échoué" },
    furniture_copy_paste_failed: { type: "error", icon: faClipboard, content: "La copie du mobilier a échoué" },
    furniture_deletion_failed: { type: "error", icon: faTrash, content: "La suppression du mobilier a échoué" },
    furniture_dragging_failed: { type: "error", icon: faArrowsAlt, content: "Le déplacement du mobilier a échoué" },
    furniture_duplication_failed: { type: "error", icon: faClone, content: "La duplication du mobilier a échoué" },
    furniture_types_loading_failed: { type: "error", icon: faSync, content: "Le chargement des types de mobilier a échoué" },
    furniture_restoration_failed: { type: "error", icon: faHistory, content: "La restauration du mobilier a échoué" },
    furniture_update_failed: { type: "error", icon: faEdit, content: "La modification du mobilier a échoué" },
    furnitures_copy_paste_failed: { type: "error", icon: faClipboard, content: "La copie des __ mobiliers a échoué" },
    furnitures_deletion_failed: { type: "error", icon: faTrash, content: "La suppression des mobiliers a échoué" },
    furnitures_duplication_failed: { type: "error", icon: faClone, content: "La duplication des __ mobiliers a échoué" },
    furnitures_loading_failed: { type: "error", icon: faSync, content: "Le chargement des mobiliers a échoué" },
    furnitures_dragging_failed: { type: "error", icon: faArrowsAlt, content: "Le déplacement des mobiliers a échoué" },
    furnitures_update_failed: { type: "error", icon: faEdit, content: "La modification des mobiliers a échoué" },
    gps_location_not_found: { type: "error", icon: faCompass, content: "La localisation gps n'est pas disponible" },
    greenspace_addition_failed: { type: "error", icon: faPlus, content: "L'ajout de l'espace vert a échoué" },
    greenspace_copy_paste_failed: { type: "error", icon: faClipboard, content: "La copie de l'espace vert a échoué" },
    greenspace_cut_failed: { type: "error", icon: faCut, content: "La découpe de l'espace vert a échoué" },
    greenspace_deletion_failed: { type: "error", icon: faTrash, content: "La suppression de l'espace vert a échoué" },
    greenspace_dragging_failed: { type: "error", icon: faArrowsAlt, content: "Le déplacement de l'espace vert a échoué" },
    greenspace_duplication_failed: { type: "error", icon: faClone, content: "La duplication de l'espace vert a échoué" },
    greenspace_editing_failed: { type: "error", icon: faVectorPolygon, content: "Le redimensionnement de l'espace vert a échoué" },
    greenspace_merge_failed: { type: "error", icon: faCube, content: "La fusion des espaces verts a échoué" },
    greenspace_restoration_failed: { type: "error", icon: faHistory, content: "La restauration de l'espace vert a échoué" },
    greenspace_rotation_failed: { type: "error", icon: faSync, content: "La rotation de l'espace vert a échoué" },
    greenspace_split_failed: { type: "error", icon: faCubes, content: "La division de l'espace vert a échoué" },
    greenspace_subtraction_failed: { type: "error", icon: faObjectSubtract, content: "La soustraction des espaces verts a échoué" },
    greenspace_update_failed: { type: "error", icon: faEdit, content: "La modification de l'espace vert a échoué" },
    greenspaces_copy_paste_failed: { type: "error", icon: faClipboard, content: "La copie des __ espaces verts a échoué" },
    greenspaces_cut_failed: { type: "info", icon: faCut, content: "La découpe des espaces verts a échoué" },
    greenspaces_deletion_failed: { type: "error", icon: faTrash, content: "La suppression des espaces verts a échoué" },
    greenspaces_dragging_failed: { type: "error", icon: faArrowsAlt, content: "Le déplacement des espaces verts a échoué" },
    greenspaces_duplication_failed: { type: "error", icon: faClone, content: "La duplication des __ espaces verts a échoué" },
    greenspaces_editing_failed: { type: "error", icon: faVectorPolygon, content: "Le redimensionnement des espaces verts a échoué" },
    greenspaces_loading_failed: { type: "error", icon: faSync, content: "Le chargement des espaces verts a échoué" },
    greenspaces_rotation_failed: { type: "error", icon: faSync, content: "La rotation des espaces verts a échoué" },
    greenspaces_split_failed: { type: "error", icon: faCubes, content: "La division des espaces verts a échoué" },
    greenspaces_update_failed: { type: "error", icon: faEdit, content: "La modification des espaces verts a échoué" },
    health_reviews_loading_failed: { type: "error", icon: faSync, content: "Le chargement des cotes sanitaires a échoué" },
    infos_update_failed: { type: "error", icon: faEdit, content: "La modification des informations a échoué" },
    interactions_loading_failed: { type: "error", icon: faSync, content: "Le chargement des interactions a échoué" },
    invitation_deletion_failed: { type: "error", icon: faSync, content: "La suppression de l'invitation a échoué" },
    invitations_deletion_failed: { type: "error", icon: faSync, content: "La suppression des invitations a échoué" },
    invitations_loading_failed: { type: "error", icon: faSync, content: "Le chargement des invitations a échoué" },
    leaf_symptoms_loading_failed: { type: "error", icon: faSync, content: "Le chargement des symptômes feuilles a échoué" },
    linked_elements_loading_failed: { type: "error", icon: faEnvelope, content: "Le chargement des liaisons des éléments a échoué" },
    mails_configuration_update_failed: { type: "error", icon: faEnvelope, content: "La modification de la configuration des mails a echoué" },
    management_classes_loading_failed: { type: "error", icon: faSync, content: "Le chargement des classes de gestion a échoué" },
    marker_addition_failed: { type: "error", icon: faPlus, content: "L'ajout du repère a échoué" },
    marker_deletion_failed: { type: "error", icon: faTrash, content: "La suppression du repère a échoué" },
    marker_dragging_failed: { type: "error", icon: faArrowsAlt, content: "Le déplacement du repère a échoué" },
    marker_update_failed: { type: "error", icon: faEdit, content: "La modification du repère a échoué" },
    markers_deletion_failed: { type: "error", icon: faTrash, content: "La suppression des repères a échoué" },
    markers_loading_failed: { type: "error", icon: faSync, content: "Le chargement des repères a échoué" },
    markers_dragging_failed: { type: "error", icon: faArrowsAlt, content: "Le déplacement des repères a échoué" },
    markers_update_failed: { type: "error", icon: faEdit, content: "La modification des repères a échoué" },
    micro_habitats_loading_failed: { type: "error", icon: faSync, content: "Le chargement des dendro-microhabitats a échoué" },
    municipalities_loading_failed: { type: "error", icon: faSync, content: "Le chargement des communes a échoué" },
    notification_addition_failed: { type: "error", icon: faPlus, content: "L'ajout de la notification a échoué" },
    notification_deletion_failed: { type: "error", icon: faTrash, content: "La suppression de la notification a échoué" },
    notifications_deletion_failed: { type: "error", icon: faTrash, content: "La suppression des notifications a échoué" },
    notifications_loading_failed: { type: "error", icon: faSync, content: "Le chargement des notifications a échoué" },
    notifications_status_update_failed: { type: "error", icon: faBell, content: "La modification du statut des notifications a échoué" },
    notifications_update_failed: { type: "error", icon: faEdit, content: "La modification des notifications a échoué" },
    number_of_trunks_loading_failed: { type: "error", icon: faSync, content: "Le chargement des nombres de troncs a échoué" },
    ontogenic_stages_loading_failed: { type: "error", icon: faSync, content: "Le chargement des stades ontogéniques a échoué" },
    organ_calibers_loading_failed: { type: "error", icon: faSync, content: "Le chargement des calibres d'organe a échoué" },
    organization_leaving_failed: { type: "error", icon: faSignOutAlt, content: "Impossible de quitter l'organisation, veuillez réessayez plus tard" },
    organization_update_failed: { type: "error", icon: faEdit, content: "La modification de l'organisation a échoué" },
    organization_user_removing_failed: { type: "error", icon: faTrash, content: "Le retrait de l'utilisateur a échoué" },
    organizations_loading_failed: { type: "error", icon: faSync, content: "Le chargement des organisations a échoué" },
    organizations_update_failed: { type: "error", icon: faEdit, content: "La modification des organisations a échoué" },
    organizations_xlsx_export_failed: { type: "error", icon: faDownload, content: "L'export des organisations a échoué" },
    password_update_failed: { type: "error", icon: faEdit, content: "La modification du mot de passe a échoué" },
    pathogens_loading_failed: { type: "error", icon: faSync, content: "Le chargement des pathogènes a échoué" },
    patrimonial_coefficients_loading_failed: { type: "error", icon: faSync, content: "Le chargement des coefficients patrimoniaux a échoué" },
    pdf_export_failed: { type: "error", icon: faFilePdf, content: "L'export de la fiche a échoué" },
    pests_loading_failed: { type: "error", icon: faSync, content: "Le chargement des ravageurs a échoué" },
    photo_addition_failed: { type: "error", icon: faPlus, content: "L'ajout de la photo a échoué" },
    photo_deletion_failed: { type: "error", icon: faTrash, content: "La suppression de la photo a échoué" },
    photos_import_failed: { type: "error", icon: faUpload, content: "L'import des photos a échoué" },
    photos_loading_failed: { type: "error", icon: faSync, content: "Le chargement des photos a échoué" },
    plantation_coefficients_loading_failed: { type: "error", icon: faSync, content: "Le chargement des coefficients de plantation a échoué" },
    plantation_types_loading_failed: { type: "error", icon: faSync, content: "Le chargement des types de plantation a échoué" },
    preview_update_failed: { type: "error", icon: faEdit, content: "La modification de l'aperçu a échoué" },
    price_list_addition_failed: { type: "error", icon: faPlus, content: "La création de la liste de prix a échoué" },
    price_list_deletion_failed: { type: "error", icon: faTrash, content: "La suppression de la liste de prix a échoué" },
    price_list_update_failed: { type: "error", icon: faEdit, content: "La modification de la liste de prix a échoué" },
    project_deletion_failed: { type: "error", icon: faTrash, content: "La suppression du projet a échoué" },
    project_duplication_failed: { type: "error", icon: faTrash, content: "Impossible de dupliquer ce projet car il est déjà en cours de duplication" },
    project_leaving_failed: { type: "error", icon: faTrash, content: "Impossible de quitter le projet" },
    project_resizing_failed: { type: "error", icon: faVectorPolygon, content: "Impossible de rétrécir la zone géographique du projet" },
    project_loading_failed: { type: "error", icon: faSync, content: "Le chargement du projet a échoué" },
    project_update_failed: { type: "error", icon: faEdit, content: "La modification du projet a échoué" },
    projections_loading_failed: { type: "error", icon: faSync, content: "Le chargement des projections a échoué" },
    projects_loading_failed: { type: "error", icon: faSync, content: "Le chargement des projets a échoué" },
    references_update_failed: { type: "error", icon: faEdit, content: "La modification des références auto-incrémentées a échoué" },
    rights_update_failed: { type: "error", icon: faEdit, content: "La modification des droits des collaborateurs a échoué" },
    risks_loading_failed: { type: "error", icon: faSync, content: "Le chargement des risques a échoué" },
    role_addition_failed: { type: "error", icon: faPlus, content: "L'ajout du rôle a échoué" },
    role_deletion_failed: { type: "error", icon: faTrash, content: "La suppression du rôle a échoué" },
    role_update_failed: { type: "error", icon: faEdit, content: "La modification du rôle a échoué" },
    roles_addition_failed: { type: "error", icon: faPlus, content: "L'ajout des rôles a échoué" },
    roles_update_failed: { type: "error", icon: faEdit, content: "La modification des rôles a échoué" },
    root_symptoms_loading_failed: { type: "error", icon: faSync, content: "Le chargement des symptômes racines a échoué" },
    runoff_coefficients_loading_failed: { type: "error", icon: faSync, content: "Le chargement des coefficient de ruissellement a échoué" },
    screenshot_failed: { type: "error", icon: faCamera, content: "La capture de la carte a échoué" },
    share_leaving_failed: { type: "error", icon: faSignOutAlt, content: "Impossible de quitter le partage, veuillez réessayez plus tard" },
    share_users_addition_failed: { type: "error", icon: faTrash, content: "L'ajout d'utilisateurs a échoué" },
    situation_coefficients_loading_failed: { type: "error", icon: faSync, content: "Le chargement des coefficients de situation a échoué" },
    space_functions_loading_failed: { type: "error", icon: faSync, content: "Le chargement des fonctions d'espace a échoué" },
    space_types_categories_loading_failed: { type: "error", icon: faSync, content: "Le chargement des catégories d'espace a échoué" },
    space_types_loading_failed: { type: "error", icon: faSync, content: "Le chargement des types d'espace a échoué" },
    station_addition_failed: { type: "error", icon: faPlus, content: "L'ajout de la station a échoué" },
    station_deletion_failed: { type: "error", icon: faTrash, content: "La suppression de la station a échoué" },
    station_dragging_failed: { type: "error", icon: faArrowsAlt, content: "Le déplacement de la station a échoué" },
    station_editing_failed: { type: "error", icon: faVectorPolygon, content: "Le redimensionnement de la station a échoué" },
    station_rotation_failed: { type: "error", icon: faSync, content: "La rotation de la station a échoué" },
    station_update_failed: { type: "error", icon: faEdit, content: "La modification de la station a échoué" },
    stations_deletion_failed: { type: "error", icon: faTrash, content: "La suppression des stations a échoué" },
    stations_dragging_failed: { type: "error", icon: faArrowsAlt, content: "Le déplacement des stations a échoué" },
    stations_editing_failed: { type: "error", icon: faVectorPolygon, content: "Le redimensionnement des stations a échoué" },
    stations_loading_failed: { type: "error", icon: faSync, content: "Le chargement des stations a échoué" },
    stations_rotation_failed: { type: "error", icon: faSync, content: "La rotation des stations a échoué" },
    stations_update_failed: { type: "error", icon: faEdit, content: "La modification des stations a échoué" },
    subscription_addition_failed: { type: "error", icon: faPlus, content: "La création de la licence a échoué" },
    subscription_deletion_failed: { type: "error", icon: faTrash, content: "La suppression de la licence a échoué" },
    subscription_update_failed: { type: "error", icon: faEdit, content: "La modification de votre licence a échoué" },
    subscriptions_deletion_failed: { type: "error", icon: faTrash, content: "La suppression des licences a échoué" },
    subscriptions_loading_failed: { type: "error", icon: faSync, content: "Le chargement des licences a échoué" },
    subscriptions_update_failed: { type: "error", icon: faEdit, content: "La modification des licences a échoué" },
    targets_loading_failed: { type: "error", icon: faSync, content: "Le chargement des cibles a échoué" },
    template_addition_failed: { type: "error", icon: faPlus, content: "L'ajout du template a échoué" },
    template_deletion_failed: { type: "error", icon: faTrash, content: "La suppression du template a échoué" },
    thematic_map_addition_failed: { type: "error", icon: faPlus, content: "La création de la carte thématique a échoué" },
    thematic_map_deletion_failed: { type: "error", icon: faTrash, content: "La suppression de la carte thématique a échoué" },
    thematic_map_update_failed: { type: "error", icon: faEdit, content: "La modification de la carte thématique a échoué" },
    theme_addition_failed: { type: "error", icon: faPlus, content: "La création du thème a échoué" },
    theme_deletion_failed: { type: "error", icon: faTrash, content: "La suppression du thème a échoué" },
    theme_update_failed: { type: "error", icon: faEdit, content: "La modification du thème a échoué" },
    tipping_risks_loading_failed: { type: "error", icon: faSync, content: "Le chargement des risques de basculement a échoué" },
    tree_addition_failed: { type: "error", icon: faPlus, content: "L'ajout de l'arbre a échoué" },
    tree_copy_paste_failed: { type: "error", icon: faClipboard, content: "La copie de l'arbre a échoué" },
    tree_deletion_failed: { type: "error", icon: faTrash, content: "La suppression de l'arbre a échoué" },
    tree_dragging_failed: { type: "error", icon: faArrowsAlt, content: "Le déplacement de l'arbre a échoué" },
    tree_duplication_failed: { type: "error", icon: faClone, content: "La duplication de l'arbre a échoué" },
    tree_ports_loading_failed: { type: "error", icon: faSync, content: "Le chargement des ports de l'arbre a échoué" },
    tree_restoration_failed: { type: "error", icon: faHistory, content: "La restauration de l'arbre a échoué" },
    tree_update_failed: { type: "error", icon: faEdit, content: "La modification de l'arbre a échoué" },
    trees_addition_failed: { type: "error", icon: faPlus, content: "L'ajout des arbres a échoué" },
    trees_copy_paste_failed: { type: "error", icon: faClipboard, content: "La copie des __ arbres a échoué" },
    trees_deletion_failed: { type: "error", icon: faTrash, content: "La suppression des arbres a échoué" },
    trees_duplication_failed: { type: "error", icon: faClone, content: "La duplication des __ arbres a échoué" },
    trees_loading_failed: { type: "error", icon: faSync, content: "Le chargement des arbres a échoué" },
    trees_dragging_failed: { type: "error", icon: faArrowsAlt, content: "Le déplacement des arbres a échoué" },
    trees_update_failed: { type: "error", icon: faEdit, content: "La modification des arbres a échoué" },
    trunk_symptoms_loading_failed: { type: "error", icon: faSync, content: "Le chargement des symptômes tronc a échoué" },
    user_addition_failed: { type: "error", icon: faPlus, content: "La création de l'utilisateur a échoué" },
    user_deletion_failed: { type: "error", icon: faTrash, content: "La suppression de l'utilisateur a échoué" },
    users_deletion_failed: { type: "error", icon: faTrash, content: "La suppression des utilisateurs a échoué" },
    users_loading_failed: { type: "error", icon: faSync, content: "Le chargement des utilisateurs a échoué" },
    users_update_failed: { type: "error", icon: faEdit, content: "La mise à jour des utilisateurs a échoué" },
    vat_validation_failed: { type: "error", icon: faExclamationTriangle, content: "Le numéro de TVA n'a pas pu être validé, veuillez vous assurer qu'il est correct" },
    vigors_loading_failed: { type: "error", icon: faSync, content: "Le chargement des vigueurs a échoué" },
    wms_service_addition_failed: { type: "error", icon: faPlus, content: "L'ajout du service WMS a échoué" },
    wms_service_deletion_failed: { type: "error", icon: faTrash, content: "La suppression du service WMS a échoué" },
    wms_service_update_failed: { type: "error", icon: faEdit, content: "La modification du service WMS a échoué" }
};

export const showToast = (toastId, ...customTexts) => {
    const toast = TOASTS[toastId];
    if (!toast) return;

    const style = StylesUtil.getToastStyle(toast.type);
    let description = i18n.t(toast.content);
    customTexts.forEach(text => {
        description = description.replace('__', text);
    });
    Toast(description, {
        icon: <FontAwesomeIcon icon={toast.icon} />,
        style: style
    });
};

export const showLoadingToast = (toastId, successId, errorId, promise, ...customTexts) => {
    // Toasts
    const toast = TOASTS[toastId];
    const successToast = TOASTS[successId];
    const errorToast = TOASTS[errorId];
    const offlineToast = TOASTS['will_sync'];

    if (!toast || !successToast || !errorToast) return;

    // Styles
    let style = StylesUtil.getToastStyle('info');
    let successStyle = StylesUtil.getToastStyle('success');
    let errorStyle = StylesUtil.getToastStyle('error');

    // Description
    let description = i18n.t(toast.content);
    customTexts.forEach(text => {
        description = description.replace('__', text);
    });
    let successDescription = i18n.t(successToast.content);
    customTexts.forEach(text => {
        successDescription = successDescription.replace('__', text);
    });
    let errorDescription = i18n.t(errorToast.content);
    customTexts.forEach(text => {
        errorDescription = errorDescription.replace('__', text);
    });

    return Toast.promise(promise, {
        loading: description,
        success: () => navigator.onLine ? successDescription : i18n.t(offlineToast.content),
        error: () => errorDescription
    }, {
        style: style,
        success: {
            icon: <FontAwesomeIcon icon={navigator.onLine ? successToast.icon : offlineToast.icon} />,
            style: navigator.onLine ? successStyle : style
        },
        error: {
            icon: <FontAwesomeIcon icon={errorToast.icon} />,
            style: errorStyle
        }
    });
};