import React, { Component } from 'react';
// Composants
import { Button, Form, Grid, Input, Segment, Select, Message, Divider } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from '../../Utils/DatePicker';
import InputWithAction from '../../Utils/InputWithAction';
// Librairies
import { isMobile, isMobileOnly } from 'react-device-detect';
import { connect } from 'react-redux';
import i18n from '../../../locales/i18n';
import { v4 as uuidv4 } from 'uuid';
import { intervalToDuration, subYears } from 'date-fns';
// Ressources
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
// Utils
import FormattersUtil from '../../../utils/FormattersUtil';
import UpdatesUtil from '../../../utils/UpdatesUtil';
import ProjectsUtil from '../../../utils/ProjectsUtil';
import DatesUtil from '../../../utils/DatesUtil';
import TreesUtil from '../../../utils/TreesUtil';

const treeOrgans = [
    { organ: 'root', label: i18n.t("racines") },
    { organ: 'collar', label: i18n.t("collet") },
    { organ: 'trunk', label: i18n.t("tronc") },
    { organ: 'branch', label: i18n.t("branches") },
    { organ: 'leaf', label: i18n.t("feuilles") }
];

class PropertiesModificationForm extends Component {
    constructor(props) {
        super(props);
        this.category = this.props.elementsToModify[0][0].feature.properties.category;
        this.baseFields = this.getBaseFields();
    }

    state = {
        category: null,
        properties: {},
        fieldNumber: 1,
        names: [],
        values: [],
        adjustedProperties: null
    };

    render() {
        const { adjustedProperties, isLoading } = this.state;

        const gridStyle = { margin: '10px', paddingBottom: '14px', border: 'solid 1px grey', borderRadius: '10px' };
        const headerRowStyle = { padding: '5px', borderBottom: 'solid 1px grey' };
        const propertiesRowStyle = { fontWeight: 'bold', paddingBottom: '3px' };

        return (
            <Form className='modal-content' loading={isLoading} error>
                <div className='modal-content-body'>
                    {adjustedProperties && this.getEfficientProperties(adjustedProperties).length > 0 ?
                        <>
                            <Message visible warning header={i18n.t("Attention !")} content={i18n.t("Suite aux modifications apportées, les propriétés suivantes seront également modifiées")} />
                            <Grid style={gridStyle}>
                                <Grid.Row style={headerRowStyle}>
                                    <Grid.Column textAlign='center'><h2>{i18n.t("Autres propriétés modifiées")}</h2></Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns={3} divided style={propertiesRowStyle}>
                                    <Grid.Column>{i18n.t("Propriété")}</Grid.Column>
                                    <Grid.Column>{i18n.t("Valeur")}</Grid.Column>
                                    <Grid.Column>{i18n.t("Raison")}</Grid.Column>
                                </Grid.Row>
                                <Divider style={{ margin: '2px 14px' }} />
                                {this.renderAdjustedProperties()}
                            </Grid>
                        </>
                        : this.renderFields()}
                </div>
                <div className='modal-content-footer'>
                    {isMobile ?
                        <Button.Group widths={4}>
                            <Button type='button' className='form-button' color='red' onClick={() => { if (adjustedProperties) this.setState({ adjustedProperties: null }); else this.props.hideForm(false); }} >
                                <FontAwesomeIcon icon={faTimes} style={{ marginRight: !isMobileOnly && '10px' }} />{!isMobileOnly && i18n.t("Annuler")}
                            </Button>
                            <Button id='o3RMEGnb' type='button' className='form-button' color='green' onClick={() => { if (adjustedProperties) this.handleSubmit(); else this.adjustProperties(); }}>
                                <FontAwesomeIcon icon={faCheck} style={{ marginRight: !isMobileOnly && '10px' }} />{!isMobileOnly && i18n.t("Valider")}
                            </Button>
                        </Button.Group>
                        :
                        <>
                            <Button type='button' className='form-button' color='red' onClick={() => { if (adjustedProperties) this.setState({ adjustedProperties: null }); else this.props.hideForm(false); }}>
                                <FontAwesomeIcon icon={faTimes} style={{ marginRight: !isMobileOnly && '10px' }} />{!isMobileOnly && i18n.t("Annuler")}
                            </Button>
                            <Button id='o3RMEGnb' type='button' className='form-button' color='green' onClick={() => { if (adjustedProperties) this.handleSubmit(); else this.adjustProperties(); }}>
                                <FontAwesomeIcon icon={faCheck} style={{ marginRight: !isMobileOnly && '10px' }} />{!isMobileOnly && i18n.t("Valider")}
                            </Button>
                        </>}
                </div>
            </Form>
        );
    }

    renderFields = () => {
        const fields = [];
        const { names, values, fieldNumber } = this.state;
        const nameOptions = this.getNameOptions();

        for (let i = 0; i < fieldNumber; i++) {
            const name = names[i]; // Nom de la propriété
            const value = values[i]; // Valeur de la propriété
            const field = this.baseFields[name] || this.props.customFields.find(cf => cf.id === name);
            const isMultiple = field?.type === 'list' && field?.isMultiple;

            fields.push(
                <Segment style={{ marginTop: 0, marginBottom: 0, paddingBottom: isMobileOnly ? null : 0 }} key={i}>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <InputWithAction icon='trash' active={name} onClick={() => this.removeField(i)}>
                                <Select
                                    style={{ width: '100%' }} placeholder={i18n.t("Champs")} selectOnBlur={false} selectOnNavigation={false}
                                    name={i} options={nameOptions.filter(option => !names.includes(option.value) || option.value === name)} value={name || ''}
                                    search={FormattersUtil.searchList} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                                    onChange={this.handleNameChange}
                                />
                            </InputWithAction>
                        </Form.Field>
                        {this.getValueOptions(name) ?
                            <Form.Field
                                control={Select} placeholder={i18n.t("Valeur")} name={i} options={this.getValueOptions(name) || []}
                                value={!value && value !== false
                                    ? (isMultiple ? [] : '')
                                    : !Array.isArray(value) && isMultiple
                                        ? [value]
                                        : (field?.type === 'boolean' ? value : value || '')}
                                selectOnBlur={false} selectOnNavigation={false} search={FormattersUtil.searchList} upward={isMobileOnly && i > 0} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                                multiple={field?.type === 'list' && field?.isMultiple}
                                disabled={!name} onChange={this.handleValueChange}
                            />
                            :
                            <>
                                {field?.type === 'date' ?
                                    <DatePicker name={i} value={value || ''} disabled={!name} forceTime={true} onChange={this.handleValueChange} />
                                    :
                                    <>
                                        {field?.type === 'number' ?
                                            <Form.Field
                                                control={Input} placeholder={i18n.t("Valeur")} type='number' step={field?.step || '0.1'}
                                                name={i} value={value || ''} autoComplete='off'
                                                disabled={!name}
                                                onChange={this.handleValueChange}
                                            />
                                            :
                                            <Form.Field
                                                control={Input} placeholder={i18n.t("Valeur")} name={i} value={value || ''}
                                                disabled={!name} autoComplete='off'
                                                onChange={this.handleValueChange}
                                            />}
                                    </>}
                            </>}
                    </Form.Group>
                </Segment>
            );

            const optionIndex = nameOptions.findIndex(option => option.value === name);
            if (optionIndex !== -1) nameOptions.splice(optionIndex, 1);
        }
        return fields.length > 0 && <Segment.Group style={{ padding: 0, marginTop: '5px' }}>{fields}</Segment.Group>;
    }

    renderAdjustedProperties = () => {
        const properties = { ...this.state.adjustedProperties };

        if (properties.essenceId) properties.gender = { value: null, reason: properties.essenceId.reason }
        if (properties.trunks) {
            properties.height = { value: properties.trunks[0]?.height || i18n.t("Donnée manquante"), reason: properties.trunks.reason };
            properties.circumference = { value: properties.trunks[0]?.circumference || i18n.t("Donnée manquante"), reason: properties.trunks.reason };
            properties.crownDiameter = { value: properties.trunks[0]?.crownDiameter || i18n.t("Donnée manquante"), reason: properties.trunks.reason };
        }
        if (properties.dominantEssenceId) properties.dominantGender = { value: null, reason: properties.dominantEssenceId.reason }

        const rows = [];
        this.getEfficientProperties(properties)
            .forEach(property => {
                const field = this.baseFields[property] || this.props.customFields.find(cf => cf.id === +property);
                const isCustomField = field && !this.baseFields[property];
                if (field?.isVisible || isCustomField) {
                    let value = properties[property].value;
                    if (isCustomField && value === 'empty') value = i18n.t("Donnée manquante");
                    else {
                        const options = this.getValueOptions(property);
                        if (options) value = options.find(option => option.value === value)?.text || i18n.t("Donnée manquante");
                        else if (field.type === 'boolean') value = value ? i18n.t("Oui") : i18n.t("Non");
                        else if (field.type === 'date') value = DatesUtil.getFormattedLocaleDateString(value) || i18n.t("Donnée manquante");
                    }

                    rows.push(
                        <Grid.Row columns={3} key={uuidv4()} divided style={{ paddingTop: '3px', paddingBottom: '3px' }}>
                            <Grid.Column>{field.label}</Grid.Column>
                            <Grid.Column>{value}</Grid.Column>
                            <Grid.Column>{properties[property].reason}</Grid.Column>
                        </Grid.Row>
                    );
                }
            });

        return rows;
    }

    getBaseFields = () => {
        const categories = { 'Arbre': 'trees', 'Espace vert': 'greenSpaces', 'Mobilier': 'furnitures' };

        const requiredFields = ProjectsUtil.getProjectRequiredFields(this.props.project);
        const publicFields = ProjectsUtil.getProjectPublicFields(this.props.project, this.props.projectCollaborators);
        const rf = requiredFields[categories[this.category]], pf = publicFields[categories[this.category]];
        const trunkCircumferenceUnit = this.props.project?.trunkCircumferenceUnit;
        const amenityFormulaType = this.props.project?.projectFormulaVersions.find(pfv => pfv.formulaId === 4)?.formulaType;

        return {
            customReference: { label: i18n.t("Référence personnalisée"), type: 'text', isVisible: rf.customReference && publicFields.main.references },
            place: { label: i18n.t("Lieu"), type: 'text', isVisible: rf.place && pf.place },
            tagId: { label: i18n.t("Tags"), type: 'list', isMultiple: true, isVisible: rf.tags && pf.tags },
            /* Arbres */
            // Booléens
            isEmpty: { label: i18n.t("Vide"), type: 'boolean', category: 'Arbre', isVisible: rf.isEmpty && pf.isEmpty, isDisabled: (p, np) => (p.isDead && np.isDead !== false) || (p.isStump && np.isStump !== false) },
            isDead: { label: i18n.t("Mort"), type: 'boolean', category: 'Arbre', isVisible: rf.isDead && pf.isDead, isDisabled: (p, np) => (p.isEmpty && np.isEmpty !== false) || (p.isStump && np.isStump !== false) },
            isStump: { label: i18n.t("Souche"), type: 'boolean', category: 'Arbre', isVisible: rf.isStump && pf.isStump, isDisabled: (p, np) => (p.isEmpty && np.isEmpty !== false) || (p.isDead && np.isDead !== false) },
            isIndexed: { label: i18n.t("Classé"), type: 'boolean', category: 'Arbre', isVisible: rf.isIndexed && pf.isIndexed, isDisabled: (p, np) => (p.isEmpty && np.isEmpty !== false) },
            isRemarkable: { label: i18n.t("Remarquable"), type: 'boolean', category: 'Arbre', isVisible: rf.isRemarkable && pf.isRemarkable, isDisabled: (p, np) => (p.isEmpty && np.isEmpty !== false) },
            // Textes
            observation: { label: i18n.t("Observation"), type: 'text', categories: ['Arbre', 'Espace verts'], isVisible: rf.observation && pf.observation },
            // Dates
            plantingDate: { label: i18n.t("Date de plantation"), type: 'date', category: 'Arbre', isVisible: rf.plantingDate && pf.plantingDate, isDisabled: (p, np) => (p.isEmpty && np.isEmpty !== false) || (p.isDead && np.isDead !== false) },
            // Nombres
            height: { label: i18n.t("Hauteur totale axe"), type: 'number', category: 'Arbre', isVisible: rf.trunks && pf.trunks },
            circumference: { label: trunkCircumferenceUnit === 'circumference' ? i18n.t("Circonférence axe") : i18n.t("Diamètre axe"), type: 'number', category: 'Arbre', isVisible: rf.trunks && pf.trunks },
            crownDiameter: { label: i18n.t("Diamètre couronne axe"), type: 'number', category: 'Arbre', isVisible: rf.trunks && pf.trunks },
            age: { label: i18n.t("Âge"), type: 'number', category: 'Arbre', step: 1, isVisible: rf.age && pf.age, isDisabled: (p, np) => (p.isEmpty && np.isEmpty !== false) || (p.isDead && np.isDead !== false) },
            // Listes déroulantes
            vernacularName: { label: i18n.t("Nom vernaculaire"), type: 'list', category: 'Arbre', isVisible: (_, pf) => pf.vernacularName, isDisabled: (p, np) => (p.isEmpty && np.isEmpty !== false) },
            gender: { label: i18n.t("Genre"), type: 'list', category: 'Arbre', isVisible: (_, pf) => pf.gender, isDisabled: (p, np) => (p.isEmpty && np.isEmpty !== false) },
            species: { label: i18n.t("Espèce"), type: 'list', category: 'Arbre', isVisible: rf.species && pf.species, isDisabled: (p, np) => (p.isEmpty && np.isEmpty !== false) },
            cultivar: { label: i18n.t("Cultivar"), type: 'list', category: 'Arbre', isVisible: rf.cultivar && pf.cultivar, isDisabled: (p, np) => (p.isEmpty && np.isEmpty !== false) },
            treePortId: { label: i18n.t("Port de l'arbre"), type: 'list', category: 'Arbre', isVisible: rf.treePort && pf.treePort, isDisabled: (p, np) => (p.isEmpty && np.isEmpty !== false) || (p.isStump && np.isStump !== false) },
            coverTypeId: { label: i18n.t("Type de couverture au sol"), type: 'list', category: 'Arbre', isVisible: rf.coverType && pf.coverType, isDisabled: (p, np) => (p.isEmpty && np.isEmpty !== false) || (p.isDead && np.isDead !== false) },
            vigorId: { label: i18n.t("Vigueur"), type: 'list', category: 'Arbre', isVisible: rf.vigor && pf.vigor, isDisabled: (p, np) => (p.isEmpty && np.isEmpty !== false) || (p.isDead && np.isDead !== false) || (p.isStump && np.isStump !== false) },
            riskId: { label: i18n.t("Risque"), type: 'list', category: 'Arbre', isVisible: rf.risk && pf.risk, isDisabled: (p, np) => (p.isEmpty && np.isEmpty !== false) || (p.isStump && np.isStump !== false) },
            tippingRiskId: { label: i18n.t("Risque de basculement/rupture"), type: 'list', category: 'Arbre', isVisible: rf.accurateRisk && pf.risk, isDisabled: (p, np) => (p.isEmpty && np.isEmpty !== false) || (p.isStump && np.isStump !== false) },
            organCaliberId: { label: i18n.t("Calibre de l'organe instable"), type: 'list', category: 'Arbre', isVisible: rf.accurateRisk && pf.risk, isDisabled: (p, np) => (p.isEmpty && np.isEmpty !== false) || (p.isStump && np.isStump !== false) },
            targetId: { label: i18n.t("Cible"), type: 'list', category: 'Arbre', isVisible: rf.accurateRisk && pf.risk, isDisabled: (p, np) => (p.isEmpty && np.isEmpty !== false) || (p.isStump && np.isStump !== false) },
            healthReviewId: { label: i18n.t("Cote sanitaire"), type: 'list', category: 'Arbre', isNumeric: true, isVisible: rf.healthReview && pf.healthReview, isDisabled: (p, np) => (p.isEmpty && np.isEmpty !== false) || (p.isDead && np.isDead !== false) || (p.isStump && np.isStump !== false) },
            ontogenicStageId: { label: i18n.t("Stade ontogénique"), type: 'list', category: 'Arbre', isNumeric: true, isVisible: rf.ontogenicStage && pf.ontogenicStage, isDisabled: (p, np) => (p.isEmpty && np.isEmpty !== false) || (p.isDead && np.isDead !== false) || (p.isStump && np.isStump !== false) },
            plantationCoefficientId: { label: i18n.t("Coefficient de plantation"), type: 'list', category: 'Arbre', isNumeric: true, isVisible: rf.plantationCoefficient && pf.plantationCoefficient, isDisabled: (p, np) => (p.isEmpty && np.isEmpty !== false) },
            situationCoefficientId: { label: i18n.t("Coefficient de situation"), type: 'list', category: 'Arbre', isNumeric: true, isVisible: rf.situationCoefficient && pf.situationCoefficient, isDisabled: (p, np) => (p.isEmpty && np.isEmpty !== false) },
            patrimonialCoefficientId: { label: i18n.t("Coefficient patrimonial"), type: 'list', category: 'Arbre', isNumeric: true, isVisible: amenityFormulaType === 'Wallonie' && rf.patrimonialCoefficient && pf.patrimonialCoefficient, isDisabled: (p, np) => (p.isEmpty && np.isEmpty !== false) },
            interactionId: { label: i18n.t("Interactions"), type: 'list', category: 'Arbre', isMultiple: true, isVisible: rf.interactions && pf.interactions, isDisabled: (p, np) => (p.isEmpty && np.isEmpty !== false) || (p.isDead && np.isDead !== false) },
            microHabitatId: { label: i18n.t("Dendro-microhabitats"), type: 'list', category: 'Arbre', isMultiple: true, isVisible: rf.microHabitats && pf.microHabitats, isDisabled: (p, np) => p.isEmpty && np.isEmpty !== false },
            ...treeOrgans.reduce((prevValue, { organ, label }) => ({
                ...prevValue,
                [`${organ}SymptomId`]: { label: `${i18n.t("Symptômes")} ${label}`, type: 'list', category: 'Arbre', isMultiple: true, isVisible: rf[`${organ}Symptoms`] && pf[`${organ}Symptoms`], isDisabled: (p, np) => p.isEmpty && np.isEmpty !== false },
                [`${organ}PathogenId`]: { label: `${i18n.t("Pathogènes")} ${label}`, type: 'list', category: 'Arbre', isMultiple: true, isVisible: rf[`${organ}Pathogens`] && pf[`${organ}Pathogens`], isDisabled: (p, np) => p.isEmpty && np.isEmpty !== false },
                [`${organ}PestId`]: { label: `${i18n.t("Ravageurs")} ${label}`, type: 'list', category: 'Arbre', isMultiple: true, isVisible: rf[`${organ}Pests`] && pf[`${organ}Pests`], isDisabled: (p, np) => p.isEmpty && np.isEmpty !== false },
                [`${organ}EpiphyteId`]: ['trunk', 'branch'].includes(organ) && { label: `${i18n.t("Épiphytes")} ${label}`, type: 'list', category: 'Arbre', isMultiple: true, isVisible: rf[`${organ}Epiphytes`] && pf[`${organ}Epiphytes`], isDisabled: (p, np) => p.isEmpty && np.isEmpty !== false },
            }), {}),
            numberOfTrunks: { label: i18n.t("Nombre d'axes"), type: 'number', category: 'Arbre', isVisible: rf.numberOfTrunks && pf.numberOfTrunks, isDisabled: (p, np) => (p.isEmpty && np.isEmpty !== false) || (p.isDead && np.isDead !== false) || (p.isStump && np.isStump !== false) },
            plantationTypeId: { label: i18n.t("Type de plantation"), type: 'list', category: 'Arbre', isVisible: rf.plantationType && pf.plantationType, isDisabled: (p, np) => (p.isDead && np.isDead !== false) },
            /* Espaces verts */
            // Booléens
            isTreeBase: { label: i18n.t("Pied d'arbre"), type: 'boolean', category: 'Espace vert', isVisible: rf.isTreeBase && pf.isTreeBase },
            // Textes
            placeExtra: { label: i18n.t("Libellé"), type: 'text', category: 'Espace vert', isVisible: rf.placeExtra && pf.placeExtra },
            detailedComposition: { label: i18n.t("Composition détaillée"), type: 'text', category: 'Espace vert', isVisible: rf.detailedComposition && pf.detailedComposition, isDisabled: (p, np) => np.dominantCompositionId === 0 || (!p.dominantCompositionId && !np.dominantCompositionId) },
            // Nombres
            annualMaintenanceFrequency: { label: i18n.t("Fréquence annuelle d'entretien"), type: 'number', category: 'Espace vert', step: 1, isVisible: rf.annualMaintenanceFrequency && pf.annualMaintenanceFrequency, isDisabled: (p, np) => (np.dominantCompositionId === 0 || (!p.dominantCompositionId && !np.dominantCompositionId)) || (np.managementClassId === 0 || (!p.managementClassId && !np.managementClassId)) },
            nbTrees: { label: i18n.t("Nombre d'arbres"), type: 'number', category: 'Espace vert', isVisible: rf.density && pf.density, isDisabled: (p, np) => p.dominantCompositionId !== 7 && np.dominantCompositionId !== 7 },
            density: { label: i18n.t("Densité"), type: 'number', category: 'Espace vert', isVisible: rf.density && pf.density, isDisabled: (p, np) => p.dominantCompositionId !== 7 && np.dominantCompositionId !== 7 },
            distanceBetweenTrunks: { label: i18n.t("Distance moyenne entre les troncs"), type: 'number', category: 'Espace vert', isVisible: rf.density && pf.density, isDisabled: (p, np) => p.dominantCompositionId !== 7 && np.dominantCompositionId !== 7 },
            averageHeight: { label: i18n.t("Hauteur moyenne"), type: 'number', category: 'Espace vert', isVisible: rf.averageHeight && pf.averageHeight, isDisabled: (p, np) => p.dominantCompositionId !== 7 && np.dominantCompositionId !== 7 },
            averageCircumference: { label: i18n.t("Circonférence moyenne des troncs"), type: 'number', category: 'Espace vert', isVisible: rf.averageCircumference && pf.averageCircumference, isDisabled: (p, np) => p.dominantCompositionId !== 7 && np.dominantCompositionId !== 7 },
            averageCrownDiameter: { label: i18n.t("Diamètre moyen des couronnes"), type: 'number', category: 'Espace vert', isVisible: rf.averageCrownDiameter && pf.averageCrownDiameter, isDisabled: (p, np) => p.dominantCompositionId !== 7 && np.dominantCompositionId !== 7 },
            // Listes déroulantes
            spaceFunctionId: { label: i18n.t("Fonction de l'espace"), type: 'list', category: 'Espace vert', isVisible: rf.spaceFunction && pf.spaceFunction },
            spaceTypeId: { label: i18n.t("Type d'espace"), type: 'list', category: 'Espace vert', isVisible: rf.spaceType && pf.spaceType },
            dominantCompositionId: { label: i18n.t("Composition dominante"), type: 'list', category: 'Espace vert', isVisible: rf.dominantComposition && pf.dominantComposition },
            managementClassId: { label: i18n.t("Classe de gestion"), type: 'list', category: 'Espace vert', isNumeric: true, isVisible: rf.managementClass && pf.managementClass },
            dominantVernacularName: { label: i18n.t("Nom vernaculaire dominant"), type: 'list', category: 'Espace vert', isVisible: rf.dominantEssence && pf.dominantEssence, isDisabled: (p, np) => p.dominantCompositionId !== 7 && np.dominantCompositionId !== 7 },
            dominantGender: { label: i18n.t("Genre dominant"), type: 'list', category: 'Espace vert', isVisible: rf.dominantEssence && pf.dominantEssence, isDisabled: (p, np) => p.dominantCompositionId !== 7 && np.dominantCompositionId !== 7 },
            dominantSpecies: { label: i18n.t("Espèce dominante"), type: 'list', category: 'Espace vert', isVisible: rf.dominantEssence && pf.dominantEssence, isDisabled: (p, np) => p.dominantCompositionId !== 7 && np.dominantCompositionId !== 7 },
            dominantCultivar: { label: i18n.t("Cultivar dominant"), type: 'list', category: 'Espace vert', isVisible: rf.dominantEssence && pf.dominantEssence, isDisabled: (p, np) => p.dominantCompositionId !== 7 && np.dominantCompositionId !== 7 },
            averageHealthReviewId: { label: i18n.t("Cote sanitaire moyenne"), type: 'list', category: 'Espace vert', isNumeric: true, isVisible: rf.averageHealthReview && pf.averageHealthReview, isDisabled: (p, np) => p.dominantCompositionId !== 7 && np.dominantCompositionId !== 7 },
            /* Mobilier */
            // Textes
            description: { label: i18n.t("Description"), type: 'text', category: 'Mobilier', isVisible: rf.description && pf.description },
            // Listes déroulantes
            conditionId: { label: i18n.t("État"), type: 'list', category: 'Mobilier', isVisible: rf.condition && pf.condition },
            typeId: { label: i18n.t("Type"), type: 'list', category: 'Mobilier', isVisible: rf.type && pf.type }
        };
    }

    getNameOptions = () => { // Retourne les filtres diponibles par catégorie
        let options = Object.keys(this.baseFields).map(property => {
            const baseField = this.baseFields[property];
            if (((!baseField.category && !baseField.categories) || baseField.category === this.category || baseField.categories?.includes(this.category)) && baseField.isVisible)
                return { text: baseField.label, value: property };
            return null;
        }).filter(o => o);

        options = options.sort((a, b) => { // On trie par ordre alphabétique
            const textA = a.text.toUpperCase();
            const textB = b.text.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });

        this.props.customFields
            .filter(cf => this.category === cf.category && cf.type !== 'formula')
            .forEach(customField => options.push({ text: customField.label, value: customField.id }));

        return options;
    }

    getValueOptions = (property) => { // Retourne la liste de choix des propriétés qui en possèdent une
        const { properties } = this.state;
        const amenityFormulaType = this.props.project?.projectFormulaVersions.find(pfv => pfv.formulaId === 4)?.formulaType;

        const { vernacularName, gender, species } = this.category === 'Arbre'
            ? { vernacularName: 'vernacularName', gender: 'gender', species: 'species' }
            : { vernacularName: 'dominantVernacularName', gender: 'dominantGender', species: 'dominantSpecies' };

        switch (property) {
            case 'vernacularName': case 'dominantVernacularName': return [
                { text: i18n.t("Donnée manquante"), value: 'empty' },
                ...[...new Set(this.props.essences
                    .filter(essence => essence.vernacularName)
                    .map(essence => essence.vernacularName))]
                    .map(vernacularName => { return { text: vernacularName, value: vernacularName } })
                    .sort((a, b) => { // On trie par ordre alphabétique
                        var textA = a.text.toUpperCase();
                        var textB = b.text.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    })
            ];
            case 'gender': case 'dominantGender': {
                const list = [
                    ...[...new Set(this.props.essences
                        .filter(essence => !properties[vernacularName] || essence.vernacularName === properties[vernacularName])
                        .map(essence => essence.gender))]
                        .map(gender => { return { text: gender, value: gender } })
                        .sort((a, b) => { // On trie par ordre alphabétique
                            const textA = a.text.toUpperCase(), textB = b.text.toUpperCase();
                            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                        })
                ];
                if (!properties.vernacularName) list.unshift({ text: i18n.t("Donnée manquante"), value: 'empty' });
                return list;
            }
            case 'species': case 'dominantSpecies': {
                const list = [
                    { text: i18n.t("Donnée manquante"), value: 'empty' },
                    ...[...new Set(this.props.essences
                        .filter(essence => essence.species && (!properties[vernacularName] || essence.vernacularName === properties[vernacularName]) && (!properties[gender] || essence.gender === properties[gender]))
                        .map(essence => essence.species))]
                        .map(species => { return { text: species, value: species } })
                        .sort((a, b) => { // On trie par ordre alphabétique
                            const textA = a.text.toUpperCase(), textB = b.text.toUpperCase();
                            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                        })
                ];
                if (!properties.vernacularName) list.unshift({ text: i18n.t("Donnée manquante"), value: 'empty' });
                return list;
            }
            case 'cultivar': case 'dominantCultivar': {
                const list = [
                    { text: i18n.t("Donnée manquante"), value: 'empty' },
                    ...[...new Set(this.props.essences
                        .filter(essence => essence.cultivar && (!properties[vernacularName] || essence.vernacularName === properties[vernacularName]) && (!properties[gender] || essence.gender === properties[gender]) && (!properties[species] || essence.species === properties[species]))
                        .map(essence => essence.cultivar))]
                        .map(cultivar => { return { text: cultivar, value: cultivar } })
                        .sort((a, b) => { // On trie par ordre alphabétique
                            const textA = a.text.toUpperCase(), textB = b.text.toUpperCase();
                            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                        })
                ];
                if (!properties.vernacularName) list.unshift({ text: i18n.t("Donnée manquante"), value: 'empty' });
                return list;
            }
            case 'treePortId': return [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.props.treePorts.map(treePort => ({ text: treePort.label, value: treePort.id }))];
            case 'coverTypeId': return [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.props.coverTypes.map(coverType => ({ text: coverType.label, value: coverType.id }))];
            case 'vigorId': return [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.props.vigors.map(vigor => ({ text: vigor.label, value: vigor.id }))];
            case 'healthReviewId': case 'averageHealthReviewId': return [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.props.healthReviews.map(healthReview => ({ text: healthReview.value + ' (' + healthReview.description + ')', value: healthReview.id }))];
            case 'ontogenicStageId': return [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.props.ontogenicStages.map(ontogenicStage => ({ text: ontogenicStage.value, value: ontogenicStage.id }))];
            case 'riskId': return [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.props.risks.map(risk => ({ text: risk.label, value: risk.id }))];
            case 'tippingRiskId': return [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.props.tippingRisks.map(tippingRisk => ({ text: tippingRisk.label, value: tippingRisk.id }))];
            case 'organCaliberId': return [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.props.organCalibers.map(organCaliber => ({ text: organCaliber.label, value: organCaliber.id }))];
            case 'targetId': return [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.props.targets.map(target => ({ text: target.label, value: target.id }))];
            case 'plantationTypeId': return [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.props.plantationTypes.map(plantationType => ({ text: plantationType.label, value: plantationType.id }))];
            case 'plantationCoefficientId': return [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.props.plantationCoefficients.filter(coefficient => coefficient[{ 'Wallonie': 'descriptionWln', 'Bruxelles': 'descriptionBxl', 'France': 'descriptionFr' }[amenityFormulaType]]).map(coefficient => ({ text: coefficient.value, value: coefficient.id }))];
            case 'situationCoefficientId': return [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.props.situationCoefficients.filter(coefficient => coefficient[{ 'Wallonie': 'descriptionWln', 'Bruxelles': 'descriptionBxl', 'France': 'descriptionFr' }[amenityFormulaType]]).map(coefficient => ({ text: coefficient.value, value: coefficient.id }))];
            case 'patrimonialCoefficientId': return [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.props.patrimonialCoefficients.map(patrimonialCoefficient => ({ text: patrimonialCoefficient.value, value: patrimonialCoefficient.id }))];
            case 'interactionId': return [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.props.interactions.map(interaction => ({ text: interaction.label, value: interaction.id }))];
            case 'microHabitatId': return [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.props.microHabitats.map(microHabitat => ({ text: microHabitat.label, value: microHabitat.id }))];
            case 'rootSymptomId': return [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.props.rootSymptoms.map(rootSymptom => ({ text: rootSymptom.label, value: rootSymptom.id }))];
            case 'collarSymptomId': return [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.props.collarSymptoms.map(collarSymptom => ({ text: collarSymptom.label, value: collarSymptom.id }))];
            case 'trunkSymptomId': return [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.props.trunkSymptoms.map(trunkSymptom => ({ text: trunkSymptom.label, value: trunkSymptom.id }))];
            case 'branchSymptomId': return [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.props.branchSymptoms.map(branchSymptom => ({ text: branchSymptom.label, value: branchSymptom.id }))];
            case 'leafSymptomId': return [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.props.leafSymptoms.map(leafSymptom => ({ text: leafSymptom.label, value: leafSymptom.id }))];
            case 'rootPathogenId': case 'collarPathogenId': case 'trunkPathogenId': case 'branchPathogenId': case 'leafPathogenId':
                return [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.props.pathogens.map(pathogen => ({ text: pathogen.label, value: pathogen.id }))];
            case 'rootPestId': case 'collarPestId': case 'trunkPestId': case 'branchPestId': case 'leafPestId':
                return [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.props.pests.map(pest => ({ text: pest.label, value: pest.id }))];
            case 'trunkEpiphyteId': case 'branchEpiphyteId':
                return [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.props.epiphytes.map(epiphyte => ({ text: epiphyte.label, value: epiphyte.id }))];
            case 'spaceFunctionId': return [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.props.spaceFunctions.map(spaceFunction => ({ text: spaceFunction.label, value: spaceFunction.id }))];
            case 'spaceTypeId': return [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.props.spaceTypes.map(spaceType => ({ text: spaceType.label, value: spaceType.id }))];
            case 'dominantCompositionId': return [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.props.dominantCompositions.map(dominantComposition => ({ text: dominantComposition.label, value: dominantComposition.id }))];
            case 'managementClassId': return [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.props.managementClasses.map(managementClass => ({ text: managementClass.value, value: managementClass.id }))];
            case 'conditionId': return [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.props.conditions.map(condition => ({ text: condition.label, value: condition.id }))];
            case 'typeId': return [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.props.furnitureTypes.map(furnitureType => ({ text: furnitureType.label, value: furnitureType.id }))];
            case 'tagId': return [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...(this.props.project?.tags || []).filter(tag => tag.category === this.category).map(tag => ({ text: tag.label, value: tag.id }))];
            case 'isEmpty': case 'isDead': case 'isStump': case 'isIndexed': case 'isRemarkable': case 'isTreeBase':
                return [{ text: i18n.t("Oui"), value: true }, { text: i18n.t("Non"), value: false }];
            default:
                const customField = this.props.customFields.find(cf => cf.id === property);
                if (customField?.type === 'boolean') return [{ text: i18n.t("Oui"), value: 'true' }, { text: i18n.t("Non"), value: 'false' }]
                else return customField?.dropdownCustomFieldValues?.map(dcfv => ({ text: dcfv.label, value: customField.isNumeric && !customField.isMultiple ? Number(dcfv.value) : String(dcfv.id) }));
        }
    }

    getEfficientProperties = (properties) => (
        Object.keys(properties)
            .filter(property => this.props.elementsToModify.some(element => {
                let value = !isNaN(property)
                    ? element[0].feature.properties.customFields?.[property] || 'empty' // Car quand ajusté c'est forcément pour retirer la propriété
                    : element[0].feature.properties[property];
                if (['height', 'circumference', 'crownDiameter'].includes(property)) {
                    const biggestTrunk = TreesUtil.getBiggestTrunk(element[0].feature.properties.trunks);
                    value = biggestTrunk?.[property] || element[0].feature.properties.trunks?.[0]?.[property] || i18n.t("Donnée manquante");
                }
                return JSON.stringify(value) !== JSON.stringify(properties[property].value);
            }))
    );

    handleNameChange = (_, { name: index, value }) => {
        this.setState(prevState => {
            const names = [...prevState.names], values = [...prevState.values], properties = { ...prevState.properties };
            if (values[index]) {
                values[index] = null;
                delete properties[names[index]];
            }
            names[index] = value;
            return { names, values, properties };
        });
    }

    handleValueChange = (_, { name: index, value }) => {
        this.setState(prevState => {
            const property = prevState.names[index];
            const names = [...prevState.names], values = [...prevState.values], properties = { ...prevState.properties };
            values[index] = value;
            properties[property] = value;

            const essenceProperties = ['vernacularName', 'dominantVernacularName', 'gender', 'dominantGender', 'species', 'dominantSpecies', 'cultivar', 'dominantCultivar'];
            for (let i = 0; i < essenceProperties.length - 2; i += 2)
                if ([essenceProperties[i], essenceProperties[i + 1]].includes(property))
                    [essenceProperties[i + 2], essenceProperties[i + 3]].forEach(property => {
                        const index = names.indexOf(property);
                        if (index !== -1) { values[index] = null; delete properties[property]; }
                    });

            return {
                values, properties,
                fieldNumber: index === prevState.fieldNumber - 1 && value !== undefined ? prevState.fieldNumber + 1 : prevState.fieldNumber
            };
        });
    }

    removeField = (index) => {
        this.setState(({ names, values, properties, fieldNumber }) => {
            delete properties[names[index]];
            names.splice(index, 1);
            if (values[index]) values.splice(index, 1);
            if (index === (fieldNumber - 1)) fieldNumber++;
            return { names, values, properties, fieldNumber: (fieldNumber - 1) || 1 };
        });
    }

    adjustProperties = () => { // Détermine les répercussions des modifications effectuées par l'utilisateur
        const amenityFormulaType = this.props.project?.projectFormulaVersions.find(pfv => pfv.formulaId === 4)?.formulaType;

        const adjustProperties = (properties, propertiesToModify, reason) => {
            Object.keys(propertiesToModify).forEach(property => {
                properties[property] = { value: propertiesToModify[property], reason };
            });
        };

        this.setState(({ properties }) => {
            const adjustedProperties = {};

            // Gestion particulière statut & champs personnalisés qui dépendent du statut
            if (properties.isEmpty) ['isDead', 'isStump'].forEach(property => { if (properties[property]) delete properties[property] });
            if (properties.isDead && properties.isStump) delete properties.isStump;
            if (this.category === 'Arbre') {
                if (properties.isEmpty) {
                    const fields = this.props.customFields.filter(cf => cf.category === 'Arbre' && !cf.forEmpty);
                    if (fields?.length) adjustProperties(adjustedProperties, fields.reduce((prevValue, field) => ({ ...prevValue, [field.id]: 'empty' }), {}), `${i18n.t("Vide")} : ${i18n.t("Oui")}`);
                }
                if (properties.isDead) {
                    const fields = this.props.customFields.filter(cf => cf.category === 'Arbre' && !cf.forDead);
                    if (fields?.length) adjustProperties(adjustedProperties, fields.reduce((prevValue, field) => ({ ...prevValue, [field.id]: 'empty' }), {}), `${i18n.t("Mort")} : ${i18n.t("Oui")}`);
                }
                if (properties.isStump) {
                    const fields = this.props.customFields.filter(cf => cf.category === 'Arbre' && !cf.forStump);
                    if (fields?.length) adjustProperties(adjustedProperties, fields.reduce((prevValue, field) => ({ ...prevValue, [field.id]: 'empty' }), {}), `${i18n.t("Souche")} : ${i18n.t("Oui")}`);
                }
            }

            Object.keys(properties)
                .sort((a, b) => ['isEmpty', 'isDead', 'isStump'].includes(a) ? 1
                    : ['isEmpty', 'isDead', 'isStump'].includes(b) ? -1
                        : this.state.names.indexOf(a) - this.state.names.indexOf(b))
                .reverse()
                .forEach(property => {
                    const value = properties[property];
                    if (!adjustedProperties[property])
                        switch (property) {
                            case 'isEmpty':
                                if (value)
                                    adjustProperties(adjustedProperties, {
                                        essenceId: 0, vigorId: 0, healthReviewId: 0, riskId: 0, tippingRiskId: 0, organCaliberId: 0, targetId: 0, plantationCoefficientId: 0,
                                        situationCoefficientId: 0, patrimonialCoefficientId: 0, isIndexed: false, isRemarkable: false, coverTypeId: 0, interactionId: [], microHabitatId: [],
                                        ...treeOrgans.reduce((prevValue, { organ }) => ({ ...prevValue, [`${organ}SymptomId`]: [], [`${organ}PathogenId`]: [], [`${organ}PestId`]: [], [`${organ}EpiphyteId`]: [] }), {}),
                                        age: 0, plantingDate: null, ontogenicStageId: 0, treePortId: 0, numberOfTrunks: 0, trunks: [], isDead: false, isStump: false
                                    }, `${i18n.t("Vide")} : ${i18n.t("Oui")}`);
                                break;
                            case 'isDead':
                                if (value)
                                    adjustProperties(adjustedProperties, {
                                        plantationTypeId: 0, vigorId: 1, healthReviewId: 1, coverTypeId: 0, interactionId: [], age: 0, plantingDate: null, ontogenicStageId: 0, treePortId: 0, isStump: false
                                    }, `${i18n.t("Mort")} : ${i18n.t("Oui")}`);
                                break;
                            case 'isStump':
                                if (value === true)
                                    adjustProperties(adjustedProperties, {
                                        ontogenicStageId: 0, vigorId: 1, riskId: 1, tippingRiskId: 1, organCaliberId: 1, targetId: 1, treePortId: 0, healthReviewId: 1,
                                    }, `${i18n.t("Souche")} : ${i18n.t("Oui")}`);
                                break;
                            case 'isIndexed':
                                if (amenityFormulaType) {
                                    const propertiesToModify = {};
                                    if (amenityFormulaType === 'Wallonie') propertiesToModify.patrimonialCoefficientId = value ? 6 : 0;
                                    else propertiesToModify.plantationCoefficientId = value ? amenityFormulaType === 'Bruxelles' ? 12 : 17 : 0;
                                    adjustProperties(adjustedProperties, propertiesToModify, `${i18n.t("Classé")} : ${value ? i18n.t("Oui") : i18n.t("Non")}`);
                                }
                                break;
                            case 'isRemarkable':
                                if (amenityFormulaType === 'Wallonie')
                                    adjustProperties(adjustedProperties, { patrimonialCoefficientId: value ? 3 : 0 }, `${i18n.t("Remarquable")} : ${value ? i18n.t("Oui") : i18n.t("Non")}`);
                                break;
                            case 'plantationCoefficientId':
                                if (amenityFormulaType !== 'Wallonie')
                                    adjustProperties(adjustedProperties, { isIndexed: [12, 17].includes(value) }, `${i18n.t("Coefficient de plantation")} : ${this.props.plantationCoefficients.find(pc => pc.id === value)?.value || ''}`);
                                break;
                            case 'patrimonialCoefficientId':
                                if (amenityFormulaType === 'Wallonie')
                                    adjustProperties(adjustedProperties, { isIndexed: value === 6, isRemarkable: value === 3 }, `${i18n.t("Coefficient patrimonial")} : ${this.props.patrimonialCoefficients.find(pc => pc.id === value)?.value || ''}`);
                                break;
                            case 'age':
                                adjustProperties(adjustedProperties, { plantingDate: value ? subYears(new Date(), value) : null }, `${i18n.t("Âge")} : ${value}`);
                                break;
                            case 'plantingDate':
                                adjustProperties(adjustedProperties, { age: value ? intervalToDuration({ start: value, end: new Date() }).years : null }, `${i18n.t("Date de plantation")} : ${DatesUtil.getFormattedLocaleDateString(value)}`);
                                break;
                            case 'dominantCompositionId':
                                if (value !== 7)
                                    adjustProperties(adjustedProperties, properties = {
                                        nbTrees: 0, density: 0, distanceBetweenTrunks: 0, dominantEssenceId: 0, averageHealthReviewId: 0, averageHeight: 0, averageCircumference: 0,
                                        averageCrownDiameter: 0
                                    }, `${i18n.t("Massif arboré")} : ${i18n.t("Non")}`);
                            default: break;
                        }
                });

            return { adjustedProperties: Object.keys(adjustedProperties).length > 0 ? adjustedProperties : null };
        }, () => { if (!this.state.adjustedProperties || !this.getEfficientProperties(this.state.adjustedProperties).length) this.handleSubmit(); });
    }

    mergeProperties = (properties, propertiesToModify) => { // Applique les modifications aux propriétés d'un élément
        const newProperties = { ...properties };
        propertiesToModify = { ...propertiesToModify };

        // Gestion du champs particulier "Essence"
        if (['Arbre', 'Espace vert'].includes(this.category)) {
            const { essenceId, vernacularName, gender, species, cultivar } = this.category === 'Arbre'
                ? { essenceId: 'essenceId', vernacularName: 'vernacularName', gender: 'gender', species: 'species', cultivar: 'cultivar' }
                : { essenceId: 'dominantEssenceId', vernacularName: 'dominantVernacularName', gender: 'dominantGender', species: 'dominantSpecies', cultivar: 'dominantCultivar' };

            if ([vernacularName, gender, species, cultivar].some(property => propertiesToModify[property])) {
                if (!this.baseFields[gender].isDisabled(properties, propertiesToModify)) {
                    if ([propertiesToModify[vernacularName], propertiesToModify[gender]].includes('empty'))
                        newProperties[essenceId] = 0;
                    else {
                        const essence = this.props.essences.find(essence => essence.id === properties[essenceId]);
                        const newEssence = this.props.essences.find(x =>
                            (!propertiesToModify[vernacularName]
                                && x.gender === (propertiesToModify[gender] || essence?.gender || null)
                                && x.species === (propertiesToModify[species] === 'empty' ? null : (propertiesToModify[species] || (!propertiesToModify[gender] && essence?.species) || null))
                                && x.cultivar === ([propertiesToModify[species], propertiesToModify[cultivar]].includes('empty') ? null : (propertiesToModify[cultivar] || (!propertiesToModify[gender] && !propertiesToModify[cultivar] && essence?.cultivar) || null)))
                            || (propertiesToModify[vernacularName] && x.vernacularName === propertiesToModify[vernacularName])
                        );
                        if (newEssence) newProperties[essenceId] = newEssence.id;
                    }
                }
                [vernacularName, gender, species, cultivar].forEach(property => delete propertiesToModify[property]);
            }
        }

        if (propertiesToModify.trunks?.length)
            for (let i = 0; i < propertiesToModify.trunks.length; i++)
                propertiesToModify.trunks[i].propertiesId = newProperties.treeId;

        Object.keys(propertiesToModify)
            .forEach(property => {
                const field = this.baseFields[property] || this.props.customFields.find(cf => cf.id === +property);
                const isCustomField = field && !this.baseFields[property];
                if (!isCustomField
                    ? (!field?.isDisabled || !field.isDisabled(properties, propertiesToModify))
                    : (this.category !== 'Arbre' || ['Empty', 'Dead', 'Stump'].every(property => field[`for${property}`] || !properties[`is${property}`] || propertiesToModify[`is${property}`] === false)))
                    if (['height', 'circumference', 'crownDiameter'].includes(property)) {
                        const value = ['height', 'crownDiameter'].includes(property) ? propertiesToModify[property] * 100 : +propertiesToModify[property];
                        const biggestTrunk = TreesUtil.getBiggestTrunk(newProperties.trunks);
                        if (biggestTrunk) biggestTrunk[property] = value;
                        else if (newProperties.trunks?.length) newProperties.trunks[0][property] = value;
                        else {
                            newProperties.trunks = [{ id: uuidv4(), propertiesId: properties.treeId, projectId: this.props.project.id, height: null, circumference: null, crownDiameter: null, order: 0, [property]: value }]
                            newProperties.numberOfTrunks = propertiesToModify.numberOfTrunks > 1 ? propertiesToModify.numberOfTrunks : 1;
                        }
                    } else {
                        if (!isNaN(property)) {
                            if (!newProperties.customFields) newProperties.customFields = {};
                            newProperties.customFields[property] = field.isMultiple ? propertiesToModify[property].join(',') : propertiesToModify[property];
                        } else newProperties[property] = propertiesToModify[property];
                    }
            });

        return newProperties;
    }

    handleSubmit = () => {
        const { properties, adjustedProperties } = this.state;
        const propertiesToModify = { ...properties, ...Object.keys(adjustedProperties || {}).reduce((prevValue, property) => ({ ...prevValue, [property]: adjustedProperties[property].value }), {}) };

        const callback = (legendName) => {
            this.props.showLoader(false);
            this.props.updateLegend(i18n.t(legendName));
            if (legendName === i18n.t("Arbres")) this.props.updateHeatmaps({ updateBaseRadius: true });
            this.props.hideForm(true);
        };

        const categories = {
            'Arbre': { loaderMessage: i18n.t("Mise à jour des arbres en cours..."), updateFn: (modifiedFeatures) => UpdatesUtil.bulkUpdateTrees(this.props.elementsToModify, modifiedFeatures, this.props.fieldList, this.props.project.id, this.props.treesLayer, 'updating', this.props.webSocketHubs, { thematicMaps: this.props.project.thematicMaps }).finally(() => callback(i18n.t("Arbres"))) },
            'Espace vert': { loaderMessage: i18n.t("Mise à jour des espaces verts en cours..."), updateFn: (modifiedFeatures) => UpdatesUtil.bulkUpdateGreenSpaces(this.props.elementsToModify.map(element => element[0]), modifiedFeatures, this.props.fieldList, this.props.project.id, this.props.greenSpacesLayer.activeChild, 'updating', { webSocketHubs: this.props.webSocketHubs, thematicMaps: this.props.project.thematicMaps }).finally(() => callback(i18n.t("Espaces verts"))) },
            'Mobilier': { loaderMessage: i18n.t("Mise à jour des mobiliers en cours..."), updateFn: (modifiedFeatures) => UpdatesUtil.bulkUpdateFurnitures(this.props.elementsToModify, modifiedFeatures, this.props.fieldList, this.props.project.id, this.props.furnituresLayer.activeChild, 'updating', this.props.webSocketHubs, { thematicMaps: this.props.project.thematicMaps }).finally(() => callback(i18n.t("Mobilier urbain"))) }
        };
        const { loaderMessage, updateFn } = categories[this.category];

        if (this.category === 'Arbre') {
            if (propertiesToModify.trunks) ['height', 'circumference', 'crownDiameter'].forEach(property => delete propertiesToModify[property]);
            if (propertiesToModify.essenceId === 0) ['vernacularName', 'gender', 'species', 'cultivar'].forEach(property => delete propertiesToModify[property]);
        }

        if (this.category === 'Espace vert') {
            if (propertiesToModify.dominantEssenceId === 0) ['dominantVernacularName', 'dominantGender', 'dominantSpecies', 'dominantCultivar'].forEach(property => delete propertiesToModify[property]);
            ['averageHeight', 'averageCrownDiameter'].forEach(property => { if (propertiesToModify[property]) propertiesToModify[property] *= 100; })
        }

        Object.keys(propertiesToModify).forEach(property => { // Gestion des "Donnée manquante"
            if (propertiesToModify[property] === 'empty' || (Array.isArray(propertiesToModify[property]) && propertiesToModify[property].includes('empty'))) {
                const field = this.baseFields[property] || this.props.customFields.find(cf => cf.id === +property);
                if (field.type === 'list' && !['vernacularName', 'gender', 'species', 'cultivar', 'dominantVernacularName', 'dominantGender', 'dominantSpecies', 'dominantCultivar'].includes(property))
                    propertiesToModify[property] = field.isMultiple ? [] : 0;
            }
        });

        const modifiedFeatures = this.props.elementsToModify.map(layers => ({ ...layers[0].feature, properties: this.mergeProperties(layers[0].feature.properties, propertiesToModify) }));
        this.props.showLoader(true, 'modal', loaderMessage);
        updateFn(modifiedFeatures);
    }
}

const mapStateToProps = (state) => ({
    essences: state.essences,
    treePorts: state.treePorts,
    coverTypes: state.coverTypes,
    vigors: state.vigors,
    healthReviews: state.healthReviews,
    ontogenicStages: state.ontogenicStages,
    risks: state.risks,
    tippingRisks: state.tippingRisks,
    organCalibers: state.organCalibers,
    targets: state.targets,
    plantationTypes: state.plantationTypes,
    plantationCoefficients: state.plantationCoefficients,
    situationCoefficients: state.situationCoefficients,
    patrimonialCoefficients: state.patrimonialCoefficients,
    interactions: state.interactions,
    microHabitats: state.microHabitats,
    rootSymptoms: state.rootSymptoms,
    collarSymptoms: state.collarSymptoms,
    trunkSymptoms: state.trunkSymptoms,
    branchSymptoms: state.branchSymptoms,
    leafSymptoms: state.leafSymptoms,
    pathogens: state.pathogens,
    pests: state.pests,
    epiphytes: state.epiphytes,
    spaceFunctions: state.spaceFunctions,
    spaceTypes: state.spaceTypes,
    dominantCompositions: state.dominantCompositions,
    runoffCoefficients: state.runoffCoefficients,
    managementClasses: state.managementClasses,
    conditions: state.conditions,
    furnitureTypes: state.furnitureTypes,
    project: state.project,
    projectCollaborators: state.projectCollaborators,
    webSocketHubs: state.webSocketHubs,
    customFields: [...state.customFields, ...state.organizationCustomFields || [], ...(state.projectsCustomFields[state.project?.id] || [])]
});

export default connect(mapStateToProps)(PropertiesModificationForm);