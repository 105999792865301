export const setCurrentAction = (action) => async (dispatch, getState) => {
    dispatch({ type: 'SET_CURRENT_ACTION', data: action });
    return Promise.resolve();
};
export const setRequest = (action) => async (dispatch, getState) => {
    dispatch({ type: 'SET_REQUEST', data: action });
    return Promise.resolve();
};

export const setIsOnline = (action) => async (dispatch, getState) => {
    dispatch({ type: 'SET_IS_ONLINE', data: action });
    return Promise.resolve();
};

export const setSync = (action) => async (dispatch, getState) => {
    dispatch({ type: 'SET_SYNC', data: action });
    return Promise.resolve();
};

export const setIsKeyboardOpen = (action) => async (dispatch, getState) => {
    dispatch({ type: 'SET_IS_KEYBOARD_OPEN', data: action });
    return Promise.resolve();
};

export const setIsDarkTheme = (action) => async (dispatch, getState) => {
    dispatch({ type: 'SET_IS_DARK_THEME', data: action });
    return Promise.resolve();
};

export const setTutorialTour = (action) => async (dispatch, getState) => {
    dispatch({ type: 'SET_TUTORIAL_TOUR', data: action });
    return Promise.resolve();
};

export const setFormulas = (formulas) => async (dispatch, getState) => {
    dispatch({ type: 'SET_FORMULAS', data: formulas });
    return Promise.resolve();
};

export const setProjections = (formulas) => async (dispatch, getState) => {
    dispatch({ type: 'SET_PROJECTIONS', data: formulas });
    return Promise.resolve();
};

export const setWebSocketHubs = (webSocketHubs) => async (dispatch, getState) => {
    dispatch({ type: 'SET_WEB_SOCKET_HUBS', data: webSocketHubs });
    return Promise.resolve();
};

export const setButtonState = (buttonName, state) => async (dispatch, getState) => {
    dispatch({ type: 'SET_BUTTON_STATE', data: { buttonName, state } });
    return Promise.resolve();
};

export const toggleToolbarExpand = () => async (dispatch, getState) => {
    dispatch({ type: 'TOGGLE_TOOLBAR_EXPAND' });
    return Promise.resolve();
};

export const setCurrencies = (formulas) => async (dispatch, getState) => {
    dispatch({ type: 'SET_CURRENCIES', data: formulas });
    return Promise.resolve();
};

export const setRedirectURL = (redirectURL) => async (dispatch, getState) => {
    dispatch({ type: 'SET_REDIRECT_URL', data: redirectURL });
    return Promise.resolve();
};