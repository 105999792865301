import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// Composants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareRootAlt, faPlusSquare, faWifiSlash } from '@fortawesome/pro-solid-svg-icons';
// Librairies
import i18n from '../../locales/i18n';
// Redux
import { connect } from 'react-redux';
import { setIsDarkTheme } from '../../actionCreators/appActions';
import { setHomeInfos } from '../../actionCreators/usersActions';
// Semantic UI
import { Menu, Icon, Label, Popup, Dropdown } from 'semantic-ui-react'
// Styles
import '../../styles/rainbow.css';
import Tasks from '../Utils/Tasks';

class AdminNavbar extends Component {
    render() {
        const subscription = this.props.logged && this.props.activeOrganization?.subscription;
        const firstName = this.props.userInfos?.firstName;
        const sync = this.props.sync?.state && !isNaN(this.props.sync.nbDone) && !isNaN(this.props.sync.nbMax) ? this.props.sync : null;
        const iconStyle = { fontSize: '16px', width: '18px', marginRight: '10px' };

        return (
            <>
                <Menu style={{ margin: 0, minHeight: '60px', fontWeight: 'none' }} id='navbar'>
                    <Menu.Item onClick={subscription?.name !== 'Commercial' ? () => this.props.changePanelContentType('Dashboard') : null}>
                        <Icon name='shield' size='big' /> {i18n.t("Panneau d'administration")}
                    </Menu.Item>
                    {subscription?.name !== 'Commercial' &&
                        <>
                            <Dropdown item simple trigger={<><Icon name='users' /> {i18n.t("Gestion des comptes")}</>}>
                                <Dropdown.Menu style={{ zIndex: 1100 }}>
                                    <Dropdown item trigger={<><Icon name='building' /> {i18n.t("Organisations")}</>}>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={(e) => { e.stopPropagation(); this.props.changePanelContentType('ManageOrganizations'); }}>
                                                <Icon name='pencil' /> {i18n.t("Gérer")}
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Dropdown item trigger={<><Icon name='address book outline' /> {i18n.t("Utilisateurs")}</>}>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={(e) => { e.stopPropagation(); this.props.changePanelContentType('ManageUsers'); }}>
                                                <Icon name='pencil' /> {i18n.t("Gérer")}
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={(e) => { e.stopPropagation(); this.props.changePanelContentType('AddUser'); }}>
                                                <Icon name='add' /> {i18n.t("Créer")}
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Dropdown item trigger={<><Icon name='trophy' /> {i18n.t("Licences")}</>}>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={(e) => { e.stopPropagation(); this.props.changePanelContentType('ManageSubscriptions'); }}>
                                                <Icon name='pencil' /> {i18n.t("Gérer")}
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={(e) => { e.stopPropagation(); this.props.changePanelContentType('AddSubscription'); }}>
                                                <Icon name='add' /> {i18n.t("Créer")}
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Dropdown item trigger={<><Icon name='folder' /> {i18n.t("Projets")}</>}>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={(e) => { e.stopPropagation(); this.props.changePanelContentType('ManageProjects'); }}>
                                                <Icon name='pencil' /> {i18n.t("Gérer")}
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Dropdown item trigger={<><Icon name='mail' /> {i18n.t("Invitations")}</>}>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={(e) => { e.stopPropagation(); this.props.changePanelContentType('ManageInvitations'); }}>
                                                <Icon name='pencil' /> {i18n.t("Gérer")}
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Dropdown item trigger={<><Icon name='bell' /> {i18n.t("Notifications")}</>}>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={(e) => { e.stopPropagation(); this.props.changePanelContentType('ManageNotifications'); }}>
                                                <Icon name='pencil' /> {i18n.t("Gérer")}
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={(e) => { e.stopPropagation(); this.props.changePanelContentType('AddNotification'); }}>
                                                <Icon name='add' /> {i18n.t("Créer")}
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown item simple trigger={<><Icon name='database' /> {i18n.t("Gestion des données")}</>}>
                                <Dropdown.Menu style={{ zIndex: 1100 }}>
                                    <Dropdown item trigger={<><Icon name='leaf' /> {i18n.t("Essences")}</>}>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={(e) => { e.stopPropagation(); this.props.changePanelContentType('ManageEssences'); }}>
                                                <Icon name='pencil' /> {i18n.t("Gérer")}
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={(e) => { e.stopPropagation(); this.props.changePanelContentType('AddEssence'); }}>
                                                <Icon name='add' /> {i18n.t("Créer")}
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Dropdown item trigger={<><Icon name='bolt' /> {i18n.t("Actions")}</>}>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={(e) => { e.stopPropagation(); this.props.changePanelContentType('ManageActions'); }}>
                                                <Icon name='pencil' /> {i18n.t("Gérer")}
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={(e) => { e.stopPropagation(); this.props.changePanelContentType('AddAction'); }}>
                                                <Icon name='add' /> {i18n.t("Créer")}
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Dropdown item trigger={<><FontAwesomeIcon icon={faSquareRootAlt} style={iconStyle} /> {i18n.t("Formules")}</>}>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={(e) => { e.stopPropagation(); this.props.changePanelContentType('ManageFormulas'); }}>
                                                <Icon name='pencil' /> {i18n.t("Gérer")}
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={(e) => { e.stopPropagation(); this.props.changePanelContentType('AddFormulaVersion'); }}>
                                                <Icon name='add' /> {i18n.t("Créer")}
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Dropdown item style={{ width: '250px' }} trigger={<><FontAwesomeIcon icon={faPlusSquare} style={iconStyle} /> {i18n.t("Champs supplémentaires")}</>}>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={(e) => { e.stopPropagation(); this.props.changePanelContentType('ManageCustomFields'); }}>
                                                <Icon name='pencil' /> {i18n.t("Gérer")}
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={(e) => { e.stopPropagation(); this.props.changePanelContentType('AddCustomField'); }}>
                                                <Icon name='add' /> {i18n.t("Créer")}
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Menu.Item onClick={() => this.props.changePanelContentType('Statistics')}>
                                <Icon name='chart area' /> {i18n.t("Statistiques")}
                            </Menu.Item>
                        </>}
                    <Menu.Menu position='right'>
                        {!this.props.isOnline ?
                            <Menu.Item>
                                <FontAwesomeIcon icon={faWifiSlash} color='red' />
                            </Menu.Item>
                            :
                            <>
                                {sync &&
                                    <Menu.Item>
                                        <Popup position='top center' content={`${i18n.t("Synchronisation en cours...")} (${sync.nbDone}/${sync.nbMax})`}
                                            trigger={<Icon name='sync alternate' color='green' size='large' style={{ margin: 0 }} />}
                                        />
                                    </Menu.Item>}
                            </>}
                        {this.props.logged &&
                            <>
                                <Dropdown item simple trigger={
                                    <>
                                        <Icon name='user' /> {firstName}
                                        {subscription &&
                                            <Label horizontal className={subscription.shortName !== 'Dev' ? null : 'rainbow-bg'}
                                                style={subscription.shortName !== 'Dev'
                                                    ? { background: subscription.color, boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', marginLeft: '10px' }
                                                    : { boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', marginLeft: '10px', color: 'white' }} title={subscription.name}>
                                                {subscription.shortName}
                                            </Label>}
                                    </>
                                }>
                                    <Dropdown.Menu style={{ zIndex: 1100 }}>
                                        <Dropdown.Item as={Link} to='/' onClick={() => { if (this.props.activeItem !== 'home') this.props.setHomeInfos(null); this.props.showProjectList(false, { renderDefaultMap: false }); }}>
                                            <Icon name='home' /> {i18n.t("Accueil")}
                                        </Dropdown.Item>
                                        <Dropdown.Item as={Link} to='/organizationPanel'>
                                            <Icon name='building' /> {i18n.t("Mon organisation")}
                                        </Dropdown.Item>
                                        <Dropdown.Item as={Link} to='/accountPanel'>
                                            <Icon name='user' /> {i18n.t("Mon compte")}
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={(e) => {
                                            e.stopPropagation();
                                            this.props.setIsDarkTheme(!this.props.isDarkTheme).then(() => localStorage.setItem('isDarkTheme', this.props.isDarkTheme));
                                        }}>
                                            {this.props.isDarkTheme
                                                ? <><Icon name='sun' /> {i18n.t("Mode jour")}</>
                                                : <><Icon name='moon' /> {i18n.t("Mode nuit")}</>}
                                        </Dropdown.Item>
                                        <Dropdown.Item as={Link} to='/login' style={{ display: this.props.logged ? 'flex' : 'none' }}
                                            onClick={() => { this.props.logUser(null) }}>
                                            <Icon name='sign out' /> {i18n.t("Déconnexion")}
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </>}
                    </Menu.Menu>
                </Menu>
                <div id='tasksStatus'></div>
            </>
        );
    }

    showNotifications = () => {
        this.setState(prevState => ({ notificationsRead: !prevState.notificationsRead }));
    }
}

// Fonctions qui permettent de faire le lien avec le store Redux
const mapStateToProps = (state) => {
    return {
        activeOrganization: state.activeOrganization,
        userInfos: state.userInfos,
        isOnline: state.isOnline,
        sync: state.sync,
        isDarkTheme: state.isDarkTheme
    };
};

const mapDispatchToProps = {
    setIsDarkTheme,
    setHomeInfos
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminNavbar);