import React, { Component } from 'react';
// Composants
import { Dimmer, Loader, Search } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InfiniteScroll from 'react-infinite-scroll-component';
// Librairies
import * as Icons from '@fortawesome/pro-solid-svg-icons';
import i18n from '../../locales/i18n';

const ITEMS_PER_ROW = 5;
const ITEMS_PER_LOAD = 500;

class IconPicker extends Component {
    state = {
        isLoading: false,
        isOpen: false,
        iconName: null,
        search: '',
        icons: []
    };

    render() {
        const { disabled } = this.props;
        const { isLoading, isOpen, iconName, search, icons } = this.state;

        return (
            <>
                {iconName &&
                    <div className='icon-picker'>
                        <div className={`icon-preview${disabled ? ' disabled' : ''}`} title={i18n.t("Sélectionner un icône")} onClick={this.toggleMenu}>
                            <FontAwesomeIcon icon={iconName} />
                        </div>
                        {isOpen && !disabled &&
                            <div id='icon-picker-container' style={{ ...this.getStyle() }}>
                                <Search placeholder={i18n.t("Rechercher...")} onSearchChange={this.handleSearchChange} showNoResults={false} size='mini' />
                                <Dimmer active={isLoading}>
                                    <Loader />
                                </Dimmer>
                                <div id='scrollableDiv' className='icon-list'>
                                    <InfiniteScroll
                                        dataLength={this.state.icons.length / 5}
                                        next={this.fetchMoreData}
                                        style={{ display: 'flex', flexDirection: 'column' }}
                                        hasMore={this.state.icons.length < this.icons.filter(icon => icon.iconName.includes(search)).length}
                                        loader={<h5 style={{ width: '100%', textAlign: 'center', marginTop: '10px' }}>{i18n.t("Chargement...")}</h5>}
                                        scrollableTarget='scrollableDiv'
                                    >
                                        {this.renderIcons()}
                                    </InfiniteScroll>
                                    {!icons.length &&
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%', width: '100%', pointerEvents: 'none' }}>
                                            <FontAwesomeIcon icon={Icons.faSearch} size='2x' style={{ marginTop: 'auto' }} />
                                            <h4 style={{ marginBottom: 'auto' }}>{i18n.t("Aucun résultat trouvé")}</h4>
                                        </div>}
                                </div>
                                <p style={{ marginLeft: 'auto', marginTop: '5px', marginBottom: 0, fontSize: '12px' }}>{icons.length}/{this.icons.filter(icon => icon.iconName.includes(search)).length}</p>
                            </div>}
                    </div>}
            </>
        );
    }

    componentDidMount = () => {
        const { iconName } = this.props;

        this.icons = [...new Set(Object
            .keys(Icons)
            .filter(key => key !== "fas" && key !== "prefix")
            .map(icon => Icons[icon]))];

        this.setState({ iconName, icons: this.icons.slice(0, ITEMS_PER_LOAD) });
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.iconName && this.props.iconName && prevProps.iconName !== this.props.iconName)
            this.setState({ iconName: this.props.iconName });
    }

    fetchMoreData = () => {
        const { icons, search } = this.state;
        if (icons.length >= this.icons.length) return;
        setTimeout(() => this.setState({ icons: this.icons.filter(icon => icon.iconName.includes(search)).slice(0, icons.length + ITEMS_PER_LOAD) }), 250);
    }

    renderIcons = () => {
        const { iconName, icons } = this.state;
        const nbRows = icons.length / ITEMS_PER_ROW;
        const rows = [];
        for (let i = 0; i < nbRows; i++)
            rows.push(icons.slice(i * ITEMS_PER_ROW, (i + 1) * ITEMS_PER_ROW));

        return rows.map((elements) => (
            <div style={{ display: 'flex', width: '100%' }}>
                {elements.map((icon, index) => {
                    const isSelected = icon.iconName === iconName;
                    return (
                        <div className={`icon-item${isSelected ? ' active' : ''}`} title={!isSelected && i18n.t("Sélectionner l'icône \"{{icon}}\"", { icon: icon.iconName })} onClick={() => { if (!isSelected) this.selectIcon(icon) }}>
                            <FontAwesomeIcon key={index} icon={icon.iconName} />
                        </div>
                    );
                })}
            </div>
        ));
    }

    getStyle = () => {
        switch (this.props.direction) {
            case 'topleft': return { bottom: '30px', right: 0 };
            case 'topright': return { bottom: '30px', left: 0 };
            case 'bottomright': return { top: '30px', left: 0 };
            default: return { top: '30px', right: 0 };
        }
    }

    toggleMenu = () => {
        this.setState(prevState => ({ isOpen: !this.props.disabled && !prevState.isOpen, search: '', icons: prevState.isOpen ? this.icons.slice(0, ITEMS_PER_LOAD) : prevState.icons }));
    }

    handleSearchChange = (_, { value }) => {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.setState({ isLoading: true });
            setTimeout(() => {
                const icons = this.icons.filter(icon => !value.length || icon.iconName.includes(value)).slice(0, ITEMS_PER_LOAD);
                this.setState({ isLoading: false, icons, search: value });
            }, 100);
        }, 500);
    }

    selectIcon = (icon) => {
        if (this.props.closeOnSelect) {
            this.setState({ isLoading: false, iconName: icon.iconName });
            if (this.props.handleIconChange) this.props.handleIconChange(icon);
            if (this.props.closeOnSelect) this.toggleMenu();
        } else {
            this.setState({ isLoading: true });
            setTimeout(() => {
                this.setState({ isLoading: false, iconName: icon.iconName });
                if (this.props.handleIconChange) this.props.handleIconChange(icon);
            }, 100);
        }
    }
}

export default IconPicker;