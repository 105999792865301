import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AppSettings from '../../AppSettings';
// Composants
import Notifications from '../Utils/Notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightToBracket, faBuilding, faCaretDown, faCaretRight, faCircle, faFileAlt, faFolder, faFolders, faGavel, faHouse, faLock, faMoon, faPenToSquare, faShieldPlus, faSignOut, faSun, faSync, faUser, faWifi, faWifiSlash } from '@fortawesome/pro-solid-svg-icons'
import { faCircle as faCircleRegular } from '@fortawesome/pro-regular-svg-icons'
import Tasks from '../../components/Utils/Tasks';
import { Menu, Label, Popup, Dropdown, Flag } from 'semantic-ui-react'
// Librairies
import i18n from '../../locales/i18n';
import { connect } from 'react-redux';
import { setIsDarkTheme } from '../../actionCreators/appActions';
import { setUserInfos } from '../../actionCreators/usersActions';
import { setProjectShortcut } from '../../actionCreators/componentsActions';
import { setHomeInfos } from '../../actionCreators/usersActions';
import { setProjectInvitations } from '../../actionCreators/projectsActions';
// Ressources
import banner from '../../resources/images/banner.png';
import bannerDT from '../../resources/images/banner-dt.png';
import urbasensePNG from '../../resources/pngs/urbasense-banner.png';
// Services
import UsersService from '../../services/UsersService';
// Styles
import '../../styles/rainbow.css';
// Utils
import { showToast } from '../../utils/ToastsUtil';
import LanguagesUtil from '../../utils/LanguagesUtil';
import RightsUtil from '../../utils/RightsUtil';
import OrganizationsService from '../../services/OrganizationsService';

class BrowserNavbar extends Component {
    state = {
        connectionState: 2,
        showPopup: false,
        showOrganizationList: false
    }

    render() {
        const { logged, activeItem, userInfos, activeOrganization, project, projectCollaborators, isDarkTheme, currentAction, isPreview, theme, logoUrl, organizations } = this.props;
        const { showOrganizationList } = this.state;
        const blobInfos = AppSettings.getBlobInfos();

        const subscription = logged && activeOrganization?.subscription;
        const firstName = userInfos?.firstName;
        const culture = LanguagesUtil.getUserCulture(this.props.userInfos);
        const sync = this.props.sync?.state && !isNaN(this.props.sync.nbDone) && !isNaN(this.props.sync.nbMax) ? this.props.sync : null;
        const connectionState = this.state.connectionState;
        const borderRight = isDarkTheme ? '1px solid rgba(255,255,255,.15)' : '1px solid rgba(0,0,0,.15)';
        const isUrbasense = AppSettings.isUrbasenseUrl();

        return (
            <Menu style={{ margin: 0, height: '60px', pointerEvents: isPreview && 'none' }} id='navbar'>
                {!isUrbasense && project?.theme?.logoName?.length && projectCollaborators.find(pc => pc.projectRole.type === 'owner').user.subscriptions[0].subscription.branding ?
                    <a target='_blank' rel='noopener noreferrer' href={project.theme.website} style={{ height: '100%', padding: '5px', borderRight }}>
                        <img style={{ height: '100%', width: '100%' }} alt={project.theme.label} src={`${blobInfos.endpoint}${blobInfos.containers.photos}/${project.theme.logoName}`} />
                    </a>
                    : <img style={{ height: '100%', width: 'auto', borderRight, padding: isUrbasense && '0 5px' }} alt='Grality logo' src={!isUrbasense ? (logoUrl || (isDarkTheme ? bannerDT : banner)) : urbasensePNG} />}
                {this.props.logged &&
                    <Menu.Item
                        as={Link} to='/' name='home' active={activeItem === 'home'} id='SdTQ8Z1Y'
                        onClick={() => { if (activeItem !== 'home') this.props.setHomeInfos(null); this.props.showProjectList(false, { renderDefaultMap: false }); }}
                    >
                        <FontAwesomeIcon icon={faHouse} style={{ marginRight: '10px' }} />{i18n.t("Accueil")}
                    </Menu.Item>}
                {!logged ?
                    <>
                        {project &&
                            <Menu.Item>
                                <FontAwesomeIcon icon={faFileAlt} style={{ marginRight: '10px' }} />{project.label}
                            </Menu.Item>}
                        {/* <Menu.Item as={Link} to='/subscriptions' active={activeItem === 'subscriptions'}>
                            <FontAwesomeIcon icon={faCircleDollar} style={{ marginRight: '10.5px' }} /> {i18n.t("Abonnements")}
                        </Menu.Item> */}
                    </>
                    :
                    <>
                        <Menu.Item
                            as={activeItem !== 'projectMap' && activeItem !== 'home' ? Link : null}
                            to='/' id='EAbEwOUy'
                            onClick={() => {
                                if (currentAction !== 'modifyingProjectSurroundings') this.props.showProjectList(true);
                                else showToast('finish_resizing');
                            }}
                        >
                            <FontAwesomeIcon icon={faFolders} style={{ marginRight: '10px' }} />{i18n.t("Mes projets")}
                        </Menu.Item>
                        {this.props.currentFolderState &&
                            <Menu.Item
                                onClick={() => {
                                    if (currentAction !== 'modifyingProjectSurroundings') this.props.showProjectList(true, { showCurrentFolder: true });
                                    else showToast('finish_resizing');
                                }}
                            >
                                <FontAwesomeIcon icon={faFolder} style={{ marginRight: '10px' }} />{this.props.currentFolderState.currentFolder?.label}
                            </Menu.Item>}
                        {project?.type === 'project' ?
                            <Dropdown
                                item simple disabled={isPreview} style={{ backgroundColor: this.props.isDarkTheme ? 'var(--black-90)' : 'rgba(0,0,0,.05)' }}
                                trigger={<>
                                    <FontAwesomeIcon icon={faFileAlt} style={{ marginRight: '10px' }} />{project.label}
                                </>}
                            >
                                <Dropdown.Menu style={{ zIndex: 1000 }}>{this.renderOptions()}</Dropdown.Menu>
                            </Dropdown>
                            : project && <Menu.Item>
                                <FontAwesomeIcon icon={faFolder} style={{ marginRight: '10px' }} />{project?.label || theme?.label || 'Projet'}
                            </Menu.Item>}
                    </>}
                <Menu.Menu position='right'>
                    {connectionState < 2 && !sync ?
                        <Popup
                            open={this.state.showPopup}
                            trigger={
                                <Menu.Item onClick={() => this.setState({ showPopup: !this.state.showPopup })}>
                                    <FontAwesomeIcon icon={!connectionState ? faWifiSlash : faWifi} color={!connectionState ? 'red' : connectionState === 1 ? 'orange' : 'green'} />
                                </Menu.Item>
                            }
                            position='bottom center'
                            content={!connectionState
                                ? i18n.t("Pas d'accès internet, vos données seront synchronisées au retour de la connexion")
                                : i18n.t("La connexion est faible, il est recommandé de travailler en mode hors ligne")}
                            style={{ zIndex: 700 }}
                        />
                        : sync &&
                        <Menu.Item>
                            <Popup
                                position='top center' content={`${i18n.t("Synchronisation en cours...")} (${sync.nbDone}/${sync.nbMax})`}
                                trigger={<FontAwesomeIcon icon={faSync} size='2x' color='green' />}
                            />
                        </Menu.Item>}
                    <Dropdown item simple trigger={<><Flag name={LanguagesUtil.getCultureFlagName(culture)} /> {culture.split('-')[0].toUpperCase()}</>}>
                        <Dropdown.Menu style={{ zIndex: 1200 }}>
                            <Dropdown.Item active={culture?.toLowerCase().includes('fr')} onClick={() => this.changeLanguage('fr-BE')}>
                                <Flag name='fr' /> FR
                            </Dropdown.Item>
                            <Dropdown.Item active={culture?.toLowerCase().includes('nl')} onClick={() => this.changeLanguage('nl-BE')}>
                                <Flag name='nl' /> NL
                            </Dropdown.Item>
                            <Dropdown.Item active={culture?.toLowerCase().includes('en')} onClick={() => this.changeLanguage('en-US')}>
                                <Flag name='gb' /> EN
                            </Dropdown.Item>
                            <Dropdown.Item active={culture?.toLowerCase().includes('es')} onClick={() => this.changeLanguage('es-ES')}>
                                <Flag name='es' /> ES
                            </Dropdown.Item>
                            <Dropdown.Item active={culture?.toLowerCase().includes('zh')} onClick={() => this.changeLanguage('zh-CHT')}>
                                <Flag name='tw' /> ZH
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    {!logged &&
                        <>
                            {!isUrbasense &&
                                <Menu.Item onClick={() => {
                                    this.props.setIsDarkTheme(!isDarkTheme).then(() => localStorage.setItem('isDarkTheme', !isDarkTheme));
                                }}>
                                    {isDarkTheme
                                        ? <><FontAwesomeIcon icon={faSun} style={{ marginRight: '10px' }} />{i18n.t("Mode jour")}</>
                                        : <><FontAwesomeIcon icon={faMoon} style={{ marginRight: '10px' }} />{i18n.t("Mode nuit")}</>}
                                </Menu.Item>}
                            <Menu.Item as={Link} to='/signup' active={activeItem === 'signup'}>
                                <FontAwesomeIcon icon={faPenToSquare} style={{ marginRight: '10px' }} />{i18n.t("Inscription")}
                            </Menu.Item>
                            <Menu.Item as={Link} to='/login' active={activeItem === 'login'}>
                                <FontAwesomeIcon icon={faArrowRightToBracket} style={{ marginRight: '10px' }} />{i18n.t("Connexion")}
                            </Menu.Item>
                        </>}
                    {logged &&
                        <>
                            {!isPreview && <>
                                <Tasks />
                                <Notifications />
                            </>}
                            <Dropdown item simple id='Yj5V0rzR' trigger={<div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div>
                                    <span style={{ fontWeight: 'bold' }}>{firstName}</span>
                                    {subscription &&
                                        <Label
                                            horizontal className={subscription.shortName !== 'Dev' ? null : 'rainbow-bg'}
                                            style={subscription.shortName !== 'Dev'
                                                ? { background: subscription.color, boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', marginLeft: '10px', padding: '4px' }
                                                : { boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', marginLeft: '10px', color: 'white', padding: '4px' }} title={subscription.name}
                                        >
                                            {subscription.shortName}
                                        </Label>}
                                </div>
                                {activeOrganization &&
                                    <span className='no-themed' style={{ marginTop: '3px', fontSize: '10pt', color: 'var(--grey-60)' }}>{activeOrganization.label}</span>}
                            </div>}>
                                <Dropdown.Menu style={{ zIndex: 1200 }}>
                                    {['Dev', 'Admin', 'Com'].includes(subscription?.shortName) &&
                                        <Dropdown.Item as={Link} to='/adminPanel' active={activeItem === 'adminPanel'}>
                                            <FontAwesomeIcon icon={faGavel} style={{ marginRight: '10px' }} />{i18n.t("Administration")}
                                        </Dropdown.Item>}
                                    {organizations?.length > 1 && <>
                                        <Dropdown.Item style={{ display: 'flex' }} onClick={() => this.setState(prevState => ({ showOrganizationList: !prevState.showOrganizationList }))}>
                                            <span style={{ marginRight: 'auto', fontWeight: 'bold' }}>{activeOrganization.label}</span>
                                            <FontAwesomeIcon icon={showOrganizationList ? faCaretDown : faCaretRight} style={{ marginLeft: '10px', justifySelf: 'flex-end', width: '9px' }} />
                                        </Dropdown.Item>
                                        {showOrganizationList && organizations.map(organization => (
                                            <Dropdown.Item className='organization-item' disabled={organization.id === activeOrganization.id} onClick={() => this.changeOrganization(organization)}>
                                                <span style={{ paddingLeft: '9px', display: 'flex', alignItems: 'center' }}>
                                                    <FontAwesomeIcon icon={organization.id === activeOrganization.id ? faCircle : faCircleRegular} style={{ fontSize: '7px', marginRight: '8px' }} />{organization.label}
                                                </span>
                                            </Dropdown.Item>
                                        ))}
                                    </>}
                                    <Dropdown.Item as={Link} to='/organizationPanel/general' active={activeItem === 'organizationPanel'}>
                                        <FontAwesomeIcon icon={faBuilding} style={{ marginRight: '10px' }} />{i18n.t("Mon organisation")}
                                    </Dropdown.Item>
                                    <Dropdown.Item as={Link} to='/accountPanel/general' active={activeItem === 'accountPanel'}>
                                        <FontAwesomeIcon icon={faUser} style={{ marginRight: '10px' }} />{i18n.t("Mon compte")}
                                    </Dropdown.Item>
                                    {/* <Dropdown.Item as={Link} to='/subscriptions' active={activeItem === 'subscriptions'}>
                                        <FontAwesomeIcon icon={faCircleDollar} style={{ marginRight: '10.5px' }} /> {i18n.t("Abonnements")}
                                    </Dropdown.Item> */}
                                    {!isUrbasense &&
                                        <Dropdown.Item onClick={(e) => {
                                            e.stopPropagation();
                                            this.props.setIsDarkTheme(!isDarkTheme).then(() => localStorage.setItem('isDarkTheme', !isDarkTheme));
                                        }}>
                                            {isDarkTheme
                                                ? <><FontAwesomeIcon icon={faSun} style={{ marginRight: '10px' }} />{i18n.t("Mode jour")}</>
                                                : <><FontAwesomeIcon icon={faMoon} style={{ marginRight: '10px' }} />{i18n.t("Mode nuit")}</>}
                                        </Dropdown.Item>}
                                    <Dropdown.Item
                                        as={Link} to='/login' style={{ display: logged ? 'flex' : 'none' }}
                                        active={activeItem === 'login'} id='lgMC9pEX' onClick={() => { this.props.logUser(null) }}
                                    >
                                        <FontAwesomeIcon icon={faSignOut} style={{ marginRight: '10px' }} />{i18n.t("Déconnexion")}
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </>}
                </Menu.Menu>
            </Menu>
        );
    }

    componentDidMount = () => {
        const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
        if (connection) {
            this.checkConnectionSpeed(connection);
            connection.onchange = () => this.checkConnectionSpeed(connection);
        }
    }

    checkConnectionSpeed = (connection) => {
        const connectionState = this.state.connectionState;
        const downlink = connection.downlink;
        let shouldUpdate = false;

        switch (connectionState) {
            case 0: if (downlink > 0) shouldUpdate = true; break;
            case 1: if (!downlink || downlink >= 1) shouldUpdate = true; break;
            case 2: if (downlink < 1) shouldUpdate = true; break;
            default: break;
        }

        if (shouldUpdate) {
            this.setState({
                connectionState: !downlink ? 0 : downlink < 1 ? 1 : 2,
                showPopup: true
            }, () => setTimeout(() => this.setState({ showPopup: false }), 5000));
        }
    }

    showNotifications = () => {
        this.setState(prevState => ({ notificationsRead: !prevState.notificationsRead }));
    }

    changeLanguage = (culture) => {
        if (this.props.userInfos) {
            UsersService.changeLanguage(culture).then(response => {
                this.props.setUserInfos({ ...this.props.userInfos, language: response.data });
                LanguagesUtil.clearLists();
                i18n.changeLanguage(culture);
                window.location.reload();
            });
        } else {
            LanguagesUtil.clearLists();
            i18n.changeLanguage(culture);
            window.location.reload();
        }
    }

    changeOrganization = (organization) => {
        OrganizationsService.updateActiveOrganization(organization.id).then((response) => {
            if (response) window.location.reload();
        });
    }

    renderOptions = () => {
        const { activeOrganization, rights, loginAsData, isOnline, project } = this.props;
        const projectSubscription = project.organization.subscription;
        const isEditable = (rights.parameters || rights.publicFields || rights.requiredFields || rights.formulas) && isOnline;
        const areRightsChangeable = rights.rights && projectSubscription.nbCollaborators && isOnline;
        const isImportable = RightsUtil.canImportGlobally(rights) && projectSubscription.import && activeOrganization?.subscription.import && isOnline && !loginAsData?.readOnly;
        const isExportable = RightsUtil.canExportGlobally(rights) && projectSubscription.export && activeOrganization?.subscription.export && isOnline;
        const isFileGalleryAvailable = projectSubscription.filesSharing;

        return (<>
            <Dropdown.Item
                icon={projectSubscription.nbCollaborators ? 'users' : 'lock'} text={i18n.t("Gérer les collaborateurs")}
                disabled={!areRightsChangeable} onClick={() => this.changeModalContentType('CollaboratorsForm', 'Gestion des collaborateurs', this.props.project, areRightsChangeable)}
            />
            <Dropdown.Item disabled={!areRightsChangeable} onClick={() => this.changeModalContentType('RoleList', 'Gestion des rôles', this.props.project, areRightsChangeable)}>
                <FontAwesomeIcon icon={projectSubscription.nbCollaborators ? faShieldPlus : faLock} style={{ marginRight: '12px' }} />{i18n.t("Gérer les rôles")}
            </Dropdown.Item>
            <Dropdown.Item
                icon={projectSubscription.import ? 'upload' : 'lock'} text={i18n.t("Importer des données")}
                disabled={!isImportable} onClick={() => this.changeModalContentType('ImportForm', i18n.t("Import de données"), this.props.project, isImportable)}
            />
            <Dropdown.Item
                icon={projectSubscription.export ? 'download' : 'lock'} text={i18n.t("Exporter des données")}
                disabled={!isExportable} onClick={() => this.changeModalContentType('ExportForm', i18n.t("Export de données"), this.props.project, isExportable)}
            />
            <Dropdown.Item
                icon={projectSubscription.filesSharing ? 'file alternate' : 'lock'} text={i18n.t("Fichiers")}
                disabled={!isFileGalleryAvailable} onClick={() => this.changeModalContentType('ProjectFilesGallery', i18n.t("Fichiers"), this.props.project, isFileGalleryAvailable)}
            />
            <Dropdown.Item icon='setting' text={i18n.t("Paramètres")} disabled={!isEditable} onClick={() => this.changeModalContentType('ProjectModificationForm', 'Paramètres du projet', this.props.project, isEditable)} />
        </>);
    }

    changeModalContentType = (formType, title, project, canAccess) => {
        if (canAccess) this.props.setProjectShortcut({ formType, title, project });
    }
}

const mapStateToProps = (state) => {
    return {
        activeOrganization: state.activeOrganization,
        userInfos: state.userInfos,
        project: state.project,
        currentAction: state.currentAction,
        isOnline: state.isOnline,
        sync: state.sync,
        isDarkTheme: state.isDarkTheme,
        projectCollaborators: state.projectCollaborators,
        rights: state.rights,
        loginAsData: state.loginAsData,
        currentFolderState: state.currentFolderState,
        organizations: state.organizations
    };
};

const mapDispatchToProps = {
    setIsDarkTheme,
    setUserInfos,
    setProjectShortcut,
    setHomeInfos,
    setProjectInvitations
};

export default connect(mapStateToProps, mapDispatchToProps)(BrowserNavbar);