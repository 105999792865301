import React, { Component } from 'react';
// Composants
import { Button, Dimmer, Grid, Loader, Message } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Librairies
import { connect } from 'react-redux';
import i18n from '../../locales/i18n';
import { setProjects } from '../../actionCreators/projectsActions';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { faFileAlt, faFolder, faFolderOpen, faHome, faLock, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { faDiagramSankey } from '@fortawesome/pro-duotone-svg-icons';
// Services
import ProjectsService from '../../services/ProjectsService';
// Utils
import StylesUtil from '../../utils/StylesUtil';
import ProjectsUtil from '../../utils/ProjectsUtil';

class CompareProjectsTree extends Component {
    state = {
        isLoading: false,
        activeIndexes: [null]
    }

    render() {
        return (
            <Dimmer
                id='move-base-projects-dimmer' active style={{ ...StylesUtil.getMapStyles().dimmerStyle, position: 'fixed', top: 0, left: 0, width: '100%', height: '100vh', zIndex: 9999 }}
                onClick={({ target }) => { if (target.classList.contains('dimmer') && !this.state.isLoading) this.props.cancel(); }}
            >
                <Grid style={{ height: isMobileOnly && '100%', width: isMobileOnly && '100%' }}>
                    <Grid.Row style={{ height: '100%', width: '100%' }} verticalAlign='middle'>
                        <Grid.Column textAlign='left' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                            {this.state.isLoading &&
                                <Dimmer active>
                                    <Loader content={i18n.t("Chargement des projets en cours...")} />
                                </Dimmer>}
                            <Message className='input-popup-form' style={{ width: isMobileOnly ? '100%' : '500px', height: isMobileOnly && '100%', overflowX: 'auto' }}>
                                <Message.Header style={{ textAlign: 'center' }}>{i18n.t("Comparer avec")}</Message.Header>
                                <Message.Content style={{ display: 'flex', flexDirection: 'column', marginTop: '30px', flex: 1, alignItems: 'flex-start' }}>
                                    {this.renderTreeStructure()}
                                    <Button color='grey' onClick={this.props.cancel} style={{ marginTop: 'auto', width: isMobile && '100%' }}>
                                        <FontAwesomeIcon icon={faTimesCircle} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                                    </Button>
                                </Message.Content>
                            </Message>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Dimmer>
        );
    }

    componentDidMount = () => {
        if (!this.props.projects) {
            this.setState({ isLoading: true });
            ProjectsService.getUserBaseProjects().then(response => {
                if (!response) response = [];
                else {
                    for (const index in response)
                        ProjectsUtil.assignProjectFormulaVersionsType(response[index], this.props.formulas);

                    if (this.props.project?.fileInfos?.length) {
                        const index = response.findIndex(x => x.id === this.props.project.id);
                        if (index !== -1) response[index].fileInfos = this.props.project.fileInfos;
                    }

                    this.props.setProjects(response);
                }
                this.setState({ isLoading: false });
            });
        }
    }

    renderTreeStructure = () => {
        const { project, projects, isDarkTheme } = this.props;
        const { activeIndexes } = this.state;
        let recommendedProjects = project.recommendedProjects || [];
        if (recommendedProjects.length && projects?.length)
            recommendedProjects = recommendedProjects
                .filter(recommendedProject => !recommendedProject.isDeleting)
                .map(recommendedProject => ProjectsUtil.getBaseProject(recommendedProject.id, recommendedProject.path, projects));

        return (
            <div style={{ marginBottom: '10px', width: '100%', color: isDarkTheme ? 'rgba(255, 255, 255, 0.75)' : 'grey' }}>
                <div className='tree-structure' style={{ marginBottom: '10px', height: '400px', overflowY: 'auto' }}>
                    {recommendedProjects.length > 0 && <>
                        <h4 style={{ margin: '0 0 5px 0' }}><FontAwesomeIcon icon={faDiagramSankey} style={{ marginRight: '5px' }} />{i18n.t("Recommandations")}</h4>
                        <div className='tree-structure-item' style={{ overflow: 'hidden', marginBottom: '10px' }}>
                            {recommendedProjects.length && projects?.length && this.renderBaseProjectsStructure(recommendedProjects, 1, [], null, true)}
                        </div>
                    </>}
                    <h4 style={{ margin: '0 0 5px 0' }}><FontAwesomeIcon icon={faFolderOpen} style={{ marginRight: '5px' }} />{i18n.t("Arborescence")}</h4>
                    <div className='tree-structure-item' style={{ overflow: 'hidden' }}>
                        <div className='item' style={{ display: 'flex' }}>
                            <div style={{ flex: 1, cursor: 'pointer' }}>
                                <FontAwesomeIcon icon={faHome} style={{ marginRight: '5px' }} />{i18n.t("Racine")}
                            </div>
                        </div>
                        {projects?.length && activeIndexes.includes(null) && this.renderBaseProjectsStructure(projects, 1)}
                    </div>
                </div>
            </div>
        );
    }

    renderBaseProjectsStructure = (baseProjects = [], level, indexes = [], subscription = null, showRecommendations = false) => {
        const { activeIndexes } = this.state;

        return baseProjects.map(baseProject => {
            const subBaseProjects = [...(baseProject.childFolders || []), ...(baseProject.projects || [])];
            const isRootFolder = this.props.projects.find(project => project.id === baseProject.id) ? true : false;
            const areSubFoldersLoaded = baseProject.type === 'project' || !isRootFolder || baseProject.childFolders || baseProject.projects ? true : false;
            const projectSubscription = subscription || baseProject.organization.subscription;
            const canAccessToStatistics = projectSubscription.statistics;
            const isOpened = baseProject.id === this.props.project?.id;
            const title = !canAccessToStatistics ? i18n.t("Upgradez votre licence pour débloquer cette fonctionnalité")
                : (baseProject.type === 'project' && !isOpened) ? i18n.t("Cliquez pour sélectionner ce projet")
                    : (baseProject.type === 'folder' && areSubFoldersLoaded && !subBaseProjects.length) ? i18n.t("Le contenu du dossier est vide")
                        : (baseProject.type === 'folder' && !areSubFoldersLoaded) ? i18n.t("Le contenu du dossier n'a pas été téléchargé")
                            : '';
            const icon = !canAccessToStatistics ? faLock : baseProject.type === 'project' ? faFileAlt : faFolder;
            const isActive = activeIndexes.includes(baseProject.id);
            const isRecommendation = baseProject.recommendedProjectId > 0 && baseProject.recommendedProjectId === this.props.project?.id;

            return (!isOpened && (showRecommendations || !isRecommendation) &&
                <div key={baseProject.id} className={`tree-structure-item${(!areSubFoldersLoaded || !canAccessToStatistics) ? ' disabled' : ''}`} style={{ marginLeft: `${level * 5}px`, overflow: 'hidden' }}>
                    <div className='item' style={{ display: 'flex' }} onClick={() => { if (baseProject.type === 'project' && canAccessToStatistics) { this.props.cancel(); this.props.compareProjectWith(baseProject); } }}>
                        <div
                            title={title} style={{ flex: 1, cursor: 'pointer' }}
                            onClick={() => {
                                const newActiveIndexes = activeIndexes.includes(baseProject.id) ? activeIndexes.filter(ai => ai !== baseProject.id) : [...activeIndexes, baseProject.id];
                                this.setState({ activeIndexes: newActiveIndexes });
                            }}
                        >
                            <FontAwesomeIcon icon={icon} style={{ marginRight: '5px' }} />{baseProject.label}
                        </div>
                    </div>
                    {subBaseProjects.length > 0 && isActive && this.renderBaseProjectsStructure(subBaseProjects, level + 1, [...indexes, baseProject.id], projectSubscription, showRecommendations)}
                </div>
            );
        });
    }
}

const mapStateToProps = (state) => {
    return {
        isOnline: state.isOnline,
        isDarkTheme: state.isDarkTheme,
        projects: state.projects,
        project: state.project,
        formulas: state.formulas,
        webSocketHubs: state.webSocketHubs
    };
};

const mapDispatchToProps = {
    setProjects
}

export default connect(mapStateToProps, mapDispatchToProps)(CompareProjectsTree);