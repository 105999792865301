import React, { Component } from 'react';
// Composants
import { Grid, Form, Select, Input, Divider } from 'semantic-ui-react';
import InputWithAction from '../../Utils/InputWithAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import i18n from '../../../locales/i18n';
import { connect } from 'react-redux';
import { isMobileOnly } from 'react-device-detect';
import { faLock, faPenField, faPlus } from '@fortawesome/pro-solid-svg-icons';
// Utils
import FormattersUtil from '../../../utils/FormattersUtil';
import DatePicker from '../../Utils/DatePicker';

class ExtraStep extends Component {
    state = {
        custom: {
            customFieldId: 0,
            value: 0
        },
        error: {
            hidden: true,
            messages: [],
            customFieldId: false,
            value: false
        },
        extraFields: [''],
        customFields: []
    };

    render() {
        const { project, properties, projectCollaborators, activeOrganization, category, defaultFieldCategories } = this.props;
        const { customFields } = this.state;

        const projectCustomFields = project?.projectCustomFields || [];
        let availableExtraFields = customFields.filter(cf => cf.category === category && cf.type !== 'formula' && !projectCustomFields.find(pcf => pcf.customFieldId === cf.id));
        if (category === 'Arbre')
            availableExtraFields = availableExtraFields.filter(aef => !(
                (properties.isEmpty && !aef.forEmpty) ||
                (properties.isDead && !aef.forDead) ||
                (properties.isStump && !aef.forStump)
            ));
        const areCustomFieldsAvailable = (project.organization || activeOrganization).subscription.customFields;

        const fieldCategories = [
            ...(project?.fieldCategories || []),
            defaultFieldCategories.find(dfc => dfc.category === category && dfc.label === 'Autres')
        ];
        if (category === 'Arbre') fieldCategories.unshift(defaultFieldCategories.find(dfc => dfc.category === category && dfc.label === 'Indicateurs'));
        const fieldCategoriesToRender = fieldCategories.filter(fieldCategory => fieldCategory.category === category && projectCustomFields.find(pcf => pcf.fieldCategoryId === fieldCategory.id));

        return (
            <div id='cat--extra'>
                {areCustomFieldsAvailable && (fieldCategoriesToRender.length || availableExtraFields.length) ?
                    <>
                        {fieldCategoriesToRender.map(fieldCategory => {
                            const renderedFields = this.props.renderFields(fieldCategory);
                            return renderedFields.length > 0 &&
                                <Grid style={{ margin: '14px 0 0 0', border: `solid 2px ${fieldCategory.color}`, borderRadius: '6px' }}>
                                    {!isMobileOnly &&
                                        <Grid.Row style={{ display: 'flex', padding: '14px 14px 0 14px', color: fieldCategory.color, fontWeight: 'bold', fontSize: '14pt' }}>
                                            <FontAwesomeIcon icon={fieldCategory.icon} style={{ alignSelf: 'center', marginRight: '5px' }} />
                                            <span className='no-themed'>{fieldCategory.label}</span>
                                        </Grid.Row>}
                                    {renderedFields}
                                </Grid>;
                        })}
                        {availableExtraFields.length > 0 &&
                            <Grid style={{ margin: '14px 0 0 0', border: 'solid 2px transparent', borderRadius: '6px' }}>
                                {!isMobileOnly &&
                                    <Grid.Row style={{ display: 'flex', padding: '14px 14px 0 14px', color: 'var(--grey-100)', fontWeight: 'bold', fontSize: '14pt' }}>
                                        <FontAwesomeIcon icon={faPlus} style={{ alignSelf: 'center', marginRight: '5px' }} />
                                        <span className='no-themed'>{i18n.t("Champs supplémentaires")}</span>
                                    </Grid.Row>}
                                {this.renderExtraFields(availableExtraFields)}
                            </Grid>}
                    </>
                    : <div style={{ display: 'flex', height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', padding: '10px' }}>
                        {areCustomFieldsAvailable ?
                            <>
                                <FontAwesomeIcon icon={faPenField} size='5x' />
                                <h3 style={{ margin: '5px 0 2px 0' }}>{i18n.t("Aucun champs personnalisé à afficher")}</h3>
                                <p style={{ margin: 0 }}>{i18n.t("La liaison de champs se fait depuis les paramètres du projet dans l'onglet « Modèle de données »")}</p>
                            </>
                            : <>
                                <FontAwesomeIcon icon={faLock} size='5x' />
                                <h3>{i18n.t("Upgradez votre licence afin de débloquer les champs personnalisés")}</h3>
                            </>}
                    </div>}
            </div>
        );
    }

    componentDidMount = () => this.loadCustomFields()
    componentDidUpdate = (prevProps) => {
        if (JSON.stringify(prevProps.properties) !== JSON.stringify(this.props.properties))
            this.loadCustomFields();
    }

    loadCustomFields = () => {
        const projectCustomFields = this.props.project?.projectCustomFields || [];
        let customFields = this.props.customFields;
        if (this.props.project?.id && this.props.projectsCustomFields[this.props.project.id])
            customFields = [
                ...customFields,
                ...this.props.projectsCustomFields[this.props.project.id].filter(cf => ( // Pour les champs des autres users, on ne garde que ceux liés au projet ou dont la donnée a été précisée sur l'élément
                    this.props.project?.projectCustomFields?.find(pcf => pcf.customFieldId === cf.id)
                    || this.props.properties.customFields?.[cf.id] !== undefined // Undefined important
                ))
            ];
        const extraFields = customFields
            .filter(cf => cf.category === this.props.category && cf.type !== 'formula' && !projectCustomFields.find(pcf => pcf.customFieldId === cf.id) && this.props.properties.customFields?.[cf.id] !== undefined) //! Undefined important car peut être ''
            .filter(cf => this.props.category !== 'Arbre' || !(
                (this.props.properties.isEmpty && !cf.forEmpty) ||
                (this.props.properties.isDead && !cf.forDead) ||
                (this.props.properties.isStump && !cf.forStump)
            ))
            .map(cf => cf.id);
        this.setState({ customFields, extraFields: [...extraFields, ''] });
    }

    renderExtraFields = (availableExtraFields) => {
        const { extraFields } = this.state;
        const fields = [];

        for (let i = 0; i < extraFields.length; i++) {
            const name = extraFields[i] || '';
            const extraFieldsOptions = availableExtraFields.map(x => ({ text: x.label, value: x.id }));

            if (extraFieldsOptions.length) {
                const customField = availableExtraFields.find(aef => aef.id === name);
                availableExtraFields = availableExtraFields.filter(aef => aef !== customField);

                const value = customField && this.props.properties.customFields && this.props.properties.customFields[customField.id]
                    ? this.props.properties.customFields[customField.id]
                    : null;

                fields.push(
                    <>
                        {isMobileOnly && i > 0 && <Divider style={{ width: '100%', marginTop: 0 }} />}
                        <Grid.Column computer={8} table={8} mobile={16} style={{ paddingTop: !i ? '14px' : 0, paddingBottom: '14px' }}>
                            <Form.Field>
                                <InputWithAction icon='trash' active={name} onClick={() => this.removeExtraField(i)}>
                                    <Select
                                        placeholder={i18n.t("Sélectionnez un champ")} style={{ width: '100%' }}
                                        name='customFieldId' options={extraFieldsOptions} value={name}
                                        search={FormattersUtil.searchList} selectOnBlur={false} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                                        onChange={(_, { value }) => this.handleNameChange(i, value)}
                                    />
                                </InputWithAction>
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column computer={8} table={8} mobile={16} style={{ paddingTop: !i ? '14px' : 0, paddingBottom: '14px' }}>
                            {!customField || ['text', 'url', 'number'].includes(customField.type) ?
                                <Form.Field
                                    control={Input} type={customField?.type || 'text'} min={customField?.min} max={customField?.max} step={customField?.step || 1} placeholder={i18n.t("Indiquez une valeur")}
                                    value={value || ''} onChange={(_, { value }) => this.handleValueChange(i, name, value)} disabled={!name}
                                />
                                : customField?.type === 'boolean' ?
                                    <Form.Field
                                        control={Select} placeholder={i18n.t("Sélectionnez une valeur")} selectOnBlur={false} clearable
                                        options={[{ text: i18n.t("Oui"), value: 'true' }, { text: i18n.t("Non"), value: 'false' }]} value={value || ''}
                                        onChange={(_, { value }) => this.handleValueChange(i, name, value)}
                                    />
                                    : customField.type === 'date' ?
                                        <DatePicker
                                            name={customField.id} value={value || ''} style={{ marginTop: '5px' }}
                                            minDate={customField.minDate || ''} maxDate={customField.maxDate || ''}
                                            onChange={this.props.handleCustomFieldChange}
                                        />
                                        :
                                        <Form.Field
                                            control={Select} placeholder={i18n.t("Sélectionnez une valeur")}
                                            clearable selection={customField.isMultiple} search={FormattersUtil.searchList} selectOnBlur={false} multiple={customField.isMultiple} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                                            options={customField.dropdownCustomFieldValues.map(dcfv => ({ text: dcfv.label, value: String(dcfv.id) }))} value={value ? (!customField.isMultiple ? value : value.split(',')) : (customField.isMultiple ? [] : '')}
                                            onChange={(_, { value }) => this.handleValueChange(i, name, value)}
                                        />}
                        </Grid.Column>
                    </>
                );
            }
        }
        return fields;
    }

    handleNameChange = (index, value) => {
        const { extraFields } = this.state;
        if (extraFields[index]) this.props.deleteCustomField(extraFields[index]);

        this.setState(({ extraFields }) => {
            extraFields[index] = value;
            return extraFields;
        });
    }

    handleValueChange = (index, name, value) => {
        const { extraFields } = this.state;
        this.props.handleCustomFieldChange(null, { name, value });
        if (index === extraFields.length - 1)
            this.setState(({ extraFields }) => {
                extraFields.push('');
                return extraFields;
            });
    }

    removeExtraField = (index) => {
        this.props.deleteCustomField(String(this.state.extraFields[index]));
        this.setState(({ extraFields }) => {
            extraFields.splice(index, 1);
            if (index === extraFields.length) extraFields.push('');
            return { extraFields };
        });
    }
}

const mapStateToProps = (state) => {
    return {
        activeOrganization: state.activeOrganization,
        projectCollaborators: state.projectCollaborators,
        project: state.project,
        customFields: state.project ? [...state.customFields, ...state.organizationCustomFields || []] : state.customFields,
        projectsCustomFields: state.projectsCustomFields,
        defaultFieldCategories: state.defaultFieldCategories
    };
};

export default connect(mapStateToProps)(ExtraStep);