import React, { Component } from 'react';
// Composants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faImage } from '@fortawesome/pro-solid-svg-icons';
import Masonry from 'react-masonry-css';
import { Modal, Button, Header } from 'semantic-ui-react';
// Librairies
import { connect } from 'react-redux';
import tinycolor from 'tinycolor2';
import i18n from '../../../locales/i18n';
import { isMobileOnly } from 'react-device-detect';

const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    700: 2,
    500: 1
}

class ExportPdfForm extends Component {
    state = {
        addOverview: false,
        colorless: false,
        addPhotos: false,
        photosId: [],
        photos: [],
        isLoading: false
    };

    render() {
        const { addOverview, colorless, addPhotos, photosId, photos, isLoading } = this.state;

        return (
            <Modal open size='small' style={{ margin: isMobileOnly && 0, height: isMobileOnly && '100%', width: isMobileOnly && '100%' }} onClose={this.props.hideExportPdfForm}>
                <Modal.Header>{i18n.t("Export PDF")}</Modal.Header>
                <Modal.Content style={{ display: isMobileOnly && 'flex', flexDirection: 'column', height: isMobileOnly && '85%' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <label style={{ width: !isMobileOnly ? '150px' : '100px' }}>Aperçu carte</label>
                        <Button.Group style={{ flex: 1 }} widths={2}>
                            <Button color={addOverview ? 'blue' : 'grey'} onClick={() => this.handleToggleChange('addOverview', true)}>{i18n.t("Avec")}</Button>
                            <Button color={!addOverview ? 'blue' : 'grey'} onClick={() => this.handleToggleChange('addOverview', false)}>{i18n.t("Sans")}</Button>
                        </Button.Group>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                        <label style={{ width: !isMobileOnly ? '150px' : '100px' }}>Couleurs</label>
                        <Button.Group style={{ flex: 1 }} widths={2}>
                            <Button color={!colorless ? 'blue' : 'grey'} onClick={() => this.handleToggleChange('colorless', false)}>{i18n.t("Couleurs")}</Button>
                            <Button color={colorless ? 'blue' : 'grey'} onClick={() => this.handleToggleChange('colorless', true)}>{i18n.t("Noir et blanc")}</Button>
                        </Button.Group>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <label style={{ width: !isMobileOnly ? '150px' : '100px' }}>Photos</label>
                        <Button.Group style={{ flex: 1 }} widths={2}>
                            <Button color={addPhotos ? 'blue' : 'grey'} disabled={!photos.length} onClick={() => this.handleToggleChange('addPhotos', true)}>{i18n.t("Avec")}</Button>
                            <Button color={!addPhotos ? 'blue' : 'grey'} disabled={!photos.length} onClick={() => this.handleToggleChange('addPhotos', false)}>{i18n.t("Sans")}</Button>
                        </Button.Group>
                    </div>
                    <Header as='h4' style={{ marginTop: '15px' }}>{`${i18n.t("Sélectionnez les photos")} (${addPhotos ? photosId.length : 0}/${photos.length})`}</Header>
                    <div style={{ display: 'flex', flex: 1, minHeight: 0, height: !isMobileOnly && '40vh', maxHeight: !isMobileOnly && '40vh' }}>
                        <div style={{ flex: 1, overflowY: 'auto', position: 'relative', border: '1px solid var(--grey-100)', borderRadius: '10px', padding: '10px' }}>
                            {this.renderPhotos()}
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='grey' disabled={isLoading} onClick={this.props.hideExportPdfForm}>{i18n.t("Annuler")}</Button>
                    <Button
                        color='blue' disabled={isLoading} loading={isLoading}
                        onClick={() => {
                            this.setState({ isLoading: true });
                            this.props.exportElementAsPdf(photosId, addOverview, colorless)
                        }}
                    >
                        {i18n.t("Exporter")}
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }

    componentDidMount = () => {
        if (this.props.photosGalleries?.length && this.props.layer) {
            const elementId = this.props.layer[0].feature.id;
            const photos = this.props.photosGalleries.filter(photo => photo.elementId === elementId);
            this.setState({ photos });
        }
    }

    renderPhotos = () => {
        const { addPhotos, photos } = this.state;
        let selectionColor = tinycolor(document.body.style.getPropertyValue('--primary-100'));
        selectionColor.setAlpha(this.props.isDarkTheme ? .2 : .4);
        return (
            photos.length ?
                <Masonry breakpointCols={breakpointColumnsObj} className='my-masonry-grid' columnClassName='my-masonry-grid_column'>
                    {this.state.photos.map(photo => {
                        const isSelected = this.state.photosId.includes(photo.id);
                        return (
                            <div style={{ position: 'relative', cursor: addPhotos ? 'pointer' : 'not-allowed', opacity: !addPhotos && '0.1' }} onClick={() => this.togglePhotoSelection(photo.id)}>
                                {(isSelected && addPhotos) &&
                                    <div style={{
                                        position: 'absolute', top: 0, bottom: 0, height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',
                                        backgroundColor: isSelected && selectionColor, borderRadius: '10px'
                                    }}
                                    >
                                        <FontAwesomeIcon icon={faCheckCircle} size='2x' style={{ color: 'var(--primary-100)' }} />
                                    </div>}
                                <img src={photo.url} alt='img' style={{ display: 'block', borderRadius: '10px', width: '100%', height: '100%', userDrag: 'none', userSelect: 'none' }} />
                            </div>
                        );
                    })}
                </Masonry>
                : <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', pointerEvents: 'none' }}>
                    <FontAwesomeIcon icon={faImage} size='6x' style={{ marginTop: 'auto' }} />
                    <h4 style={{ marginBottom: 'auto' }}>{i18n.t("Aucun résultat trouvé")}</h4>
                </div>
        );
    }

    handleToggleChange = (name, checked) => this.setState({ [name]: checked });
    togglePhotoSelection = (photoId) => {
        if (this.state.addPhotos) {
            let photosId = [...this.state.photosId];
            this.setState({ photosId: photosId.includes(photoId) ? photosId.filter(pi => pi !== photoId) : [...photosId, photoId] });
        }
    }
}

const mapStateToProps = (state) => {
    return {
        layer: state.layer,
        photosGalleries: state.photosGalleries,
        isDarkTheme: state.isDarkTheme
    };
}

export default connect(mapStateToProps)(ExportPdfForm);