import React, { Component } from 'react';
// Librairies
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-labels/src/chartjs-plugin-labels';
import { connect } from 'react-redux';
// Librairies
import i18n from '../../locales/i18n';

class GendersChart extends Component {
    state = {
        data: null,
        options: null
    }

    render() {
        return (
            <div className='modal-content'>
                <div className='modal-content-body'>
                    {this.state.data && this.state.options
                        ? <Bar data={this.state.data} options={this.state.options} />
                        : i18n.t("Aucune donnée trouvée")}
                </div>
            </div>
        );
    }

    componentDidMount = () => {
        this.loadData();
        if (this.props.webSocketHubs.elementsHub) {
            this.props.webSocketHubs.elementsHub.on('SendElements', this.loadData);
            this.props.webSocketHubs.elementsHub.on('UpdateElements', this.loadData);
            this.props.webSocketHubs.elementsHub.on('RemoveElements', this.loadData);
        }
    }

    loadData = () => {
        // On récupert la liste des genres
        const genders = {};

        this.props.essences.forEach(essence => {
            const gender = essence.gender;
            if (!genders[gender]) genders[gender] = 0;
        });

        // On compte le nombre d'arbres par genre
        const layers = this.props.layer.getLayers();
        for (const layerName in layers) {
            const layer = layers[layerName];
            const gender = this.props.essences.find(x => x.id === layer.feature.properties.essenceId)?.gender;
            if (gender) genders[gender]++;
        }

        const data = Object.keys(genders)
            .map(gender => ({ label: gender, value: genders[gender] }))
            .filter(gender => gender.value)
            .sort((a, b) => b.value - a.value);

        if (data.length > 0) {
            const themeColor = this.props.isDarkTheme ? 'white' : 'black';
            this.setState({
                data: {
                    labels: data.map(gender => gender.label),
                    datasets: [{
                        label: i18n.t("Nombre"),
                        backgroundColor: 'rgba(0, 128, 0, 0.6)',
                        borderColor: 'rgba(0, 0, 0, 1)',
                        borderWidth: 1,
                        data: data.map(gender => gender.value),
                    }]
                },
                options: {
                    maintainAspectRatio: false,
                    legend: { display: false },
                    scales: {
                        xAxes: [{
                            ticks: { fontColor: themeColor },
                            scaleLabel: {
                                display: true,
                                labelString: 'Genres',
                                fontColor: themeColor
                            }
                        }],
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                min: 0,
                                fontColor: themeColor
                            },
                            scaleLabel: {
                                display: true,
                                labelString: i18n.t("Nombre d'arbres"),
                                fontColor: themeColor
                            }
                        }]
                    },
                    layout: {
                        padding: { top: 20 }
                    },
                    plugins: {
                        labels: {
                            render: 'value',
                            showZero: false,
                            fontColor: themeColor
                        }
                    }
                }
            });
        }
    }
}

const mapStateToProps = (state) => {
    return {
        essences: state.essences,
        isDarkTheme: state.isDarkTheme,
        webSocketHubs: state.webSocketHubs
    };
};

export default connect(mapStateToProps)(GendersChart);