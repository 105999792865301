import React, { Component } from 'react';
// Composants
import FilesGallery from './FilesGallery';
// Librairies
import { connect } from 'react-redux';
import { setProject, setProjects, setUserProjects } from '../../actionCreators/projectsActions';
// Services
import FileInfosService from '../../services/FileInfosService';
// Utils
import ProjectsUtil from '../../utils/ProjectsUtil';

class ProjectFilesGallery extends Component {
    state = {
        isLoading: true,
        project: null
    }

    render() {
        const { isLoading, project } = this.state;
        return (<FilesGallery isLoading={isLoading} files={project?.fileInfos} project={project} setFiles={this.setFiles} />);
    }

    componentDidMount = () => this.loadFiles();
    componentDidUpdate = () => {
        const projectInProps = ProjectsUtil.getBaseProject(this.props.projectToEdit?.id, this.props.projectToEdit?.path, this.props.projects);
        if (JSON.stringify(this.state.project?.fileInfos) !== JSON.stringify(this.props.projectToEdit?.fileInfos)
            || (projectInProps && JSON.stringify(ProjectsUtil.getBaseProject(this.props.projectToEdit?.id, this.props.projectToEdit?.path, this.props.projects).fileInfos) !== JSON.stringify(this.props.projectToEdit?.fileInfos)))
            this.loadFiles();
    }

    loadFiles = async () => {
        let { projectToEdit } = this.props;
        if (projectToEdit) {
            if (!projectToEdit.fileInfos) {
                const files = await FileInfosService.getProjectFileInfos(projectToEdit.id, 'file', true);
                if (files?.length > 0) projectToEdit.fileInfos = files;
                else projectToEdit.fileInfos = [];
            }

            this.setState({ isLoading: false, project: projectToEdit }, () => ProjectsUtil.updateProjectsInProps(this.state.project, this.props.projects, this.props.formulas, this.props.project, this.props.setProjects, this.props.setProject));
        }
    }

    setFiles = (files) => {
        let { project } = this.state;
        project.fileInfos = files;
        if (this.state.project.id === this.props.project?.id) this.props.setProject(project);
        this.setState({ project }, () => ProjectsUtil.updateProjectsInProps(this.state.project, this.props.projects, this.props.formulas, this.props.project, this.props.setProjects, this.props.setProject));
    }
}

const mapStateToProps = (state) => {
    return {
        project: state.project,
        projects: state.projects,
        formulas: state.formulas,
        isOnline: state.isOnline
    };
};

const mapDispatchToProps = {
    setProject,
    setProjects,
    setUserProjects
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectFilesGallery);